import React, { useEffect, useState } from "react";
import { AES } from "crypto-js";

const Storage = ({ phoneNo, password }) => {
  const [encryptedPhoneNo, setEncryptedPhoneNo] = useState("");
  const [encryptedPassword, setEncryptedPassword] = useState("");

  useEffect(() => {
    if (phoneNo && password) {
      const encryptedPhoneNumber = AES.encrypt(
        phoneNo,
        "encryptionSecret"
      ).toString();
      const encryptedPwd = AES.encrypt(password, "encryptionSecret").toString();

      setEncryptedPhoneNo(encryptedPhoneNumber);
      setEncryptedPassword(encryptedPwd);
    }
  }, [phoneNo, password]);

  useEffect(() => {
    if (encryptedPhoneNo && encryptedPassword) {
      localStorage.setItem("phone", encryptedPhoneNo);
      localStorage.setItem("password", encryptedPassword);
    }
  }, [encryptedPhoneNo, encryptedPassword]);

  return null;
};

export default Storage;
