import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { Typography, Button, Modal } from '@mui/material';
import AddressPopup from '../../../Components/Modal/Modal';
import ApiHandler from '../../../Api/Apihandler';
import { styled } from '@mui/material/styles';
import { List, ListItem, ListItemText } from '@mui/material';
import './Address.css';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import HeaderMain from '../../../Components/Header/Header';
import Footer from '../../../Components/Footer/Footer';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

export default function () {
	const [modal, setModal] = useState(false);
	const [addressList, setAddressList] = useState([]);
	const [addressId, setAddressId] = useState();
	const [selectedAddress, setSelectedAddress] = useState(null);
	const [addNew, setAddNew] = useState(false);

	const handleEditClick = (address) => {
		setSelectedAddress(address);
		setModal(true);
	};

	const Item = styled(Paper)(({ theme }) => ({
		backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
		...theme.typography.body2,
		// padding: theme.spacing(1),
		textAlign: 'center',
		color: theme.palette.text.secondary,
	}));
	const id = localStorage.getItem('loggedIn');

	useEffect(() => {
		_getAddress(id);
	}, [id]);

	const _getAddress = async () => {
		const response = await ApiHandler.getAddress(id);
		const data = response.data.data;
		try {
			setAddressList(data);
		} catch (error) {
			console.log('get address erros', error);
		}
	};

	const _deleteAddress = async (id) => {
		const response = await ApiHandler.deleteAddress(id);
		try {
			alert('Address deleted successfully');
			_getAddress();
		} catch (error) {
			console.log('addressdeletecatch', error);
		}
	};

	return (
		<Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
			<Grid
				container
				rowSpacing={3}
				// columnSpacing={{ xs: 1, sm: 2, md: 3 }}
				sx={{ py: 2, my: 5 }}
				lg={8}
			>
				<Grid
					item
					xs={12}
					sx={{ display: 'flex', justifyContent: 'space-around', pb: 3 }}
				>
					<Typography
						gutterBottom
						variant="h4"
						component="div"
						fontWeight={'700'}
						fontSize={{
							lg: '20',
							md: '20px',
							sm: '18px',
							xs: '18px',
						}}
						color={'#484848'}
						textAlign={'start'}
					>
						My Addresses
					</Typography>
					<Button
						variant="contained"
						sx={{
							textAlign: 'center',
							height: '44px',
							width: {
								xl: '200px',
								lg: '200px',
								md: '150px',
								sm: '150px',
								xs: '150px',
							},
							fontWeight: 600,
							fontSize: '12px',
							lineHeight: '18px',
							letterSpacing: '.01em',
							borderRadius: '12px !important',
							boxShadow: 'none !important',
							my: 2,
							background: ' linear-gradient(to right, #141e30, #243b55)',
						}}
						onClick={() => {
							setModal(true);
							setAddNew(true);
						}}
					>
						+Add New
					</Button>
				</Grid>

				<Modal open={modal} onClose={() => setModal(false)}>
					<div onClick={() => setModal(false)}>
						<AddressPopup
							button={() => setModal(false)}
							addNew={addNew}
							setModal={setModal}
							_getAddress={_getAddress}
						/>
					</div>
				</Modal>

				{addressList.profile_address &&
					addressList.profile_address.map((add, i) => {
						return (
							<Grid
								item
								xs={12}
								sm={12}
								md={6}
								lg={6}
								xl={6}
								sx={{
									pl: 0,
								}}
								key={add.id}
							>
								<Card
									sx={{
										mx: 1,
										height: '100%',
										boxShadow: 'none',
										border: '0.1px solid #eee',
									}}
								>
									<CardContent>
										<Typography
											gutterBottom
											variant="h4"
											component="div"
											fontWeight={'700'}
											fontSize={{
												lg: '15px',
												md: '15px',
												sm: '15px',
												xs: '15px',
											}}
											color={'#484848'}
											textAlign={'start'}
											// pt={2}
											sx={{ display: 'flex' }}
										>
											<HomeOutlinedIcon sx={{ pb: 0.5, mr: 1 }} />{' '}
											{add.type_of_address}
										</Typography>
										<Typography
											gutterBottom
											variant="h4"
											component="div"
											fontWeight={'700'}
											fontSize={{
												lg: '13px',
												md: '13px',
												sm: '13px',
												xs: '13px',
											}}
											color={'#595959'}
											textAlign={'start'}
											sx={{ overflow: 'auto' }}
											pb={2}
										>
											{add.door_no} {','}&nbsp;
											{add.street} {};
										</Typography>

										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												justifyContent: 'start',
											}}
										>
											<Button
												variant="contained"
												size="small"
												sx={{
													textAlign: 'center',
													height: '36px',
													width: {
														xl: '100px',
														lg: '100px',
														md: '100px',
														sm: '100px',
														xs: '100px',
													},
													fontWeight: 600,
													fontSize: '12px',
													lineHeight: '18px',
													letterSpacing: '.01em',
													borderRadius: '5px !important',
													boxShadow: 'none !important',
													m: 1,
													background:
														'linear-gradient(to top, #09203f 0%, #537895 100%)',
												}}
												onClick={() => handleEditClick(add)}
											>
												Edit
											</Button>
											<Button
												variant="contained"
												size="small"
												sx={{
													textAlign: 'center',
													height: '36px',
													width: {
														xl: '100px',
														lg: '100px',
														md: '100px',
														sm: '100px',
														xs: '100px',
													},
													fontWeight: 600,
													fontSize: '12px',
													lineHeight: '18px',
													letterSpacing: '.01em',
													borderRadius: '5px !important',
													boxShadow: 'none !important',
													m: 1,
													background: '#D62121',
													'&:hover': {
														background: '#D62121',
													},
												}}
												onClick={() => {
													_deleteAddress(add.id);
												}}
											>
												Delete
											</Button>
										</div>
									</CardContent>
								</Card>
							</Grid>
						);
					})}
				{selectedAddress && (
					<div>
						<Modal
							open={modal}
							onClose={() => {
								setModal(false);
								setSelectedAddress(null);
							}}
						>
							<div
								onClick={() => {
									setModal(false);
									setSelectedAddress(null);
								}}
							>
								<AddressPopup
									button={() => setModal(false)}
									typeofAddress={selectedAddress.type_of_address}
									adddoorNo={selectedAddress.door_no}
									addStreet={selectedAddress.street}
									addArea={selectedAddress.area}
									addCity={selectedAddress.city}
									addLandmark={selectedAddress.landmark}
									addPincode={selectedAddress.pincode}
									addId={selectedAddress.id}
									setModal={setModal}
									_getAddress={_getAddress}
									setSelectedAddress={setSelectedAddress}
								/>
							</div>
						</Modal>
					</div>
				)}
			</Grid>
		</Box>
	);
}
