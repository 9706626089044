import React, { useRef, useState } from 'react';
import './Panvarification.css';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import kyc from '../../../Assets/Images/PAN_VERIFICATION.png';
import { Typography, InputLabel, TextField, Button } from '@mui/material';
// import pancard from "../../../assets/Images/pancard.jpeg";
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import HeaderMain from '../../../Components/Header/Header';
import Footer from '../../../Components/Footer/Footer';
import { ToastContainer, toast } from 'react-toastify';
import { useEffect } from 'react';
import ApiHandler from '../../../Api/Apihandler';
import EditIcon from '@mui/icons-material/Edit';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useNavigate } from 'react-router';
import verified from '../../../Assets/Images/verify-user-01.png';
import Loader from '../../../Constants/Loader';
import cloud_computing from '../../../Assets/Images/Icons/cloud-computing.png';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

export default function ProfileVarification() {
	const [error, setError] = useState(null);
	const [profileData, setProfileData] = useState([]);
	const [pageLoader, setPageLoader] = useState(false);
	const [pandetail, setPanDetail] = useState({
		panNum: '',
		panName: '',
		panDOB: '',
	});

	const [profileId, setProfileId] = useState('');
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const [basePic, setBasePic] = useState(null);
	const [selectedFile, setSelectedFile] = useState(null);

	const handleFileChange = (event) => {
		const file = event.target.files[0];

		if (file && file.name === selectedFile?.name) {
			document.getElementById('image-file-input').value = '';
		} else {
			setSelectedFile(file);
			upload();

			const reader = new FileReader();
			reader.onloadend = () => {
				let baseData = reader.result;
				const base64Data = baseData.split(',')[1];
				setBasePic(base64Data);
			};

			reader.readAsDataURL(file);
		}
	};

	const handleDelete = () => {
		setSelectedFile(null);
		document.getElementById('image-file-input').value = '';
	};

	const loginId = localStorage.getItem('loggedIn');
	useEffect(() => {
		setPageLoader(true);
		setProfileId(loginId);
		_getProfile(loginId);
	}, []);
	const intprofile = parseInt(profileId);
	const _getProfile = async () => {
		try {
			const response = await ApiHandler.getProfile(loginId);
			const data = response.data.data;
			setProfileData(data);
			setTimeout(() => {
				setPageLoader(false);
			}, 3000);
		} catch (error) {
			console.log('profile Data error', error);
		}
	};

	const dobDate = new Date(pandetail.panDOB);
	const formattedDate = dobDate.toLocaleDateString('en-GB', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
	});
	console.log('Formatted Date:', formattedDate.replace(/\//g, '-'));

	// const StringDob = String(dob).slice(4, 15);
	const navigate = useNavigate();

	const pan_verify = async () => {
		const data = {
			profile_id: intprofile,
			name_as_per_pan: pandetail.panName.toUpperCase(),
			pan_number: 'ABCPV1234D',
			dob: formattedDate.replace(/\//g, '-'),
			pan_image: basePic,
		};

		const response = await ApiHandler.verifyPan(data);
		try {
			if (response.data.status === 201) {
				alert('already verified');
				navigate('/about/profile');
			} else if (response.data.status === 200) {
				alert('Verification Successful!');
				navigate('/about/profile');
			} else {
				alert('Verification Successful!');
				navigate('/about/profile');
				console.log('pan_response_else', response);
			}
		} catch (error) {
			console.log('pan verify catch error', error);
		}
	};

	const pan_Name = () => {
		toast.error('Enter valid name as per PAN');
	};
	const panNumber = () => {
		toast.error('Enter Valid PAN number');
	};
	const panDateofBirth = () => {
		toast.error('Enter DOB as per PAN');
	};

	const toastStyle = {
		fontSize: '13px',
	};
	const upload = () => {
		toast.success('Your PAN Image upload successfully!', {
			style: toastStyle,
		});
	};
	const uploadError = () => {
		toast.error('Please Upload PAN Image', {
			style: toastStyle,
		});
	};

	const validation = () => {
		if (
			pandetail.panNum.length === 0
			// !pandetail.panNum.match("[A-Z]{5}[0-9]{4}[A-Z]{1}")
		) {
			setError(1);
			panNumber();
		} else if (
			pandetail.panName.length === 0 ||
			!pandetail.panName.match('[A-Zs]+')
		) {
			setError(2);
			pan_Name();
		} else if (pandetail.panDOB.length === 0) {
			setError(3);
			panDateofBirth();
		} else if (!selectedFile) {
			setError(4);
			uploadError();
		} else {
			pan_verify();
		}
	};

	const Item = styled(Paper)(({ theme }) => ({
		backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
		...theme.typography.body2,
		// padding: theme.spacing(1),
		textAlign: 'center',
		color: theme.palette.text.secondary,
	}));

	return (
		<>
			{profileData[0] && profileData[0].verified_profile === true ? (
				<div className="profile_verified">
					<img
						src={verified}
						alt={'verified_yourself_image'}
						className="verified_img"
					/>
					<Typography
						gutterBottom
						variant="h6"
						component="div"
						fontSize={{
							lg: '18px',
							md: '18px',
							sm: '18px',
							xs: '13px',
						}}
						my={2}
						fontWeight={'600px'}
						color={'green'}
						textAlign={'center'}
					>
						You Identity Has Been Verified.
					</Typography>
				</div>
			) : (
				<Box
					sx={{
						width: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignContent: 'center',
						textAlign: 'center',
						py: 4,
						backgroundColor: '#EDF2F3',
					}}
				>
					<Grid
						container
						xs={12}
						sm={12}
						lg={11}
						xl={11}
						md={11}
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignContent: 'center',
							textAlign: 'center',
						}}
					>
						<Grid
							item
							sx={{
								display: { lg: 'flex', xl: 'flex', md: 'flex' },
								justifyContent: 'center',
								alignContent: 'center',
								// boxShadow: { lg: 1, xl: 1, md: 1, sm: 'none', xs: 'none' },
								backgroundColor: 'white',
								// bgcolor: (theme) =>
								// 	theme.palette.mode === 'dark' ? '#101010' : '#fff',
								// color: (theme) =>
								// 	theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',

								borderRadius: { lg: 2, xl: 2, md: 2, sm: 'none', xs: 'none' },
								textAlign: 'center',
							}}
						>
							<Grid
								item
								xs={12}
								sm={12}
								md={5}
								xl={5}
								lg={5}
								sx={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
									alignContent: 'center',
									textAlign: 'center',
								}}
							>
								<Item
									sx={{
										boxShadow: 'none',
										m: 2,
										// pt: { lg: 5, xl: 5, md: 5 },
										backgroundColor: 'white',
									}}
								>
									<img
										src={kyc}
										alt="kyc"
										style={{
											width: '300px',
											height: '300px',
											background: 'none',
										}}
									/>
									<Typography
										gutterBottom
										variant="h4"
										component="div"
										fontWeight={'700'}
										letterSpacing={0.5}
										lineHeight={1.5}
										fontSize={{
											lg: '15px',
											md: '15px',
											sm: '12px',
											xs: '11px',
										}}
										color={'#484848'}
										// textAlign={'center'}
										pt={4}
										display={'flex'}
										justifyContent={'ceter'}
										alignContent={'center'}
									>
										Don't missout on the benefits of our platform login now for
										a richer, more rewarding experience
									</Typography>{' '}
								</Item>
							</Grid>
							<Grid
								item
								xs={12}
								sm={12}
								md={5}
								xl={5}
								lg={5}
								sx={{
									boxShadow: 'none',
									m: 2,
									backgroundColor: 'white',
									pl: { lg: 5, xl: 5, md: 5 },
								}}
							>
								<Typography
									gutterBottom
									variant="h5"
									component="div"
									fontSize={{
										lg: '16px',
										md: '16px',
										sm: '14px',
										xs: '14px',
									}}
									fontWeight={700}
									color={'ffff'}
									textAlign={'center'}
									pb={2}
								>
									Verify Your Identity
								</Typography>{' '}
								{/* <img src={pancard} style={{ width: '300px', height: '200px' }} /> */}
								{/* <div className="pan_img_container">
									<div className="pan_img">
										<img src={panImage || pancard} alt="pancard" />
									</div>
									<div className="pan_edit">
										<input
											type="file"
											accept="image/*"
											onChange={handleProfileFileChange}
											ref={fileInputRef}
											style={{ display: 'none' }}
										/>
										<EditIcon
											className="pan_edit_icon"
											onClick={() => {
												handleOpen();
												fileInputRef.current.click();
											}}
										/>
									</div>
								</div> */}
								{error === 4 ? (
									<div>
										<Box
											pt={1}
											pb={3}
											px={3}
											// border="1px dashed #000000"
											// borderRadius={2}
											textAlign="center"
											sx={{
												display: 'flex',
												flexDirection: 'column',
												justifyContent: 'center',
												alignContent: 'center',
												background: '#f3f3fe',
												mt: 1,
											}}
										>
											<InputLabel
												sx={{
													fontSize: {
														xs: '13px',
														md: '13px',
														lg: '13px',
														fontWeight: 700,
													},
													pb: 1,
													textAlign: 'start',
												}}
											>
												Upload Image <span style={{ color: 'red' }}>*</span>
											</InputLabel>
											<div
												style={{
													border: '1px dashed #000000',
													borderRadius: '3px',
													background: 'white',
													padding: '15px',
												}}
											>
												<div style={{ textAlign: 'center' }}>
													<img
														src={cloud_computing}
														style={{
															height: '40px',
															width: '40px',
														}}
													/>
												</div>

												<input
													type="file"
													accept="image/*"
													onChange={handleFileChange}
													style={{ display: 'none' }}
													id="image-file-input"
												/>
												<label htmlFor="image-file-input">
													<Button
														sx={{
															fontWeight: 600,
															fontSize: '12px',
															lineHeight: '18px',
															letterSpacing: '.01em',
															textTransform: 'none',
														}}
														component="span"
													>
														Upload a File
													</Button>
												</label>
											</div>
											{selectedFile && (
												<div
													style={{
														display: 'flex',
														justifyContent: 'space-between',
														marginTop: '6px',
														background: '#dae6ff',
													}}
												>
													<Typography variant="subtitle1">
														{selectedFile.name}
													</Typography>
													<DeleteOutlineIcon
														sx={{
															color: 'red',
															cursor: 'pointer',
														}}
														onClick={handleDelete}
													/>
												</div>
											)}
										</Box>
									</div>
								) : (
									<div>
										<Box
											pt={1}
											pb={3}
											px={3}
											// border="1px dashed #000000"
											// borderRadius={2}
											textAlign="center"
											sx={{
												display: 'flex',
												flexDirection: 'column',
												justifyContent: 'center',
												alignContent: 'center',
												background: '#f3f3fe',
												mt: 1,
											}}
										>
											<InputLabel
												sx={{
													fontSize: {
														xs: '13px',
														md: '13px',
														lg: '13px',
														fontWeight: 700,
													},
													pb: 1,
													textAlign: 'start',
												}}
											>
												Upload Image <span style={{ color: 'red' }}>*</span>
											</InputLabel>
											<div
												style={{
													border: '1px dashed #000000',
													borderRadius: '3px',
													background: 'white',
													padding: '15px',
												}}
											>
												<div style={{ textAlign: 'center' }}>
													<img
														src={cloud_computing}
														style={{
															height: '40px',
															width: '40px',
														}}
													/>
												</div>

												<input
													type="file"
													accept="image/*"
													onChange={handleFileChange}
													style={{ display: 'none' }}
													id="image-file-input"
												/>
												<label htmlFor="image-file-input">
													<Button
														sx={{
															fontWeight: 600,
															fontSize: '12px',
															lineHeight: '18px',
															letterSpacing: '.01em',
															textTransform: 'none',
														}}
														component="span"
													>
														Upload a File
													</Button>
												</label>
											</div>
											{selectedFile && (
												<div
													style={{
														display: 'flex',
														justifyContent: 'space-between',
														marginTop: '6px',
														background: '#dae6ff',
													}}
												>
													<Typography variant="subtitle1">
														{selectedFile.name}
													</Typography>
													<DeleteOutlineIcon
														sx={{
															color: 'red',
															cursor: 'pointer',
														}}
														onClick={handleDelete}
													/>
												</div>
											)}
										</Box>
									</div>
								)}
								<InputLabel
									sx={{
										fontSize: {
											xs: '13px',
											md: '13px',
											lg: '13px',
											fontWeight: 700,
										},
										my: 1,
										pt: 4,
										textAlign: 'start',
									}}
								>
									PAN Number <span style={{ color: 'red' }}>*</span>
								</InputLabel>
								{error === 1 ? (
									<div>
										<TextField
											autoComplete="off"
											fullWidth
											label=""
											id="fullWidth"
											placeholder="Enter your pan number"
											size="small"
											value={pandetail.panNum}
											onChange={(e) => {
												const input = e.target.value;
												const sanIniInput = input.replace(/[^A-Za-z0-9]/g, '');
												if (sanIniInput.length <= 10) {
													setPanDetail({
														...pandetail,
														panNum: sanIniInput.toUpperCase(),
													});
												}
											}}
										/>
									</div>
								) : (
									<TextField
										autoComplete="off"
										fullWidth
										label=""
										id="fullWidth"
										placeholder="Enter your pan number"
										size="small"
										value={pandetail.panNum}
										onChange={(e) => {
											const input = e.target.value;
											const sanIniInput = input.replace(/[^A-Za-z0-9]/g, '');
											if (sanIniInput.length <= 10) {
												setPanDetail({
													...pandetail,
													panNum: sanIniInput.toUpperCase(),
												});
											}
										}}
									/>
								)}
								<InputLabel
									sx={{
										fontSize: {
											xs: '13px',
											md: '13px',
											lg: '13px',
											fontWeight: 700,
										},
										my: 1,
										textAlign: 'start',
									}}
								>
									Enter Your Name As Per PAN
									<span style={{ color: 'red' }}>*</span>
								</InputLabel>
								{error === 2 ? (
									<div>
										<TextField
											fullWidth
											autoComplete="off"
											label=""
											size="small"
											variant="outlined"
											placeholder="Enter name as per pan"
											value={pandetail.panName}
											onChange={(e) => {
												const input = e.target.value;
												const correctInput = input.replace(/[^A-Za-z\s]/g, '');
												if (!correctInput.startsWith(' ')) {
													setPanDetail({
														...pandetail,
														panName: correctInput,
													});
												}
											}}
										/>
									</div>
								) : (
									<TextField
										fullWidth
										autoComplete="off"
										id="outlined-basic"
										label=""
										size="small"
										variant="outlined"
										placeholder="Enter name as per pan"
										value={pandetail.panName}
										onChange={(e) => {
											const input = e.target.value;
											const correctInput = input.replace(/[^A-Za-z\s]/g, '');
											if (!correctInput.startsWith(' ')) {
												setPanDetail({
													...pandetail,
													panName: correctInput,
												});
											}
										}}
									/>
								)}
								<InputLabel
									sx={{
										fontSize: {
											xs: '13px',
											md: '13px',
											lg: '13px',
											fontWeight: 700,
										},
										textAlign: 'start',
										mt: 1,
									}}
								>
									Select Your DOB
									<span style={{ color: 'red' }}>*</span>
								</InputLabel>
								{error === 3 ? (
									<div>
										<LocalizationProvider dateAdapter={AdapterDayjs}>
											<DemoContainer components={['DesktopDatePicker']}>
												<DesktopDatePicker
													label=""
													value={pandetail.panDOB}
													onChange={(newValue) => {
														setPanDetail({
															...pandetail,
															panDOB: newValue,
														});
													}}
													slotProps={{
														textField: { size: 'small' },
													}}
													sx={{
														width: '100%',
														'& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
															{
																borderColor: '#CBCDCB',
															},
													}}
												/>
											</DemoContainer>
										</LocalizationProvider>
									</div>
								) : (
									<div>
										<LocalizationProvider dateAdapter={AdapterDayjs}>
											<DemoContainer
												components={['DatePicker', 'DatePicker', 'DatePicker']}
											>
												<DatePicker
													views={['year', 'month', 'day']}
													value={pandetail.panDOB}
													onChange={(newValue) => {
														setPanDetail({
															...pandetail,
															panDOB: newValue,
														});
													}}
													format="YYYY/MM/DD"
													sx={{
														width: '100%',
														'& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
															{
																borderColor: '#CBCDCB',
															},
													}}
												/>
											</DemoContainer>
										</LocalizationProvider>
									</div>
								)}
								<Button
									variant="contained"
									size="medium"
									sx={{
										textAlign: 'center',
										height: '44px',
										width: {
											xl: '250px',
											lg: '250px',
											md: '200px',
											sm: '200px',
											xs: '200px',
										},
										fontWeight: 600,
										fontSize: '12px',
										lineHeight: '18px',
										letterSpacing: '.01em',
										borderRadius: '12px !important',
										boxShadow: 'none !important',
										background: ' linear-gradient(to right, #141e30, #243b55)',
										marginTop: 6,
										marginBottom: 4,
									}}
									onClick={() => {
										validation();
									}}
								>
									{' '}
									SUBMIT
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Box>
			)}
		</>
	);
}
