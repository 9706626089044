import React, { useState, useEffect, Fragment } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { getComaPrice } from '../../Constants/Comaprice';
import Modal from '@mui/material/Modal';
import Slider from '@mui/material/Slider';
import CloseIcon from '@mui/icons-material/Close';

const EmiCalculatorModal = ({
	showEmiModal,
	setShowEmiModal,
	details,
	exRoomPrice,
}) => {
	const [interest, setInterest] = useState(9.5);
	const [tenure, setTenure] = useState(60);
	const [emi, setEmi] = useState(0);
	const [loan, setLoan] = useState(0);
	const [downPayment, setdownPayment] = useState(100000);
	const [totalPayAmount, setTotalPayAmount] = useState(0);
	const [chgloanAmount, setChgloanAmount] = useState(0);

	useEffect(() => {
		calculateLoan();
	}, [downPayment, loan, tenure, interest, exRoomPrice, chgloanAmount]);

	const calculateLoan = () => {
		let ConvertedInterest = interest / 100;
		let loanAmount = exRoomPrice - downPayment;
		setLoan(loanAmount);
		let newDownPay = exRoomPrice - loanAmount;
		setdownPayment(newDownPay);
		let totalInterest = (ConvertedInterest / 12) * tenure;
		let loanAmtWithInt = loanAmount * totalInterest + loanAmount;
		let emiAmount = loanAmtWithInt / tenure;
		setTotalPayAmount(loanAmtWithInt);
		setEmi(emiAmount);
	};

	const handleClose = () => {
		setShowEmiModal(false);
	};

	const handleSliderChange = (event, values) => {
		setdownPayment(values);
	};
	const handleSliderInterestChange = (event, values) => {
		setInterest(values);
	};
	const handleSliderTensureChange = (event, emiTensure) => {
		setTenure(emiTensure);
	};

	return (
		<Modal open={showEmiModal} close={handleClose}>
			<Box className="modal">
				<Grid className="emi_calculator">
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							paddingBottom: '8px',
						}}
					>
						<Typography
							id="modal-modal-title"
							variant="h6"
							component="h2"
							color={'#00D09C'}
							fontWeight={'700'}
							textAlign={'center'}
							sx={{
								pt: {
									xl: '20px',
									lg: '20px',
									md: '20px',
									sm: '20px',
									xs: '20px',
								},
							}}
						>
							Plan Your Loans Smarter With Our EMI Calculator
						</Typography>
						<CloseIcon
							onClick={handleClose}
							sx={{
								width: '34px',
								height: '34px',
								borderRadius: '50%',
								backgroundColor: '#F7F7F7',
								cursor: 'pointer',
								padding: 1,
								mt: 1,
								pt: { xl: '10px', lg: '10px' },
							}}
						/>
					</div>

					<Box sx={{ flexGrow: 1 }}>
						<Grid container>
							<Grid item xs={6}>
								<Typography
									gutterBottom
									variant="h6"
									component="div"
									fontWeight={'600'}
									fontSize={{
										lg: '18px',
										md: '18px',
										sm: '18px',
										xs: '18px',
									}}
									color={'#000'}
									sx={{ my: 2 }}
									textAlign={'center'}
								>
									{details.length > 0
										? getComaPrice(totalPayAmount.toFixed(0)) + ' /-'
										: null}
								</Typography>
								<Typography
									gutterBottom
									variant="h6"
									component="div"
									fontWeight={'600'}
									fontSize={{
										lg: '13px',
										md: '13px',
										sm: '13px',
										xs: '13px',
									}}
									color={'#484848'}
									sx={{ my: 1.5 }}
									textAlign={'center'}
								>
									Total Amount <br />
									(Principal + Interest)
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography
									gutterBottom
									variant="h6"
									component="div"
									fontWeight={'600'}
									fontSize={{
										lg: '18px',
										md: '18px',
										sm: '18px',
										xs: '18px',
									}}
									color={'#000'}
									sx={{ my: 2 }}
									textAlign={'center'}
								>
									{details && details.length > 0
										? details[0].price === 0
											? 'TBA'
											: getComaPrice(emi.toFixed(0)) + ' /- '
										: null}
								</Typography>
								<Typography
									gutterBottom
									variant="h6"
									component="div"
									fontWeight={'600'}
									fontSize={{
										lg: '13px',
										md: '13px',
										sm: '13px',
										xs: '13px',
									}}
									color={'#484848'}
									sx={{ my: 1.5 }}
									textAlign={'center'}
								>
									Indicative EMI <br />
									(Per/Months)
								</Typography>
							</Grid>
							<div
								style={{
									marginTop: 25,
									width: '100%',
									height: 0.5,
									backgroundColor: 'grey',
									alignSelf: 'center',
								}}
							></div>
							<Box sx={{ flexGrow: 1 }}>
								<Grid container>
									<Grid item xs={6}>
										<Typography
											gutterBottom
											variant="h4"
											component="div"
											fontWeight={'600'}
											fontSize={{
												lg: '14px',
												md: '14px',
												sm: '14px',
												xs: '14px',
											}}
											color={'#484848'}
											sx={{ my: 2 }}
											textAlign={'start'}
										>
											Down Payment
										</Typography>
									</Grid>
									<Grid item xs={6}>
										<Typography
											gutterBottom
											variant="h4"
											component="div"
											fontWeight={'600'}
											fontSize={{
												lg: '14px',
												md: '14px',
												sm: '14px',
												xs: '14px',
											}}
											color={'#000'}
											sx={{ my: 2 }}
											textAlign={'end'}
										>
											{details.length > 0
												? details[0].price === 0
													? 'TBA'
													: getComaPrice(downPayment) + ' /- '
												: null}
											{}
										</Typography>
									</Grid>
								</Grid>
							</Box>
							<Slider
								size="medium"
								// defaultValue={100000}
								aria-label="Small"
								valueLabelDisplay="auto"
								valueLabelFormat={getComaPrice}
								min={0}
								max={200000}
								step={1}
								onChange={handleSliderChange}
								value={
									details && details.length > 0
										? details[0].price === 0
											? 0
											: downPayment
										: null
								}
							/>
							<Box sx={{ flexGrow: 1 }}>
								<Grid container>
									<Grid item xs={6}>
										<Typography
											gutterBottom
											variant="h4"
											component="div"
											fontWeight={'600'}
											fontSize={{
												lg: '14px',
												md: '14px',
												sm: '14px',
												xs: '14px',
											}}
											color={'#484848'}
											sx={{ my: 2 }}
											textAlign={'start'}
										>
											Loan Amount
										</Typography>
									</Grid>
									<Grid item xs={6}>
										<Typography
											gutterBottom
											variant="h4"
											component="div"
											fontWeight={'600'}
											fontSize={{
												lg: '14px',
												md: '14px',
												sm: '14px',
												xs: '14px',
											}}
											color={'#000'}
											sx={{ my: 2 }}
											textAlign={'end'}
										>
											{details.length > 0
												? details[0].price === 0
													? 'TBA'
													: getComaPrice(loan.toFixed(0)) + ' /- '
												: null}
										</Typography>
									</Grid>
								</Grid>
							</Box>
							<Slider
								size="medium"
								aria-label="Small"
								defaultValue={loan}
								valueLabelDisplay="auto"
								valueLabelFormat={(loan) => getComaPrice(loan.toFixed(0))}
								min={0}
								max={3000000}
								onchange={setLoan}
								value={loan}
							/>
							<Box sx={{ flexGrow: 1 }}>
								<Grid container>
									<Grid item xs={6}>
										<Typography
											gutterBottom
											variant="h4"
											component="div"
											fontWeight={'600'}
											fontSize={{
												lg: '14px',
												md: '14px',
												sm: '14px',
												xs: '14px',
											}}
											color={'#484848'}
											sx={{ my: 2 }}
											textAlign={'start'}
										>
											Tenure
										</Typography>
									</Grid>
									<Grid item xs={6}>
										<Typography
											gutterBottom
											variant="h4"
											component="div"
											fontWeight={'600'}
											fontSize={{
												lg: '14px',
												md: '14px',
												sm: '14px',
												xs: '14px',
											}}
											color={'#000'}
											sx={{ my: 2 }}
											textAlign={'end'}
										>
											{details.length > 0
												? details[0].price === 0
													? 'TBA'
													: getComaPrice(tenure).replace(/₹/g, '') + ' / Months'
												: null}
										</Typography>
									</Grid>
								</Grid>
							</Box>
							<Slider
								size="medium"
								aria-label="Small"
								valueLabelDisplay="auto"
								min={1}
								max={240}
								onChange={handleSliderTensureChange}
								step={1}
								value={
									details.length > 0
										? details[0].price === 0
											? 0
											: tenure
										: null
								}
							/>
							<Box sx={{ flexGrow: 1 }}>
								<Grid container>
									<Grid item xs={6}>
										<Typography
											gutterBottom
											variant="h4"
											component="div"
											fontWeight={'600'}
											fontSize={{
												lg: '14px',
												md: '14px',
												sm: '14px',
												xs: '14px',
											}}
											color={'#484848'}
											sx={{ my: 2 }}
											textAlign={'start'}
										>
											Interest
										</Typography>
									</Grid>
									<Grid item xs={6}>
										<Typography
											gutterBottom
											variant="h4"
											component="div"
											fontWeight={'600'}
											fontSize={{
												lg: '14px',
												md: '14px',
												sm: '14px',
												xs: '14px',
											}}
											color={'#000'}
											sx={{ my: 2 }}
											textAlign={'end'}
										>
											{details.length > 0
												? details[0].price === 0
													? 'TBA'
													: interest &&
													  interest.toLocaleString(undefined, {
															minimumFractionDigits: 1,
															maximumFractionDigits: 1,
													  }) + '%'
												: null}
										</Typography>
									</Grid>
								</Grid>
							</Box>
							<Slider
								size="medium"
								aria-label="Small"
								valueLabelDisplay="auto"
								min={0}
								max={30}
								onChange={handleSliderInterestChange}
								step={0.1}
								value={
									details.length > 0
										? details[0].price === 0
											? 0
											: interest
										: null
								}
							/>
						</Grid>
					</Box>
				</Grid>
			</Box>
		</Modal>
	);
};

export default EmiCalculatorModal;
