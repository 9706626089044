import React, { useState, useEffect } from 'react';
import './Header.css';
import Signup from '../../Authentication/Signup/Signup';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import ApiHandler from '../../Api/Apihandler';
import AccountCircleIcon from '@mui/icons-material/AccountCircleOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { AES, enc } from 'crypto-js';
import { useNavigate } from 'react-router-dom';
import Category from '../Category/Category';
import useMediaQuery from '@mui/material/useMediaQuery';
import ContactlessOutlinedIcon from '@mui/icons-material/ContactlessOutlined';
import { Container, InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useLocation } from 'react-router-dom';

const HeaderMain = () => {
	const [modal, setModal] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');
	const [isSearchIconVisible, setSearchIconVisible] = useState(true);
	const location  = useLocation();
	const navigate = useNavigate();
	const isSmallScreen = useMediaQuery('(max-width:1000px');
	const profileId = localStorage.getItem('loggedIn');

	useEffect(() => {
		const encryptedPhoneNo = localStorage.getItem('phone');
		const encryptedPassword = localStorage.getItem('password');
		if (encryptedPhoneNo && encryptedPassword) {
			const decryptedPhone = AES.decrypt(
				encryptedPhoneNo,
				'encryptionSecret'
			).toString(enc.Utf8);
			const decryptedPwd = AES.decrypt(
				encryptedPassword,
				'encryptionSecret'
			).toString(enc.Utf8);
			Checkuser(decryptedPhone);
		}
		if (location.pathname === '/search') {
			setSearchIconVisible(false);
		}
	}, []);

	const handleChange = (event) => {
		const searchTerms = event.target.value;
		setSearchTerm(searchTerms);
		searchItem(searchTerms);
	};

	const navigationSearch = () => {
		if (location.pathname !== '/search') {
			const searchterm = searchTerm;
			navigate('/search', { state: { searchterm } });
		}
	};

	const navigationSearchSmallScreen = () => {
		navigate('/search');
	};

	const searchItem = async (term) => {
		if (term.length >= 4) {
			const data = {
				profile_id: localStorage.getItem('loggedIn')
					? localStorage.getItem('loggedIn')
					: 0,
				keyword: term,
			};
			try {
				const response = await ApiHandler.searchFilter(data);
				const data = response.data.data;
				// setSearchData(data);
				navigate('/search', { state: { data } });
			} catch (error) {
				console.log('Header Search Data Error',error);
			}
		} else {
			navigate('/search', { state: { term } });
		}
	};


	const Checkuser = async (phoneNumber) => {
		const data = { phone: phoneNumber };
		try {
			const response = await ApiHandler.checkUser(data);
			if (response.data.status === 404) {
				localStorage.removeItem('password');
				localStorage.removeItem('phone');
				localStorage.removeItem('loggedIn');
			}
		} catch (error) {
			console.error('Error checking user Header:', error);
		}
	};

	const openModal = () => {
		setModal(true);
	};

	const closeModal = () => {
		setModal(false);
	};

	return (
		<div
			style={{
				zIndex: 999,
				top: 0,
				left: 0,
				position: 'sticky',
				borderBottom: '1px solid rgba(221,229,235,.5)',
			}}
		>
			<div className="nav_bar_container">
				<div className="nav_bar">
					<div
						className="nav_bar_img"
						style={{ cursor: 'pointer' }}
						onClick={() => navigate('/')}
					>
						<img
							src="https://kalyanimotors.com/static/media/kalyani_dark.5bca4adb5ec73478b2ce.png"
							alt="kalyani_log"
						/>
					</div>

					{isSmallScreen ? null : <Category />}
					{isSmallScreen ? null : (
						<div>
							<TextField
								id="search"
								type="search"
								value={searchTerm}
								variant="outlined"
								autoComplete="off"
								placeholder="What are you looking for?"
								onChange={handleChange}
								onClick={navigationSearch}
								sx={{
									width: 350,
									height: 52,
									color: '#000',
									background: '#E5EAFF',
									borderRadius: 10,
									padding: '0px 16px',
									'& .MuiOutlinedInput-root': {
										'& fieldset': {
											borderColor: 'transparent',
										},
										'&:hover fieldset': {
											borderColor: 'transparent',
										},
										'&.Mui-focused fieldset': {
											borderColor: 'transparent',
										},
									},
								}}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<SearchIcon style={{ color: '#9195A2' }} />
										</InputAdornment>
									),
								}}
							/>
						</div>
					)}

					{isSmallScreen ? null : (
						<div className="header-icons">
							{profileId ? (
								<div>
									<div className="login_icons">
										<ShoppingCartOutlinedIcon
											sx={{ mx: 2, width: 26, height: 26 }}
											onClick={() => navigate('/cart')}
										/>
										<ContactlessOutlinedIcon
											sx={{ mx: 2, width: 26, height: 26 }}
											onClick={() => navigate('/payments/payment_request')}
										/>
										<AccountCircleIcon
											color="gray"
											sx={{ mx: 2, width: 26, height: 26 }}
											onClick={() => navigate('/about/profile')}
										/>
									</div>
								</div>
							) : (
								<div>
									<Button
										variant="contained"
										className="nav_bar_btn"
										onClick={openModal}
										sx={{
											cursor: 'pointer',
											width: '100px',
											fontSize: '13px !important',
											height: '35px',
											padding: '10px',
											mx: 2,
											borderRadius: '10px !important',
											background: '#EB232A',
											boxShadow: 'none ! important',
											marginRight: '5px',
											'&:hover': {
												background: '#EB232A',
												boxShadow: 'none ! important',
											},
										}}
									>
										SIGN IN
									</Button>
								</div>
							)}
							{/* {login.status !== 200 && (
               
              )} */}
						</div>
					)}

					{/* <div className="icon_shadow" onClick={toggleMenuBar}>
						<MenuIcon style={{ fontSize: '30px', cursor: 'pointer' }} />
					</div> */}
					{isSmallScreen && (
						<div style={{ marginRight: 10 }}>
							{profileId ? (
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-evenly',
										alignContent: 'center',
										columnGap: 30,
									}}
								>
									{isSearchIconVisible && (
										<SearchIcon
											onClick={navigationSearchSmallScreen}
											sx={{ width: 22, height: 22, cursor: 'pointer' }}
										/>
									)}
									<ShoppingCartOutlinedIcon
										sx={{ width: 22, height: 22, cursor: 'pointer' }}
										onClick={() => navigate('/cart')}
									/>
									<ContactlessOutlinedIcon
										sx={{ width: 22, height: 22, cursor: 'pointer' }}
										onClick={() => navigate('/payment_request')}
									/>
									<AccountCircleIcon
										color="gray"
										sx={{ width: 22, height: 22, cursor: 'pointer' }}
										onClick={() => navigate('/about/profile')}
									/>
								</div>
							) : (
								<div
									style={{
										display: 'flex',
										alignContent: 'center',
										alignItems: 'center',
									}}
								>
									{isSearchIconVisible && (
										<SearchIcon
											onClick={navigationSearchSmallScreen}
											sx={{ width: 22, height: 22, cursor: 'pointer' }}
										/>
									)}
									<Button
										variant="contained"
										className="nav_bar_li"
										onClick={openModal}
										sx={{
											cursor: 'pointer',
											width: '100px',
											fontSize: '13px !important',
											height: '35px',
											padding: '10px',
											mx: 2,
											borderRadius: '10px !important',
											background: '#EB232A',
											boxShadow: 'none ! important',
											marginRight: '5px',
											'&:hover': {
												background: '#EB232A',
												boxShadow: 'none ! important',
											},
										}}
									>
										SIGN IN
									</Button>
								</div>
							)}
						</div>
					)}
				</div>
				{isSmallScreen && <Category />}
				{/* <Drawer anchor="right" open={menuBar} onClose={() => setMenuBar(false)}>
          <div
            style={{
              width: 200,
              height: "100%",
              overflowY: "hidden",
              backgroundColor: "#fff",
            }}
          >
            {menuBar && (
              <>
                {isSmallScreen ? <Category /> : null}
                <div className="mobile_login_btn">
                  {profileId ? (
                    <div className="login_icons">
                      <ShoppingCartOutlinedIcon
                        sx={{ width: 26, height: 26, cursor: "pointer" }}
                        onClick={() => navigate("/cart")}
                      />
                      <ContactlessOutlinedIcon
                        sx={{ width: 26, height: 26, cursor: "pointer" }}
                        onClick={() => navigate("/payment_request")}
                      />
                      <AccountCircleIcon
                        color="gray"
                        sx={{ mx: 2, width: 26, height: 26, cursor: "pointer" }}
                        onClick={() => navigate("/about/profile")}
                      />
                    </div>
                  ) : (
                    <Button
                      variant="contained"
                      className="nav_bar_li"
                      onClick={openModal}
                      sx={{ cursor: "pointer" }}
                    >
                      SIGN IN
                    </Button>
                  )}
                </div>
              </>
            )}
          </div>
        </Drawer> */}

				<div>
					<Modal
						open={modal}
						onClose={closeModal}
						className="address_modal_container"
					>
						<div>
							<Signup button={closeModal} />
						</div>
					</Modal>
				</div>
			</div>
		</div>
	);
};

export default HeaderMain;
