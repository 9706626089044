import React, { useState, useEffect } from 'react';
import HeaderMain from '../../../Components/Header/Header';
import Footer from '../../../Components/Footer/Footer';
import {
	Typography,
	Button,
	Box,
	Grid,
	Skeleton,
	styled,
	Paper,
} from '@mui/material';
import './Yourorder.css';
import ApiHandler from '../../../Api/Apihandler';
import Loader from '../../../Constants/Loader';
import EmptyCartImage from '../../../Assets/Images/no-item-found-illustration-01.png';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import OfflineBoltOutlinedIcon from '@mui/icons-material/OfflineBoltOutlined';
import { getComaPrice } from '../../../Constants/Comaprice';
// import Profile from "./../Profile/Profile";
import { useNavigate } from 'react-router';
import { useMediaQuery } from '@mui/material';
import { formatDate } from '../../../Constants/FormatDateTime';

export default function YourOrders() {
	const [activeBtn, setActiveBtn] = useState(true);
	const [orders, setOrders] = useState([]);
	const [serviceOrders, setServiceOrders] = useState([]);
	const [loader, setLoader] = useState(false);

	const isSmallScreen = useMediaQuery('(max-width:767px)');

	const Item = styled(Paper)(({ theme }) => ({
		backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
		...theme.typography.body2,
		padding: theme.spacing(1),
	}));

	const loginId = localStorage.getItem('loggedIn');

	useEffect(() => {
		setLoader(true);
		// _getwishList(loginId);
		_getOrderList();
		_getServiceOrderList();
	}, []);

	// const _getwishList = async () => {
	//   const response = await ApiHandler.getWishList(loginId);
	//   const data = response.data.data;
	//   try {
	//     setTimeout(() => {
	//       setLoader(false);
	//       setPageLoader(false);
	//     }, 2000);
	//     setOrders(data);
	//   } catch (error) {
	//     console.log("orders error", error);
	//   }
	// };

	const _getOrderList = async () => {
		const loginId = localStorage.getItem('loggedIn');
		const type = 'NewCar';
		const response = await ApiHandler.getOrderList(loginId, type);
		const data = response.data.data;
		try {
			setTimeout(() => {
				setLoader(false);
			}, 2000);
			setOrders(data);
		} catch (error) {
			console.log('orders error', error);
		}
	};
	const _getServiceOrderList = async () => {
		const loginId = localStorage.getItem('loggedIn');
		const type = 'Service';
		const response = await ApiHandler.getOrderList(loginId, type);
		const data = response.data.data;
		try {
			setTimeout(() => {
				setLoader(false);
			}, 2000);
			setServiceOrders(data);
		} catch (error) {
			console.log('service Orders error', error);
		}
	};

	console.log('service_Order', serviceOrders);

	const navigate = useNavigate();
	const handlenavigate = (id, title) => {
		const Replace = title.replace(/ /g, '_');
		// navigate(`/car/${id}/${Replace}`);
	};

	return (
		<div>
			<Typography
				gutterBottom
				variant="h4"
				component="div"
				fontWeight={'700'}
				fontSize={{ lg: '20px', md: '20px', sm: '20px', xs: '18px' }}
				color={'#484848'}
				sx={{ my: 2 }}
				textAlign={'center'}
				lineHeight={2}
			>
				Your Orders
			</Typography>

			<Box sx={{ flexGrow: 1 }}>
				<Grid container>
					<Grid
						xs={12}
						sx={{
							display: 'flex',
							justifyContent: 'space-evenly',
							alignItems: 'center',
							my: 3,
						}}
					>
						<Button
							variant="contained"
							className={
								activeBtn === true ? 'yourOrdersBtnActive' : 'yourOrdersBtn'
							}
							size="small"
							sx={{
								textAlign: 'center',
								height: '44px',
								width: isSmallScreen ? '150px' : '200px',
								fontWeight: 600,
								fontSize: '12px',
								lineHeight: '18px',
								letterSpacing: '.01em',
								borderRadius: '12px !important',
								boxShadow: 'none !important',
								textTransform: 'none',
							}}
							onClick={() => setActiveBtn(true)}
						>
							Booked Cars
						</Button>
						<Button
							variant="contained"
							className={
								activeBtn === false ? 'yourOrdersBtnActive' : 'yourOrdersBtn'
							}
							size="small"
							sx={{
								textAlign: 'center',
								height: '44px',
								width: isSmallScreen ? '150px' : '200px',
								fontWeight: 600,
								fontSize: '12px',
								lineHeight: '18px',
								letterSpacing: '.01em',
								borderRadius: '12px !important',
								boxShadow: 'none !important',
								textTransform: 'none',
							}}
							onClick={() => setActiveBtn(false)}
						>
							Booked Services
						</Button>
					</Grid>
				</Grid>
			</Box>

			<Box sx={{ width: '100%', my: 2 }} className="wishLists">
				<Grid
					container
					rowSpacing={3}
					//columnSpacing={{ xs: 1, sm: 2, md: 3 }}
					sx={{ display: 'flex', justifyContent: 'center' }}
				>
					{loader ? (
						activeBtn === true ? (
							Array.from({ length: orders.length }).map((index) => (
								<Grid key={index} item xs={12} sm={12} md={12} lg={12} p={1}>
									<Skeleton variant="rectangular" width="100%" height="305px" />
									<Skeleton variant="rounded" style={{ marginTop: '10px' }} />
									<Skeleton
										variant="rounded"
										width="90%"
										style={{ marginTop: '10px' }}
									/>
									<Skeleton
										variant="rounded"
										width="60%"
										style={{ marginTop: '10px' }}
									/>
								</Grid>
							))
						) : (
							Array.from({ length: serviceOrders.length }).map((index) => (
								<Grid key={index} item xs={12} sm={12} md={12} lg={12} p={1}>
									<Skeleton variant="rectangular" width="100%" height="305px" />
									<Skeleton variant="rounded" style={{ marginTop: '10px' }} />
									<Skeleton
										variant="rounded"
										width="90%"
										style={{ marginTop: '10px' }}
									/>
									<Skeleton
										variant="rounded"
										width="60%"
										style={{ marginTop: '10px' }}
									/>
								</Grid>
							))
						)
					) : activeBtn === true ? (
						orders.length === 0 ? (
							<div className="emptyorders_img_container">
								<img src={EmptyCartImage} className="emptyorders_img" />
								<Typography
									gutterBottom
									variant="h4"
									component="div"
									fontWeight={'600'}
									fontSize={{
										lg: '16px',
										md: '16px',
										sm: '16px',
										xs: '13px',
									}}
									color={'#282828'}
									textAlign={'center'}
								>
									You have not done any booking yet.
								</Typography>
							</div>
						) : (
							orders.map((item) => (
								<Grid
									item
									xs={11}
									lg={10}
									xl={10}
									md={11}
									sm={11}
									className="orderscar_card"
									style={{ cursor: 'pointer' }}
									// onClick={item.booking_status === "Booked" ? "" : navigate()}
									// onClick={() => {
									// 	navigate('/payment_failure');
									// 	navigate('/payment_success');
									// }}
								>
									{/* <Item> */}
									<Grid
										item
										xs={12}
										sx={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'space-evenly',
											alignContent: 'center',
											alignItems: 'center',
											border: {
												xl: '0.1px solid #eee',
												lg: '0.1px solid #eee',
											},
											borderRadius: 5,
											// boxShadow: {
											//   md: 1,
											//   sm: 1,
											//   xs: 1,
											//   lg: "none",
											//   xl: "none",
											// },
										}}
										onClick={() =>
											handlenavigate(
												item.item_description.id,
												item.item_description.subtitle
											)
										}
									>
										<Grid
											item
											xs={6}
											sx={{
												display: 'flex',
												flexDirection: 'row',
												justifyContent: 'space-evenly',
												alignContent: 'center',
												alignItems: 'center',
											}}
										>
											<div className="orders_img_container">
												<img
													alt="complex"
													src={
														item.item_description &&
														item.item_description.images &&
														item.item_description.images[0]
													}
													className="cart_img_choose"
													height={'100%'}
													width={'100%'}
												/>
											</div>
										</Grid>
										<Grid item xs={6}>
											<Typography
												gutterBottom
												variant="h4"
												component="div"
												fontWeight={'600'}
												fontSize={{
													lg: '16px',
													md: '16px',
													sm: '16px',
													xs: '13px',
												}}
												color={'#282828'}
												textAlign={'start'}
												sx={{ my: 1, mx: 2 }}
											>
												{item.item_description.title}
											</Typography>
											<Typography
												color="text.secondary"
												gutterBottom
												variant="h4"
												component="div"
												fontWeight={'600'}
												fontSize={{
													lg: '12px',
													md: '12px',
													sm: '12px',
													xs: '11px',
												}}
												sx={{ my: 1, mx: 2 }}
												textAlign={'start'}
											>
												({item.item_description.color})
											</Typography>
											<Typography
												gutterBottom
												variant="h4"
												component="div"
												fontWeight="700"
												color="#000"
												fontSize={{
													lg: '12px',
													md: '12px',
													sm: '12px',
													xs: '12px',
												}}
												sx={{ my: 1, mx: 2, textAlign: 'start' }}
											>
												Payment Status:{' '}
												<span
													style={{
														color:
															item.booking_id.payment_status === 'Success'
																? '#006D00'
																: item.booking_id.payment_status === 'Pending'
																? 'orange'
																: 'red',
														fontSize: '12px',
													}}
												>
													{item.booking_id.payment_status === 'Success'
														? 'Paid'
														: item.booking_id.payment_status}
												</span>
											</Typography>
											<Typography
												gutterBottom
												variant="h4"
												component="div"
												fontWeight="700"
												color="#000"
												fontSize={{
													lg: '12px',
													md: '12px',
													sm: '12px',
													xs: '12px',
												}}
												sx={{ my: 1, mx: 2, textAlign: 'start' }}
											>
												Booking Status:{' '}
												<span
													style={{
														color:
															item.booking_status === 'Booked'
																? '#006D00'
																: item.booking_status === 'Pending'
																? 'orange'
																: 'red',
														fontSize: '12px',
													}}
												>
													{item.booking_status}
												</span>
											</Typography>
											<Typography
												color="#000"
												gutterBottom
												variant="h4"
												component="div"
												fontWeight={'600'}
												fontSize={{
													lg: '12px',
													md: '12px',
													sm: '12px',
													xs: '12px',
												}}
												sx={{ mt: 2, mx: 2 }}
												textAlign={'start'}
											>
												Booked At:{' '}
												<span
													style={{
														color: '#666666',
														fontSize: '12px',
													}}
												>
													{formatDate(item.booking_id.booked_at)}
												</span>
											</Typography>
										</Grid>
									</Grid>
									{/* </Item> */}
								</Grid>
							))
						)
					) : serviceOrders.length === 0 ? (
						<div className="emptyorders_img_container">
							<img src={EmptyCartImage} className="emptyorders_img" />
							<Typography
								gutterBottom
								variant="h4"
								component="div"
								fontWeight={'600'}
								fontSize={{
									lg: '16px',
									md: '16px',
									sm: '16px',
									xs: '13px',
								}}
								color={'#282828'}
								textAlign={'center'}
							>
								You have not done any booking yet.
							</Typography>
						</div>
					) : (
						serviceOrders.map((item) => (
							<Grid
								item
								xs={11}
								lg={10}
								xl={10}
								md={11}
								sm={11}
								className="orderscar_card"
							>
								<Grid
									item
									xs={12}
									sx={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-evenly',
										alignContent: 'center',
										alignItems: 'center',
										border: {
											xl: '0.1px solid #eee',
											lg: '0.1px solid #eee',
										},
										borderRadius: 5,
									}}
								>
									<Grid
										item
										xs={6}
										sx={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'space-evenly',
											alignContent: 'center',
											alignItems: 'center',
										}}
									>
										<div className="orders_serviceimg_container">
											<img
												alt="complex"
												src={item.item_description.images[0]}
												className="cart_img_choose"
												height={'100%'}
												width={'100%'}
											/>
										</div>
									</Grid>
									<Grid item xs={6}>
										<Typography
											gutterBottom
											variant="h4"
											component="div"
											fontWeight={'600'}
											fontSize={{
												lg: '16px',
												md: '16px',
												sm: '16px',
												xs: '13px',
											}}
											color={'#282828'}
											textAlign={'start'}
											sx={{ my: 1, mx: 2 }}
										>
											{item.item_description.title}
										</Typography>
										<Typography
											color="grey"
											gutterBottom
											variant="h4"
											component="div"
											fontWeight={'500'}
											fontSize={{
												lg: '12px',
												md: '12px',
												sm: '12px',
												xs: '12px',
											}}
											sx={{ my: 1, mx: 2 }}
											textAlign={'start'}
										>
											{item.color}
										</Typography>
										<Typography
											gutterBottom
											variant="h4"
											component="div"
											fontWeight="700"
											fontSize={{
												lg: '13px',
												md: '13px',
												sm: '13px',
												xs: '11px',
											}}
											sx={{ my: 1, mx: 2, textAlign: 'start' }}
										>
											{' '}
											Booking Status :
											<span style={{ color: '#006D00', paddingLeft: '5px' }}>
												{item.booking_status === 'Booked'
													? 'Received'
													: item.booking_status}
											</span>
										</Typography>
									</Grid>
								</Grid>
							</Grid>
						))
					)}
				</Grid>
			</Box>
		</div>
	);
}
