import React, { useState, useEffect } from "react";
import "./Category.css";
import ApiHandler from "../../Api/Apihandler";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Typography } from "@mui/material";
import { useLocation } from "react-router-dom";


const Category = () => {
  const [category, setCategory] = useState([]);
  const navigate = useNavigate();
    useEffect(() => {
    _getCategory();
  }, []);
  const location = useLocation();
  const modifyPath = location.pathname;
  const modifyPathAddress = modifyPath.replace(/\/allcar|\/all|\//g,'')
  const pathModified = modifyPathAddress.replace(/usedcars/gi, 'Used Cars').replace(/services/gi, 'Service');
   
  
  const channelClick = (id, title) => {
    if(id === 1 || id === 2){
      navigate(`/allcar/${title}`, { state: { id } });
    }
    else if (id === 3){
      navigate('/usedcars', { state: { id } })
    }
    else if(id === 4){
      navigate(`/allservices`, { state: { id } })
    }
  };




  const _getCategory = async () => {
    try {
      const response = await ApiHandler.getCategoiries();
      const data = response.data.data;
      if(data){
        setCategory(data);
      }
    } catch (error) {
      console.log("Error in getting category", error);
    }
  };
  category.sort((a, b) => a.id - b.id);
  const isSmallScreen = useMediaQuery("(max-width:1000px)");


  return (
    <div className="category_main">
      {category.map((item) => {
        return (
          <div
            key={item.id}
            className="category_item"
            onClick={() => channelClick(item.id, item.title)}
          >
            {isSmallScreen ? null : null}
            <Typography
              color="#465166"
              fontSize={14}
              textTransform={"uppercase"}
              fontWeight={600}
              className={
               modifyPathAddress === item.title || pathModified === item.title  ? "active_navtitle" : "nav_title"
              }
              style={{
                position: "relative",
              }}
            >
              {item.title}
            </Typography>
          </div>
        );
      })}
    </div>
  );
};

export default Category;

{
  /* <img
                src={`http://superapi.kalyanicrm.com/${item.image}`}
                style={{
                  width: "100%",
                  height: "60px",
                  objectFit: "contain",
                }}
              /> */
}
