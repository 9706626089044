import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import NosearchResult from '../../Assets/Images/not-yet-searched-illustration-01.png';
import './Search.css';
import ApiHandler from '../../Api/Apihandler';
import SearchIcon from '@mui/icons-material/Search';
import No_Result_Found_Img from '../../Assets/Images/no_results_found.png';

import {
	Typography,
	Button,
	Box,
	Grid,
	Skeleton,
	styled,
	Paper,
	TextField,
	useMediaQuery,
	InputAdornment,
} from '@mui/material';

const Search = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { data, term, searchterm } = location.state || {};
	const [searchTerm, setSearchTerm] = useState('');
	const [searchData, setSearchData] = useState([]);

	const handleChange = (event) => {
		const searchTerms = event.target.value;
		setSearchTerm(searchTerms);
		searchItem(searchTerms);
	};

	const searchItem = async (term) => {
		if (term.length >= 4) {
			const data = {
				profile_id: localStorage.getItem('loggedIn')
					? localStorage.getItem('loggedIn')
					: 0,
				keyword: term,
			};

			const response = await ApiHandler.searchFilter(data);
			try {
				const data = response.data.data;
				console.log('search data', data);
				setSearchData(data);
			} catch (error) {
				console.log('Header Search Data Error');
			}
		} else {
			setSearchData([]);
		}
	};

	const isSmallScreen = useMediaQuery('(max-width:1000px');

	useEffect(() => {}, []);

	const handleClick = (id, title) => {
		const Replace = title.replace(/ /g, '_');
		navigate(`/car/${Replace}`, { state: { id, title } });
	};

	return (
		<div>
			{isSmallScreen && (
				<Box
					sx={{
						mt: 4,
						textAlign: 'center',
						display: 'flex',
						justifyContent: 'center',
					}}
				>
					<Grid container xs={12} display={'flex'} justifyContent={'center'}>
						<Grid item xs={10}>
							<TextField
								fullWidth
								id="search"
								type="search"
								value={searchTerm}
								variant="outlined"
								autoComplete="off"
								placeholder="What are you looking for?"
								onChange={handleChange}
								sx={{
									height: 52,
									color: '#000',
									background: '#E5EAFF',
									borderRadius: 10,
									padding: '0px 16px',
									'& .MuiOutlinedInput-root': {
										'& fieldset': {
											borderColor: 'transparent',
										},
										'&:hover fieldset': {
											borderColor: 'transparent',
										},
										'&.Mui-focused fieldset': {
											borderColor: 'transparent',
										},
									},
								}}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<SearchIcon style={{ color: '#9195A2' }} />
										</InputAdornment>
									),
								}}
							/>
						</Grid>
					</Grid>
				</Box>
			)}

			{isSmallScreen && (
				<div>
					{searchTerm === '' ? (
						<div className="NotYetSearched" style={{ marginTop: 30 }}>
							<img
								src={NosearchResult}
								width="220px"
								height="200px"
								alt="search_result"
							/>
							<Typography
								gutterBottom
								variant="h4"
								component="div"
								fontWeight={'700'}
								fontSize={'16px'}
								color={'#484848'}
								textAlign={'center'}
								lineHeight={2}
							>
								Not Yet Searched
							</Typography>
						</div>
					) : searchTerm && searchTerm.length < 4 ? (
						<div className="NotYetSearched" style={{ marginTop: 30 }}>
							<img
								src={NosearchResult}
								width="220px"
								height="200px"
								alt="search_result"
							/>
							<Typography
								gutterBottom
								variant="h4"
								component="div"
								fontWeight={'700'}
								fontSize={'16px'}
								color={'#484848'}
								textAlign={'center'}
								lineHeight={2}
							>
								Enter at least 4 characters to search.
							</Typography>
						</div>
					) : searchData && searchData.length === 0 ? (
						<div className="NotYetSearched" style={{ marginTop: 30 }}>
							<img
								src={No_Result_Found_Img}
								width="150px"
								height="200px"
								alt="no_result"
							/>
							<Typography
								gutterBottom
								variant="h4"
								component="div"
								fontWeight={'700'}
								fontSize={'16px'}
								color={'#484848'}
								textAlign={'center'}
								lineHeight={2}
							>
								Search Item Not Found
							</Typography>
						</div>
					) : (
						<Grid
							container
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							sx={{
								display: 'flex',
								justifyContent: 'center',
								flexDirection: 'column',
								justifyItems: 'center',
								textAlign: 'center',
							}}
						>
							{searchData.map((ele) => (
								<Grid
									item
									xl={12}
									lg={12}
									md={12}
									sm={12}
									xs={12}
									key={ele.id}
									onClick={() => handleClick(ele.id, ele.subtitle)}
									sx={{
										cursor: 'pointer',
										py: 2,
										px: 1,
										my: 1,
										mx: { xl: 10, lg: 10, md: 4, sm: 2, xs: 2 },
										borderRadius: 3,
										boxShadow: 1,
									}}
								>
									<Grid
										container
										xl={12}
										lg={12}
										md={12}
										sm={12}
										xs={12}
										sx={{
											display: 'flex',
											justifyItems: 'center',
											alignItems: 'center',
										}}
									>
										<Grid
											item
											xl={3}
											lg={3}
											md={3}
											sm={4}
											xs={6}
											sx={{ display: 'flex', justifyContent: 'center' }}
										>
											<Grid
												item
												sx={{
													boxShadow: '8px',
													border: '0.1px solid #eee',
													borderRadius: '12px',
													py: { xl: 2.5, lg: 2.5, md: 2, sm: 1.5, xs: 1 },
													width: {
														xl: '160px',
														lg: '160px',
														md: '160px',
														sm: '140px',
														xs: '120px',
													},
													height: {
														xl: '140px',
														lg: '140px',
														md: '140px',
														sm: '120px',
														xs: '100px',
													},
												}}
											>
												<img
													src={ele.images[0]}
													width={'100%'}
													height={'100%'}
													alt={`image-${ele.id}`}
												/>
											</Grid>
										</Grid>
										<Grid
											item
											xl={9}
											lg={9}
											md={9}
											sm={8}
											xs={6}
											textAlign={'start'}
										>
											<Typography
												gutterBottom
												variant="h4"
												component="div"
												fontWeight={'700'}
												fontSize={{
													xl: '16px',
													lg: '16px',
													md: '16px',
													sm: '14px',
													xs: '13px',
												}}
												color={'#145DA0'}
												lineHeight={2}
											>
												{ele.subtitle}
											</Typography>
											<Typography
												gutterBottom
												variant="h4"
												component="div"
												fontWeight={'600'}
												fontSize={'12px'}
												color={'#484848'}
												lineHeight={2}
												display={{
													xl: 'contents',
													lg: 'contents',
													md: 'contents',
													sm: 'none',
													xs: 'none',
												}}
											>
												{ele.description}
											</Typography>
											<Typography
												gutterBottom
												variant="h4"
												component="div"
												fontWeight={'600'}
												fontSize={'10px'}
												color={'#484848'}
												lineHeight={2}
												display={{
													xl: 'none',
													lg: 'none',
													md: 'none',
													sm: 'contents',
													xs: 'contents',
												}}
											>
												{ele.description.slice(0, 100)}...
											</Typography>
										</Grid>
									</Grid>
								</Grid>
							))}
						</Grid>
					)}
				</div>
			)}

			{!isSmallScreen && (
				<div>
					{searchterm === '' || term === '' ? (
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								height: '100vh', // This will make the container full height of the viewport
							}}
						>
							<div style={{ textAlign: 'center' }}>
								<div style={{ width: '280px', height: '250px' }}>
									<img
										src={NosearchResult}
										width="100%"
										height="100%"
										alt="search_result"
									/>
								</div>
								<Typography
									gutterBottom
									variant="h4"
									component="div"
									fontWeight={'700'}
									fontSize={{
										lg: '20px',
										md: '20px',
										sm: '20px',
										xs: '18px',
									}}
									color={'#484848'}
									textAlign={'center'}
									lineHeight={2}
								>
									Not Yet Searched
								</Typography>
							</div>
						</div>
					) : term && term.length < 4 ? (
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								height: '100vh', // This will make the container full height of the viewport
							}}
						>
							<div style={{ textAlign: 'center' }}>
								<div style={{ width: '280px', height: '250px' }}>
									<img
										src={NosearchResult}
										width="100%"
										height="100%"
										alt="search_result"
									/>
								</div>
								<Typography
									gutterBottom
									variant="h4"
									component="div"
									fontWeight={'700'}
									fontSize={{
										lg: '20px',
										md: '20px',
										sm: '20px',
										xs: '18px',
									}}
									color={'#484848'}
									textAlign={'center'}
									lineHeight={2}
								>
									Enter at least 4 characters to search.
								</Typography>
							</div>
						</div>
					) : data && data.length === 0 ? (
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								height: '100vh', // This will make the container full height of the viewport
							}}
						>
							<div>
								<div
									style={{ width: '150px', height: '200px', margin: 'auto' }}
								>
									<img
										src={No_Result_Found_Img}
										width="100%"
										height="100%"
										alt="no_result"
									/>
								</div>

								<Typography
									gutterBottom
									variant="h4"
									component="div"
									fontWeight={'700'}
									fontSize={{ lg: '20px', md: '20px', sm: '20px', xs: '18px' }}
									color={'#484848'}
									textAlign={'center'}
									lineHeight={2}
								>
									Search Item Not Found
								</Typography>
							</div>
						</div>
					) : (
						<Grid
							container
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							sx={{
								display: 'flex',
								justifyContent: 'center',
								flexDirection: 'column',
								justifyItems: 'center',
								textAlign: 'center',
							}}
						>
							{data &&
								data.map((ele) => (
									<Grid
										item
										xl={12}
										lg={12}
										md={12}
										sm={12}
										xs={12}
										key={ele.id}
										onClick={() => handleClick(ele.id, ele.subtitle)}
										sx={{
											cursor: 'pointer',
											py: 2,
											px: 1,
											my: 1,
											mx: { xl: 10, lg: 10, md: 4, sm: 2, xs: 2 },
											borderRadius: 3,
											boxShadow: 1,
										}}
									>
										<Grid
											container
											xl={12}
											lg={12}
											md={12}
											sm={12}
											xs={12}
											sx={{
												display: 'flex',
												justifyItems: 'center',
												alignItems: 'center',
											}}
										>
											<Grid
												item
												xl={3}
												lg={3}
												md={3}
												sm={4}
												xs={6}
												sx={{ display: 'flex', justifyContent: 'center' }}
											>
												<Grid
													item
													sx={{
														boxShadow: '8px',
														border: '0.1px solid #eee',
														borderRadius: '12px',
														py: { xl: 2.5, lg: 2.5, md: 2, sm: 1.5, xs: 1 },
														width: {
															xl: '160px',
															lg: '160px',
															md: '160px',
															sm: '140px',
															xs: '120px',
														},
														height: {
															xl: '140px',
															lg: '140px',
															md: '140px',
															sm: '120px',
															xs: '100px',
														},
													}}
												>
													<img
														src={ele.images[0]}
														width={'100%'}
														height={'100%'}
														alt={`image-${ele.id}`}
													/>
												</Grid>
											</Grid>
											<Grid
												item
												xl={9}
												lg={9}
												md={9}
												sm={8}
												xs={6}
												textAlign={'start'}
											>
												<Typography
													gutterBottom
													variant="h4"
													component="div"
													fontWeight={'700'}
													fontSize={{
														xl: '16px',
														lg: '16px',
														md: '16px',
														sm: '14px',
														xs: '13px',
													}}
													color={'#145DA0'}
													lineHeight={2}
												>
													{ele.subtitle}
												</Typography>
												<Typography
													gutterBottom
													variant="h4"
													component="div"
													fontWeight={'600'}
													fontSize={'12px'}
													color={'#484848'}
													lineHeight={2}
													display={{
														xl: 'contents',
														lg: 'contents',
														md: 'contents',
														sm: 'none',
														xs: 'none',
													}}
												>
													{ele.description}
												</Typography>
												<Typography
													gutterBottom
													variant="h4"
													component="div"
													fontWeight={'600'}
													fontSize={'10px'}
													color={'#484848'}
													lineHeight={2}
													display={{
														xl: 'none',
														lg: 'none',
														md: 'none',
														sm: 'contents',
														xs: 'contents',
													}}
												>
													{ele.description.slice(0, 100)}...
												</Typography>
											</Grid>
										</Grid>
									</Grid>
								))}
						</Grid>
					)}
				</div>
			)}
		</div>
	);
};

export default Search;
