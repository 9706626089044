import React, { useState, useEffect } from 'react';
import './Model.css';
import { useLocation } from 'react-router-dom';
import ApiHandler from '../../Api/Apihandler';
import Carcard from '../../Components/Card/Card';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';

const Model = () => {
	const location = useLocation();
	const { id } = location.state || {};
	const [model, setModel] = useState([]);
	const [loader, setLoader] = useState(true);

	const navigate = useNavigate();

	useEffect(() => {
		const _getModelList = async (id) => {
			try {
				const response = await ApiHandler.getModelList(id);
				const data = response.data.data;
				if (data) {
					setModel(data);
					const timeout = setTimeout(() => {
						setLoader(false);
					}, 1200);
					return () => clearTimeout(timeout);
				}
			} catch (error) {
				console.log('error: ', error);
			}
		};
		_getModelList(id);
	}, [id]);

	const handleClick = (title, id, index) => {
		const Replace = title.replace(/ /g, '_');
		navigate(
			`/allcar/variant/${Replace}`,
			{ state: { title, id } },
			{ replace: true }
		);
	};

	return (
		<div className="model">
			<div
				className="model_container"
				style={{ marginTop: 10, width: '96%', margin: 'auto', marginBottom: 5 }}
			>
				<div className="model_title_bar"></div>
				<Typography
					gutterBottom
					variant="h4"
					component="div"
					fontWeight={'700'}
					fontSize={{ lg: '30px', md: '25px', sm: '20px', xs: '18px' }}
					color={'#002441'}
					sx={{ my: 2, mx: 1 }}
					textAlign={'start'}
				>
					{location.pathname.replace(/\/allcar|\//g, '') === 'Arena'
						? 'Arena World'
						: 'Nexa World'}
				</Typography>
				<Box
					sx={{
						marginTop: 10,
						width: '100%',
						margin: 'auto',
						marginBottom: 5,
						minHeight: 600,
					}}
				>
					<Grid container rowSpacing={2}>
						{loader
							? Array.from({ length: model.length }).map((index) => (
									<Grid key={index} item xs={6} sm={4} md={4} lg={3} p={1}>
										<Skeleton
											variant="rectangular"
											width="100%"
											height="305px"
										/>
										<Skeleton variant="rounded" style={{ marginTop: '10px' }} />
										<Skeleton
											variant="rounded"
											width="90%"
											style={{ marginTop: '10px' }}
										/>
										<Skeleton
											variant="rounded"
											width="60%"
											style={{ marginTop: '10px' }}
										/>
									</Grid>
							  ))
							: model.map((item, index) => (
									<Grid key={index} item xs={6} sm={4} md={4} lg={3} p={1}>
										<Carcard
											handleClick={() => handleClick(item.title, item.id)}
											title={item.title}
											images={item.images[0]}
											price={item.price}
											carTitle={item.title}
										/>
									</Grid>
							  ))}
					</Grid>
				</Box>
			</div>
		</div>
	);
};

export default Model;
