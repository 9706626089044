import React, { useState, useEffect } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import './Banner.css';
import banner_img from '../../Assets/Images/6-alpa-award-banner.jpg';
import hyderabad_banner_img from '../../Assets/Images/double-hatric-hyderabad.jpg';
import exchange_banner_img from '../../Assets/Images/exchange-banner.jpg';
import smart_finance_img from '../../Assets/Images/smart-finance.jpg';
import subscribe_drive_img from '../../Assets/Images/subscribe-drive-swap-repeat-banner.jpg';
import banner7_img from '../../Assets/Images/BANNER_7.png';
import banner8_img from '../../Assets/Images/banner_8.png';
import banner1_img from '../../Assets/Images/Banner_1.png';
import banner2_img from '../../Assets/Images/banner_2.png';
import banner3_img from '../../Assets/Images/BANNER_3.png';
import banner4_img from '../../Assets/Images/BANNER_3.png';
import five_thrive_banner_img from '../../Assets/Images/five-thrive-banner.jpg';
import ApiHandler from '../../Api/Apihandler';

function Banner() {
	const [banners, setBanners] = useState([]);
	const basUrl = 'http://superapi.kalyanicrm.com/';

	const data = [
		{
			url: banner_img,
			title: 'second slide',
		},
		{
			url: hyderabad_banner_img,
			title: 'third slide',
		},
		{
			url: exchange_banner_img,
			title: 'fourth slide',
		},
		{
			url: smart_finance_img,
			title: 'fifth slide',
		},
		{
			url: subscribe_drive_img,
			title: 'sixth slide',
		},
		{
			url: banner7_img,
			title: 'seventh slide',
		},
		{
			url: banner8_img,
			title: 'Eighth slide',
		},
		{
			url: five_thrive_banner_img,
			title: 'Nighth slide',
		},
		{
			url: banner1_img,
			title: 'tenth slide',
		},

		{
			url: banner2_img,
			title: 'l1th slide',
		},

		{
			url: banner3_img,
			title: '12th slide',
		},
		{
			url: banner4_img,
			title: '13th slide',
		},
	];

	const [banner, setBanner] = useState([]);

	useEffect(() => {
		// getBanners();
		fetch('https://kalyanimotorsapi.kalyanicrm.com/api/banner')
			.then((res) => res.json())
			.then((data) => setBanner(data));
	}, []);

	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 3000,
		showIndicator: false,
		dots: false,
		nextArrow: <SampleNextArrow />,
		prevArrow: <SamplePrevArrow />,
	};

	const getBanners = async () => {
		const response = await ApiHandler.getBannerData();
		let filteredBanners = response.data.data?.filter((item) => {
			return item.promotion_type == 'image';
		});
		setBanners(filteredBanners);
	};

	return (
		<div style={{ margin: '0px 0px' }}>
			<Slider {...settings}>
				{banner.map((item, index) => {
					return (
						<div className="banner_slider" key={index}>
							<div className="banner">
								<div className="banner_img">
									<img
										src={`https://kalyanimotorsadmin.kalyanicrm.com/storage/${item.banner_img}`}
										alt="Banner"
										style={{
											width: '100%',
											height: '35vw',
											objectFit: 'cover',
										}}
									/>
								</div>
							</div>
						</div>
					);
				})}
			</Slider>
		</div>
	);
}

export default Banner;

function SampleNextArrow(props) {
	const { className, style, onClick } = props;
	return (
		<div
			className={className}
			style={{
				...style,
				height: '40px',
				width: '50px',
				backgroundColor: 'white',
				borderRadius: '6px',
				color: 'black',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				boxShadow: '-2px -4px 3px rgba(0,0,0,0.2)',
				right: 0,
			}}
			onClick={onClick}
		/>
	);
}
function SamplePrevArrow(props) {
	const { className, style, onClick } = props;
	return (
		<div
			className={className}
			style={{
				...style,
				height: '40px',
				width: '50px',
				backgroundColor: 'white',
				borderRadius: '6px',
				color: 'black',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				boxShadow: '4px 4px 4px 3px rgba(0,0,0,0.2)',
				left: 0,
				zIndex: 20,
			}}
			onClick={onClick}
		/>
	);
}
