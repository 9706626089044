import React, { useState, useEffect } from "react";
import ApiHandler from "../../../../Api/Apihandler";
import "./Service_card.css";
import { Link } from "react-router-dom";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  Button,
  Grid,
  Card,
  CardContent,
  CardActions,
  Box,
  Typography,
  Skeleton,
} from "@mui/material";

import { useMediaQuery } from "@mui/material";

const ServiceMainCards = () => {
  const [serviceData, setServiceData] = useState([]);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    setLoader(true);
    getServiceDate(4);
  }, []);

  const getServiceDate = async (id) => {
    const response = await ApiHandler.getModelList(id);
    if (response.data.status === 200) {
      const data = response.data.data;
      setServiceData(data);
      setLoader(false);
    } else {
      console.log("apilist", response.data.data);
    }
  };

  const isSmallScreen = useMediaQuery("(max-width:767px)");

  return (
    <div className="service">
      <Typography
        gutterBottom
        variant="h2"
        component="div"
        fontWeight={"700"}
        color={"black"}
        fontSize={{ xs: "18px", sm: "20px", md: "25px", lg: "30px" }}
        textAlign={"center"}
        marginTop={5}
      >
        Car Services And Maintenance
      </Typography>
      {/* <Typography
				gutterBottom
				variant="p"
				component="div"
				fontWeight={'400'}
				margin={2}
				lineHeight={1.5}
				color={'rgba(0,0,0,0.6)'}
				fontSize={{ xs: '12px', sm: '16px' }}
				textAlign={'center'}
				sx={{ px: { xl: 28, lg: 28, md: 5 } }}
			>
				Get all your car needs i.e. repairs, maintenance, body repairs, cashless
				insurance claims insurance renewals, accessories, car care etc., under a
				single roof of Kalyani Service Centres across silicon valley of India –
				Bengaluru City.Mysore.
			</Typography> */}

      <Box
        sx={{
          mt: 3,
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          justifyItems: "center",
          alignItems: "center",
        }}
      >
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            justifyItems: "center",
            alignItems: "center",
          }}
        >
          {serviceData &&
            serviceData.map((item, index) => {
              return (
                <Grid
                  item
                  key={index}
                  xs={12}
                  sm={12}
                  md={4}
                  lg={3}
                  xl={3}
                  p={2}
                >
                  {loader ? (
                    <Grid item xs={12} p={1}>
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height="305px"
                      />
                      <Skeleton
                        variant="rounded"
                        style={{ marginTop: "10px" }}
                      />
                      <Skeleton
                        variant="rounded"
                        width="90%"
                        style={{ marginTop: "10px" }}
                      />
                      <Skeleton
                        variant="rounded"
                        width="60%"
                        style={{ marginTop: "10px" }}
                      />
                    </Grid>
                  ) : (
                    <Link
                      key={item.id}
                      to={`/allservices/${item.title.replace(/ /g, "_")}`}
                    >
                      <Card
                        sx={{
                          cursor: "pointer",
                          border: "0.5px solid #eee",
                          boxShadow: "none",
                          borderRadius: 4,
                          transition: "transform 1s ease-in-out",
                          transition: "transform 0.4s ease-in",
                          "&:hover": {
                            position: "relative",
                            transform: isSmallScreen
                              ? "scale(1.0)"
                              : "scale(1.05)",
                          },
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                          justifyItems: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <img
                          src={item.images[3]}
                          alt={item.title}
                          style={{
                            width: "120px",
                            height: "120px",
                            margin: "20px 0px",
                          }}
                        />
                        <CardContent
                          sx={{
                            background: "#fafafa",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <div
                          // style={{
                          //   height: "160px",
                          // }}
                          >
                            <Typography
                              gutterBottom
                              variant="p"
                              component="div"
                              fontWeight={"700"}
                              color={"black"}
                              fontSize={{ xs: "14px", sm: "16px" }}
                              textAlign={"start"}
                            >
                              {item.title}
                            </Typography>
                            <div
                              style={{
                                height: isSmallScreen ? "auto" : 80,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                              }}
                            >
                              <Typography
                                gutterBottom
                                variant="p"
                                component="div"
                                color={"rgba(0,0,0,0.6)"}
                                fontSize={{ xs: "14px" }}
                                textAlign={"start"}
                                pt={1}
                              >
                                {item.description}
                              </Typography>
                            </div>
                            <div
                              style={{
                                height: "40px",
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "flex-end",
                              }}
                            >
                              <Button
                                sx={{
                                  fontWeight: 600,
                                  fontSize: "12px",
                                  textAlign: "start",
                                  color: "#eb232a",
                                  lineHeight: "20px",
                                  mt: 2,
                                  textTransform: "none",
                                }}
                                size="small"
                                // onClick={handleClick}
                                className="knowmorebtn"
                              >
                                KNOW MORE
                                <KeyboardArrowRightIcon />
                              </Button>
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    </Link>
                  )}
                </Grid>
              );
            })}
        </Grid>
      </Box>
    </div>
  );
};

export default ServiceMainCards;
