import React, { useState } from 'react';
import './Usedcar.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import BuySellToggleBtn from '../../Components/UsedCar/BuySellToggleBtn';
import BuyAndSellSection from '../../Components/UsedCar/BuyAndSellSection';

const Usedcars = () => {
	const [activeList, setActiveList] = useState('buy');
	const isMobileScreen = useMediaQuery('(max-width: 767px)');

	const buy = 'buy';
	const sell = 'sell';

	return (
		<div className="usedcars">
			<div
				style={{
					top: isMobileScreen ? 95 : 60,
				}}
				className="usedCar_sub"
			>
				<div className="buy_sell_btn_container">
					{[buy, sell].map((label) => (
						<BuySellToggleBtn
							key={label}
							label={label}
							activeList={activeList}
							setActiveList={setActiveList}
						/>
					))}
				</div>
			</div>
			<BuyAndSellSection type={activeList} />
		</div>
	);
};

export default Usedcars;
