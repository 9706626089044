import client from "./Client";
const getBannerData = () => client.get("promotions/get_banners");
const getAllCar = () => client.get("showcases/items");
const getUrlTitle = (e) => client.post("showcases/item_by_title/", e);
const getUrlSubTitle = (e) => client.post("showcases/item_by_subtitle/", e);
const getCategoiries = () => client.get("showcases/get_category");
const getModelList = (e) => client.get("showcases/get_itemlist/" + e);
const getVarientsList = (e) => client.get(`showcases/get_dist_itemdesc` + e);
const getDetails = (e) => client.get(`showcases/get_itemdesc` + e);
const getSmallItemData = (e) => client.get("showcases/get_itemlist/" + e);
const getColorsDetails = (e) => client.post("showcases/get_color_itemdesc/", e);
const postUsedCarData = (e) => client.post("bookings/make_booking/", e);
const getFeaturedData = (e) => client.post("promotions/get_featured_item/", e);
const makeWishList = (e) => client.post("utilities/make_wishlist/", e);
const removeWishList = (e) => client.put("utilities/make_wishlist/" + e + "/");
const getWishList = (e) => client.get("utilities/get_wishlisted_items/" + e);
const checkUser = (e) => client.post("utilities/get_user/", e);
const registerUser = (e) => client.post("auth/users/", e);
const profileCreate = (e) => client.post("utilities/profile_detail/", e);
const getProfile = (e) => client.get(`utilities/get_profile/${e}`);
const updateProfile = (e) => client.put("utilities/profile_detail/", e);
const loginUser = (e) => client.post("utilities/login/", e);
const forgotPassword = (e) => client.put("utilities/forgot_password/", e);
const searchFilter = (e) => client.post("showcases/search_item/", e);
const getAddress = (e) =>
  client.get("utilities/profile_address_create_get/" + e);
const createAddress = (e, data) =>
  client.post(`utilities/profile_address_create_get/${e}`, data);
const modifyAddress = (e, data) =>
  client.put(`utilities/profile_address_update_delete_get/${e}`, data);
const deleteAddress = (e) =>
  client.delete(`utilities/profile_address_update_delete_get/${e}`);
const getPaymentlist = (mob) =>
  client.get("utilities/instantiate_payment/" + mob);
const paymentSuccess = (e) => client.post("utilities/payment_transaction/", e);
const createFbToken = (e, body) =>
  client.post(`utilities/profile_fbtoken_create_get/${e}`, body);

const insuranceForm = (e) => client.post("/bookings/make_booking/", e);
const getRigistered_city = (rto) => client.get(`/utilsdata/get_rto/${rto}`);
const getOutletCity = () => client.get("utilsdata/get_city/");
const getOutletLocation = (city, channel) =>
  client.get(`utilsdata/get_outlets/${city}?channel=${channel}`);
const getBookingId = (e) => client.post("bookings/create_fetch_appbooking/", e);

const makeSuccessfulBooking = (e) => client.post("bookings/make_booking/", e);

const insuranceCarlist = () => client.get("showcases/insurance_car_data");
const insuranceVarientlist = (id) =>
  client.get("showcases/insurance_car_data/" + id);

const jwtPaymentRequest = (e) =>
  client.post("bookings/create_decode_booking/", e);

const verifyCashFreePayment = (e) =>
  client.post(`utilities/payment_status/${e}`);

const getOrderList = (porfileId, type) =>
  client.get(`bookings/get_transaction_record/${porfileId}?type=${type}`);

// const verifyCashFreeBookingPayment = (
//   booking_id,
//   source,
//   gateway,
//   status,
//   order_id
// ) =>
//   client.get(
//     `bookings/verify_booking_id?booking_id=${booking_id}&source=${source}&mode_of_payment=${gateway}&payment_status=${status}&order_id=${order_id}`
//   );

const verifyCashFreeBookingPayment = (token) =>
  client.post(`bookings/verify_booking_id/${token}`);

const beforeVerify = (body) =>
  client.post("utilities/make_transaction_log/", body);

const verifyPan = (e) => client.post("utilities/update_user_pan/", e);
const sellcarForm = (enquirydata) =>
  client.post("bookings/create_usedcarsell_enq/", enquirydata);

const carBookingJWT = (e) => client.post("bookings/create_decode_booking/", e);

const getLoyalityPoint = (e) =>
  client.get(`utilities/check_loyalti_points/${e}`);

const PaymentReqLoyalityPoint = (id, data) =>
  client.post(`utilities/make_pr_session/${id}`, data);

const getPriceList = (e) => client.get(`utilsdata/advance_price_list/${e}`);

const sendEmail = (log) => client.post("utilsdata/fire_email/", log);

const rewardSummary = (profile_id) =>
  client.get("/loyalty/loyalti_summary/" + profile_id);

const sendOtp = (data) =>
  client.post("https://api.in.kaleyra.io/v1/HXIN1756628118IN/verify", data, {
    headers: {
      "api-key": "Afb321cdf738fcaeb559af67cb91f6749",
      "Content-type": "application/json",
    },
  });

const verifyOtp = (verify) =>
  client.post(
    "https://api.in.kaleyra.io/v1/HXIN1756628118IN/verify/validate",
    verify,
    {
      headers: {
        "api-key": "Afb321cdf738fcaeb559af67cb91f6749",
        "Content-type": "application/json",
      },
    }
  );

export default {
  getModelList,
  getAllCar,
  getDetails,
  getUrlTitle,
  getUrlSubTitle,
  getCategoiries,
  getSmallItemData,
  getVarientsList,
  getColorsDetails,
  sendOtp,
  verifyOtp,
  getBannerData,
  postUsedCarData,
  getFeaturedData,
  makeWishList,
  removeWishList,
  getWishList,
  checkUser,
  registerUser,
  profileCreate,
  getProfile,
  updateProfile,
  loginUser,
  forgotPassword,
  searchFilter,
  getPaymentlist,
  paymentSuccess,
  createAddress,
  getAddress,
  modifyAddress,
  deleteAddress,
  createFbToken,
  insuranceForm,
  getRigistered_city,
  getOutletCity,
  getOutletLocation,
  getBookingId,
  makeSuccessfulBooking,
  insuranceCarlist,
  insuranceVarientlist,
  verifyPan,
  jwtPaymentRequest,
  verifyCashFreePayment,
  sellcarForm,
  getOrderList,
  carBookingJWT,
  verifyCashFreeBookingPayment,
  getLoyalityPoint,
  PaymentReqLoyalityPoint,
  beforeVerify,
  getPriceList,
  sendEmail,
  rewardSummary,
};
