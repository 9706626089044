import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';

const BookNowModal = ({
	showBookNowModal,
	setShowBookNowModal,
	details,
	setBtn,
	userBookCheck,
	phoneNumber,
}) => {
	const handleClose = () => {
		setShowBookNowModal(false);
	};

	const ButtonTitle = [
		{ id: '1', title: 'Myself' },
		{ id: '2', title: 'Others' },
	];

	return (
		<Modal
			open={showBookNowModal}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box
				sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					width: { xl: 600, lg: 600, md: 500, sm: 400, xs: 350 },
					bgcolor: 'white',
					boxShadow: 24,
					borderRadius: 3,
				}}
			>
				<div
					style={{
						textAlign: 'end',
					}}
				>
					<CloseIcon
						onClick={handleClose}
						sx={{
							width: '34px',
							height: '34px',
							mt: 1,
							mr: 1,
							borderRadius: '50%',
							backgroundColor: '#F7F7F7',
							cursor: 'pointer',
							padding: 1,
						}}
					/>
				</div>
				<Grid container>
					<Grid item xs={12} px={1.5}>
						<Typography
							gutterBottom
							id="modal-modal-title"
							variant="h6"
							component="div"
							color={'#000'}
							fontWeight={'600'}
							textAlign={'center'}
							fontSize={{
								xl: '18px',
								lg: '18px',
								md: '16px',
								sm: '14px',
								xs: '14px',
							}}
						>
							Vehicle Registration Information
						</Typography>
						<Typography
							id="modal-modal-title"
							gutterBottom
							variant="p"
							component="div"
							fontSize={{ xs: '12px', sm: '12px', lg: '12px' }}
							color={'rgba(0,0,0,0.6)'}
							my={1}
						>
							<strong style={{ color: 'rgba(0,0,0)', fontSize: '13px' }}>
								NOTE :
							</strong>{' '}
							This information is crucial for booking and will be used for
							vehicle registration. Please provide accurate details to ensure a
							smooth process.
						</Typography>
						<Typography
							id="modal-modal-title"
							gutterBottom
							variant="p"
							component="div"
							fontSize={{ xs: '14px', sm: '14px', lg: '14px', xl: '14px' }}
							color={'rgba(0,0,0,0.6)'}
							mb={1.5}
							fontWeight={'600'}
						>
							Thank you for your cooperation.
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						sx={{ display: 'flex', justifyContent: 'space-evenly' }}
					>
						<Button
							variant="contained"
							onClick={() =>
								userBookCheck(
									details[0].subtitle,
									details[0].id,
									details.length > 0 && details[0].images[0],
									details[0].itemlist,
									// details[0].description,
									ButtonTitle[0].title,
									setBtn('Myself'),
									phoneNumber
								)
							}
							sx={{
								textAlign: 'center',
								height: '44px',
								width: {
									xl: '200px',
									lg: '200px',
									md: '200px',
									sm: '150px',
									xs: '150px',
								},
								fontWeight: 600,
								fontSize: '12px',
								lineHeight: '18px',
								letterSpacing: '.01em',
								borderRadius: '12px !important',
								boxShadow: 'none !important',
								my: 2,
								background: ' linear-gradient(to right, #141e30, #243b55)',
								textTransform: 'none',
							}}
							fullWidth
						>
							{ButtonTitle[0].title}
						</Button>
						<Button
							variant="contained"
							sx={{
								textAlign: 'center',
								height: '44px',
								width: {
									xl: '200px',
									lg: '200px',
									md: '200px',
									sm: '150px',
									xs: '150px',
								},
								fontWeight: 600,
								fontSize: '12px',
								lineHeight: '18px',
								letterSpacing: '.01em',
								borderRadius: '12px !important',
								boxShadow: 'none !important',
								my: 2,
								background: ' linear-gradient(to right, #141e30, #243b55)',
								textTransform: 'none',
							}}
							fullWidth
							onClick={() =>
								userBookCheck(
									details[0].subtitle,
									details[0].id,
									details.length > 0 && details[0].images[0],
									details[0].itemlist,
									// details[0].description,
									ButtonTitle[1].title,
									setBtn('Others'),
									phoneNumber
								)
							}
						>
							{ButtonTitle[1].title}
						</Button>
					</Grid>
				</Grid>
			</Box>
		</Modal>
	);
};

export default BookNowModal;
