import React, { useState } from 'react';
import '../../Pages/Details/Details.css';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { getComaPrice } from '../../Constants/Comaprice';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import { useMediaQuery } from '@mui/material';

const HeroCarDetail = ({
	details,
	selectedItemId,
	onClickModel,
	wishlists,
	addWishlist,
	phoneNumber,
	clickedMyself,
	userCheck,
	handleOpen,
	variant,
	handleSelectChange,
	userBookCheck,
}) => {
	const [carno, setCarno] = useState(0);

	const isSmallScreen = useMediaQuery('(max-width:767px)');

	return (
		<Box sx={{ flexGrow: 1 }}>
			<Grid container className="car_details">
				<Grid item xs={12} md={6} sm={12} xl={6} lg={6}>
					{details.length > 0 ? (
						<Zoom>
							<img
								className="details_image"
								src={details[0].images[carno]}
								alt={details[0].subtitle}
							/>
						</Zoom>
					) : null}
					<div>
						{details.map((item, index) => (
							<div
								key={index}
								style={{
									display: 'flex',
									flexDirection: 'row',
									gap: '5px',
									maxWidth: '500px',
									flexWrap: 'wrap',
									overflow: 'auto',
									padding: 5,
									cursor: 'pointer',
								}}
							>
								{item.images && item.images.length > 0 ? (
									item.images.map((image, imageIndex) => (
										<img
											key={`${index}-${imageIndex}`}
											src={image}
											style={{
												width: '45px',
												height: '30px',
												...(imageIndex === carno
													? { border: '1px solid grey' }
													: { border: 'none' }),
											}}
											alt={`Image ${index + 1}-${imageIndex + 1}`}
											onClick={() => setCarno(imageIndex)}
										/>
									))
								) : (
									<div>No Images Available</div>
								)}
							</div>
						))}
					</div>
				</Grid>
				<Grid item xs={12} md={6} sm={12} xl={6} lg={6}>
					<Box sx={{ flexGrow: 1 }}>
						<Grid container>
							<Grid item xs={6} className="car_version">
								<Typography
									gutterBottom
									variant="h4"
									component="div"
									fontWeight={'500'}
									fontSize={{
										lg: '13px',
										md: '13px',
										sm: '13px',
										xs: '13px',
									}}
									color={'#6f6f6f'}
									sx={{ my: 2 }}
									textAlign={'start'}
								>
									Version
								</Typography>

								<FormControl fullWidth>
									<Select
										sx={{ fontSize: 13 }}
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										value={selectedItemId}
										onChange={handleSelectChange}
									>
										{variant.map((item, index) => (
											<MenuItem
												sx={{ fontSize: 13 }}
												value={item.title}
												valuemax={item.id}
												key={index}
											>
												<Link
													key={index}
													to={`/car/${item.subtitle.replace(/ /g, '_')}`}
													style={{ color: '#000' }}
												>
													{item.title}
												</Link>
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={6} className="car_color">
								<Typography
									gutterBottom
									variant="h4"
									component="div"
									fontWeight={'500'}
									fontSize={{
										lg: '13px',
										md: '13px',
										sm: '13px',
										xs: '13px',
									}}
									color={'#6f6f6f'}
									sx={{ my: 2 }}
									textAlign={'start'}
								>
									Color
								</Typography>
								<Typography
									gutterBottom
									variant="h4"
									component="div"
									fontWeight={'600'}
									fontSize={{
										lg: '13px',
										md: '13px',
										sm: '13px',
										xs: '13px',
									}}
									color={'#484848'}
									sx={{ my: 2 }}
									textAlign={'start'}
								>
									{details.length > 0 ? details[0].color : null}
								</Typography>
							</Grid>
						</Grid>
					</Box>

					<Box sx={{ flexGrow: 1, my: 1 }}>
						<Grid>
							<Grid container>
								<Grid item xs={6} sx={{ p: { xs: 2 } }}>
									<Typography
										gutterBottom
										variant="p"
										component="div"
										fontSize={{ xs: '14px', sm: '14px', lg: '14px' }}
										color={'rgba(0,0,0,0.6)'}
									>
										Ex-Shoroom Price
									</Typography>
									<Typography
										gutterBottom
										variant="h4"
										component="div"
										fontWeight={'700'}
										fontSize={{
											lg: '22px',
											md: '22px',
											sm: '18px',
											xs: '18px',
										}}
										color={'#484848'}
										sx={{ my: 1 }}
										textAlign={'start'}
									>
										{details.length > 0
											? getComaPrice(details[0].ex_price.toFixed(0)) + ' /-'
											: null}
									</Typography>
								</Grid>
								<Grid
									item
									xs={6}
									sx={{
										textAlign: 'center',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
									}}
								>
									<Button
										variant="outlined"
										size="small"
										onClick={handleOpen}
										sx={{
											color: 'black',
											borderColor: '#00afa0',
											fontWeight: '700',
											height: '44px',
											width: isSmallScreen ? '150px' : '200px',
											fontWeight: 600,
											fontSize: '12px',
											lineHeight: '18px',
											letterSpacing: '.01em',
											background: 'none',
											borderRadius: '12px !important',

											'&:hover': {
												borderColor: '#00afa0',
											},
										}}
									>
										Emi Calculator
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Box>
					<Box sx={{ flexGrow: 1 }}>
						<Grid container>
							<Grid item xs={6} sx={{ p: { xs: 2, md: 0 } }}>
								<Button
									variant="contained"
									className="wishlist"
									size="small"
									sx={{
										textAlign: 'center',
										height: '44px',
										width: isSmallScreen ? '150px' : '200px',
										fontWeight: 600,
										fontSize: '12px',
										lineHeight: '18px',
										letterSpacing: '.01em',
										// background: "#002441",
										borderRadius: '12px !important',
										boxShadow: 'none !important',
										background:
											wishlists === details[0]?.id ||
											addWishlist[0] === details[0]?.id
												? '#54D969'
												: '#FF004D',
										'&:hover': {
											background:
												wishlists === details[0]?.id ||
												addWishlist[0] === details[0]?.id
													? '#54D969'
													: '#FF004D',
										},
									}}
									onClick={() => {
										userCheck(phoneNumber);
									}}
								>
									{wishlists === details[0]?.id ||
									addWishlist[0] === details[0]?.id
										? 'Wishlist Added'
										: 'Add to Wishlist'}
								</Button>
							</Grid>
							<Grid
								xs={6}
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									p: { xs: 2, md: 0 },
								}}
							>
								<Button
									variant="contained"
									className="book"
									size="small"
									sx={{
										textAlign: 'center',
										height: '44px',
										width: isSmallScreen ? '150px' : '200px',
										fontWeight: 600,
										fontSize: '12px',
										lineHeight: '18px',
										letterSpacing: '.01em',
										// background: "#002441",
										borderRadius: '12px !important',
										boxShadow: 'none !important',
										background: '#FF004D',
										'&:hover': {
											background: '#FF004D',
										},
									}}
									onClick={() =>
										userBookCheck(
											details[0].subtitle,
											details[0].id,
											details.length > 0 && details[0].images[0],
											details[0].itemlist,
											// details[0].description,
											clickedMyself,
											phoneNumber
										)
									}
								>
									Book Now
								</Button>
							</Grid>
						</Grid>
					</Box>
					<Typography
						id="hero_car_images"
						gutterBottom
						variant="p"
						component="div"
						fontSize={{ xs: '14px', sm: '14px', lg: '14px' }}
						color={'rgba(0,0,0,0.6)'}
						mt={3}
						sx={{
							textAlign: {
								xs: 'center',
								sm: 'start',
								md: 'start',
								xl: 'start',
								lg: 'start',
							},
							mx: { xs: 1, md: 0 },
						}}
					>
						Get on-road price
					</Typography>
					<Box
						sx={{
							textAlign: {
								xs: 'center',
								sm: 'start',
								md: 'start',
								xl: 'start',
								lg: 'start',
							},
						}}
					>
						<Button
							sx={{
								mx: { xs: 2, md: 0 },
								my: 1,
								textAlign: 'center',
								height: '44px',
								width: isSmallScreen ? '150px' : '200px',
								fontWeight: 600,
								fontSize: '12px',
								lineHeight: '18px',
								letterSpacing: '.01em',
								// background: "#002441",
								borderRadius: '12px !important',
								boxShadow: 'none !important',
								background: '#002441',
								'&:hover': {
									background: '#002441',
								},
							}}
							variant="contained"
							size="small"
							onClick={onClickModel}
							className="price"
						>
							Get Best Offers
						</Button>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
};

export default HeroCarDetail;
