import React from 'react';
import Loader_img from '../Assets/Images/Icons/loader.gif';

const Loader = () => {
	return (
		<div>
			<div
				className="HomeLoaderContainer"
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					position: 'fixed',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					backgroundColor: '#fff',
					zIndex: 9999,
				}}
			>
				<img
					src={Loader_img}
					className="HomeLoaderContent"
					style={{
						width: '200px',
					}}
				></img>
			</div>
		</div>
	);
};

export default Loader;
