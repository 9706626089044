import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import jwtEncode from "jwt-encode";
import { cashfree } from "../../../Api/Utils/Util";
import { AES } from "crypto-js";
import {
  updateCrm,
  verifyPaymentRequest,
} from "./GatewayCommonFunction";

import {commonFunction} from "./GatewayCommonFunction"

import { useNavigate } from "react-router-dom";

const Cashfree = () => {
  const location = useLocation();
  const { response, profileId, stateValues, grandTotal } = location.state || {};
  console.log("cashfree data", response);
  const sessionId = response.gateway_session_id;
  const name = stateValues.name;
  const email = stateValues.email;
  const mobile = stateValues.mobile;

  useEffect(() => {
    veryfyApi();
  }, [response, sessionId]);

  const veryfyApi = async (status, message) => {
    if (response.source === "paymentList") {
      const beforeVerify_body = {
        crm_profile: profileId,
        payment_instance: response.Booking_id,
        booking_instance: "",
        transaction_reference_no: response.gateway_order_id,
        transaction_status: status,
        mode_of_payment: response.mode_of_payment,
        transaction_charge: 0,
        total_transaction_amount: grandTotal,
        remarks: message,
      };
      updateCrm(beforeVerify_body);
      const token_object = {
        payment_gateway: response.mode_of_payment,
        order_id: response.gateway_order_id,
        payment_status: status,
        payment_request_id: "12345",
        transaction_id: "124322",
      };
      const token = jwtEncode(token_object, "secret");
      
      try {
        const returnUrl = `http://localhost:3000/payments/payment_request/${token}`;
        let checkoutOptions = {
          paymentSessionId: sessionId,
          redirect: true,
          returnUrl: returnUrl,
        };
        cashfree.checkout(checkoutOptions).then(function (result) {
          console.log(JSON.stringify(result));
          if (result.error) {
            alert(result.error.message);
          }
          if (result.redirect) {
            console.log("Redirection");
          }
        });

        // payment collection
        // let options = {
        //   values: {
        //     upiId: "9976866379@paytm",
        //   },
        // };
        // let component = cashfree.create("upiCollect", options);
        let paymentOptions = {
          redirect: true,
          // paymentMethod: component,
          paymentSessionId: sessionId,
          returnUrl: returnUrl,
        };

        const paymentResult = await cashfree.pay(paymentOptions);
        console.log("paymentResult", paymentResult);

        if (paymentResult.error) {
          // Handle error
        }

        if (paymentResult.redirect) {
          // Handle redirection
        }

        if (paymentResult.paymentDetails) {
          // Handle payment success
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    } else if (response.source === "NewCar") {
      const beforeVerify_body = {
        crm_profile: profileId,
        payment_instance: "",
        booking_instance: response.Booking_id,
        transaction_reference_no: response.gateway_order_id,
        transaction_status: status,
        mode_of_payment: response.mode_of_payment,
        transaction_charge: 0,
        total_transaction_amount: grandTotal,
        remarks: message,
      };
      updateCrm(beforeVerify_body);

      const token_object = {
        source: response.source,
        mode_of_payment: response.mode_of_payment,
        payment_status: status,
        order_id: response.gateway_order_id,
        payment_instance: response.Booking_id,
      };
      const token = jwtEncode(token_object, "secret");
      console.log("cashfree token", token);
    

      try {
        const returnUrl = `http://localhost:3000/payments/${token}`;
        let checkoutOptions = {
          paymentSessionId: sessionId,
          redirect: true,
          returnUrl: returnUrl,
        };
        cashfree.checkout(checkoutOptions).then(function (result) {
          if (result.error) {
            alert(result.error.message);
          }
          if (result.redirect) {
            console.log("Redirection");
          }
        });

        // payment collection
        // let options = {
        //   values: {
        //     upiId: "9976866379@paytm",
        //   },
        // };
        // let component = cashfree.create("upiCollect", options);

        let paymentOptions = {
          redirect: true,
          // paymentMethod: component,
          paymentSessionId: sessionId,
          returnUrl: returnUrl,
        };

        const paymentResult = await cashfree.pay(paymentOptions);
        console.log("paymentResult", paymentResult);

        if (paymentResult.error) {
          // Handle error
        }

        if (paymentResult.redirect) {
          // Handle redirection
        }

        if (paymentResult.paymentDetails) {
          // Handle payment success
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    }
  };

  
};

export default Cashfree;
