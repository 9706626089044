import React, { useState, useRef, useEffect } from 'react';
import './Profile.css';
import Footer from '../../../Components/Footer/Footer';
import Header_Main from '../../../Components/Header/Header';
import CoverImg from '../../../Assets/Images/car-insurance-illustration-01.png';
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router';
import Apihandler from '../../../Api/Apihandler';
import PropTypes from 'prop-types';
import gal3 from '../../../Assets/Images/gal3.jpg';
import gal4 from '../../../Assets/Images/gal4.avif';
import gal2 from '../../../Assets/Images/gal2.avif';
import { useLocation, useParams, Link } from 'react-router-dom';
import gal8 from '../../../Assets/Images/gal8.jpg';
import Loader from '../../../Constants/Loader';
import Avathar from '../../../Assets/Images/AVATHAR.png';
import VerifiedIcon from '@mui/icons-material/Verified';

function CustomTabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`tabpanel-${index}`}
			aria-labelledby={`tab-${index}`}
			{...other}
		>
			{value === index && <Typography>{children}</Typography>}
		</div>
	);
}

CustomTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index, profile) {
	return {
		id: `tab-${index}-${profile}`,
		'aria-controls': `tabpanel-${index}-${profile}`,
	};
}

const tabLabels = [
	'About Kalyani',
	'Why Kalyani Motors',
	'Quality Policy',
	'Privacy Policy',
	'Cancellation And Refund Policy',
	'Terms And Conditions',
	'Awards and Achievements',
	'Maruti Driving School',
	'Carriers',
	'Gallery',
];

const Profile = () => {
	const [profileData, setProfileData] = useState([]);
	const loginId = localStorage.getItem('loggedIn');
	const { profile } = useParams();
	const [value, setValue] = useState(0);
	const navigate = useNavigate();
	const handleChange = (event, newValue) => {
		const newUrl = `/about/${tabLabels[newValue].replace(/ /g, '_')}`;
		window.history.pushState(null, null, newUrl);
		setValue(newValue);
	};

	useEffect(() => {
		_getProfile(loginId);
	}, []);

	useEffect(() => {
		const find = profile.replace(/_/g, ' ').trim();
		const filterIndex = tabLabels.findIndex(
			(item) => item.toLowerCase().trim() === find.toLowerCase()
		);

		if (filterIndex !== -1) {
			setValue(filterIndex);
		}
	}, [profile, tabLabels]);

	const _getProfile = async () => {
		try {
			const response = await Apihandler.getProfile(loginId);
			const data = response.data.data[0];
			setProfileData({
				phone: data.phone,
				first_name: data.user.first_name,
				last_name: data.user.last_name,
				email: data.user.email,
				verification: data.verified_profile,
				membership: data.membership,
			});
		} catch (error) {
			console.log('profile Data error', error);
		}
	};

	const handleTabClick = (event, index) => {
		handleChange(event, index);
	};

	return (
		<div>
			<Box>
				<Grid
					className="profile_divider"
					container
					sx={{ py: 5, display: 'flex', justifyContent: 'center', gap: 2 }}
				>
					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						lg={3}
						xl={3}
						className="profile_title"
					>
						<Grid item xs={12}></Grid>
						<Grid>
							<div className="Profile_details">
								<div className="profile_img_container">
									<div className="profile_img">
										<img src={Avathar} alt="profile_img" />
									</div>
								</div>
								<div className="profile_name_container">
									<Typography
										gutterBottom
										variant="h6"
										component="div"
										fontSize={{
											md: '13px',
											sm: '13px',
											xs: '13px',
											lg: '13px',
										}}
										mt={1.5}
										fontWeight={'600'}
										color={'#000'}
										textAlign={'center'}
										className="profile_name"
									>
										Name :{' '}
										<span style={{ color: '#46517F' }}>
											{`${profileData.first_name} ${profileData.last_name}`
												? `${profileData.first_name} ${profileData.last_name}`
												: 'name'}{' '}
										</span>
										{profileData.verification === true ? (
											<VerifiedIcon style={{ color: 'green', fontSize: 18 }} />
										) : null}
									</Typography>
									<Typography
										gutterBottom
										variant="h6"
										component="div"
										fontSize={{
											md: '13px',
											sm: '13px',
											xs: '13px',
											lg: '13px',
										}}
										my={1}
										fontWeight={'600'}
										color={'#000'}
										textAlign={'center'}
										className="profile_email"
									>
										Email :{' '}
										<span style={{ color: '#46517F' }}>
											{profileData.email ? profileData.email : 'email'}
										</span>
									</Typography>
									<Typography
										gutterBottom
										variant="h6"
										component="div"
										fontSize={{
											lg: '13px',
											md: '13px',
											sm: '13px',
											xs: '13px',
										}}
										my={0.5}
										fontWeight={'600'}
										color={'#000'}
										textAlign={'center'}
										className="profile_member"
									>
										Membership :{' '}
										<span style={{ color: '#46517F' }}>
											{profileData.membership === 'Member'
												? 'Silver'
												: profileData.membership}{' '}
										</span>
									</Typography>
								</div>
							</div>
							<Grid
								item
								xs={12}
								sx={{
									flexGrow: 1,
									width: '100%',
									// display: "flex",
									// justifyContent: "center",
								}}
							>
								<div className="Verification_container">
									<div className="verification_titles">
										<Typography
											gutterBottom
											variant="h6"
											component="div"
											fontSize={{
												lg: '13px',
												md: '13px',
												sm: '13px',
												xs: '13px',
											}}
											mt={4}
											py={1.5}
											fontWeight={'600px'}
											color={'#000'}
											textAlign={'center'}
											sx={{
												borderTop: '1.5px solid #e1e1e1',
												borderBottom: '1.5px solid #e1e1e1',
												cursor: 'pointer',
											}}
											onClick={() => {
												navigate('/about/profilevarification');
											}}
										>
											Verify Profile
										</Typography>
										<Typography
											gutterBottom
											variant="h6"
											component="div"
											py={1}
											fontSize={{
												lg: '13px',
												md: '13px',
												sm: '13px',
												xs: '13px',
											}}
											fontWeight={'600px'}
											color={'#000'}
											textAlign={'center'}
											sx={{
												borderBottom: '1.5px solid #e1e1e1',
												cursor: 'pointer',
											}}
											onClick={() => {
												navigate('/about/saving_corner');
											}}
										>
											Saving Corner
										</Typography>
										<Typography
											gutterBottom
											variant="h6"
											component="div"
											py={1}
											fontSize={{
												lg: '13px',
												md: '13px',
												sm: '13px',
												xs: '13px',
											}}
											fontWeight={'600px'}
											color={'#000'}
											textAlign={'center'}
											sx={{
												borderBottom: '1.5px solid #e1e1e1',
												cursor: 'pointer',
											}}
											onClick={() => {
												navigate('/about/addressvarification');
											}}
										>
											Address
										</Typography>
										<Typography
											gutterBottom
											variant="h6"
											component="div"
											py={1}
											fontSize={{
												lg: '13px',
												md: '13px',
												sm: '13px',
												xs: '13px',
											}}
											fontWeight={'600px'}
											color={'#000'}
											textAlign={'center'}
											// onClick={handleOrderNavigate}
											onClick={() => {
												navigate('/about/yourorders');
											}}
											sx={{
												cursor: 'pointer',
											}}
										>
											Your Orders
										</Typography>
										<Typography
											gutterBottom
											variant="h6"
											component="div"
											py={1}
											fontSize={{
												lg: '13px',
												md: '13px',
												sm: '13px',
												xs: '13px',
											}}
											fontWeight={'600px'}
											color={'#000'}
											textAlign={'center'}
											sx={{
												borderTop: '1.5px solid #e1e1e1',
												borderBottom: '1.5px solid #e1e1e1',
												cursor: 'pointer',
											}}
											onClick={() => {
												// localStorage.clear();
												localStorage.removeItem('password');
												localStorage.removeItem('phone');
												localStorage.removeItem('loggedIn');
												navigate('/');
											}}
										>
											Logout
										</Typography>
									</div>
								</div>
							</Grid>
						</Grid>
					</Grid>
					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						lg={8}
						xl={8}
						className="profile_other"
					>
						<div style={{ overflowX: 'auto', overflow: 'auto' }}>
							<Tabs
								value={value}
								onChange={handleChange}
								aria-label="basic tabs example"
								variant="scrollable"
								// scrollButtons={true}
								sx={{
									backgroundColor: '#243B55',
									color: 'white',
									width: '100%',
								}}
							>
								{tabLabels.map((label, index) => (
									<Link key={index} to={`/about/${label.replace(/ /g, '_')}`}>
										<Tab
											label={label}
											// {...a11yProps(index)}
											className={`lowercase-tab ${
												index === value ? 'active-tab' : ''
											}`}
											// selected={index === filterIndex}
											sx={{
												color: 'white',
											}}
											onClick={(event) => handleTabClick(event, index)}
										/>
									</Link>
								))}
							</Tabs>
						</div>
						<CustomTabPanel index={0} value={value}>
							<div
								style={{
									display: 'flex',
									justifyContent: 'start',
									alignItems: 'center',
									flexDirection: 'column',
								}}
							>
								<Typography
									sx={{
										fontSize: 20,
										fontWeight: '700',
										textAlign: 'start',
										color: '#333',
										mx: 2,
										my: 2,
									}}
								>
									ABOUT KALYANI
								</Typography>
								<Typography
									sx={{
										fontSize: 14,
										fontWeight: '500',
										textAlign: 'start',
										color: '#333',
										mx: 2,
										my: 2,
									}}
								>
									Kalyani Motors is one of the best authorized Maruti Suzuki car
									dealers was established by an intuitive person Late
									Sri.S.Anantha Raju in Bangalore in the year 2006. Later the
									present Managing Director Mr. Mohan Raju took forward the
									legacy and has played a vital role in the growth of Kalyani
									Motors and also successfully modernized the automobile
									industry in India.
								</Typography>
								<Typography
									sx={{
										fontSize: 14,
										fontWeight: '500',
										textAlign: 'start',
										color: '#333',
										mx: 2,
										my: 2,
									}}
								>
									The main vision of Kalyani Motors is to create and sustain
									highly delighted customers by giving excellent services and
									become the top-most dealers of Maruti Suzuki.
								</Typography>
								<Typography
									sx={{
										fontSize: 14,
										fontWeight: '500',
										textAlign: 'start',
										color: '#333',
										mx: 2,
										my: 2,
									}}
								>
									Kalyani Motors is authorized Sales and Service Dealer for
									Maruti Suzuki and Nexa Range of Automobiles in Bangalore and
									Hyderabad. We have been noted for our excellence and prominent
									achievements that speak for the innovations and the various
									milestones achieved. We are overwhelmed and honoured to
									receive the prestigious “Platinum Dealership” award from
									Maruti Suzuki Arena Channel for the 10 consecutive time in
									Bangalore and 5 consecutive time in Hyderabad and Alpha
									Dealership in Nexa Channel for Bangalore and Hyderabad for 4th
									time.
								</Typography>
								<Typography
									sx={{
										fontSize: 14,
										fontWeight: '500',
										textAlign: 'start',
										color: '#333',
										mx: 2,
										my: 2,
									}}
								>
									Kalyani Motors have successfully marked its presence in
									Bengaluru and Hyderabad with 13 state-of-the-art Arena
									Showrooms & 4 state-of-the-art NEXA Showrooms, 20 Service
									Centres, 6 True Value outlets, 19 Bodyshops, 4 Maruti Driving
									Schools, 2 commercial outlets and 2 Service On Wheels (SOW) in
									Bangalore & Hyderabad.
								</Typography>
								<Typography
									sx={{
										fontSize: 14,
										fontWeight: '500',
										textAlign: 'start',
										color: '#333',
										mx: 2,
										my: 2,
									}}
								>
									The success of the Kalyani Motors would not have been possible
									without dedication, unity & support of “Kalyani Motors Team”
									who are highly-qualified, talented and innovative individuals
									with specific areas of expertise and experience.
								</Typography>
								<Typography
									sx={{
										fontSize: 14,
										fontWeight: '500',
										textAlign: 'start',
										color: '#333',
										mx: 2,
										my: 2,
									}}
								>
									Kalyani Motors have always aimed to create a healthy & unique
									environment for its customers. We, Kalyani Motors as a premium
									Maruti Suzuki Car dealership always endeavour to achieve
									highest level of customer satisfaction and to fulfil your
									dream of owning a car.
								</Typography>
							</div>
						</CustomTabPanel>

						<CustomTabPanel index={1} value={value}>
							<div>
								<div
									style={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										flexDirection: 'column',
									}}
								>
									<Typography
										sx={{
											fontSize: 20,
											fontWeight: '700',
											textAlign: 'start',
											color: '#333',
											mx: 2,
											my: 2,
										}}
									>
										WHY KALYANI MOTORS
									</Typography>
									<Typography
										sx={{
											fontSize: 14,
											fontWeight: '500',
											textAlign: 'start',
											color: '#333',
											mx: 2,
											my: 2,
										}}
									>
										Embracing the provisions of dynamic markets, Kalyani Group
										of companies has forged ahead unremittingly since the year
										2006. The group ventured into the automobile industry with
										the setting up of Kalyani Motors, in Bengaluru initially,
										and has been hitherto recognised for its excellence and
										notable achievements.
									</Typography>
									<Typography
										sx={{
											fontSize: 14,
											fontWeight: '500',
											textAlign: 'start',
											color: '#333',
											mx: 2,
											my: 2,
										}}
									>
										With a fruitful and successful relationship with Maruti
										Suzuki, Kalyani Motors revolutionised the automobile
										industry in India, gathering many accolades for its
										expansion in various cities like Ramanagar, Channapatna,
										Kanakapura & now Hyderabad.
									</Typography>
									<Typography
										sx={{
											fontSize: 14,
											fontWeight: '500',
											textAlign: 'start',
											color: '#333',
											mx: 2,
											my: 2,
										}}
									>
										As a "Platinum Dealer" for six consecutive years, Kalyani
										Motors is the authorised sales & service dealer for Maruti
										Suzuki range of automobiles in Bangalore, with the estimates
										for the current year turnover standing at over Rs. 13.5
										Billion. Kalyani Motors has many firsts and achievements to
										its credit. The aggressive growth of the company owes to
										steadfast processes and methodologies for rendering
										impeccable quality services and establishing lasting
										customer relationships.
									</Typography>
									<Typography
										sx={{
											fontSize: 14,
											fontWeight: '500',
											textAlign: 'start',
											color: '#333',
											mx: 2,
											my: 2,
										}}
									>
										Kalyani Motors has a team of over 3467 employees, with
										nearly 400 sales personnel and 450 technical force, and has
										sold more than 95,000 cars within a short span of 10 years.
										Carrying on the legacy and aspiring higher, Kalyani Motors
										has now grown big and has successfully created a strong
										presence in Bengaluru with the 9 State-of-the-art showrooms,
										10 Service Centres, 5 True Value outlets, 10 Bodyshops, 2
										Maruti Driving Schools and recently entered into the premium
										segment through Nexa outlets in JPNagar & RRNagar-Mysore Rd;
										and in Hyderabad, with the 1 state-of-the-art showroom, 2
										Service Centres, 2 Bodyshops, 1 True Value outlet and
										India’s largest 200th Nexa outlet in Nagole, Hyderabad.
										Also, the future holds 3 more outlets in Bangalore and 5
										more at Hyderabad.
									</Typography>
									<Typography
										sx={{
											fontSize: 14,
											fontWeight: '500',
											textAlign: 'start',
											color: '#333',
											mx: 2,
											my: 2,
										}}
									>
										The collective, dedicated and consorted efforts of ‘Team
										Kalyani’ have been instrumental in Kalyani Motors winning
										accolades and establishing a strong and satisfied customer
										base. The state-of-art service centre fulfils our penchant
										for delivering the best to our customers. Our ever-friendly
										and ever-helpful sales personnel are known for their
										alacrity and ensure that every visit to Kalyani Motors is
										pleasant and memorable.
									</Typography>
								</div>
							</div>
						</CustomTabPanel>
						<CustomTabPanel index={2} value={value}>
							<div>
								<div
									style={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										flexDirection: 'column',
									}}
								>
									<Typography
										sx={{
											fontSize: 20,
											fontWeight: '700',
											textAlign: 'start',
											color: '#333',
											mx: 2,
											my: 2,
										}}
									>
										QUALITY POLICY
									</Typography>
									<Typography
										sx={{
											fontSize: 14,
											fontWeight: '500',
											textAlign: 'start',
											color: '#333',
											mx: 2,
											my: 2,
										}}
									>
										Quality has been of paramount importance to us. We deal with
										only genuine components to ensimport from ure highest
										standards in quality and reliability. Our quality policy has
										been designed in tune with customer requirements. Our
										quality strategy is to maintain the reputation of the
										company by constantly meeting and exceeding customer
										expectations on every occasion. We ensure that all quality
										specifications are agreed to and clarified by both customers
										and suppliers before proceeding further with the procurement
										process and ensure delivery of defect free quality products
										always on time. We always commit ourselves for every order
										equally to ensure total product quality and reduce
										variation. We strive hard to constantly improve our quality,
										cost, delivery and service levels towards obtaining customer
										satisfaction.
									</Typography>
								</div>
							</div>
						</CustomTabPanel>

						<CustomTabPanel index={3} value={value}>
							<div>
								<div
									style={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										flexDirection: 'column',
									}}
								>
									<Typography
										sx={{
											fontSize: 20,
											fontWeight: '700',
											textAlign: 'start',
											color: '#333',
											mx: 2,
											my: 2,
										}}
									>
										PRIVACY POLICY
									</Typography>
									<Typography
										sx={{
											fontSize: 14,
											fontWeight: '500',
											textAlign: 'start',
											color: '#333',
											mx: 2,
											my: 2,
										}}
									>
										Kalyani Motors is one of the best authorized Maruti Suzuki
										car dealers was established by an intuitive person Late
										Sri.S.Anantha Raju in Bangalore in the year 2006. Later the
										present Managing Director Mr. Mohan Raju took forward the
										legacy and has played a vital role in the growth of Kalyani
										Motors and also successfully modernized the automobile
										industry in India.
									</Typography>
									<Typography
										sx={{
											fontSize: 16,
											fontWeight: '600',
											textAlign: 'center',
											color: '#000',

											my: 2,
										}}
									>
										What the terms and conditions cover
									</Typography>
									<Typography
										sx={{
											fontSize: 14,
											fontWeight: '500',
											textAlign: 'start',
											color: '#333',
											mx: 2,
											my: 2,
										}}
									>
										This covers Kalyani Motors treatment of personally
										identifiable information that Kalyani Motors collects when
										you are on Kalyani Motors site and when you use our
										services. This policy also covers Kalyani Motors treatment
										of any Personally identifiable information that Kalyani
										Motors shares with you. This policy does not apply to the
										practices of companies that Kalyani Motors does not own or
										control or Kalyani Motors does not own or employ or manage.
									</Typography>
									<Typography
										sx={{
											fontSize: 16,
											fontWeight: '600',
											textAlign: 'center',
											color: '#000',
											fontWeight: '700',
											my: 2,
										}}
									>
										Information Sharing and Disclosure
									</Typography>
									<Typography
										sx={{
											fontSize: 14,
											fontWeight: '500',
											textAlign: 'start',
											color: '#333',
											mx: 2,
											my: 2,
										}}
									>
										Kalyani Motors will not sell or rent your Personally
										Identifiable Information to anyone. Kalyani Motors will send
										Personally Identifiable Information about you when: We have
										consent to share the information We need to share your
										information to provide the product or service you have
										requested We respond to subpoenas, court orders or legal
										process. When we find your action on the web site violates
										the Kalyani Motors terms and condition or any of your usage
										guidelines for specific products or services.
									</Typography>
									<Typography
										sx={{
											fontSize: 16,
											fontWeight: '600',
											textAlign: 'center',
											color: '#000',
											fontWeight: '700',
											my: 2,
										}}
									>
										Security
									</Typography>
									<Typography
										sx={{
											fontSize: 14,
											fontWeight: '500',
											textAlign: 'start',
											color: '#333',
											mx: 2,
											my: 2,
										}}
									>
										Your Kalyani Motors account information is
										password-protected for your privacy and security We have
										taken adequate measures to secure access to your personal
										data?
									</Typography>
									<Typography
										sx={{
											fontSize: 16,
											fontWeight: '600',
											textAlign: 'center',
											color: '#000',
											fontWeight: '700',
											my: 2,
										}}
									>
										Changes to this Policy
									</Typography>
									<Typography
										sx={{
											fontSize: 14,
											fontWeight: '500',
											textAlign: 'start',
											color: '#333',
											mx: 2,
											my: 2,
										}}
									>
										Kalyani Motors may edit this policy from time to time. If we
										make any substantial changes, we will notify you by posting
										a prominent announcement on our pages.
									</Typography>
									<Typography
										gutterBottom
										variant="h4"
										component="div"
										sx={{
											fontSize: 16,
											fontWeight: '600',
											textAlign: 'start',
											color: '#000',
											my: 2,
										}}
									>
										Email Disclaimer
									</Typography>
									<Typography
										sx={{
											fontSize: 14,
											fontWeight: '500',
											textAlign: 'start',
											color: '#333',
											mx: 2,
											my: 2,
										}}
									>
										This electronic transmission contains information from
										Kalyani Motors Pvt . Ltd. which is confidential and
										proprietary, and is intended for use only by the person
										named herein. In case, you are not the intended recipient,
										pls. don’t use this information in any manner, whatsoever,
										and immediately return it to Sender. Any views or opinions
										presented in this email are solely those of the author and
										may not necessarily reflect the opinion of Kalyani Motors.
									</Typography>
								</div>
							</div>
						</CustomTabPanel>

						<CustomTabPanel index={4} value={value}>
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									flexDirection: 'column',
								}}
							>
								<Typography
									sx={{
										fontSize: 20,
										fontWeight: '700',
										textAlign: 'start',
										color: '#333',
										mx: 2,
										my: 2,
									}}
								>
									CANCELLATION AND REFUND POLICY
								</Typography>
								<Box>
									<Grid container>
										<Grid item xs={1}>
											<Typography
												gutterBottom
												variant="h4"
												component="div"
												sx={{
													fontSize: 16,
													fontWeight: '600',
													textAlign: 'start',
													color: '#000',
													my: 2,
													textAlign: 'end',
												}}
											>
												1.
											</Typography>
										</Grid>
										<Grid xs={11}>
											<Typography
												sx={{
													fontSize: 14,
													fontWeight: '500',
													textAlign: 'start',
													color: '#333',
													mx: 2,
													my: 2,
												}}
											>
												Bookings made online does not have an option of offline
												cancellation.
											</Typography>
										</Grid>

										<Grid xs={1}>
											<Typography
												gutterBottom
												variant="h4"
												component="div"
												sx={{
													fontSize: 16,
													fontWeight: '600',
													textAlign: 'start',
													color: '#000',
													my: 2,
													textAlign: 'end',
												}}
											>
												2.
											</Typography>
										</Grid>
										<Grid xs={11}>
											<Typography
												sx={{
													fontSize: 14,
													fontWeight: '500',
													textAlign: 'start',
													color: '#333',
													mx: 2,
													my: 2,
												}}
											>
												Only cashless payments can be made at Kalyani Motors.
											</Typography>
										</Grid>
										<Grid xs={1}>
											<Typography
												gutterBottom
												variant="h4"
												component="div"
												sx={{
													fontSize: 16,
													fontWeight: '600',
													textAlign: 'start',
													color: '#000',
													my: 2,
													textAlign: 'end',
												}}
											>
												3.
											</Typography>
										</Grid>
										<Grid xs={11}>
											<Typography
												sx={{
													fontSize: 14,
													fontWeight: '500',
													textAlign: 'start',
													color: '#333',
													mx: 2,
													my: 2,
												}}
											>
												It will take upto 25 - 30 days to refund the amount.
												Bookings made online the amount will be refunded by the
												same mode of payment made.
											</Typography>
										</Grid>
										<Grid xs={1}>
											<Typography
												gutterBottom
												variant="h4"
												component="div"
												sx={{
													fontSize: 16,
													fontWeight: '600',
													textAlign: 'start',
													color: '#000',
													my: 2,
													textAlign: 'end',
												}}
											>
												4.
											</Typography>
										</Grid>
										<Grid xs={11}>
											<Typography
												sx={{
													fontSize: 14,
													fontWeight: '500',
													textAlign: 'start',
													color: '#333',
													mx: 2,
													my: 2,
												}}
											>
												Refund made is directly credited to the issuer's bank
												account . If the funds has not been credited, it is
												advised to take it to the notice of issuing bank to
												resolve the issue.
											</Typography>
										</Grid>
										<Grid xs={1}>
											<Typography
												gutterBottom
												variant="h4"
												component="div"
												sx={{
													fontSize: 16,
													fontWeight: '600',
													textAlign: 'start',
													color: '#000',
													my: 2,
													textAlign: 'end',
												}}
											>
												5.
											</Typography>
										</Grid>
										<Grid xs={11}>
											<Typography
												sx={{
													fontSize: 14,
													fontWeight: '500',
													textAlign: 'start',
													color: '#333',
													mx: 2,
													my: 2,
												}}
											>
												Customers are not allowed to make any changes or
												modifications of any models after the booking has been
												made online.
											</Typography>
										</Grid>
										<Grid xs={1}>
											<Typography
												gutterBottom
												variant="h4"
												component="div"
												sx={{
													fontSize: 16,
													fontWeight: '600',
													textAlign: 'start',
													color: '#000',
													my: 2,
													textAlign: 'end',
												}}
											>
												6.
											</Typography>
										</Grid>
										<Grid xs={11}>
											<Typography
												sx={{
													fontSize: 14,
													fontWeight: '500',
													textAlign: 'start',
													color: '#333',
													mx: 2,
													my: 2,
												}}
											>
												Any changes or modifications that has to be done can
												only be done by cancelling the existing booking and a
												fresh booking needs to be done.
											</Typography>
										</Grid>
									</Grid>
								</Box>
							</div>
						</CustomTabPanel>
						<CustomTabPanel index={5} value={value}>
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									flexDirection: 'column',
								}}
							>
								<Typography
									sx={{
										fontSize: 20,
										fontWeight: '700',
										textAlign: 'start',
										color: '#333',
										mx: 2,
										my: 2,
									}}
								>
									TERMS AND CONDITIONS
								</Typography>
								<Box>
									<Grid container>
										<Grid xs={1}>
											<Typography
												gutterBottom
												variant="h4"
												component="div"
												sx={{
													fontSize: 16,
													fontWeight: '600',
													textAlign: 'start',
													color: '#000',
													my: 2,
													textAlign: 'end',
												}}
											>
												1.
											</Typography>
										</Grid>
										<Grid xs={11}>
											<Typography
												sx={{
													fontSize: 14,
													fontWeight: '500',
													textAlign: 'start',
													color: '#333',
													mx: 2,
													my: 2,
												}}
											>
												Bookings made online does not have an option of offline
												cancellation.
											</Typography>
										</Grid>

										<Grid xs={1}>
											<Typography
												gutterBottom
												variant="h4"
												component="div"
												sx={{
													fontSize: 16,
													fontWeight: '600',
													textAlign: 'start',
													color: '#000',
													my: 2,
													textAlign: 'end',
												}}
											>
												2.
											</Typography>
										</Grid>
										<Grid xs={11}>
											<Typography
												sx={{
													fontSize: 14,
													fontWeight: '500',
													textAlign: 'start',
													color: '#333',
													mx: 2,
													my: 2,
												}}
											>
												Only cashless payments can be made at Kalyani Motors.
											</Typography>
										</Grid>
										<Grid xs={1}>
											<Typography
												gutterBottom
												variant="h4"
												component="div"
												sx={{
													fontSize: 16,
													fontWeight: '600',
													textAlign: 'start',
													color: '#000',
													my: 2,
													textAlign: 'end',
												}}
											>
												3.
											</Typography>
										</Grid>
										<Grid xs={11}>
											<Typography
												sx={{
													fontSize: 14,
													fontWeight: '500',
													textAlign: 'start',
													color: '#333',
													mx: 2,
													my: 2,
												}}
											>
												It will take upto 25 - 30 days to refund the amount.
												Bookings made online the amount will be refunded by the
												same mode of payment made.
											</Typography>
										</Grid>
										<Grid xs={1}>
											<Typography
												gutterBottom
												variant="h4"
												component="div"
												sx={{
													fontSize: 16,
													fontWeight: '600',
													textAlign: 'start',
													color: '#000',
													my: 2,
													textAlign: 'end',
												}}
											>
												4.
											</Typography>
										</Grid>
										<Grid xs={11}>
											<Typography
												sx={{
													fontSize: 14,
													fontWeight: '500',
													textAlign: 'start',
													color: '#333',
													mx: 2,
													my: 2,
												}}
											>
												Refund made is directly credited to the issuer's bank
												account . If the funds has not been credited, it is
												advised to take it to the notice of issuing bank to
												resolve the issue.
											</Typography>
										</Grid>
										<Grid xs={1}>
											<Typography
												gutterBottom
												variant="h4"
												component="div"
												sx={{
													fontSize: 16,
													fontWeight: '600',
													textAlign: 'start',
													color: '#000',
													my: 2,
													textAlign: 'end',
												}}
											>
												5.
											</Typography>
										</Grid>
										<Grid xs={11}>
											<Typography
												sx={{
													fontSize: 14,
													fontWeight: '500',
													textAlign: 'start',
													color: '#333',
													mx: 2,
													my: 2,
												}}
											>
												Customers are not allowed to make any changes or
												modifications of any models after the booking has been
												made online.
											</Typography>
										</Grid>
										<Grid xs={1}>
											<Typography
												gutterBottom
												variant="h4"
												component="div"
												sx={{
													fontSize: 16,
													fontWeight: '600',
													textAlign: 'start',
													color: '#000',
													my: 2,
													textAlign: 'end',
												}}
											>
												6.
											</Typography>
										</Grid>
										<Grid xs={11}>
											<Typography
												sx={{
													fontSize: 14,
													fontWeight: '500',
													textAlign: 'start',
													color: '#333',
													mx: 2,
													my: 2,
												}}
											>
												Any changes or modifications that has to be done can
												only be done by cancelling the existing booking and a
												fresh booking needs to be done.
											</Typography>
										</Grid>
									</Grid>
								</Box>
							</div>
						</CustomTabPanel>
						<CustomTabPanel index={6} value={value}>
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									flexDirection: 'column',
								}}
							>
								<Typography
									sx={{
										fontSize: 20,
										fontWeight: '700',
										textAlign: 'start',
										color: '#333',
										mx: 2,
										my: 2,
									}}
								>
									AWARDS AND ACHIEVEMENTS
								</Typography>
								<Typography
									sx={{
										fontSize: 14,
										fontWeight: '500',
										textAlign: 'start',
										color: '#333',
										mx: 2,
										my: 2,
									}}
								>
									Our longstanding reputation is sure to continue with strong
									growth in the future. We have received innumerable awards that
									speak for the innovations introduced by us and the various
									milestones achieved.
								</Typography>
							</div>
						</CustomTabPanel>
						<CustomTabPanel index={7} value={value}>
							<div>
								<div
									style={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										flexDirection: 'column',
									}}
								>
									<Typography
										sx={{
											fontSize: 20,
											fontWeight: '700',
											textAlign: 'start',
											color: '#333',
											mx: 2,
											my: 2,
										}}
									>
										MARUTI DRIVING SCHOOL
									</Typography>
									<Typography
										sx={{
											fontSize: 14,
											fontWeight: '500',
											textAlign: 'start',
											color: '#333',
											mx: 2,
											my: 2,
										}}
									>
										Maruti Driving School was established on 30th Nov 2011 with
										the goal to provide the best motor-driving training. The
										purpose is to impart not just better driving skills but also
										better awareness towards overall road safety. MDS provides
										immense opportunities to learn driving & develop confidence.
										It is definitely the right way to move towards a safer
										future.
									</Typography>
								</div>
							</div>
						</CustomTabPanel>
						<CustomTabPanel index={8} value={value}>
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									flexDirection: 'column',
								}}
							>
								<Typography
									sx={{
										fontSize: 20,
										fontWeight: '700',
										textAlign: 'start',
										color: '#333',
										mx: 2,
										my: 2,
									}}
								>
									CAREERS
								</Typography>
								<Typography
									sx={{
										fontSize: 14,
										fontWeight: '500',
										textAlign: 'start',
										color: '#333',
										mx: 2,
										my: 2,
									}}
								>
									The best people produce the best product. That's why Kalyani
									Motors is constantly on the lookout for bright, ambitious team
									players who have a strong commitment to drive the company
									forward. We invite you to follow your own dream & explore the
									career opportunities with Kalyani Motors.
								</Typography>
								<Typography
									sx={{
										fontSize: 14,
										fontWeight: '500',
										textAlign: 'start',
										color: '#333',
										mx: 2,
										my: 2,
									}}
								>
									Please forward your CV to
									recruiter3@kalyanimotors.com/recruiter6@kalyanimotors.com or
									Call Ms. Anitha 9900070712/9900599910
								</Typography>
							</div>
						</CustomTabPanel>
						<CustomTabPanel index={9} value={value}>
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									flexDirection: 'column',
								}}
							>
								<Typography
									sx={{
										fontSize: 20,
										fontWeight: '700',
										textAlign: 'start',
										color: '#333',
										mx: 2,
										my: 2,
									}}
								>
									GALLERY
								</Typography>
								<Box>
									<Grid
										container
										rowSpacing={2}
										xs={12}
										sx={{ display: 'flex', justifyContent: 'center' }}
									>
										<Grid item xs={11}>
											<img src={gal3} alt="gal_3" width={'100%'} />
										</Grid>
										<Grid item xs={11}>
											<img src={gal4} alt="gal_4" width={'100%'} height={200} />
										</Grid>
										{/* <Grid item xs={11}>
											<img src={gal2} width={'100%'} height={400} />
										</Grid> */}
										<Grid item xs={11}>
											<img src={gal8} alt="gal_8" width={'100%'} height={200} />
										</Grid>
									</Grid>
								</Box>
							</div>
						</CustomTabPanel>
					</Grid>
				</Grid>
			</Box>
		</div>
	);
};

export default Profile;
