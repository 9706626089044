import React, { useState, useEffect } from 'react';
import './Cart.css';
import Footer from '../../Components/Footer/Footer';
import Header_Main from '../../Components/Header/Header';
import ApiHandler from '../../Api/Apihandler';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { getComaPrice } from '../../Constants/Comaprice';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import OfflineBoltOutlinedIcon from '@mui/icons-material/OfflineBoltOutlined';
import Skeleton from '@mui/material/Skeleton';
import Loader from '../../Constants/Loader';
import { useNavigate } from 'react-router-dom';
import EmptyCartImage from '../../Assets/Images/empty-cart-01.png';
import SwipeToDelete from 'react-swipe-to-delete-component';
import 'react-swipe-to-delete-component/dist/swipe-to-delete.css';
import { useMediaQuery } from '@mui/material';

const Cart = () => {
	const [wishList, setWishList] = useState([]);
	const [loader, setLoader] = useState(false);
	const [pageLoader, setPageLoader] = useState(false);
	const [activeBtn, setActiveBtn] = useState(true);
	const isSmallScreen = useMediaQuery('(max-width:767px)');

	const loginId = localStorage.getItem('loggedIn');
	const navigate = useNavigate();

	const handleCartBook = (
		cartTitle,
		cartId,
		cartImage,
		cartColor,
		cartChannel
	) => {
		const Replace = cartTitle.replace(/ /g, '_');
		navigate(`/ebook/${cartId}/${Replace}`, {
			state: { cartTitle, cartId, cartImage, cartColor, cartChannel },
		});
	};
	const handleCartServiceBook = (
		cartTitle,
		cartId,
		cartImage,
		cartColor,
		cartChannel,
		cartAmount
	) => {
		const Replace = cartTitle.replace(/ /g, '_');
		navigate(`/Serviceebook/${Replace}`, {
			state: {
				cartTitle,
				cartId,
				cartImage,
				cartColor,
				cartChannel,
				cartAmount,
			},
		});
	};

	const Item = styled(Paper)(({ theme }) => ({
		backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
		...theme.typography.body2,
		padding: theme.spacing(1),
	}));

	useEffect(() => {
		setLoader(true);
		setPageLoader(true);
		_getwishList(loginId);
	}, []);
	const _getwishList = async () => {
		const response = await ApiHandler.getWishList(loginId);
		const data = response.data.data;
		try {
			setTimeout(() => {
				setLoader(false);
				setPageLoader(false);
			}, 2000);
			setWishList(data);
		} catch (error) {
			console.log('wishlist error', error);
		}
	};

	const filter = wishList.filter((item) => item.item.channel === 4);
	const carFilter = wishList.filter((item) => item.item.channel !== 4);

	const _removewishList = async (id) => {
		const response = await ApiHandler.removeWishList(id);
		if (response.data.status === 200) {
			_getwishList();
		}
	};

	const handleClick = (id) => {
		_removewishList(id);
	};

	const handleDelete = () => {
		alert('swipe to delete');
	};

	return (
		<div>
			<Typography
				gutterBottom
				variant="h4"
				component="div"
				fontWeight={'700'}
				fontSize={{ lg: '20px', md: '20px', sm: '20px', xs: '18px' }}
				color={'#484848'}
				sx={{ my: 2 }}
				textAlign={'center'}
				lineHeight={2}
			>
				WISHLIST FUEL YOUR DREAM CAR
			</Typography>

			<Box sx={{ flexGrow: 1 }}>
				<Grid container>
					<Grid
						xs={12}
						sx={{
							display: 'flex',
							justifyContent: 'space-evenly',
							alignItems: 'center',
							my: 5,
						}}
					>
						<Button
							variant="contained"
							className={
								activeBtn === true ? 'wishlistBtnActive' : 'wishlistBtn'
							}
							size="small"
							sx={{
								textAlign: 'center',
								height: '44px',
								width: isSmallScreen ? '150px' : '200px',
								fontWeight: 600,
								fontSize: '12px',
								lineHeight: '18px',
								letterSpacing: '.01em',
								borderRadius: '12px !important',
								boxShadow: 'none !important',
							}}
							onClick={() => setActiveBtn(true)}
						>
							Cars
						</Button>
						<Button
							variant="contained"
							className={
								activeBtn === false ? 'wishlistBtnActive' : 'wishlistBtn'
							}
							size="small"
							sx={{
								textAlign: 'center',
								height: '44px',
								width: isSmallScreen ? '150px' : '200px',
								fontWeight: 600,
								fontSize: '12px',
								lineHeight: '18px',
								letterSpacing: '.01em',
								borderRadius: '12px !important',
								boxShadow: 'none !important',
							}}
							onClick={() => setActiveBtn(false)}
						>
							Services
						</Button>
					</Grid>
				</Grid>
			</Box>

			<Box sx={{ width: '100%', my: 2 }} className="wishLists">
				<Grid
					container
					rowSpacing={4}
					//columnSpacing={{ xs: 1, sm: 2, md: 3 }}
					sx={{
						display: 'flex',
						justifyContent: 'center',
					}}
				>
					{loader ? (
						activeBtn === true ? (
							Array.from({ length: carFilter.length }).map((index) => (
								<Grid key={index} item xs={12} sm={12} md={12} lg={12} p={1}>
									<Skeleton variant="rectangular" width="100%" height="305px" />
									<Skeleton variant="rounded" style={{ marginTop: '10px' }} />
									<Skeleton
										variant="rounded"
										width="90%"
										style={{ marginTop: '10px' }}
									/>
									<Skeleton
										variant="rounded"
										width="60%"
										style={{ marginTop: '10px' }}
									/>
								</Grid>
							))
						) : (
							Array.from({ length: carFilter.length }).map((index) => (
								<Grid key={index} item xs={12} sm={12} md={12} lg={12} p={1}>
									<Skeleton variant="rectangular" width="100%" height="305px" />
									<Skeleton variant="rounded" style={{ marginTop: '10px' }} />
									<Skeleton
										variant="rounded"
										width="90%"
										style={{ marginTop: '10px' }}
									/>
									<Skeleton
										variant="rounded"
										width="60%"
										style={{ marginTop: '10px' }}
									/>
								</Grid>
							))
						)
					) : activeBtn === true ? (
						carFilter.length === 0 ? (
							<div className="emptycart_img_container">
								<img src={EmptyCartImage} className="emptycart_img" />
								<Typography
									gutterBottom
									variant="h4"
									component="div"
									fontWeight={'600'}
									fontSize={{
										lg: '16px',
										md: '16px',
										sm: '16px',
										xs: '13px',
									}}
									color={'#282828'}
									textAlign={'start'}
								>
									No Items Found
								</Typography>
							</div>
						) : (
							carFilter.map((item) => (
								<Grid
									item
									xs={11}
									lg={10}
									xl={10}
									md={11}
									sm={11}
									p={0}
									className="wishlistcar_card"
									sx={{ cursor: 'pointer' }}
								>
									<SwipeToDelete
										key={item.id}
										onDelete={() => _removewishList(item.id)}
									>
										<Item
											sx={{
												borderRadius: '0px',
												boxShadow: '1px 2px 9px #F4AAB9',
											}}
											className="cart_maincard"
										>
											<Grid
												item
												xs={12}
												sx={{
													display: 'flex',
													flexDirection: 'row',
													justifyContent: 'space-evenly',
													alignContent: 'center',
													alignItems: 'center',
												}}
												onClick={() =>
													navigate(
														`/car/${item.item.subtitle.replace(/ /g, '_')}`,
														{ state: { cartId: item.item.id } }
													)
												}
											>
												<Grid
													item
													xs={6}
													sx={{
														display: 'flex',
														flexDirection: 'row',
														justifyContent: 'space-evenly',
														alignContent: 'center',
														alignItems: 'center',
													}}
												>
													<div className="wishlist_img_container">
														<img
															alt={item.item.title}
															src={item.item.images[0]}
															className="cart_img_choose"
															height={'100%'}
															width={'100%'}
														/>
													</div>
												</Grid>
												<Grid item xs={6}>
													<Typography
														gutterBottom
														variant="h4"
														component="div"
														fontWeight={'600'}
														fontSize={{
															lg: '16px',
															md: '16px',
															sm: '16px',
															xs: '13px',
														}}
														color={'#282828'}
														textAlign={'start'}
														sx={{ my: 1, mx: 2 }}
													>
														{item.item.title}
													</Typography>
													<Typography
														color="text.secondary"
														gutterBottom
														variant="h4"
														component="div"
														fontWeight={'600'}
														fontSize={{
															lg: '12px',
															md: '12px',
															sm: '12px',
															xs: '11px',
														}}
														sx={{ my: 1, mx: 2 }}
														textAlign={'start'}
													>
														({item.item.color})
													</Typography>
													<Typography
														color="#006D00"
														gutterBottom
														variant="h4"
														component="div"
														fontWeight={'600'}
														fontSize={{
															lg: '14px',
															md: '14px',
															sm: '14px',
															xs: '12px',
														}}
														sx={{ my: 1, mx: 2 }}
														textAlign={'start'}
													>
														Ex-ShowRoom Price /-
													</Typography>
													<Typography
														gutterBottom
														variant="h4"
														component="div"
														fontWeight="bold"
														fontSize={{
															lg: '15px',
															md: '15px',
															sm: '15px',
															xs: '15px',
														}}
														sx={{ my: 1, mx: 2, textAlign: 'start' }}
													>
														<span style={{ color: '#282828' }}>
															{getComaPrice(parseInt(item.item.price)) +
																' ' +
																'/-'}
														</span>
													</Typography>
												</Grid>
											</Grid>

											<Grid
												xs={12}
												sx={{
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
												}}
											>
												{' '}
												<Grid
													item
													xs={6}
													sx={{
														borderRight: '1px solid #e1e1e1',
														display: 'flex',
														justifyContent: 'center',
														alignContent: 'center',
														borderTop: '1px solid #e1e1e1',
													}}
												>
													<Button
														size="small"
														sx={{
															textAlign: 'center',
															color: '#7A7A7A',
															fontWeight: '600',
															fontSize: {
																lg: '12px',
																md: '12px',
																sm: '12px',
																xs: '12px',
															},
															textTransform: 'none',
															py: 1.5,
															width: '100%',
															'&:hover': {
																background: '#E80000 !important',
																color: '#fff',
															},
															borderRadius: 'none',
														}}
														onClick={() => handleClick(item.id)}
														className="btnRemove"
													>
														<DeleteOutlineOutlinedIcon sx={{ fontSize: 18 }} />
														&nbsp; Remove
													</Button>
												</Grid>
												<Grid
													item
													xs={6}
													sx={{
														display: 'flex',
														justifyContent: 'center',
														alignContent: 'center',
														borderTop: '1px solid #e1e1e1',
													}}
												>
													<Button
														size="small"
														sx={{
															textAlign: 'center',
															color: '#7A7A7A',
															fontWeight: '600',
															fontSize: {
																lg: '12px',
																md: '12px',
																sm: '12px',
																xs: '12px',
															},
															textTransform: 'none',
															py: 1.5,
															width: '100%',
															'&:hover': {
																background:
																	' linear-gradient(to right, #1e3c72, #2a5298) !important',
																// ' linear-gradient(to right, #000000, #0f9b0f)',

																color: '#fff',
															},
															borderRadius: 'none',
														}}
														onClick={() =>
															handleCartBook(
																item.item.subtitle,
																item.id,
																item.item.images[0],
																item.item.color,
																item.item.channel,
																item.item.price
															)
														}
														className="btnEbook"
													>
														<OfflineBoltOutlinedIcon sx={{ fontSize: 18 }} />{' '}
														&nbsp; E-Book
													</Button>
												</Grid>
											</Grid>
										</Item>
									</SwipeToDelete>
								</Grid>
							))
						)
					) : filter.length === 0 ? (
						<div className="emptycart_img_container">
							<img src={EmptyCartImage} className="emptycart_img" />
							<Typography
								gutterBottom
								variant="h4"
								component="div"
								fontWeight={'600'}
								fontSize={{
									lg: '16px',
									md: '16px',
									sm: '16px',
									xs: '13px',
								}}
								color={'#282828'}
								textAlign={'start'}
							>
								No Items Found
							</Typography>
						</div>
					) : (
						filter.map((item) => (
							<Grid
								item
								xs={11}
								lg={10}
								xl={10}
								md={11}
								sm={11}
								className="wishlistcar_card"
								// onClick={() =>
								//   navigate(
								//     `/ServiceDetails/${item.item.id}/${String(
								//       item.item.itemlist
								//     )}/${item.item.title.replace(/ /g, "_")}`
								//   )
								// }
							>
								<SwipeToDelete
									key={item.id}
									onDelete={() => _removewishList(item.id)}
								>
									<Item
										sx={{
											borderRadius: '0px',
										}}
										className="servicecardcart"
									>
										<Grid
											item
											xs={12}
											sx={{
												display: 'flex',
												flexDirection: 'row',
												justifyContent: 'space-evenly',
												alignContent: 'center',
												alignItems: 'center',
											}}
										>
											<Grid
												item
												xs={6}
												sx={{
													display: 'flex',
													flexDirection: 'row',
													justifyContent: 'space-evenly',
													alignContent: 'center',
													alignItems: 'center',
												}}
											>
												<div className="wishlist_serviceimg_container">
													<img
														alt="complex"
														src={item.item.images[0]}
														className="cart_img_choose"
														height={'100%'}
														width={'100%'}
													/>
												</div>
											</Grid>
											<Grid item xs={6}>
												<Typography
													gutterBottom
													variant="h4"
													component="div"
													fontWeight={'600'}
													fontSize={{
														lg: '16px',
														md: '16px',
														sm: '16px',
														xs: '13px',
													}}
													color={'#282828'}
													textAlign={'start'}
													sx={{ my: 1, mx: 2 }}
												>
													{item.item.title}
												</Typography>
												<Typography
													color="#006D00"
													gutterBottom
													variant="h4"
													component="div"
													fontWeight={'600'}
													fontSize={{
														lg: '14px',
														md: '14px',
														sm: '14px',
														xs: '12px',
													}}
													sx={{ my: 1, mx: 2 }}
													textAlign={'start'}
												>
													Ex-ShowRoom Price /-
												</Typography>
												<Typography
													gutterBottom
													variant="h4"
													component="div"
													fontWeight="bold"
													fontSize={{
														lg: '15px',
														md: '15px',
														sm: '15px',
														xs: '15px',
													}}
													sx={{ my: 1, mx: 2, textAlign: 'start' }}
												>
													<span style={{ color: '#282828' }}>
														{getComaPrice(parseInt(item.item.price)) +
															' ' +
															'/-'}
													</span>
												</Typography>
											</Grid>
										</Grid>

										<Grid
											xs={12}
											sx={{
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
											}}
										>
											{' '}
											<Grid
												item
												xs={6}
												sx={{
													borderRight: '1px solid #e1e1e1',
													display: 'flex',
													justifyContent: 'center',
													alignContent: 'center',
													borderTop: '1px solid #e1e1e1',
												}}
											>
												<Button
													size="small"
													sx={{
														textAlign: 'center',
														color: '#7A7A7A',
														fontWeight: '600',
														fontSize: {
															lg: '12px',
															md: '12px',
															sm: '12px',
															xs: '12px',
														},
														textTransform: 'none',
														py: 1.5,
														width: '100%',
														'&:hover': {
															background: '#E80000 !important',
															color: '#fff',
														},
													}}
													onClick={() => handleClick(item.id)}
													className="btnRemoveservice"
												>
													<DeleteOutlineOutlinedIcon sx={{ fontSize: 18 }} />
													&nbsp; Remove
												</Button>
											</Grid>
											<Grid
												item
												xs={6}
												sx={{
													display: 'flex',
													justifyContent: 'center',
													alignContent: 'center',
													borderTop: '1px solid #e1e1e1',
												}}
											>
												<Button
													size="small"
													sx={{
														textAlign: 'center',
														color: '#7A7A7A',
														fontWeight: '600',
														fontSize: {
															lg: '12px',
															md: '12px',
															sm: '12px',
															xs: '12px',
														},
														textTransform: 'none',
														py: 1.5,
														width: '100%',
														'&:hover': {
															background:
																' linear-gradient(to right, #1e3c72, #2a5298) !important',
															color: '#fff',
														},
													}}
													onClick={() =>
														handleCartServiceBook(
															item.item.title,
															item.id,
															item.item.images[0],
															item.item.color,
															item.item.channel
														)
													}
													className="btnEbookservice"
												>
													<OfflineBoltOutlinedIcon sx={{ fontSize: 18 }} />{' '}
													&nbsp; E-Book
												</Button>
											</Grid>
										</Grid>
									</Item>
								</SwipeToDelete>
							</Grid>
						))
					)}
				</Grid>
			</Box>
		</div>
	);
};

export default Cart;
