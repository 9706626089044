import { create } from 'apisauce';

const client = create({
	baseURL:
		process.env.NODE_ENV == 'development'
			? 'https://2402-45-112-136-250.ngrok-free.app'
			: 'http://superapi.kalyanicrm.com/',
	withCredentials: false,
	headers:
		process.env.NODE_ENV == 'development'
			? {
					Accept: 'application/json',
					['ngrok-skip-browser-warning']: '98547',
			  }
			: null,
});

export default client;
