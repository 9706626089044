import ApiHandler from "../../../Api/Apihandler";


export const updateCrm = async (params_data) => {
  const response = await ApiHandler.beforeVerify(params_data);
  console.log("updateCrm", response);
};

export const verifyBookingPayment = async (token, callback) => {
  try {
    const response = await ApiHandler.verifyCashFreeBookingPayment(token);
    const  data = response.data.data;
    if(data){
      if(data.booking_id.payment_status === "Success"){
         callback("success",data)
        }
        else{
        callback("failure",data)
        
      }
    }
  } catch (error) {
    console.log('cashfree verify booking',error)
  }
};

export const verifyPaymentRequest = async (token,callback) => {
  const response = await ApiHandler.verifyCashFreePayment(token);
  console.log('response: ', response);

  // if (response.data.status === 200) {
  //   if (response.data.data.payment_status === "Success") {
  //     window.history.push("http://localhost:3000/payment_success", {
  //       state: response.data.data,
  //     });
  //   } else {
  //     window.history.push("http://localhost:3000/payment_failure", {
  //       state: response.data.data,
  //     });
  //   }
  // }
};







