import React, { useState, useEffect } from 'react';
import {
	Navigate,
	useLocation,
	useNavigate,
	useParams,
} from 'react-router-dom';
import './EBook_Details.css';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import Button from '@mui/material/Button';
import { getComaPrice } from '../../../Constants/Comaprice';
import Divider from '@mui/material/Divider';
import Modal from '@mui/material/Modal';
import Coin from '../../../Assets/Images/Gifs/Coin.gif';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import ApiHandler from '../../../Api/Apihandler';
// import jwt from "jsonwebtoken";
import HeaderMain from '../../../Components/Header/Header';
import Footer from '../../../Components/Footer/Footer';
import jwtEncode from 'jwt-encode';
import goto_myorders from '../../../Assets/Images/goto_myorders.png';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
// import Payment_Success from "../../../Common Components/Success Screen/Payment_Success";
import redeem_points from '../../../Assets/Images/Icons/redeem_points.png';
import redeem_icon from '../../../Assets/Images/Icons/redeem_icon.png';
import redeem_right_icon from '../../../Assets/Images/Icons/redeem_right_icon.png';
import SuccessFully_done from '../../../Assets/Images/Gifs/payment_successful.gif';
import reward from '../../../Assets/Images/reward.png';

const Ebook_Details = ({ route }) => {
	const [modal, setModal] = useState(false);
	const [redeemPoint, setRedeemPoint] = useState([]);
	const [btnPressed, setbtnPressed] = useState(false);
	const [checkAmount, setCheckAmount] = useState(0);
	const [saving, setSaving] = useState('');
	const [beforeGST, setBeforeGST] = useState('');
	const [GST, setGST] = useState('');
	const [grandTotal, setGrandTotal] = useState();
	const location = useLocation();
	const [error, setError] = useState(null);
	const [loyalityPoint, setLoyalityPoint] = useState([]);
	const [randomNum, setRandomNum] = useState(0);
	const [redeemSuccess, setRedeemSuccess] = useState(false);

	const openRedeemSuccess = () => {
		setRedeemSuccess(true);
		setTimeout(() => {
			setRedeemSuccess(false);
		}, 3000);
	};
	const closeRedeemSuccess = () => {
		setRedeemSuccess(false);
	};

	const { Replace, ReplaceId } = useParams();
	// const [user, setUser] = useState({
	//   name: "test",
	//   email: "test@gmail.com",
	//   city: "Bangalore",
	//   outlet: "Nayandhalli",
	//   address1: "nayandhalli ",
	//   address2: "mysore road",
	//   pincode: "561109",
	//   model: "Maruti Suzuki Swift",
	//   variant: "Maruti Suzuki Swift LXI 1.2L 5MT",
	//   color: "Solid Fire Red",
	//   mob_no: "9834542536",
	//   purpose: "new car booking Advance",
	//   amount: "",
	//   Referred_by: "",
	//   Employee_Id: "",
	// });

	const [paymentModal, setPaymentModal] = useState(false);
	console.log('redem point', redeemPoint[0]);

	const paymentModalOpen = () => {
		setPaymentModal(true);
	};
	const paymentModalClose = () => {
		setPaymentModal(false);
	};

	const {
		name,
		email,
		mobile,
		variant,
		color,
		cities,
		locations,
		cstreet,
		carea,
		clandmark,
		ccity,
		cdoor_no,
		cpincode,
		payment,
		images,
		empCode,
		empName,
		amount,
		addressId,
		itemlist,
		description,
		others_name,
		others_email,
		others_mobile,
		others_pan,
		others_panImg,
		others_relationship,
		booked_for,
	} = location.state || {};

	const stateValues = { ...location.state };

	//already car booked popup
	const [open, setOpen] = useState(false);
	const handleSuccessOpen = () => setOpen(true);
	const handleSuccessClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		_getLoyalityPoint(loginId);
	}, []);

	useEffect(() => {
		let points = 0;
		redeemPoint.map((item) => {
			if (item.claimed) {
				points = points + +item.selectedPoints;
			}
		});
		setSaving(points);
		console.log('points', points);
		let befGST = payment - points;
		setBeforeGST(befGST);
		let gst = (befGST * 18) / 100;
		setGST((befGST * 18) / 100);
		setGrandTotal(befGST);
		setCheckAmount(befGST + gst);
	}, [redeemPoint]);

	// useEffect(() => {
	//   showToast("success", "bottom", "Button Pressed", "You pressed the button!");
	//   setUser({
	//     ...location.state.user,
	//     amount:
	//       location.state.source === "Service"
	//         ? location.state.user.item_price
	//         : location.state.user.amount,
	//     purpose:
	//       location.state.source === "Service"
	//         ? "Service Booking"
	//         : "NewCar Booking",
	//     source: location.state.source === "Service" ? "Service" : "NewCar",
	//   });
	//   generateRandomNumber();
	// }, []);

	const address = [cdoor_no, cstreet];
	const formattedAddress = address.join(', ');

	const isSmallScreen = useMediaQuery('(max-width:600px)');
	const openModal = () => {
		setModal(true);
	};

	const closeModal = () => {
		setModal(false);
	};

	const loginId = localStorage.getItem('loggedIn');

	// const generateRandomNumber = () => {
	//   const currentTime = Date.now();
	//   const randomSuffix = Math.floor(Math.random() * 10000);
	//   const randomNumber =
	//     currentTime.toString() + randomSuffix.toString().padStart(4, "0");
	//   setRandomNum(randomNumber);
	// };

	const handleNewCar = (title, points, selectedPoints) => {
		let newarray = redeemPoint.map((ite, i) => {
			if (title == ite.subtitle) {
				if (selectedPoints > points || selectedPoints === 0) {
					setError(i);
					return { ...ite, claimed: false };
				} else {
					return { ...ite, claimed: !ite.claimed };
				}
			} else {
				return ite;
			}
		});
		setRedeemPoint(newarray);
	};

	const _getLoyalityPoint = async (loginId) => {
		const response = await ApiHandler.getLoyalityPoint(loginId);
		if (response.data.status !== 200) {
		} else {
			console.log('loyalt data', response.data.data);
			response.data.data &&
				response.data.data.length >= 0 &&
				setRedeemPoint([
					{
						title: 'Earned Points',
						subtitle: 'EarnedPoint',
						claimed: false,
						points: response.data.data[0].balance_points,
						selectedPoints: 0,
					},
					{
						title: 'Inaugural Points',
						subtitle: 'InauguralPoint',
						claimed: false,
						points: response.data.data[0].inaugural_points,
						selectedPoints: 0,
					},
				]);
			setLoyalityPoint(response.data.data);
		}
	};

	const profileId = localStorage.getItem('loggedIn');

	console.log('loyalityPOint', redeemPoint);

	const paymentString = payment;
	const paymentInteger = parseInt(paymentString, 10);

	const newCarBooking = async () => {
		const Data = {
			source: 'NewCar',
			name: name,
			email: email,
			mobile: mobile,
			profilePanCard: '',
			profilePanName: '',
			profileBookedFor: booked_for,
			profileRelationship: others_relationship,
			otherPanNumber: others_pan,
			panPicOthers: others_panImg,
			payment_id: '',
			profile: profileId,
			city: cities,
			outlets: locations,
			address: addressId,
			amount: grandTotal,
			itemlist: itemlist,
			item_description: description,
			item_price: amount,
			ex_price: '',
			employee_id: empCode,
			referred_by: empName,
			booking_status: 'Pending',
			loyalti_transaction_type:
				redeemPoint[0].claimed == true || redeemPoint[1].claimed == true
					? 'Redeem'
					: 'Add',
			gross_amount: paymentInteger,
			e_point:
				redeemPoint[0].claimed == true ? redeemPoint[0].selectedPoints : 0,
			i_point:
				redeemPoint[1].claimed == true ? redeemPoint[1].selectedPoints : 0,
			cgst_sgst: 0,
			net_paid:
				grandTotal.toFixed(2) === parseInt('0.00')
					? 0
					: parseInt(grandTotal.toFixed(2)),
		};
		setbtnPressed(true);
		const encoded_token = jwtEncode(Data, 'secret');
		const tokenData = {
			token: encoded_token,
		};
		console.log('token', encoded_token);
		const carResponse = await ApiHandler.carBookingJWT(tokenData);
		console.log('newcar booking jwt response ----', carResponse.data);

		if (carResponse.data.status === 200) {
			NavigationData(carResponse.data.data);
		} else if (carResponse.data.data === 'Already Booked and Pending') {
			// setModalVisible(true);
			// alert('Already Booked This Car And Payment is Pending');
			handleSuccessOpen();
		} else {
			// _sendMail("shibasankardev@gmail.com", carResponse);
			alert('error');
		}
		setbtnPressed(false);
	};
	const navigate = useNavigate();
	const NavigationData = async (response) => {
		if (response.mode_of_payment === 'CashFree') {
			// console.log("payment data", data);
			setbtnPressed(false);
			navigate('/payment/cashfree', {
				state: { response, profileId, stateValues, grandTotal },
			});
		} else if (response.mode_of_payment === 'Razorpay') {
			const data = {
				payment_gateway: response.mode_of_payment,
				params_data: {
					Booking_id: response.id,
					grandTotal: grandTotal,
					api_key: response.api_key,
				},
			};
			// paymentGateway(data, navigation);
			setbtnPressed(false);
			navigate('/payment/razorpay', { state: { data } });
		} else if (response.mode_of_payment === 'Cashless') {
			const beforeVerify_body = {
				crm_profile: response.profile.id,
				payment_instance: '',
				booking_instance: '',
				transaction_reference_no: 'cashless_' + randomNum,
				transaction_status: 'Success',
				mode_of_payment: response.mode_of_payment,
				transaction_charge: 0,
				total_transaction_amount: response.gross_amount,
				remarks: '',
			};
			const transactionResponse = await ApiHandler.beforeVerify(
				beforeVerify_body
			);

			console.log('transactionResponse', transactionResponse);
			// navigation.navigate("BookingSuccess", response);
			setbtnPressed(false);
		} else if (response.mode_of_payment === 'Paytm') {
			const data = {
				payment_gateway: response.mode_of_payment,
				params_data: {
					Booking_id: response.id,
					grandTotal: grandTotal,
					api_key: response.api_key,
				},
			};
			navigate('/payment/paytm', { state: { data } });
			setbtnPressed(false);
		} else {
			console.log('no payment gateway found');
		}
	};

	return (
		<div className="Ebook_details_screen">
			<div className="ebook_details">
				<div className="ebook_details_container">
					<Typography
						gutterBottom
						variant="h4"
						component="div"
						fontWeight={'700'}
						fontSize={{ lg: '20px', md: '20px', sm: '20px', xs: '18px' }}
						color={'#000'}
						sx={{ my: 2, py: 3 }}
						textAlign={'center'}
					>
						ORDER DETAILS
					</Typography>
					<Box
						className="cus_details"
						style={{ display: 'flex', justifyContent: 'center' }}
					>
						<Grid container lg={11} sm={12} xs={12} xl={11} md={12}>
							<Grid xs={12} lg={6} sm={12} md={6} xl={6}>
								<Box sx={{ flexGrow: 1 }}>
									<Grid container>
										<Grid xs={5}>
											<Typography
												gutterBottom
												variant="p"
												component="div"
												fontWeight={'700'}
												fontSize={{
													lg: '13px',
													md: '13px',
													sm: '13px',
													xs: '13px',
												}}
												color={'#000'}
												sx={{ my: 1, p: 2 }}
												textAlign={'start'}
											>
												Name:
											</Typography>
										</Grid>
										<Grid xs={7}>
											<Typography
												gutterBottom
												variant="p"
												component="div"
												fontWeight={'500'}
												fontSize={{
													lg: '13px',
													md: '13px',
													sm: '13px',
													xs: '13px',
												}}
												color={'#484848'}
												sx={{ my: 1, p: 2 }}
												textAlign={'start'}
											>
												{booked_for === 'Others' ? others_name : name}
											</Typography>
										</Grid>
									</Grid>
								</Box>
								<Box sx={{ flexGrow: 1 }}>
									<Grid container>
										<Grid xs={5}>
											<Typography
												gutterBottom
												variant="p"
												component="div"
												fontWeight={'700'}
												fontSize={{
													lg: '13px',
													md: '13px',
													sm: '13px',
													xs: '13px',
												}}
												color={'#000'}
												sx={{ p: 2 }}
												textAlign={'start'}
											>
												Booking Address:
											</Typography>
										</Grid>
										<Grid xs={7}>
											<div
												style={{
													maxWidth: '250px',
													overflow: 'hidden',
													textOverflow: 'ellipsis',
												}}
											>
												<Typography
													gutterBottom
													variant="p"
													component="div"
													fontWeight={'500'}
													fontSize={{
														lg: '13px',
														md: '13px',
														sm: '13px',
														xs: '13px',
													}}
													color={'#484848'}
													textAlign={'start'}
													sx={{ p: 2 }}
												>
													{formattedAddress}
												</Typography>
											</div>
										</Grid>
									</Grid>
								</Box>
								<Box sx={{ flexGrow: 1 }}>
									<Grid container>
										<Grid xs={5}>
											<Typography
												gutterBottom
												variant="p"
												component="div"
												fontWeight={'700'}
												fontSize={{
													lg: '13px',
													md: '13px',
													sm: '13px',
													xs: '13px',
												}}
												color={'#000'}
												sx={{ p: 2 }}
												textAlign={'start'}
											>
												Booking Details:
											</Typography>
										</Grid>
										<Grid xs={7}>
											<Typography
												gutterBottom
												variant="p"
												component="div"
												fontWeight={'500'}
												fontSize={{
													lg: '13px',
													md: '13px',
													sm: '13px',
													xs: '13px',
												}}
												color={'#484848'}
												textAlign={'start'}
												sx={{ p: 2 }}
											>
												{variant},{color}
											</Typography>
										</Grid>
									</Grid>
								</Box>
								<Box sx={{ flexGrow: 1 }}>
									<Grid container>
										<Grid xs={5}>
											<Typography
												gutterBottom
												variant="p"
												component="div"
												fontWeight={'700'}
												fontSize={{
													lg: '13px',
													md: '13px',
													sm: '13px',
													xs: '13px',
												}}
												color={'#000'}
												sx={{ p: 2 }}
												textAlign={'start'}
											>
												Location Details:
											</Typography>
										</Grid>
										<Grid xs={7}>
											<Typography
												gutterBottom
												variant="p"
												component="div"
												fontWeight={'500'}
												fontSize={{
													lg: '13px',
													md: '13px',
													sm: '13px',
													xs: '13px',
												}}
												color={'#484848'}
												textAlign={'start'}
												sx={{ p: 2 }}
											>
												{cities},{locations}
											</Typography>
										</Grid>
									</Grid>
								</Box>
							</Grid>
							<Grid xs={12} lg={6} sm={12} md={6} xl={6}>
								<div className="order_car">
									<img
										src={images}
										className="order_car_img"
										style={{
											width: isSmallScreen ? '200px' : '420px',
											height: isSmallScreen ? '200px' : '300px',
										}}
									/>
								</div>
							</Grid>
						</Grid>
					</Box>
					<div>
						<Box
							className="cus_details"
							style={{ display: 'flex', justifyContent: 'center' }}
						>
							<Grid
								container
								lg={11}
								sm={12}
								xs={12}
								xl={11}
								md={12}
								sx={{
									border: '0.1px solid #eee',
									borderRadius: 2,
								}}
							>
								<Grid
									xs={12}
									md={12}
									sm={12}
									lg={6}
									xl={6}
									// sx={{
									// 	background: 'linear-gradient(to right, #ece9e6, #ffffff)',
									// }}
								>
									<div>
										<Typography
											gutterBottom
											variant="h4"
											component="div"
											fontWeight={'700'}
											fontSize={{
												lg: '20px',
												md: '20px',
												sm: '20px',
												xs: '18px',
											}}
											color={'#000'}
											sx={{ my: 1, py: 1 }}
											textAlign={'center'}
										>
											SAVING CORNER
										</Typography>
										<Box className="redeem" sx={{ py: 4 }}>
											<Grid container>
												<Grid xs={7} pl={{ xl: 8, lg: 8, md: 6, sm: 2 }}>
													<Box
														sx={{
															display: 'flex',
															justifyContent: 'center',
															alignItems: 'center',
														}}
													>
														<Grid
															container
															sx={{
																display: 'flex',
																justifyContent: 'center',
																alignContent: 'center',
															}}
														>
															<Grid
																xs={11}
																className="redeem_grid"
																sx={{
																	display: 'flex',
																	justifyContent: 'center',
																	alignContent: 'center',
																	my: 1,
																}}
															>
																<img
																	src={redeem_icon}
																	style={{ width: '20px', height: '20px' }}
																/>
																&nbsp;
																<Typography
																	gutterBottom
																	variant="h4"
																	component="div"
																	fontWeight={'700'}
																	fontSize={'18px'}
																	color={'#003B73'}
																	sx={{
																		px: { xl: 1, lg: 1, md: 1 },
																	}}
																	textAlign={'start'}
																>
																	Redeem Points
																</Typography>
															</Grid>
														</Grid>
													</Box>
													{redeemPoint && redeemPoint.length !== 0 && (
														<>
															{redeemPoint.map((item) => (
																<Box
																	sx={{
																		display: 'flex',
																		justifyContent: 'center',
																		alignItems: 'center',
																	}}
																	key={item.title}
																>
																	{item.claimed === true && (
																		<Grid
																			container
																			sx={{
																				display: 'flex',
																				justifyContent: 'start',
																				alignContent: 'center',
																			}}
																		>
																			<Grid
																				xs={11}
																				sx={{
																					display: 'flex',
																					justifyContent: 'start',
																					alignContent: 'center',
																					my: 1,
																				}}
																			>
																				<img
																					src={redeem_right_icon}
																					style={{
																						width: '15px',
																						height: '16px',
																					}}
																					alt="Redeem Icon"
																				/>
																				&nbsp;
																				<Typography
																					gutterBottom
																					variant="h4"
																					component="div"
																					fontWeight={'700'}
																					fontSize={'14px'}
																					color={'#000'}
																					sx={{
																						px: { xl: 1, lg: 1, md: 1 },
																					}}
																					textAlign={'start'}
																				>
																					{item.title}
																				</Typography>
																				<Typography
																					gutterBottom
																					variant="h4"
																					component="div"
																					fontWeight={'700'}
																					fontSize={'14px'}
																					color={'green'}
																					ml={1}
																					textAlign={'start'}
																				>
																					{getComaPrice(item.selectedPoints)}
																				</Typography>
																			</Grid>
																		</Grid>
																	)}
																</Box>
															))}
														</>
													)}
												</Grid>
												<Grid xs={5}>
													<div className="apply_btn">
														<Button
															onClick={openModal}
															variant="outlined"
															size="small"
															className=""
															sx={{
																fontSize: {
																	xs: '12px',
																	sm: '12px',
																	md: '12px',
																	lg: '13px',
																	xl: '13px',
																	textTransform: 'none',
																	color: 'green',
																	border: '1px solid green',
																},
																fontWeight: 600,
																letterSpacing: 0.5,
																py: 1,
															}}
														>
															{' '}
															Apply Offer
															<KeyboardArrowRightIcon />
														</Button>
													</div>
													{redeemPoint && redeemPoint.length !== 0 && (
														<>
															{redeemPoint.map(
																(item, index) =>
																	item.claimed === true && (
																		<div
																			style={{
																				display: 'flex',
																				flexDirection: 'column',
																				justifyContent: 'end',
																			}}
																		>
																			<Button
																				key={index}
																				onClick={() => {
																					handleNewCar(
																						item.subtitle,
																						item.points,
																						item.selectedPoints
																					);
																				}}
																				variant="text"
																				size="small"
																				className=""
																				sx={{
																					fontSize: {
																						xs: '12px',
																						sm: '12px',
																						md: '12px',
																						lg: '13px',
																						xl: '13px',
																					},
																					fontWeight: 600,
																					letterSpacing: 0.5,
																					py: 1,
																					color: 'red',
																					textTransform: 'none',
																				}}
																			>
																				Remove
																			</Button>
																		</div>
																	)
															)}
														</>
													)}
												</Grid>
											</Grid>
										</Box>
									</div>
								</Grid>
								<Grid
									xs={12}
									md={12}
									sm={12}
									lg={6}
									xl={6}
									// sx={{ backgroundColor: '#E5DAD6' }}
								>
									<div>
										<Typography
											gutterBottom
											variant="h4"
											component="div"
											fontWeight={'700'}
											fontSize={{
												lg: '20px',
												md: '20px',
												sm: '20px',
												xs: '18px',
											}}
											color={'#000'}
											sx={{ my: 1, py: 1 }}
											textAlign={'center'}
										>
											BILLING SUMMERY
										</Typography>
										<Box sx={{ flexGrow: 1 }} className="billing_summery">
											<Grid container>
												<Grid xs={6} md={6} sm={6} lg={6} xl={6}>
													<Typography
														gutterBottom
														variant="p"
														component="div"
														fontWeight={'700'}
														fontSize={{
															lg: '13px',
															md: '13px',
															sm: '13px',
															xs: '13px',
														}}
														color={'#000'}
														sx={{ my: 1, p: 1, pl: { xl: 10, lg: 10 } }}
														textAlign={'start'}
													>
														Subtotal
													</Typography>
												</Grid>
												<Grid xs={6} md={6} sm={6} lg={6} xl={6}>
													<Typography
														gutterBottom
														variant="p"
														component="div"
														fontWeight={'500'}
														fontSize={{
															lg: '13px',
															md: '13px',
															sm: '13px',
															xs: '13px',
														}}
														color={'#484848'}
														sx={{ my: 1, p: 1 }}
														textAlign={'center'}
													>
														+ {getComaPrice(payment)}
													</Typography>
												</Grid>
												<Grid xs={6} md={6} sm={6} lg={6} xl={6}>
													<Typography
														gutterBottom
														variant="p"
														component="div"
														fontWeight={'700'}
														fontSize={{
															lg: '13px',
															md: '13px',
															sm: '13px',
															xs: '13px',
														}}
														color={'#000'}
														sx={{ p: 1, pl: { xl: 10, lg: 10 } }}
														textAlign={'start'}
													>
														Total Saving
													</Typography>
												</Grid>
												<Grid xs={6} md={6} sm={6} lg={6} xl={6}>
													<Typography
														gutterBottom
														variant="p"
														component="div"
														fontWeight={'500'}
														fontSize={{
															lg: '13px',
															md: '13px',
															sm: '13px',
															xs: '13px',
														}}
														color={'green'}
														textAlign={'center'}
														sx={{ p: 1 }}
													>
														- {getComaPrice(saving)}
													</Typography>
												</Grid>
												<Divider
													sx={{
														border: '1px solid black',
														width: '100%',
														my: 2,
													}}
												/>
												<Grid xs={6} md={6} sm={6} lg={6} xl={6}>
													<Typography
														gutterBottom
														variant="p"
														component="div"
														fontWeight={'700'}
														fontSize={{
															xl: '16px',
															lg: '16px',
															md: '14px',
															sm: '14px',
															xs: '14px',
														}}
														color={'#000'}
														sx={{ p: 1, pl: { xl: 10, lg: 10 } }}
														textAlign={'start'}
													>
														Grand Total
													</Typography>
												</Grid>
												<Grid xs={6} md={6} sm={6} lg={6} xl={6}>
													<Typography
														gutterBottom
														variant="p"
														component="div"
														fontWeight={'600'}
														fontSize={{
															xl: '16px',
															lg: '16px',
															md: '13px',
															sm: '13px',
															xs: '13px',
														}}
														color={'#484848'}
														textAlign={'center'}
														sx={{ p: 1 }}
													>
														{getComaPrice(grandTotal)}
													</Typography>
												</Grid>
											</Grid>
										</Box>
										{saving !== 0 && (
											<div style={{ display: 'flex' }}>
												<Typography
													gutterBottom
													variant="p"
													component="div"
													fontWeight={'600'}
													fontSize={'14px'}
													color={'#10BC10'}
													textAlign={'start'}
													sx={{ p: 1 }}
													pl={{ xl: 10, lg: 10, sm: 2, xs: 2 }}
												>
													You Will Save {getComaPrice(saving)} on this order
												</Typography>
												<div style={{ marginTop: '6px' }}>
													<img src={reward} width={'20px'} height={'20px'} />
													<img src={reward} width={'20px'} height={'20px'} />
												</div>
											</div>
										)}
									</div>
								</Grid>
							</Grid>
						</Box>
					</div>
					<div className="place_order">
						<Button
							variant="contained"
							size="small"
							className="order_btn"
							id="place_btn"
							sx={{
								textAlign: 'center',
								height: '44px',
								width: {
									xl: '250px',
									lg: '250px',
									md: '200px',
									sm: '200px',
									xs: '200px',
								},
								fontWeight: 600,
								fontSize: '12px',
								lineHeight: '18px',
								letterSpacing: '.01em',
								borderRadius: '12px !important',
								boxShadow: 'none !important',
								my: 2,
								background: ' linear-gradient(to right, #141e30, #243b55)',
							}}
							onClick={() => {
								// navigate("/carbooking_success");
								// navigate('/processing_payment');
								newCarBooking();
								// paymentModal();
								// navigate(`/payment_success/${ReplaceId}/${Replace}`);
							}}
						>
							Place Order
						</Button>
					</div>

					<Modal
						open={modal}
						onClose={closeModal}
						className="redeem_point_container"
					>
						<div className="redeem_point">
							<div
								style={{
									// background: '#cf9fff',
									textAlign: 'end',
									// borderRadius: '10px',
									borderTopRightRadius: '20px',
									borderTopLeftRadius: '20px',
								}}
							>
								<CloseIcon
									sx={{ mr: 1, mt: 1, cursor: 'pointer' }}
									onClick={closeModal}
								/>
							</div>
							<h3 className="redeem_heading">Redemption Points</h3>

							<Box>
								<Grid
									container
									xs={12}
									sx={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									{redeemPoint && redeemPoint.length !== 0 && (
										<>
											{redeemPoint.map((item, index) => (
												<Grid
													item
													xs={11}
													sx={{
														boxShadow: 1,
														my: 2,
														textAlign: 'center',
														borderRadius: 3,
														display: 'flex',
														flexDirection: 'column',
														justifyContent: 'center',
													}}
												>
													
															<div>
																<div
																	style={{
																		display: 'flex',
																		flexDirection: 'row',
																		alignItems: 'center',
																		justifyContent: 'center',
																		paddingTop: 15,
																		paddingBottom: 5,
																	}}
																>
																	{/* <ReceiptPercentIcon
                                      size={20}
                                      color={"blue"}
                                    /> */}
																	<img
																		src={redeem_points}
																		style={{
																			widht: '20px',
																			height: '20px',
																			marginRight: '5px',
																		}}
																	/>
																	<Typography
																		style={{
																			fontSize: 18,
																			fontWeight: '600',
																		}}
																	>
																		{'Your ' +
																			item.title +
																			': ' +
																			Math.floor(item.points)}
																	</Typography>
																	<img
																		src={Coin}
																		style={{
																			width: 65,
																			height: 40,
																			objectFit: 'contain',
																			// marginLeft: -10,
																		}}
																	/>
																</div>
																{item.points.toFixed(0) == 0 ? null : (
																	<Typography
																		style={{
																			color: 'green',
																			paddingTop: 10,
																		}}
																	>
																		You are Saving {Math.floor(item.points)}{' '}
																		With This Points
																	</Typography>
																)}

																<div
																	style={{
																		display: 'flex',
																		flexDirection: 'row',
																		alignItems: 'center',
																		justifyContent: 'space-evenly',
																		paddingTop: 20,
																		paddingBottom: 15,
																	}}
																>
																	<TextField
																		id="standard-basic"
																		label=""
																		variant="outlined"
																		size="small"
																		disabled={item.claimed}
																		value={item.selectedPoints}
																		onChange={(e) => {
																			let updated = redeemPoint.map(
																				(ite, i) => {
																					if (i === index) {
																						return {
																							...ite,
																							selectedPoints: e.target.value
																								.split('.')[0]
																								.replace(/[^0-9 ]/g, '')
																								.trim(),
																						};
																					} else {
																						return ite;
																					}
																				}
																			);
																			setRedeemPoint(updated);
																			setError(null);
																		}}
																		placeholder="Enter Points To Redeem"
																		placeholderTextColor="#8c8c8c"
																		style={{
																			color: item.claimed ? '#aaa' : 'black',
																			borderBottomColor: 'grey',
																		}}
																		autoCorrect={false}
																		autoComplete="off"
																	/>
																	{item.claimed ? (
																		<Button
																			className="OfferCoin_Added"
																			variant="contained"
																			onClick={() => {
																				handleNewCar(
																					item.subtitle,
																					item.points,
																					item.selectedPoints
																				);
																			}}
																			sx={{
																				marginBottom: '10px',
																			}}
																		>
																			Added
																		</Button>
																	) : (
																		<Button
																			className="OfferCoin_Add"
																			variant="contained"
																			onClick={() => {
																				openRedeemSuccess();
																				closeModal();
																				handleNewCar(
																					item.subtitle,
																					item.points,
																					item.selectedPoints
																				);
																			}}
																			sx={{
																				marginBottom: '10px',
																			}}
																		>
																			Add
																		</Button>
																	)}
																</div>
																{error === index ? (
																	<Typography
																		style={{
																			marginLeft: 20,
																			marginVertical: 1,
																			fontSize: 14,
																			fontWeight: '600',
																			color: 'red',
																			textAlign: 'start',
																		}}
																	>
																		Please Enter Valid Points&nbsp;
																	</Typography>
																) : (
																	<></>
																)}
															</div>
														
												</Grid>
											))}
										</>
									)}
								</Grid>
							</Box>
						</div>
					</Modal>
				</div>

				<Modal
					open={open}
					onClose={handleSuccessClose}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<Box
						sx={{
							position: 'absolute',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
							boxShadow: 24,
							width: { xl: 550, lg: 550, md: 450, sm: 450, xs: 350 },
							background: 'white',
							py: { xl: 5, lg: 5, md: 5, sm: 4, xs: 4 },
							px: { xl: 8, lg: 8, md: 5, sm: 4, xs: 4 },
							borderRadius: 4,
						}}
					>
						<Grid
							container
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignContent: 'center',
								flexDirection: 'column',
								justifyItems: 'center',
								// py: 2,
							}}
						>
							<Grid
								item
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignContent: 'center',
									flexDirection: 'column',
									justifyItems: 'center',
									textAlign: 'center',
								}}
							>
								<div>
									<img
										src={goto_myorders}
										alt="success icon"
										width={'200px'}
										height={'160px'}
									/>
								</div>
								<Typography
									gutterBottom
									variant="h5"
									component="div"
									fontSize={{ xs: 12, sm: 12, md: 12, lg: 14, xl: 14 }}
									fontWeight={'500'}
									textAlign={'center'}
									pt={3}
									color={'#000'}
								>
									You have already booked this car please go to your orders to
									continue the payment
								</Typography>
								<div>
									<Button
										variant="contained"
										size="small"
										onClick={() => {
											navigate('/about/yourorders');
										}}
										sx={{
											textAlign: 'center',
											height: '44px',
											width: {
												xl: '250px',
												lg: '250px',
												md: '200px',
												sm: '200px',
												xs: '200px',
											},
											fontWeight: 600,
											fontSize: '12px',
											lineHeight: '18px',
											letterSpacing: '.01em',
											borderRadius: '12px !important',
											boxShadow: 'none !important',
											my: 2,
											background:
												' linear-gradient(to right, #141e30, #243b55)',
										}}
									>
										Your Orders
									</Button>
								</div>
							</Grid>
						</Grid>
					</Box>
				</Modal>

				<Modal
					open={redeemSuccess}
					onClose={openRedeemSuccess}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<Box
						sx={{
							position: 'absolute',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
							boxShadow: 24,
							width: { xl: 450, lg: 450, md: 450, sm: 450, xs: 350 },
							background: 'white',
							py: { xl: 5, lg: 5, md: 5, sm: 4, xs: 4 },
							px: { xl: 8, lg: 8, md: 5, sm: 4, xs: 4 },
							borderRadius: 4,
						}}
					>
						<Grid container xs={12}>
							<Grid item textAlign={'center'} xs={12}>
								<img src={SuccessFully_done} width={'120px'} height={'100px'} />
								<Typography
									gutterBottom
									variant="h4"
									component="div"
									fontWeight={'700'}
									fontSize={'20px'}
									color={'#000'}
								>
									You Saved {getComaPrice(saving)}
								</Typography>
								<Typography
									gutterBottom
									variant="h4"
									component="div"
									fontWeight={'700'}
									fontSize={'15px'}
									color={'#444444'}
									my={1.5}
								>
									With this reward points
								</Typography>
								<Typography
									gutterBottom
									variant="h4"
									component="div"
									fontWeight={'700'}
									fontSize={'12px'}
									color={'#274472'}
								>
									Wow! Thanks
								</Typography>
							</Grid>
						</Grid>
					</Box>
				</Modal>
			</div>
		</div>
	);
};
export default Ebook_Details;
