import React, { useState, useEffect } from 'react';
import './Buyform.css';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SpeedIcon from '@mui/icons-material/Speed';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import ApiHandler from '../../../Api/Apihandler';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import TextField from '@mui/material/TextField';
import LoopOutlinedIcon from '@mui/icons-material/LoopOutlined';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { AES, enc } from 'crypto-js';
import { useNavigate } from 'react-router';
import { Success_Screen } from '../../../Components/PaymentsStatus/Success';
import { Failure_Screen } from '../../../Components/Failure/Failure';
import { useLocation } from 'react-router';
import Loader from '../../../Constants/Loader';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import FormControl from '@mui/material/FormControl';
import AddressPopup from '../../../Components/Modal/Modal';
import { List, ListItem, ListItemText } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const Buyform = () => {
	const location = useLocation();

	const [model, setModel] = useState([]);
	const [modelId, setModelId] = useState(null);
	const [variantId, setVariantId] = useState(null);
	const [modelTitle, setModelTitle] = useState(null);
	const [classTitle, setClassTitle] = useState(null);
	const [fuelTitle, setFuelTitle] = useState(null);
	const [transmissionTitle, setTransmissionTitle] = useState(null);
	const [kmsTitle, setKmsTitle] = useState(null);
	const [priceTitle, setPriceTitle] = useState(null);
	const [year, setYear] = useState(null);
	const [variant, setVariant] = useState([]);
	const [filter, setFilter] = useState([]);
	const [choose, setChoose] = useState('brand');
	const [select, setSelect] = useState('Brand');
	const [yearsInRange, setYearsInRange] = useState([]);
	const [selectedYearFrom, setSelectedYearFrom] = useState(null);
	const [selectedYearTo, setSelectedYearTo] = useState(null);
	const [contactModal, setContactModal] = useState(false);
	const [referred, setReferred] = useState(false);
	const [choosenBrandValue, setChoosenBrandValue] = useState('');
	const [choosenModelValue, setChoosenModelValue] = useState('');
	const [choosenFuelValue, setChoosenFuelValue] = useState('');
	const [choosenTransmissionValue, setChoosenTransmissionValue] = useState('');
	const [choosenKms, setChoosenKms] = useState('');
	const [choosenPriceValue, setChoosenPriceValue] = useState('');
	const [profileData, setProfileData] = useState([]);
	const [error, setError] = useState(null);
	const loginId = localStorage.getItem('loggedIn');
	const [autoFill, setAutoFill] = useState();
	const [AddressList, setAddressList] = useState([]);
	const [addressListModal, setAddressListModal] = useState(false);
	const [outlet, setOutlet] = useState({
		address: '',
		pincode: '',
	});
	const [buyForm, setBuyForm] = useState({
		name: '',
		email: '',
		phone: '',
		empName: '',
		empId: '',
	});

	const { filterInt, id } = location.state || {};

	const [loader, setLoader] = useState(true);

	//sucess modal code

	const [open, setOpen] = useState(false);
	const handleSuccessOpen = () => setOpen(true);
	const handleSuccessClose = () => {
		setOpen(false);
		navigate('/');
	};

	// failure model code

	const [failure, setFailure] = useState(false);
	const handleFailureOpen = () => setFailure(true);
	const handleFailureClose = () => {
		setFailure(false);
	};

	useEffect(() => {
		_getBrand(filterInt || id);
		_getModel();
		_getProfile(loginId);
	}, [modelId]);

	const loggedIn = parseInt(loginId);

	const _getProfile = async () => {
		try {
			const response = await ApiHandler.getProfile(loginId);
			const data = response.data.data[0];
			setProfileData(data);
			_getAddress(data.id);
		} catch (error) {
			console.log('profile Data error', error);
		}
	};

	const _getAddress = async (id) => {
		try {
			const response = await ApiHandler.getAddress(id);
			const data = response.data.data;
			if (data) {
				setAddressList(data);
				setLoader(false);
			}
		} catch (error) {
			console.log('Ebook_getAddress error', error);
		}
	};

	const handleOpen = () => setContactModal(true);
	const handleClose = () => setContactModal(false);

	const handleChoosen = (id, title) => {
		setModelId(id);
		setModelTitle(title);
		setSelect('Model');
		setChoose('model');
		setChoosenBrandValue(title);
	};
	const handleModelChoosen = (id, title) => {
		setVariantId(id);
		setClassTitle(title);
		setSelect('Fuel');
		setChoose('fuel');
		setChoosenModelValue(title);
	};

	const handleFuelChoosen = (id, title) => {
		setFuelTitle(title);
		setSelect('Transmission');
		setChoose('transmission');
		setChoosenFuelValue(title);
		const FilterData = variant.filter((item) => item.id === variantId);
		setFilter(FilterData);

		if (FilterData.length > 0) {
			const color = FilterData[0].color;
			console.log('color', color);

			const yearRange = extractYearsFromColor(color);
			setYearsInRange(yearRange);
		} else {
			console.log('No data found for variantId:', variantId);
		}
	};

	const extractYearsFromColor = (color) => {
		const rangePattern = /(\d{4}) to (\d{4})/;
		const singleYearPattern = /(\d{4})/;
		if (color.includes(' to ')) {
			const matches = color.match(rangePattern);
			if (matches && matches.length === 3) {
				const startYear = parseInt(matches[1], 10);
				const endYear = parseInt(matches[2], 10);
				const years = [];
				for (let year = startYear; year <= endYear; year++) {
					years.push(year);
				}
				return years;
			}
		} else if (color.match(singleYearPattern)) {
			const singleMatch = parseInt(color.match(singleYearPattern)[1], 10);
			return [singleMatch];
		}

		return [];
	};

	const handleTransmissionChoosen = (id, title) => {
		setTransmissionTitle(title);
		setSelect('Year');
		setChoose('year');
		setChoosenTransmissionValue(title);
	};

	const handleYearClick = (year) => {
		if (selectedYearFrom === null) {
			setSelectedYearFrom(year);
			setYear(`from ${year}`);
		} else if (
			selectedYearFrom !== null &&
			selectedYearTo === null &&
			selectedYearFrom <= year
		) {
			setSelectedYearTo(year);
			setYear(`${selectedYearFrom} to ${year}`);
			setSelect('Kms');
			setChoose('kms');
		}
	};

	const navigate = useNavigate();
	const BuyCarSubmission = async () => {
		let kmsSplit = kmsTitle.split('-');
		// setLoader(true);
		const data = {
			source: 'UsedCar',
			address: profileData && profileData.profile_address[0].id,
			itemlist: modelId,
			profile: loggedIn,
			item_description: variantId,
			booking_id: '',
			name: (profileData && profileData.user.first_name) || buyForm.name,
			brand: modelTitle,
			model: classTitle,
			email: profileData && profileData.user.email,
			transmission: transmissionTitle,
			fuel: fuelTitle,
			year: year,
			phone: (profileData && profileData.phone) || buyForm.phone,
			kms_driven_starting: kmsSplit[0] + 'km',
			kms_driven_ending: kmsSplit[1],
			price: priceTitle,
			lat: '',
			long: '',
			enquire_at: new Date().toJSON().slice(0, 10),
			scheduled: '',
			employee_id: buyForm.empId,
			referred_by: buyForm.empName,
			type: '',
			number_of_owners: '',
		};

		const response = await ApiHandler.postUsedCarData(data);
		if (response.data.status === 200) {
			handleClose();
			handleSuccessOpen();
		} else {
			handleClose();
			handleFailureOpen();
		}
	};

	const handleKmsChoosen = (id, title) => {
		setKmsTitle(title);
		setSelect('Price');
		setChoose('price');
		setChoosenKms(title);
	};

	const handlePriceChoosen = (id, title) => {
		setPriceTitle(title);

		setChoosenPriceValue(title);
	};

	const onClickBrand = () => {
		setChoose('brand');
		setSelect('Brand');
	};
	const onClickModel = () => {
		if (modelId !== null) {
			setChoose('model');
			setSelect('Model');
		}
	};
	const onClickFuel = () => {
		if (variantId !== null) {
			setChoose('fuel');
			setSelect('Fuel');
		}
	};
	const onClickTransmission = () => {
		if (fuelTitle !== null) {
			setChoose('transmission');
			setSelect('Transmission');
		}
	};
	const onClickYear = () => {
		if (transmissionTitle !== null) {
			setChoose('year');
			setSelect('Year');
		}
	};

	const onClickKms = () => {
		if (year !== null) {
			setChoose('kms');
			setSelect('Kms');
		}
	};

	const onClickPrice = () => {
		if (kmsTitle !== null) {
			setChoose('price');
			setSelect('Price');
		}
	};

	const _getBrand = async () => {
		try {
			const response = await ApiHandler.getModelList(filterInt || id);
			const data = response.data.data;
			setModel(data);
		} catch (error) {
			console.log('error', error);
		}
	};

	const _getModel = async () => {
		try {
			const response = await ApiHandler.getVarientsList('/' + modelId);
			const data = response.data.data;
			setVariant(data);
		} catch (error) {
			console.log('error', error);
		}
	};

	const _updateProfile = async () => {
		// const loginId = localStorage.getItem('loggedIn');
		const data = {
			phone: profileData && profileData.phone,
			gender: profileData && profileData.gender,
			year_of_birth: profileData && profileData.year_of_birth,
			first_name: (profileData && profileData.user.first_name) || buyForm.name,
			lat: '216.321',
			long: '073.310',
			last_name: profileData && profileData.user.last_name,
			email: profileData && profileData.user.email,
			user: profileData && profileData.user.id,
		};

		const response = await ApiHandler.updateProfile(data);
		try {
			if (response.data.status === 200) {
				// console.log('profile change', response.data);
			}
		} catch (error) {
			console.log('profile edit error', error);
		}
	};

	const toastStyle = {
		fontSize: '13px',
	};

	const empname = () => {
		toast.error('Please enter a Employee Name', { style: toastStyle });
	};
	const empcode = () => {
		toast.error('Please enter a Employee Id', { style: toastStyle });
	};
	const address = () =>
		toast.error('Please enter an address', { style: toastStyle });

	const addressValidation = () => {
		if (outlet.address.length === 0 && !autoFill) {
			setError(6);
			address();
		} else {
			setContactModal(true);
		}
	};

	const validation = () => {
		if (referred) {
			if (buyForm.empName.length === 0) {
				setError(1);
				empname();
			} else if (buyForm.empId.length === 0) {
				setError(2);
				empcode();
			} else {
				setError(0);
				_updateProfile();
				BuyCarSubmission();
			}
		} else {
			_updateProfile();
			BuyCarSubmission();
		}
	};

	if (loader) {
		return <Loader />;
	}

	return (
		<div>
			<div>
				<div className="buyForm">
					<Typography
						gutterBottom
						variant="h4"
						component="div"
						fontWeight={'700'}
						fontSize={'20px'}
						color={'#282828'}
						sx={{ my: 3, mx: 2 }}
						textAlign={'center'}
					>
						Find Your Perfect Ride
					</Typography>
					<Box sx={{}}>
						<div className="Form_Models">
							<Grid className="Form_Brands">
								<Card
									sx={{
										my: 2,
										width: {
											xl: '250px',
											lg: '250px',
											md: '200px',
											sm: '200px',
											xs: '200px',
										},
										borderRadius: 4,
										cursor: 'pointer',
										color: choose === 'brand' ? '#fff' : '#000',
										background:
											choose === 'brand'
												? 'linear-gradient(to right, #141e30, #243b55)'
												: '#fff',

										display: 'flex',
										justifyContent: 'center',
									}}
									onClick={() => onClickBrand()}
								>
									<CardHeader
										avatar={
											<BrandingWatermarkIcon
												style={{ color: choose === 'brand' ? '#fff' : 'red' }}
											/>
										}
										title="Brand"
										titleTypographyProps={{
											style: { fontSize: 15, fontWeight: '600' },
										}}
										subheader={modelTitle}
										subheaderTypographyProps={{
											style: {
												color: choose === 'brand' ? '#fff' : '#000',
												marginTop: 2,
												fontSize: 11,
												fontWeight: '500',
											},
										}}
									/>
								</Card>
								<Card
									sx={{
										my: 2,
										width: {
											xl: '250px',
											lg: '250px',
											md: '200px',
											sm: '200px',
											xs: '200px',
										},
										borderRadius: 4,
										display: 'flex',
										justifyContent: 'center',
										alignContent: 'center',
										cursor: 'pointer',
										color: choose === 'model' ? '#fff' : '#000',
										background:
											choose === 'model'
												? 'linear-gradient(to right, #141e30, #243b55)'
												: '#fff',
									}}
									onClick={() => onClickModel()}
								>
									<CardHeader
										avatar={
											<DriveEtaIcon
												style={{ color: choose === 'model' ? '#fff' : 'red' }}
											/>
										}
										title="Model"
										titleTypographyProps={{
											style: { fontSize: 15, fontWeight: '600' },
										}}
										subheader={classTitle}
										subheaderTypographyProps={{
											style: {
												color: choose === 'model' ? '#fff' : '#000',
												marginTop: 2,
												fontSize: 11,
												fontWeight: '500',
											},
										}}
									/>
								</Card>
								<Card
									sx={{
										my: 2,
										width: {
											xl: '250px',
											lg: '250px',
											md: '200px',
											sm: '200px',
											xs: '200px',
										},
										display: 'flex',
										borderRadius: 4,
										justifyContent: 'center',
										alignContent: 'center',
										cursor: 'pointer',
										color: choose === 'fuel' ? '#fff' : '#000',
										background:
											choose === 'fuel'
												? 'linear-gradient(to right, #141e30, #243b55)'
												: '#fff',
									}}
									onClick={() => onClickFuel()}
								>
									<CardHeader
										avatar={
											<LocalGasStationIcon
												style={{ color: choose === 'fuel' ? '#fff' : 'red' }}
											/>
										}
										title="Fuel"
										titleTypographyProps={{
											style: { fontSize: 15, fontWeight: '600' },
										}}
										subheader={fuelTitle}
										subheaderTypographyProps={{
											style: {
												color: choose === 'fuel' ? '#fff' : '#000',
												marginTop: 2,
												fontSize: 11,
												fontWeight: '500',
											},
										}}
									/>
								</Card>
								<Card
									sx={{
										my: 2,
										width: {
											xl: '250px',
											lg: '250px',
											md: '200px',
											sm: '200px',
											xs: '200px',
										},
										display: 'flex',
										borderRadius: 4,
										justifyContent: 'center',
										alignContent: 'center',
										cursor: 'pointer',
										color: choose === 'transmission' ? '#fff' : '#000',
										background:
											choose === 'transmission'
												? 'linear-gradient(to right, #141e30, #243b55)'
												: '#fff',
									}}
									onClick={() => onClickTransmission()}
								>
									<CardHeader
										avatar={
											<SettingsSuggestIcon
												style={{
													color: choose === 'transmission' ? '#fff' : 'red',
												}}
											/>
										}
										title="Transmission"
										titleTypographyProps={{
											style: { fontSize: 15, fontWeight: '600' },
										}}
										subheader={transmissionTitle}
										subheaderTypographyProps={{
											style: {
												color: choose === 'transmission' ? '#fff' : '#000',
												marginTop: 2,
												fontSize: 11,
												fontWeight: '500',
											},
										}}
									/>
								</Card>
								<Card
									sx={{
										my: 2,
										width: {
											xl: '250px',
											lg: '250px',
											md: '200px',
											sm: '200px',
											xs: '200px',
										},
										display: 'flex',
										borderRadius: 4,
										justifyContent: 'center',
										alignContent: 'center',
										cursor: 'pointer',
										color: choose === 'year' ? '#fff' : '#000',
										background:
											choose === 'year'
												? 'linear-gradient(to right, #141e30, #243b55)'
												: '#fff',
									}}
									onClick={() => onClickYear()}
								>
									<CardHeader
										avatar={
											<CalendarMonthIcon
												style={{ color: choose === 'year' ? '#fff' : 'red' }}
											/>
										}
										title="Year"
										titleTypographyProps={{
											style: { fontSize: 15, fontWeight: '600' },
										}}
										subheader={year}
										subheaderTypographyProps={{
											style: {
												color: choose === 'year' ? '#fff' : '#000',
												marginTop: 2,
												fontSize: 11,
												fontWeight: '500',
											},
										}}
									/>
								</Card>
								<Card
									sx={{
										my: 2,
										width: {
											xl: '250px',
											lg: '250px',
											md: '200px',
											sm: '200px',
											xs: '200px',
										},
										display: 'flex',
										borderRadius: 4,
										justifyContent: 'center',
										alignContent: 'center',
										cursor: 'pointer',
										color: choose === 'kms' ? '#fff' : '#000',
										background:
											choose === 'kms'
												? 'linear-gradient(to right, #141e30, #243b55)'
												: '#fff',
									}}
									onClick={() => onClickKms()}
								>
									<CardHeader
										avatar={
											<SpeedIcon
												style={{ color: choose === 'kms' ? '#fff' : 'red' }}
											/>
										}
										title="Kms"
										titleTypographyProps={{
											style: { fontSize: 15, fontWeight: '600' },
										}}
										subheader={kmsTitle}
										subheaderTypographyProps={{
											style: {
												color: choose === 'kms' ? '#fff' : '#000',
												marginTop: 2,
												fontSize: 11,
												fontWeight: '500',
											},
										}}
									/>
								</Card>
								<Card
									sx={{
										my: 2,
										width: {
											xl: '250px',
											lg: '250px',
											md: '200px',
											sm: '200px',
											xs: '200px',
										},
										display: 'flex',
										borderRadius: 4,
										justifyContent: 'center',
										alignContent: 'center',
										cursor: 'pointer',
										color: choose === 'price' ? '#fff' : '#000',
										background:
											choose === 'price'
												? 'linear-gradient(to right, #141e30, #243b55)'
												: '#fff',
									}}
									onClick={() => onClickPrice()}
								>
									<CardHeader
										avatar={
											<PriceChangeIcon
												style={{ color: choose === 'price' ? '#fff' : 'red' }}
											/>
										}
										title="Price"
										titleTypographyProps={{
											style: { fontSize: 15, fontWeight: '600' },
										}}
										subheader={priceTitle}
										subheaderTypographyProps={{
											style: {
												color: choose === 'price' ? '#fff' : '#000',
												marginTop: 2,
												fontSize: 11,
												fontWeight: '500',
											},
										}}
									/>
								</Card>
							</Grid>
						</div>
						{select === 'Brand' && (
							<div>
								<Grid xs={12}>
									<Typography
										gutterBottom
										variant="h4"
										component="div"
										fontWeight={'700'}
										fontSize={'20px'}
										color={'#282828'}
										sx={{ my: 4, mx: 2 }}
										textAlign={'center'}
									>
										Choose Your Brand
									</Typography>
									<Box sx={{ margin: 1 }}>
										<Grid container spacing={2}>
											{Array.isArray(model) &&
												model.length > 0 &&
												model.map((item) => (
													<Grid item xs={6} sm={6} md={2} lg={2} key={item.id}>
														<Card
															onClick={() => handleChoosen(item.id, item.title)}
															sx={{
																backgroundColor:
																	choosenBrandValue === item.title
																		? 'skyblue'
																		: 'white',
																cursor: 'pointer',
																borderRadius: 4,
															}}
														>
															<div className="choosen_brand">
																<div style={{}}>
																	<CardMedia
																		component="img"
																		height="100%"
																		width="100%"
																		image={item.images}
																		alt={item.title}
																	/>
																</div>
																<Typography
																	gutterBottom
																	variant="h5"
																	component="div"
																	fontSize={{ xs: 13 }}
																	fontWeight={'600'}
																	textAlign={'center'}
																	m={1}
																>
																	{item.title}
																</Typography>
															</div>
														</Card>
													</Grid>
												))}
										</Grid>
									</Box>
								</Grid>
							</div>
						)}
						{select === 'Model' && (
							<div>
								<Grid xs={12}>
									<Typography
										gutterBottom
										variant="h4"
										component="div"
										fontWeight={'700'}
										fontSize={'20px'}
										color={'#282828'}
										sx={{ my: 4, mx: 2, borderRadius: 4 }}
										textAlign={'center'}
									>
										Choose Your Model
									</Typography>
									<Box sx={{ margin: 1 }}>
										<Grid container spacing={2}>
											{Array.isArray(variant) &&
												variant.length > 0 &&
												variant.map((item) => (
													<Grid item xs={6} sm={6} md={2} lg={2} key={item.id}>
														<Card
															onClick={() =>
																handleModelChoosen(item.id, item.title)
															}
															sx={{
																backgroundColor:
																	choosenModelValue === item.title
																		? 'skyblue'
																		: 'white',
																cursor: 'pointer',
															}}
														>
															<div className="choosen_model">
																<Typography
																	gutterBottom
																	variant="h5"
																	component="div"
																	fontSize={{ xs: 13 }}
																	fontWeight={'600'}
																	textAlign={'center'}
																	m={1}
																>
																	{item.title}
																</Typography>
															</div>
														</Card>
													</Grid>
												))}
										</Grid>
									</Box>
								</Grid>
							</div>
						)}
						{select === 'Fuel' && (
							<div>
								<Grid xs={12}>
									<Typography
										gutterBottom
										variant="h4"
										component="div"
										fontWeight={'700'}
										fontSize={'20px'}
										color={'#282828'}
										sx={{ my: 4, mx: 2, borderRadius: 4 }}
										textAlign={'center'}
									>
										Choose Your Fuel Type
									</Typography>
									<Box sx={{ margin: 1 }}>
										<Grid container spacing={2}>
											{[
												{ title: 'Petrol' },
												{ title: 'Diesel' },
												{ title: 'CNG' },
												{ title: 'Electric' },
											].map((item, index) => (
												<Grid item xs={6} sm={6} md={2} lg={2} key={index}>
													<Card
														onClick={() =>
															handleFuelChoosen(item.id, item.title)
														}
														sx={{
															backgroundColor:
																choosenFuelValue === item.title
																	? 'skyblue'
																	: 'white',
															cursor: 'pointer',
														}}
													>
														<CardContent className="fuelcard">
															<div
																style={{
																	display: 'flex',
																	flexDirection: 'column',
																	alignItems: 'center',
																	justifyContent: 'center',
																}}
															>
																<Typography
																	gutterBottom
																	variant="h5"
																	component="div"
																	fontSize={{ xs: 13 }}
																	fontWeight={'600'}
																	textAlign={'center'}
																	m={1}
																>
																	{item.title}
																</Typography>
															</div>
														</CardContent>
													</Card>
												</Grid>
											))}
										</Grid>
									</Box>
								</Grid>
							</div>
						)}
						{select === 'Transmission' && (
							<div>
								<Grid xs={12}>
									<Typography
										gutterBottom
										variant="h4"
										component="div"
										fontWeight={'700'}
										fontSize={'20px'}
										color={'#282828'}
										sx={{ my: 4, mx: 2, borderRadius: 4 }}
										textAlign={'center'}
									>
										Choose Your Transmission Type
									</Typography>
									<Box sx={{ margin: 1 }}>
										<Grid container spacing={2}>
											{[{ title: 'Manual' }, { title: 'Automatic' }].map(
												(item, index) => (
													<Grid item xs={6} sm={6} md={2} lg={2} key={index}>
														<Card
															onClick={() =>
																handleTransmissionChoosen(item.id, item.title)
															}
															sx={{
																backgroundColor:
																	choosenTransmissionValue === item.title
																		? 'skyblue'
																		: 'white',
																cursor: 'pointer',
															}}
														>
															<CardContent className="fuelcard">
																<div
																	style={{
																		display: 'flex',
																		flexDirection: 'column',
																		alignItems: 'center',
																		justifyContent: 'center',
																	}}
																>
																	<Typography
																		gutterBottom
																		variant="h5"
																		component="div"
																		fontSize={{ xs: 13 }}
																		fontWeight={'600'}
																		textAlign={'center'}
																		m={1}
																	>
																		{item.title}
																	</Typography>
																</div>
															</CardContent>
														</Card>
													</Grid>
												)
											)}
										</Grid>
									</Box>
								</Grid>
							</div>
						)}
						{select === 'Year' && (
							<div>
								<Grid xs={12}>
									<Typography
										gutterBottom
										variant="h4"
										component="div"
										fontWeight={'700'}
										fontSize={'20px'}
										color={'#282828'}
										sx={{ my: 4, mx: 2, borderRadius: 4 }}
										textAlign={'center'}
									>
										Choose Your Year Range
									</Typography>
									<Box sx={{ my: 4 }}>
										<Grid
											container
											spacing={2}
											alignItems="center"
											justifyContent="center"
										>
											<Grid item xs={5}>
												<TextField
													label="select year from"
													variant="outlined"
													value={
														selectedYearFrom !== null ? selectedYearFrom : ''
													}
													fullWidth
												/>
											</Grid>
											<Grid item xs={5}>
												<TextField
													label="select year to"
													variant="outlined"
													value={selectedYearTo !== null ? selectedYearTo : ''}
													fullWidth
												/>
											</Grid>
											<Grid item xs={1}>
												<LoopOutlinedIcon
													onClick={() => {
														setSelectedYearFrom(null);
														setSelectedYearTo(null);
														setYear(null);
													}}
													style={{ cursor: 'pointer' }}
												/>
											</Grid>
										</Grid>
									</Box>
									<Box sx={{ my: 2 }}>
										<Grid container spacing={2}>
											{Array.isArray(yearsInRange) &&
												yearsInRange.length > 0 &&
												yearsInRange.map((year) => {
													return (
														<Grid item xs={6} sm={6} md={2} lg={2} key={year}>
															<Card
																onClick={() => {
																	if (yearsInRange.length === 1) {
																		console.log('yearsInRange: ', yearsInRange);
																		setSelectedYearFrom(year);
																		setSelectedYearTo(year);
																		setYear(`${year} to ${year}`);
																		setSelect('Kms');
																		setChoose('kms');
																	} else {
																		handleYearClick(year);
																	}
																}}
															>
																<div
																	style={{
																		display: 'flex',
																		flexDirection: 'column',
																		alignItems: 'center',
																		justifyContent: 'center',
																		margin: 'auto',
																		cursor:
																			selectedYearFrom === null
																				? 'pointer'
																				: selectedYearFrom <= year
																				? 'pointer'
																				: 'not-allowed',
																		width: '120px',
																		height: '65px',
																	}}
																>
																	<Typography
																		gutterBottom
																		variant="h5"
																		component="div"
																		fontSize={{ xs: 13 }}
																		fontWeight={'600'}
																		textAlign={'center'}
																		m={1}
																	>
																		{year}
																	</Typography>
																</div>
															</Card>
														</Grid>
													);
												})}
										</Grid>
									</Box>
								</Grid>
							</div>
						)}
						{select === 'Kms' && (
							<div>
								<Grid xs={12}>
									<Typography
										gutterBottom
										variant="h4"
										component="div"
										fontWeight={'700'}
										fontSize={'20px'}
										color={'#282828'}
										sx={{ my: 4, mx: 2, borderRadius: 4 }}
										textAlign={'center'}
									>
										Choose Your Kms
									</Typography>
									<Box sx={{ margin: 1 }}>
										<Grid container spacing={2}>
											{[
												{ title: 'Upto 10000 KM' },
												{ title: '10000-20000 KM' },
												{ title: '20000-30000 KM' },
												{ title: '30000-40000 KM' },
												{ title: '40000-50000 KM' },
												{ title: '50000-60000 KM' },
												{ title: '60000-70000 KM' },
												{ title: '70000-80000 KM' },
												{ title: '80000-90000 KM' },
												{ title: '90000-100000 KM' },
												{ title: 'Above 100000 KM' },
											].map((item, index) => (
												<Grid item xs={6} sm={6} md={2} lg={2} key={index}>
													<Card
														onClick={() =>
															handleKmsChoosen(item.id, item.title)
														}
														sx={{
															backgroundColor:
																choosenKms === item.title ? 'skyblue' : 'white',
															cursor: 'pointer',
														}}
													>
														<CardContent className="fuelcard">
															<div
																style={{
																	display: 'flex',
																	flexDirection: 'column',
																	alignItems: 'center',
																	justifyContent: 'center',
																}}
															>
																<Typography
																	gutterBottom
																	variant="h5"
																	component="div"
																	fontSize={{ xs: 13 }}
																	fontWeight={'600'}
																	textAlign={'center'}
																	m={1}
																>
																	{item.title}
																</Typography>
															</div>
														</CardContent>
													</Card>
												</Grid>
											))}
										</Grid>
									</Box>
								</Grid>
							</div>
						)}
						{select === 'Price' && (
							<div>
								<Grid xs={12}>
									<Typography
										gutterBottom
										variant="h4"
										component="div"
										fontWeight={'700'}
										fontSize={'20px'}
										color={'#282828'}
										sx={{ my: 4, mx: 2, borderRadius: '10px' }}
										textAlign={'center'}
									>
										Choose Your Price
									</Typography>
									<Box sx={{ margin: 1 }}>
										<Grid container spacing={2}>
											{[
												{ title: 'Upto 2 Lakhs' },
												{ title: '2-4 Lakhs' },
												{ title: '4-6 Lakhs' },
												{ title: '6-8 Lakhs' },
												{ title: '8-10 Lakhs' },
												{ title: '10 Above' },
											].map((item, index) => (
												<Grid item xs={6} sm={6} md={2} lg={2} key={index}>
													<Card
														onClick={() =>
															handlePriceChoosen(item.id, item.title)
														}
														sx={{
															backgroundColor:
																choosenPriceValue === item.title
																	? 'skyblue'
																	: 'white',
															cursor: 'pointer',
														}}
													>
														<CardContent className="fuelcard">
															<div
																style={{
																	display: 'flex',
																	flexDirection: 'column',
																	alignItems: 'center',
																	justifyContent: 'center',
																}}
															>
																<Typography
																	gutterBottom
																	variant="h5"
																	component="div"
																	fontSize={{ xs: 13 }}
																	fontWeight={'600'}
																	textAlign={'center'}
																	m={1}
																>
																	{item.title}
																</Typography>
															</div>
														</CardContent>
													</Card>
												</Grid>
											))}
										</Grid>
									</Box>
									<div>
										<Typography
											gutterBottom
											variant="h4"
											component="div"
											fontWeight={'700'}
											fontSize={'20px'}
											color={'#282828'}
											sx={{ my: { xs: 1, sm: 1, md: 3 }, borderRadius: '10px' }}
											textAlign={'start'}
										>
											Address Details <span style={{ color: 'red' }}>*</span>
										</Typography>
										<FormControl
											sx={{
												width: {
													xs: '100%',
													sm: '60%',
													md: '60%',
													xl: '60%',
													lg: '60%',
												},
												display: 'flex',
												flexDirection: 'column',
												justifyContent: 'center',
												alignItems: 'center',
											}}
										>
											{error === 6 && !autoFill ? (
												<>
													{' '}
													<TextField
														fullWidth
														label=""
														variant="outlined"
														size="medium"
														value={
															autoFill
																? `${autoFill.door_no || ''}, ${
																		autoFill.street || ''
																  }`
																: outlet.address
														}
														onChange={(e) =>
															setOutlet({
																...outlet,
																address: e.target.value,
															})
														}
														onClick={() => setAddressListModal(true)}
														multiline
														autoComplete="off"
													/>
												</>
											) : (
												<TextField
													fullWidth
													label=""
													variant="outlined"
													size="medium"
													// defaultValue={outlet.address}
													value={
														autoFill
															? `${autoFill.door_no || ''}, ${
																	autoFill.street || ''
															  }`
															: outlet.address
													}
													onChange={(e) =>
														setOutlet({
															...outlet,
															address: e.target.value,
														})
													}
													onClick={() => setAddressListModal(true)}
													autoComplete="off"
													multiline
												/>
											)}
										</FormControl>
										<Button
											variant="contained"
											size="small"
											onClick={() => {
												addressValidation();
											}}
											sx={{
												textAlign: 'center',
												height: '44px',
												width: {
													xl: '200px',
													lg: '200px',
													md: '150px',
													sm: '150px',
													xs: '150px',
												},
												fontWeight: 600,
												fontSize: '12px',
												lineHeight: '18px',
												letterSpacing: '.01em',
												borderRadius: '5px !important',
												boxShadow: 'none !important',
												my: 2,
												background:
													' linear-gradient(to right, #141e30, #243b55)',
											}}
										>
											save & Continue
										</Button>
									</div>
								</Grid>
							</div>
						)}
					</Box>
					<Modal open={contactModal} close={handleClose}>
						<Box className="contact_modal" onClick={() => handleClose()}>
							<Grid
								className="contact_details"
								onClick={(e) => e.stopPropagation()}
								sx={{
									background: 'white',
								}}
							>
								<Typography
									gutterBottom
									variant="p"
									component="div"
									fontWeight={'600'}
									fontSize={{ xs: '14px', sm: '14px', lg: '18px' }}
									color={'rgba(0,0,0)'}
									my={2}
									textAlign={'start'}
								>
									Your Contact Details
								</Typography>
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<TextField
										id="outlined-basic"
										// label="Enter Your Name"
										placeholder="Enter Your Name"
										value={
											`${profileData.user.first_name} ${profileData.user.last_name}`
												? `${profileData.user.first_name} ${profileData.user.last_name}`
												: buyForm.name
										}
										// onChange={(e) =>
										// 	setBuyForm({ ...buyForm, name: e.target.value })
										// }
										// onChange={(e) => {
										//   const newValue = e.target.value;
										//   setBuyForm((prevUserDetail) => ({
										//     ...prevUserDetail,
										//     name: newValue,
										//   }));
										//   if (profileData) {
										//     const updatedProfileData = [...profileData];
										//     updatedProfileData.user.first_name = newValue;
										//     setProfileData(updatedProfileData);
										//   }
										// }}
										variant="outlined"
										size="small"
										sx={{ my: 2 }}
									/>

									<TextField
										id="outlined-basic"
										label="Enter Your Phone Number"
										variant="outlined"
										value={(profileData && profileData.phone) || buyForm.phone}
										// onChange={(e) =>
										//   setBuyForm({ ...buyForm, phone: e.target.value })
										// }
										size="small"
										sx={{ my: 2 }}
									/>
									<FormGroup>
										<FormControlLabel
											control={
												<Checkbox onClick={() => setReferred(!referred)} />
											}
											label="Have You Been Referred By Our Employee?"
										/>
									</FormGroup>
									{referred && (
										<div>
											{error === 1 ? (
												<div>
													<TextField
														fullWidth
														id="outlined-basic"
														// label="Enter Employee Name"
														variant="outlined"
														placeholder="Enter Employee Name"
														value={buyForm.empName}
														onChange={(e) => {
															const input = e.target.value;
															const correctInput = input.replace(
																/[^A-Za-z\s]/g,
																''
															);
															if (!correctInput.startsWith(' ')) {
																setBuyForm({
																	...buyForm,
																	empName: correctInput,
																});
															}
														}}
														size="small"
														sx={{ my: 2 }}
													/>
												</div>
											) : (
												<TextField
													fullWidth
													id="outlined-basic"
													// label="Enter Employee Name"
													variant="outlined"
													placeholder="Enter Employee Name"
													value={buyForm.empName}
													onChange={(e) => {
														const input = e.target.value;
														const correctInput = input.replace(
															/[^A-Za-z\s]/g,
															''
														);
														if (!correctInput.startsWith(' ')) {
															setBuyForm({ ...buyForm, empName: correctInput });
														}
													}}
													size="small"
													sx={{ my: 2 }}
												/>
											)}
											{error === 2 ? (
												<div>
													<TextField
														fullWidth
														id="outlined-basic"
														// label="Enter Employee Id"
														placeholder="Enter Employee Id"
														variant="outlined"
														value={buyForm.empId}
														size="small"
														onChange={(e) => {
															const input = e.target.value;
															const correctInput = input.replace(
																/[^A-Za-z0-9]/g,
																''
															);
															if (
																!correctInput.startsWith(' ') &&
																correctInput.length <= 11
															) {
																setBuyForm({ ...buyForm, empId: correctInput });
															}
														}}
														sx={{ my: 2 }}
													/>
												</div>
											) : (
												<TextField
													fullWidth
													id="outlined-basic"
													// label="Enter Employee Id"
													placeholder="Enter Employee Id"
													variant="outlined"
													value={buyForm.empId}
													size="small"
													onChange={(e) => {
														const input = e.target.value;
														const correctInput = input.replace(
															/[^A-Za-z0-9]/g,
															''
														);
														if (
															!correctInput.startsWith(' ') &&
															correctInput.length <= 11
														) {
															setBuyForm({ ...buyForm, empId: correctInput });
														}
													}}
													sx={{ my: 2 }}
												/>
											)}
										</div>
									)}
									<div style={{ textAlign: 'end', marginTop: 10 }}>
										<Button
											variant="contained"
											size="small"
											onClick={() => {
												validation();
											}}
											sx={{
												textAlign: 'center',
												height: '44px',
												width: {
													xl: '200px',
													lg: '200px',
													md: '150px',
													sm: '150px',
													xs: '150px',
												},
												fontWeight: 600,
												fontSize: '12px',
												lineHeight: '18px',
												letterSpacing: '.01em',
												borderRadius: '5px !important',
												boxShadow: 'none !important',
												my: 2,
												background:
													' linear-gradient(to right, #141e30, #243b55)',
											}}
										>
											Submit
										</Button>
									</div>
								</div>
							</Grid>
						</Box>
					</Modal>
				</div>
			</div>
			<Modal open={addressListModal} className="address_modal_container">
				<div
					className="addressList_modal_service"
					onClick={() => setAddressListModal(false)}
				>
					<div
						className="addressList_service"
						onClick={(e) => {
							e.stopPropagation();
						}}
					>
						<div style={{ textAlign: 'end' }}>
							<CloseIcon onClick={() => setAddressListModal(false)} />
						</div>

						<List>
							{AddressList.profile_address.map((item, index) => (
								<ListItem
									onClick={() => {
										setAutoFill(item);
										setAddressListModal(false);
									}}
									sx={{
										justifyContent: 'center',
										display: 'flex',
									}}
								>
									<div className="list_service">
										<Box sx={{ flexGrow: 1 }}>
											<ListItemText
												primary={
													<span
														style={{
															fontSize: 21,
															fontWeight: '700',
															paddingTop: 12,
															paddingBottom: 12,
														}}
													>
														<span></span> <span>{item.type_of_address}</span>
													</span>
												}
											/>

											<ListItemText
												sx={{ my: 1 }}
												primary={
													<span>
														{item.door_no}
														{','}
														{item.street}
													</span>
												}
											/>
										</Box>
									</div>
								</ListItem>
							))}
						</List>
					</div>
				</div>
			</Modal>
			<Modal
				open={open}
				onClose={handleSuccessClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Success_Screen
					handleSuccessClose={handleSuccessClose}
					title={'Successfully Submitted details'}
					ThankTitle={'Thank You!'}
				/>
			</Modal>

			<Modal
				open={failure}
				onClose={handleFailureClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Failure_Screen
					handleFailureClose={handleFailureClose}
					title={'Failed to Submit Details'}
				/>
			</Modal>
		</div>
	);
};
export default Buyform;
