import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import "./Card.css";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { getComaPrice } from "../../Constants/Comaprice";
import { TextField } from "@mui/material";
import Modal from "@mui/material/Modal";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

export default function Carcard({
  title,
  price,
  images,
  handleClick,
  username,
  phone,
  carTitle,
}) {
  const [onroadModel, setOnroadModel] = useState(false);
  const [thankOpen, setThankOpen] = useState(false);
  // let navigate = useNavigate();
  // const handleClick = () => {
  //   navigate('/variant')
  // }
  const onClickModel = () => {
    setOnroadModel(true);
  };
  const handleonRoadClose = () => {
    setOnroadModel(false);
  };

  const thankyouOpen = () => setThankOpen(true);
  const thankyouClose = () => setThankOpen(false);

  const isCardWidth = useMediaQuery("(max-width:450px)");
  const isFontSize = useMediaQuery("(max-width:500px)");
  const isSmallScreen = useMediaQuery("(max-width:767px)");
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 368,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 3,
  };
  return (
    <div className="main_card">
      <Box sx={{ marginTop: 2 }}>
        <Grid>
          <Card
            onClick={handleClick}
            sx={{
              width: "100%",
              height: isCardWidth ? "auto" : "auto",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              borderRadius: 6,
              cursor: "pointer",
              border: "0.1px solid #eee",
              boxShadow: "none",
              transition: "transform 1s ease-in-out",
              transition: "transform 0.4s ease-in",
              "&:hover": {
                position: "relative",
                transform: "scale(1.05)",
              },
            }}
          >
            <div className="card_img">
              <img
                src={images}
                alt={carTitle}
                style={{
                  width: "100%",
                  // height: isSmallScreen ? "auto" : "200px",
                }}
              />
            </div>
            <CardContent sx={{ background: "#fafafa" }}>
              <div>
                <div
                  style={{
                    height: isSmallScreen ? "40px" : "40px",
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "nowrap",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography
                    gutterBottom
                    variant="h4"
                    component="div"
                    fontWeight={"600"}
                    fontSize={{ xs: "14px", sm: "16px" }}
                    color={"#002441"}
                    // sx={{ paddingTop: "16px !important" }}
                  >
                    {title}
                  </Typography>
                </div>

                <Typography
                  gutterBottom
                  variant="p"
                  component="div"
                  fontSize={{ xs: "13px", sm: "14px" }}
                  color={"#465166"}
                >
                  Ex-Showroom Price
                </Typography>
                <Typography
                  gutterBottom
                  variant="p"
                  fontSize={{ xs: "13px", sm: "14px" }}
                  fontWeight={"600"}
                  component="div"
                  color={"#465166"}
                >
                  {getComaPrice(price)}
                </Typography>
                <div>
                  <Button
                    sx={{
                      fontWeight: 600,
                      fontSize: "12px",
                      textAlign: "start",
                      color: "#eb232a",
                      lineHeight: "22px",
                      my: 2,
                      textTransform: "none",
                    }}
                    size="small"
                    onClick={handleClick}
                    className="knowmorebtn"
                  >
                    KNOW MORE
                    <KeyboardArrowRightIcon />
                  </Button>
                </div>
              </div>
            </CardContent>
            {/* <CardActions sx={{ display: 'flex', flexDirection: 'column' }}> */}
            {/* <Button
								sx={{
									my: 0.5,
									width: '100%',
									padding: {
										xs: '8px 8px',
										sm: '8px 15px',
										md: '8px 15px',
										lg: '8px 15px',
										xl: '8px 15px',
									},
									fontWeight: 600,
									fontSize: { xs: '8px', sm: '12px', lg: '12px' },
									background: 'linear-gradient(to right, #a73737, #7a2828)',
									'&:hover': {
										background: 'white',
										color: '#3056A1',
									},
								}}
								variant="contained"
								size="small"
								className="card_btn"
								onClick={() => {
									onClickModel();
								}}
							>
								Show On-Road Price
							</Button> */}

            {/* </CardActions> */}
          </Card>
        </Grid>
      </Box>
      <Box sx={{ flexGrow: 1, my: 1 }}>
        <Grid>
          <Grid container>
            <Grid item xs={6}></Grid>
            <Grid item xs={6} sx={{ textAlign: "center" }}>
              <Modal
                open={onroadModel}
                close={handleonRoadClose}
                className="details_onroad_price"
              >
                <Box className="on_road_modal modal">
                  <Grid className="onroad_price">
                    {/* <Onroadprice backgroundImage={backgroundImage} style={{}}/>
                        <div style={{textAlign:'end'}}>
                          <Button variant="contained" size='small' onClick={handleonRoadClose} >Close</Button>
                        </div> */}
                    <Typography
                      gutterBottom
                      variant="p"
                      component="div"
                      fontWeight={"700"}
                      fontSize={{ xs: "14px", sm: "14px", lg: "20px" }}
                      color={"rgba(0,0,0)"}
                      my={2}
                      textAlign={"center"}
                    >
                      Get On-road price
                    </Typography>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <TextField
                        id="outlined-basic"
                        label="Enter Your Name"
                        variant="outlined"
                        value={username}
                        size="small"
                        sx={{ my: 2 }}
                      />
                      <TextField
                        id="outlined-basic"
                        label="Enter Your Phone Number"
                        variant="outlined"
                        value={phone}
                        size="small"
                        sx={{ my: 2 }}
                      />
                      <TextField
                        id="outlined-basic"
                        // label="Enter Your Phone Number"
                        variant="outlined"
                        value={title}
                        size="small"
                        sx={{ my: 2 }}
                      />
                      <Button
                        variant="contained"
                        size="small"
                        className="modal_onroad"
                        sx={{
                          fontSize: {
                            xs: "12px",
                            sm: "12px",
                            md: "14px",
                            textTransform: "none",
                            marginTop: 10,
                          },
                          fontWeight: 500,
                          letterSpacing: 0.5,
                        }}
                        onClick={() => {
                          handleonRoadClose();
                          thankyouOpen();
                        }}
                      >
                        Check On-Road Price
                      </Button>

                      <div style={{ textAlign: "end", marginTop: 10 }}>
                        <Button
                          variant="text"
                          size="small"
                          onClick={() => {
                            handleonRoadClose();
                          }}
                          sx={{
                            textTransform: "none",
                            fontSize: { xs: "12px", sm: "12px", md: "16px" },
                          }}
                          fontWeight={"700"}
                        >
                          Close
                        </Button>
                      </div>
                    </div>
                  </Grid>
                </Box>
              </Modal>
              <div>
                <Modal
                  open={thankOpen}
                  onClose={thankyouClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      Thank you for your interest in we will get in touch with
                      you soon...
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingTop: 20,
                      }}
                    >
                      <Button
                        variant="text"
                        onClick={thankyouClose}
                        sx={{
                          fontSize: "14px",
                          fontWeight: "700",
                          textTransform: "none",
                          color: "#2193b0",
                          cursor: "pointer",
                        }}
                      >
                        Close
                      </Button>
                    </div>
                  </Box>
                </Modal>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
