import React, { useState, useEffect, Fragment } from 'react';
import ApiHandler from '../../Api/Apihandler';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import Modal from '@mui/material/Modal';

import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import { InputLabel } from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { toast } from 'react-toastify';
import cloud_computing from '../../Assets/Images/Icons/cloud-computing.png';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useNavigate } from 'react-router-dom';

const PanVerifyModal = ({
	showPanVerifyModal,
	setShowPanVerifyModal,
	details,
	btn,
	variant,
	id,
}) => {
	const navigate = useNavigate();

	const profileId = localStorage.getItem('loggedIn');

	const [error, setError] = useState(null);
	const [pandetail, setPanDetail] = useState({
		panNum: '',
		panName: '',
		panDOB: '',
	});

	const [selectedFile, setSelectedFile] = useState(null);
	const [profilePanId, setPanProfileId] = useState('');
	const [responseData, setResponseData] = useState('');
	const [open, setOpen] = useState(false);
	const [basePic, setBasePic] = useState(null);

	//  const handleOpen = () => setOpen(true);

	useEffect(() => {
		const loginId = localStorage.getItem('loggedIn');
		setPanProfileId(loginId);
	});

	const intprofile = parseInt(profileId);

	const dobDate = new Date(pandetail.panDOB);
	const formattedDate = dobDate.toLocaleDateString('en-GB', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
	});

	const pan_verify = async () => {
		const data = {
			profile_id: intprofile,
			name_as_per_pan: pandetail.panName.toUpperCase(),
			pan_number: 'ABCPV1234D',
			dob: formattedDate.replace(/\//g, '-'),
			pan_image: basePic,
			// verified_pan: "True",
		};
		const response = await ApiHandler.verifyPan(data);
		try {
			if (response.data.status === 200) {
				setShowPanVerifyModal(false);
				if (btn === ' Myself') {
					const Replace = details[0].subtitle.replace(/ /g, '_');
					navigate(`/ebook/${id}/${Replace}`, {
						state: {
							// title,
							id: details[0].id,
							channel: variant[0].channel,
							subtitle: details[0].subtitle,
							img: details.length > 0 && details[0].images[0],
							itemlist: details[0].itemlist,
							btnTitle: btn,
						},
					});
					setShowPanVerifyModal(false);
				} else {
					const Replace = details[0].subtitle.replace(/ /g, '_');
					navigate(`/ebook/${id}/${Replace}`, {
						state: {
							// title,
							id: details[0].id,
							channel: variant[0].channel,
							subtitle: details[0].subtitle,
							img: details.length > 0 && details[0].images[0],
							itemlist: details[0].itemlist,
							btnTitle: btn,
						},
					});
					setShowPanVerifyModal(false);
				}
			}
		} catch (error) {
			console.log('pan verify catch error', error);
		}
	};

	const pan_Name = () => {
		toast.error('Enter valid name as per PAN.');
	};
	const panNumber = () => {
		toast.error('Enter a valid PAN number.');
	};
	const panDateofBirth = () => {
		toast.error('Enter DOB as per PAN.');
	};

	const today = new Date();

	const maxDate = new Date(
		today.getFullYear() - 18,
		today.getMonth(),
		today.getDate()
	);

	const validation = () => {
		if (
			pandetail.panNum.length === 0
			// !pandetail.panNum.match("[A-Z]{5}[0-9]{4}[A-Z]{1}")
		) {
			setError(1);
			panNumber();
		} else if (
			pandetail.panName.length === 0 ||
			!pandetail.panName.match('[A-Zs]+')
		) {
			setError(2);
			pan_Name();
		} else if (pandetail.panDOB.length === 0) {
			setError(3);
			panDateofBirth();
		} else if (!selectedFile) {
			setError(4);
			uploadError();
		} else {
			pan_verify();
		}
	};

	const handleDelete = () => {
		setSelectedFile(null);
		document.getElementById('image-file-input').value = '';
	};

	const handleFileChange = (event) => {
		const file = event.target.files[0];

		if (file && file.name === selectedFile?.name) {
			document.getElementById('image-file-input').value = '';
		} else {
			setSelectedFile(file);
			upload();

			const reader = new FileReader();
			reader.onloadend = () => {
				let baseData = reader.result;
				const base64Data = baseData.split(',')[1];
				setBasePic(base64Data);
			};

			reader.readAsDataURL(file);
		}
	};

	const toastStyle = {
		fontSize: '13px',
	};
	const upload = () => {
		toast.success('Your PAN Image upload successfully!', {
			style: toastStyle,
		});
	};
	const uploadError = () => {
		toast.error('Please Upload PAN Image', {
			style: toastStyle,
		});
	};

	return (
		<Modal
			open={showPanVerifyModal}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box
				sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					width: { xl: 700, lg: 700, md: 500, sm: 400, xs: 350 },
					bgcolor: 'white',
					boxShadow: 24,
					borderRadius: 4,
				}}
			>
				<div
					style={{
						textAlign: 'end',
					}}
				>
					<CloseIcon
						onClick={() => setShowPanVerifyModal(false)}
						sx={{
							width: '34px',
							height: '34px',
							mt: 1,
							mr: 1,
							borderRadius: '50%',
							backgroundColor: '#F7F7F7',
							cursor: 'pointer',
							padding: 1,
						}}
					/>
				</div>
				<Grid container px={4} className="panverify_modal">
					<Grid item xs={12}>
						<Typography
							gutterBottom
							variant="h5"
							component="div"
							fontSize={{
								lg: '20px',
								md: '16px',
								sm: '14px',
								xs: '14px',
							}}
							fontWeight={700}
							color={'ffff'}
							textAlign={'center'}
						>
							Verify Your Identity for Car Booking
						</Typography>{' '}
						<InputLabel
							sx={{
								fontSize: {
									xs: '13px',
									md: '13px',
									lg: '13px',
									fontWeight: 700,
								},
								my: 1.5,

								textAlign: 'start',
							}}
						>
							PAN Number <span style={{ color: 'red' }}>*</span>
						</InputLabel>
						{error === 1 ? (
							<div>
								<TextField
									autoComplete="off"
									fullWidth
									label=""
									id="fullWidth"
									placeholder="Enter Pan Number"
									size="small"
									value={pandetail.panNum}
									onChange={(e) => {
										const input = e.target.value;
										const sanIniInput = input.replace(/[^A-za-z0-9]/g, '');
										if (sanIniInput.length <= 10) {
											setPanDetail({
												...pandetail,
												panNum: sanIniInput.toUpperCase(),
											});
										}
									}}
								/>
							</div>
						) : (
							<TextField
								autoComplete="off"
								fullWidth
								label=""
								id="fullWidth"
								placeholder="Enter Pan Number"
								size="small"
								value={pandetail.panNum}
								onChange={(e) => {
									const input = e.target.value;

									const sanIniInput = input.replace(/[^A-za-z0-9]/g, '');
									if (sanIniInput.length <= 10) {
										setPanDetail({
											...pandetail,
											panNum: sanIniInput.toUpperCase(),
										});
									}
								}}
							/>
						)}
						<InputLabel
							sx={{
								fontSize: {
									xs: '13px',
									md: '13px',
									lg: '13px',
									fontWeight: 700,
								},
								my: 1.5,
								textAlign: 'start',
							}}
						>
							Enter Name As Per PAN
							<span style={{ color: 'red' }}>*</span>
						</InputLabel>
						{error === 2 ? (
							<div>
								<TextField
									fullWidth
									autoComplete="off"
									label=""
									size="small"
									variant="outlined"
									placeholder="Enter Name"
									value={pandetail.panName}
									onChange={(e) => {
										const input = e.target.value;
										const correctInput = input.replace(/[^A-Za-z\s]/g, '');
										if (!correctInput.startsWith(' ')) {
											setPanDetail({ ...pandetail, panName: correctInput });
										}
									}}
								/>
							</div>
						) : (
							<TextField
								fullWidth
								autoComplete="off"
								id="outlined-basic"
								label=""
								size="small"
								variant="outlined"
								placeholder="Enter Name"
								value={pandetail.panName}
								onChange={(e) => {
									const input = e.target.value;
									const correctInput = input.replace(/[^A-Za-z\s]/g, '');
									if (!correctInput.startsWith(' ')) {
										setPanDetail({ ...pandetail, panName: correctInput });
									}
								}}
							/>
						)}
						<InputLabel
							sx={{
								fontSize: {
									xs: '13px',
									md: '13px',
									lg: '13px',
									fontWeight: 700,
								},
								textAlign: 'start',
								mt: 1.5,
								mb: 1,
							}}
						>
							Select Your DOB
							<span style={{ color: 'red' }}>*</span>
						</InputLabel>
						{error === 3 ? (
							<div>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DemoContainer components={['DesktopDatePicker']}>
										<DesktopDatePicker
											className="date_picker"
											label=""
											value={pandetail.panDOB}
											onChange={(newValue) => {
												setPanDetail({
													...pandetail,
													panDOB: newValue,
												});
											}}
											slotProps={{
												textField: { size: 'small' },
											}}
											sx={{
												width: '100%',
												'& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
													{
														borderColor: '#B1B1B1',
													},
											}}
										/>
									</DemoContainer>
								</LocalizationProvider>
							</div>
						) : (
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DemoContainer components={['DesktopDatePicker']}>
									<DesktopDatePicker
										className="date_picker"
										label=""
										// maxDate={maxDate}
										value={pandetail.panDOB}
										onChange={(newValue) => {
											setPanDetail({
												...pandetail,
												panDOB: newValue,
											});
										}}
										sx={{
											width: '100%',
											'& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
												{
													borderColor: '#B1B1B1',
												},
										}}
									/>
								</DemoContainer>
							</LocalizationProvider>
						)}
						{/* {error === 4 ? (
									<InputLabel
										sx={{
											fontSize: {
												xs: '13px',
												md: '13px',
												lg: '13px',
												fontWeight: 700,
											},
											my: 1.5,

											textAlign: 'start',
										}}
									>
										Upload Image<span style={{ color: 'red' }}>*</span>
									</InputLabel>
								) : (
									<div>
										<InputLabel
											sx={{
												fontSize: {
													xs: '13px',
													md: '13px',
													lg: '13px',
													fontWeight: 700,
												},
												my: 1.5,

												textAlign: 'start',
											}}
										>
											Upload Image <span style={{ color: 'red' }}>*</span>
										</InputLabel>
										<Button
											component="label"
											variant="contained"
											startIcon={<CloudUploadIcon />}
											type="file"
										>
											Upload file
										</Button>
									</div>
								)} */}
						{error === 4 ? (
							<div>
								<Box
									pt={1}
									pb={3}
									px={3}
									// border="1px dashed #000000"
									// borderRadius={2}
									textAlign="center"
									sx={{
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										alignContent: 'center',
										background: '#f3f3fe',
										mt: 1,
									}}
								>
									<InputLabel
										sx={{
											fontSize: {
												xs: '13px',
												md: '13px',
												lg: '13px',
												fontWeight: 700,
											},
											pb: 1,
											textAlign: 'start',
										}}
									>
										Upload Image <span style={{ color: 'red' }}>*</span>
									</InputLabel>
									<div
										style={{
											border: '1px dashed #000000',
											borderRadius: '3px',
											background: 'white',
											padding: '15px',
										}}
									>
										<div style={{ textAlign: 'center' }}>
											<img
												src={cloud_computing}
												style={{
													height: '40px',
													width: '40px',
												}}
											/>
										</div>

										<input
											type="file"
											accept="image/*"
											onChange={handleFileChange}
											style={{ display: 'none' }}
											id="image-file-input"
										/>
										<label htmlFor="image-file-input">
											<Button
												sx={{
													fontWeight: 600,
													fontSize: '12px',
													lineHeight: '18px',
													letterSpacing: '.01em',
													textTransform: 'none',
												}}
												component="span"
											>
												Upload a File
											</Button>
										</label>
									</div>
									{selectedFile && (
										<div
											style={{
												display: 'flex',
												justifyContent: 'space-between',
												marginTop: '6px',
												background: '#dae6ff',
											}}
										>
											<Typography variant="subtitle1">
												{selectedFile.name}
											</Typography>
											<DeleteOutlineIcon
												sx={{
													color: 'red',
													cursor: 'pointer',
												}}
												onClick={handleDelete}
											/>
										</div>
									)}
								</Box>
							</div>
						) : (
							<div>
								<Box
									pt={1}
									pb={3}
									px={3}
									// border="1px dashed #000000"
									// borderRadius={2}
									textAlign="center"
									sx={{
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										alignContent: 'center',
										background: '#f3f3fe',
										mt: 1,
									}}
								>
									<InputLabel
										sx={{
											fontSize: {
												xs: '13px',
												md: '13px',
												lg: '13px',
												fontWeight: 700,
											},
											pb: 1,
											textAlign: 'start',
										}}
									>
										Upload Image <span style={{ color: 'red' }}>*</span>
									</InputLabel>
									<div
										style={{
											border: '1px dashed #000000',
											borderRadius: '3px',
											background: 'white',
											padding: '15px',
										}}
									>
										<div style={{ textAlign: 'center' }}>
											<img
												src={cloud_computing}
												style={{
													height: '40px',
													width: '40px',
												}}
											/>
										</div>

										<input
											type="file"
											accept="image/*"
											onChange={handleFileChange}
											style={{ display: 'none' }}
											id="image-file-input"
										/>
										<label htmlFor="image-file-input">
											<Button
												sx={{
													fontWeight: 600,
													fontSize: '12px',
													lineHeight: '18px',
													letterSpacing: '.01em',
													textTransform: 'none',
												}}
												component="span"
											>
												Upload a File
											</Button>
										</label>
									</div>
									{selectedFile && (
										<div
											style={{
												display: 'flex',
												justifyContent: 'space-between',
												marginTop: '6px',
												background: '#dae6ff',
											}}
										>
											<Typography variant="subtitle1">
												{selectedFile.name}
											</Typography>
											<DeleteOutlineIcon
												sx={{
													color: 'red',
													cursor: 'pointer',
												}}
												onClick={handleDelete}
											/>
										</div>
									)}
								</Box>
							</div>
						)}
						<div style={{ textAlign: 'center' }}>
							<Button
								variant="contained"
								size="medium"
								sx={{
									textAlign: 'center',
									height: '44px',
									width: {
										xl: '250px',
										lg: '250px',
										md: '200px',
										sm: '200px',
										xs: '200px',
									},
									fontWeight: 600,
									fontSize: '12px',
									lineHeight: '18px',
									letterSpacing: '.01em',
									borderRadius: '12px !important',
									boxShadow: 'none !important',
									background: ' linear-gradient(to right, #141e30, #243b55)',
									my: 1.5,
								}}
								onClick={() => {
									validation();
								}}
							>
								{' '}
								SUBMIT
							</Button>
						</div>
					</Grid>
				</Grid>
			</Box>
		</Modal>
	);
};

export default PanVerifyModal;
