import React, { useState, useEffect } from "react";
import "./Success.css";
import { Box, Grid, Typography, Button } from "@mui/material";
import PaymentSuccess_gif from "../../Assets/Images/Gifs/payment_successful.gif";
import PaymentFailure_gif from "../../Assets/Images/Gifs/payment_error.gif";
import { useNavigate } from "react-router";
import { getComaPrice } from "../../Constants/Comaprice";
import SuccessfullyDone from "../../Assets/Images/Gifs/Successfully_Done.gif";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import "core-js/stable/atob";
import Service_Success_img from "../../Assets/Images/Service_Success.png";
import reward from "../../Assets/Images/reward.png";
import { useParams } from "react-router-dom";
import { verifyBookingPayment} from "../../Pages/EBooking/Gateways/GatewayCommonFunction"
import { formatDate } from "../../Constants/FormatDateTime";
import { normalFormatDate } from "../../Constants/FormatDateTime";
import Loader from "../../Constants/Loader";

export function PaymentsStatus() {
  const navigate = useNavigate()
  const [statusData, setStatusData] = useState(null);
  console.log('statusData: ', statusData);
  const { token } = useParams();
  const [loading,setLoading]= useState(true)

  useEffect(() => {
    if (token) {  
     verifyBookingPayment(token, (status,data)=>{
      setTimeout(()=>{
          setLoading(false)
       },20000)
      setStatusData(data);
      });
    }
  }, [token]);

  if(loading){
    return  <Loader/>
  }
  
  console.log('statusData?.booking_id: ', statusData?.booking_id);

  const {payment_status,amount,booked_at,name,gateway_order_id,payment_verified_at,e_point,i_point,gross_amount} = statusData?.booking_id;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          p: 3,
          background: "#fafafa",
        }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            display: "flex",
            justifyContent: {
              xl: "space-around",
              lg: "space-around",
              md: "space-around",
              sm: "space-around",
              xs: "space-between",
            },
            alignContent: "center",
            mt: 2.5,
          }}
        >
          <img
            src="https://kalyanimotors.com/static/media/kalyani_dark.5bca4adb5ec73478b2ce.png"
            alt="kalyani_log"
            width={"120px"}
            height={"50px"}
          />

          <Typography
            gutterBottom
            variant="h5"
            component="div"
            fontSize={{ xs: 13, sm: 13, md: 16, lg: 18, xl: 18 }}
            fontWeight={"700"}
            textAlign={"center"}
            color={"#003B73"}
            mt={1}
          >
            PAYMENT RECEIPT
          </Typography>
        </Grid>

        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            justifyItems: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              justifyItems: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ textAlign: "center", marginTop: 1, marginBottom: 1 }}>
              <img
                src={payment_status === "Success" ? PaymentSuccess_gif : PaymentFailure_gif}
                alt="success gif"
                width={"140px"}
                height={"120px"}
              />
            </div>
           <Typography
            gutterBottom
            variant="h2"
            component="div"
            fontWeight={"700"}
            fontSize={{
              md: "14px",
              sm: "14px",
              xs: "14px",
              lg: "20px",
              xl: "20px",
            }}
            color={payment_status === "Success" ? "green" : "red"}
            textAlign={"center"}
            my={1}
          >
            {amount ? getComaPrice(amount) : "No Data Availble"} /-
          </Typography>

            <Typography
              gutterBottom
              variant="h2"
              component="div"
              fontWeight={"700"}
              fontSize={{
                md: "14px",
                sm: "14px",
                xs: "14px",
                lg: "20px",
                xl: "20px",
              }}
              color={payment_status === "Success" ? "green" : "red"}
              textAlign={"center"}
            >
             {payment_status === "Success" ? "Payment Success" : " Payment Faild"} 
            </Typography>
            <Typography
              gutterBottom
              variant="p"
              component="div"
              fontWeight={"500"}
              fontSize={{
                md: "14px",
                sm: "14px",
                xs: "14px",
                lg: "16px",
                xl: "16px",
              }}
              color={"#000"}
              textAlign={"center"}
            >
              Booked At: {booked_at ? formatDate(booked_at) : 'No Data Available'}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            display: {
              xl: "flex",
              lg: "flex",
              md: "flex",
              sm: "none",
              xs: "none",
            },
            justifyContent: "center",
            flexDirection: "row",
            justifyItems: "center",
            alignContent: "center",
            alignItems: "center",
            gap: 4,
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              mt: 3,
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              gutterBottom
              variant="h2"
              component="div"
              fontWeight={"700"}
              fontSize={{
                md: "14px",
                sm: "14px",
                xs: "14px",
                lg: "16px",
                xl: "16px",
              }}
              color={"#000"}
              textAlign={"center"}
            >
              BOOKING DETAIL
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              mt: 3,
              width: "100%",

              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              gutterBottom
              variant="h2"
              component="div"
              fontWeight={"700"}
              fontSize={{
                md: "14px",
                sm: "14px",
                xs: "14px",
                lg: "16px",
                xl: "16px",
              }}
              color={"#000"}
              textAlign={"center"}
            >
              BILL DETAIL
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
            justifyItems: "center",
            alignContent: "center",
            alignItems: "center",
            gap: 4,
          }}
        >
          <Grid
            item
            xs={12}
            sm={10}
            md={5}
            lg={5}
            xl={5}
            sx={{
              // boxShadow: 1,
              my: 2,
              width: "100%",
              p: 4,
              display: "flex",
              justifyContent: "center",
              background: "white",
              borderRadius: 4,
            }}
          >
            <Grid
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                display: "flex",
                justifyItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                gutterBottom
                variant="h2"
                component="div"
                fontWeight={"700"}
                fontSize={{
                  md: "14px",
                  sm: "14px",
                  xs: "14px",
                  lg: "16px",
                  xl: "16px",
                }}
                color={"#000"}
                textAlign={"center"}
                display={{
                  xl: "none",
                  lg: "none",
                  md: "none",
                  sm: "flex",
                  xs: "flex",
                }}
              >
                BOOKING DETAIL
              </Typography>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  justifyItems: "center",
                  px: { xl: 6, lg: 6, sm: 2 },
                }}
              >
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  display={"flex"}
                  flexDirection={"column"}
                >
                  <Typography
                    gutterBottom
                    variant="p"
                    component="div"
                    fontSize={{
                      xs: "13px",
                      sm: "14px",
                      md: "15px",
                      lg: "14px",
                      xl: "14px",
                    }}
                    fontWeight={"600"}
                    color={"#274472"}
                    my={2}
                  >
                    Name :
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="p"
                    component="div"
                    fontSize={{
                      xs: "13px",
                      sm: "14px",
                      md: "15px",
                      lg: "14px",
                      xl: "14px",
                    }}
                    fontWeight={"600"}
                    color={"#274472"}
                    my={2}
                  >
                    Transaction No :
                  </Typography>
                    <Typography
                    gutterBottom
                    variant="p"
                    component="div"
                    fontSize={{
                      xs: "13px",
                      sm: "14px",
                      md: "15px",
                      lg: "14px",
                      xl: "14px",
                    }}
                    fontWeight={"600"}
                    color={"#274472"}
                    my={2}
                  >
                    Payment Received At :
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="p"
                    component="div"
                    fontSize={{
                      xs: "13px",
                      sm: "14px",
                      md: "15px",
                      lg: "14px",
                      xl: "14px",
                    }}
                    fontWeight={"600"}
                    color={"#274472"}
                    my={2}
                  >
                    Booked Item:
                  </Typography>

                  <Typography
                    gutterBottom
                    variant="p"
                    component="div"
                    fontSize={{
                      xs: "13px",
                      sm: "14px",
                      md: "15px",
                      lg: "14px",
                      xl: "14px",
                    }}
                    fontWeight={"600"}
                    color={"#274472"}
                    my={2}
                  >
                    Item Color :
                  </Typography>
                </Grid>{" "}
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={{
                    xl: "center",
                    lg: "center",
                    md: "center",
                    sm: "center",
                    xs: "center",
                  }}
                >
                  <Typography
                    gutterBottom
                    variant="p"
                    component="div"
                    fontSize={{
                      xs: "13px",
                      sm: "14px",
                      md: "15px",
                      lg: "14px",
                      xl: "14px",
                    }}
                    fontWeight={"600"}
                    color={"#274472"}
                    my={2}
                  >
                    {statusData?.name ??  "No Data Available"}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="p"
                    component="div"
                    fontSize={{
                      xs: "13px",
                      sm: "14px",
                      md: "15px",
                      lg: "14px",
                      xl: "14px",
                    }}
                    fontWeight={"600"}
                    color={"#274472"}
                    my={2}
                  >
                   {gateway_order_id ??  "No Data Available"}
                  </Typography>
                 
                   <Typography
                    gutterBottom
                    variant="p"
                    component="div"
                    fontSize={{
                      xs: "13px",
                      sm: "14px",
                      md: "15px",
                      lg: "14px",
                      xl: "14px",
                    }}
                    fontWeight={"600"}
                    color={"#274472"}
                    my={2}
                  >
                     {payment_verified_at ? normalFormatDate(payment_verified_at) : 'No Data Available'}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="p"
                    component="div"
                    fontSize={{
                      xs: "13px",
                      sm: "14px",
                      md: "15px",
                      lg: "14px",
                      xl: "14px",
                    }}
                    fontWeight={"600"}
                    color={"#274472"}
                    my={2}
                  >
                    {statusData?.item_description?.title ?? 'No Data Available'}
                  </Typography>

                  <Typography
                    gutterBottom
                    variant="p"
                    component="div"
                    fontSize={{
                      xs: "14px",
                      sm: "14px",
                      md: "14px",
                      lg: "14px",
                      xl: "14px",
                    }}
                    fontWeight={"600"}
                    color={"#274472"}
                    my={2}
                  >
                    {statusData?.item_description?.color ??  'No Data Available'}
                  </Typography>
                </Grid>{" "}
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sm={10}
            md={5}
            lg={5}
            xl={5}
            sx={{
              // boxShadow: 1,
              my: 2,
              width: "100%",
              p: 4,
              display: "flex",
              justifyContent: "center",
              background: "white",
              borderRadius: 4,
            }}
          >
            <Grid
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                display: "flex",
                justifyItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                gutterBottom
                variant="h2"
                component="div"
                fontWeight={"700"}
                fontSize={{
                  md: "14px",
                  sm: "14px",
                  xs: "14px",
                  lg: "16px",
                  xl: "16px",
                }}
                color={"#000"}
                textAlign={"center"}
                display={{
                  xl: "none",
                  lg: "none",
                  md: "none",
                  sm: "flex",
                  xs: "flex",
                }}
              >
                BILL DETAIL
              </Typography>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  justifyItems: "center",
                  px: { xl: 6, lg: 6, sm: 2 },
                }}
              >
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  display={"flex"}
                  flexDirection={"column"}
                >
                  <Typography
                    gutterBottom
                    variant="p"
                    component="div"
                    fontSize={{
                      xs: "13px",
                      sm: "14px",
                      md: "15px",
                      lg: "14px",
                      xl: "14px",
                    }}
                    fontWeight={"600"}
                    color={"#274472"}
                    my={2}
                  >
                    SubTotal :
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="p"
                    component="div"
                    fontSize={{
                      xs: "13px",
                      sm: "14px",
                      md: "15px",
                      lg: "14px",
                      xl: "14px",
                    }}
                    fontWeight={"600"}
                    color={"#274472"}
                    my={2}
                  >
                    Earned Points :
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="p"
                    component="div"
                    fontSize={{
                      xs: "13px",
                      sm: "14px",
                      md: "15px",
                      lg: "14px",
                      xl: "14px",
                    }}
                    fontWeight={"600"}
                    color={"#274472"}
                    my={2}
                  >
                    Inaugural Points:
                  </Typography>
                  <hr />
                  <Typography
                    gutterBottom
                    variant="p"
                    component="div"
                    fontSize={{
                      xs: "13px",
                      sm: "14px",
                      md: "15px",
                      lg: "14px",
                      xl: "14px",
                    }}
                    fontWeight={"600"}
                    color={"#274472"}
                    my={2}
                  >
                    Grand Total : &nbsp;
                  </Typography>
                </Grid>{" "}
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"center"}
                >
                  <Typography
                    gutterBottom
                    variant="p"
                    component="div"
                    fontSize={{
                      xs: "13px",
                      sm: "14px",
                      md: "15px",
                      lg: "14px",
                      xl: "14px",
                    }}
                    fontWeight={"600"}
                    color={"#274472"}
                    my={2}
                  >
                    &nbsp; +  {amount ? getComaPrice(amount): 'No Data Available'}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="p"
                    component="div"
                    fontSize={{
                      xs: "13px",
                      sm: "14px",
                      md: "15px",
                      lg: "14px",
                      xl: "14px",
                    }}
                    fontWeight={"600"}
                    color={"#274472"}
                    my={2}
                  >
                    &nbsp; -  {e_point ? getComaPrice(e_point): e_point == 0 ? getComaPrice(0) : 'No Data Available'}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="p"
                    component="div"
                    fontSize={{
                      xs: "13px",
                      sm: "14px",
                      md: "15px",
                      lg: "14px",
                      xl: "14px",
                    }}
                    fontWeight={"600"}
                    color={"#274472"}
                    my={2}
                  >
                    &nbsp; - {i_point ? getComaPrice(i_point): i_point == 0 ? getComaPrice(0) : 'No Data Available'}
                  </Typography>
                  <hr />
                  <Typography
                    gutterBottom
                    variant="p"
                    component="div"
                    fontSize={{
                      xs: "13px",
                      sm: "14px",
                      md: "15px",
                      lg: "14px",
                      xl: "14px",
                    }}
                    fontWeight={"600"}
                    color={"#274472"}
                    my={2}
                  >
                    &nbsp; {gross_amount ? getComaPrice(gross_amount): 'No Data Available'}
                  </Typography>
                </Grid>{" "}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              display: "flex",
              justifyContent: {
                xl: "start",
                lg: "start",
                md: "start",
                sm: "center",
                xs: "center",
              },
            }}
          >
            <Button
              variant="outlined"
              size="small"
              id="place_btn"
              sx={{
                textAlign: "center",
                height: "44px",
                width: {
                  xl: "250px",
                  lg: "250px",
                  md: "200px",
                  sm: "200px",
                  xs: "350px",
                },
                fontWeight: 600,
                fontSize: "12px",
                lineHeight: "18px",
                letterSpacing: ".01em",
                borderRadius: "12px !important",
                boxShadow: "none !important",
                mt: 1,
                color:payment_status === "Success" ?  "#1D741B" : "red" ,
                border: payment_status === "Success" ? "1px solid #1D741B" : "1px solid red",
                "&:hover": {
                  border:payment_status === "Success" ? "1px solid #1D741B" : "1px solid red",
                  background: "white",
                },
                background: "white",
              }}
              onClick={() => navigate("/")}
            >
              Back
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}


export function Success_Screen(props) {
  const { handleSuccessClose, title, ThankTitle } = props;
  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        boxShadow: 24,
        width: { xl: 500, lg: 500, md: 450, sm: 450, xs: 350 },
        background: "white",
        borderRadius: 3,
      }}
    >
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "end",
          alignContent: "center",
          textAlign: "end",
        }}
      >
        <Grid
          item
          sx={{
            display: "flex",
            justifyContent: "end",
            alignContent: "center",
            textAlign: "end",
            p: 1,
            cursor: "pointer",
          }}
        >
          <CloseOutlinedIcon
            onClick={handleSuccessClose}
            sx={{
              width: "34px",
              height: "34px",
              borderRadius: "50%",
              backgroundColor: "#F7F7F7",
              cursor: "pointer",
              padding: 1,
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          flexDirection: "column",
          justifyItems: "center",
          // py: 2,
        }}
      >
        <Grid
          item
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            flexDirection: "column",
            justifyItems: "center",
            textAlign: "center",
          }}
        >
          <div>
            <img
              src={SuccessfullyDone}
              alt="success gif"
              width={"160px"}
              height={"160px"}
            />
          </div>

          <Typography
            gutterBottom
            variant="h5"
            component="div"
            fontSize={{ xs: 20, sm: 20, md: 20, lg: 24, xl: 24 }}
            fontWeight={"700"}
            textAlign={"center"}
          >
            {ThankTitle}
          </Typography>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            fontSize={{ xs: 16, sm: 16, md: 16, lg: 18, xl: 18 }}
            fontWeight={"700"}
            textAlign={"center"}
            pb={4}
            pt={1}
          >
            {title}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export function ServiceBooking_Success(props) {
  const navigate = useNavigate();
  return (
    <Box>
      <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            justifyItems: "center",
            textAlign: "center",
            alignContent: "center",
            alignItems: "center",
            m: 2,
            mt: 5,
          }}
        >
          <div>
            <img
              src={PaymentSuccess_gif}
              alt="success gif"
              width={"140px"}
              height={"120px"}
            />
          </div>
          <div>
            <img src={Service_Success_img} width={"200px"} height={"200px"} />
          </div>
          <Typography
            gutterBottom
            variant="h2"
            component="div"
            fontWeight={"700"}
            fontSize={{
              md: "14px",
              sm: "14px",
              xs: "14px",
              lg: "16px",
              xl: "16px",
            }}
            color={"#000"}
          >
            Thank you for the information. We will soon get back to you.
          </Typography>
          <div>
            <Button
              variant="outlined"
              size="small"
              id="place_btn"
              sx={{
                textAlign: "center",
                height: "44px",
                width: "200px",
                fontWeight: 600,
                fontSize: "12px",
                lineHeight: "18px",
                letterSpacing: ".01em",
                borderRadius: "12px !important",
                boxShadow: "none !important",
                mt: 1,
                color: "white",
                border: "1px solid #043BB1",
                "&:hover": {
                  border: "1px solid #043BB1",
                  background: "#043BB1",
                },
                background: "#043BB1",
              }}
              onClick={() => navigate("/")}
            >
              Go To Home
            </Button>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}

export function PaymentsRequestStatus(props) {
  const [statusData, setStatusData] = useState(null);
  const { token } = useParams();

  useEffect(() => {
    if (token) {  
     verifyBookingPayment(token, (status,data)=>{
      console.log('data: ', data);
      setStatusData(data);
      });
    }
  }, [token]);


  return (
    <Box sx={{ background: "#fafafa" }}>
      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        textAlign={"center"}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignContent: "center",
            mt: 2.5,
          }}
        >
          <img
            src="https://kalyanimotors.com/static/media/kalyani_dark.5bca4adb5ec73478b2ce.png"
            alt="kalyani_log"
            width={"120px"}
            height={"50px"}
          />

          <Typography
            gutterBottom
            variant="h5"
            component="div"
            fontSize={{ xs: 13, sm: 13, md: 16, lg: 18, xl: 18 }}
            fontWeight={"700"}
            textAlign={"center"}
            color={"#003B73"}
            mt={1}
          >
            PAYMENT RECEIPT
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          justifyItems: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            justifyItems: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ textAlign: "center", marginTop: 1, marginBottom: 1 }}>
            <img
              src={PaymentSuccess_gif}
              alt="success gif"
              width={"120px"}
              height={"100px"}
            />
          </div>
          <Typography
            gutterBottom
            variant="h2"
            component="div"
            fontWeight={"700"}
            fontSize={{
              md: "14px",
              sm: "14px",
              xs: "14px",
              lg: "20px",
              xl: "20px",
            }}
            color={statusData?.booking_id?.payment_status === "Success" ? "green" : "red"}
            textAlign={"center"}
            my={1}
          >
            {getComaPrice(statusData?.booking_id?.amount)}
          </Typography>

          <Typography
            gutterBottom
            variant="h2"
            component="div"
            fontWeight={"700"}
            fontSize={{
              md: "14px",
              sm: "14px",
              xs: "14px",
              lg: "20px",
              xl: "20px",
            }}
            color={"#000"}
            textAlign={"center"}
          >
            {statusData?.booking_id?.payment_status === "Success" ? "Payment Success" : "Payment Failed" }
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{
          display: {
            xl: "flex",
            lg: "flex",
            md: "flex",
            sm: "none",
            xs: "none",
          },
          justifyContent: "center",
          flexDirection: "row",
          justifyItems: "center",
          alignContent: "center",
          alignItems: "center",
          gap: 4,
        }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          lg={5}
          xl={5}
          sx={{
            mt: 3,
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography
            gutterBottom
            variant="h2"
            component="div"
            fontWeight={"700"}
            fontSize={{
              md: "14px",
              sm: "14px",
              xs: "14px",
              lg: "16px",
              xl: "16px",
            }}
            color={"#000"}
            textAlign={"center"}
          >
            BOOKING DETAILS
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          lg={5}
          xl={5}
          sx={{
            mt: 3,
            width: "100%",

            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography
            gutterBottom
            variant="h2"
            component="div"
            fontWeight={"700"}
            fontSize={{
              md: "14px",
              sm: "14px",
              xs: "14px",
              lg: "16px",
              xl: "16px",
            }}
            color={"#000"}
            textAlign={"center"}
          >
            PAYMENT DETAILS
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          justifyItems: "center",
          alignContent: "center",
          alignItems: "center",
          columnGap: 4,
          rowGap: { lg: 0, xl: 0, md: 4, sm: 4, xs: 4 },
        }}
      >
        <Grid
          item
          xs={12}
          sm={10}
          md={5}
          lg={5}
          xl={5}
          sx={{
            // boxShadow: 1,
            my: 2,
            width: "100%",
            p: 4,
            display: "flex",
            justifyContent: "center",
            background: "white",
            borderRadius: 4,
          }}
        >
          <Grid
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              display: "flex",
              justifyItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              gutterBottom
              variant="h2"
              component="div"
              fontWeight={"700"}
              fontSize={{
                md: "14px",
                sm: "14px",
                xs: "14px",
                lg: "16px",
                xl: "16px",
              }}
              color={"#000"}
              textAlign={"center"}
              display={{
                xl: "none",
                lg: "none",
                md: "none",
                sm: "flex",
                xs: "flex",
              }}
            >
              BOOKING DETAIL
            </Typography>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                justifyItems: "center",
                px: { xl: 6, lg: 6, sm: 2 },
              }}
            >
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                display={"flex"}
                flexDirection={"column"}
              >
                <Typography
                  gutterBottom
                  variant="p"
                  component="div"
                  fontSize={{
                    xs: "13px",
                    sm: "14px",
                    md: "15px",
                    lg: "14px",
                    xl: "14px",
                  }}
                  fontWeight={"600"}
                  color={"#274472"}
                  my={2}
                >
                  Requested By :
                </Typography>
                <Typography
                  gutterBottom
                  variant="p"
                  component="div"
                  fontSize={"15px"}
                  fontWeight={"500"}
                  color={"#274472"}
                  my={2}
                >
                  Created At :
                </Typography>
                <Typography
                  gutterBottom
                  variant="p"
                  component="div"
                  fontSize={{
                    xs: "13px",
                    sm: "14px",
                    md: "15px",
                    lg: "14px",
                    xl: "14px",
                  }}
                  fontWeight={"600"}
                  color={"#274472"}
                  my={2}
                >
                  Customer ID/ Reg.No :
                </Typography>

                <Typography
                  gutterBottom
                  variant="p"
                  component="div"
                  fontSize={{
                    xs: "13px",
                    sm: "14px",
                    md: "15px",
                    lg: "14px",
                    xl: "14px",
                  }}
                  fontWeight={"600"}
                  color={"#274472"}
                  my={2}
                >
                  Reference No :
                </Typography>
                <Typography
                  gutterBottom
                  variant="p"
                  component="div"
                  fontSize={{
                    xs: "13px",
                    sm: "14px",
                    md: "15px",
                    lg: "14px",
                    xl: "14px",
                  }}
                  fontWeight={"600"}
                  color={"#274472"}
                  my={2}
                >
                  Purpose :
                </Typography>
              </Grid>{" "}
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={{
                  xl: "center",
                  lg: "center",
                  md: "center",
                  sm: "center",
                  xs: "center",
                }}
              >
                <Typography
                  gutterBottom
                  variant="p"
                  component="div"
                  fontSize={{
                    xs: "13px",
                    sm: "14px",
                    md: "15px",
                    lg: "14px",
                    xl: "14px",
                  }}
                  fontWeight={"600"}
                  color={"#7F8FA3"}
                  my={2}
                >
                  Admin
                </Typography>
                <Typography
                  gutterBottom
                  variant="p"
                  component="div"
                  fontSize={"15px"}
                  fontWeight={"500"}
                  color={"#7F8FA3"}
                  my={2}
                >
                  02/01/2024 (11:34:06)
                </Typography>
                <Typography
                  gutterBottom
                  variant="p"
                  component="div"
                  fontSize={{
                    xs: "13px",
                    sm: "14px",
                    md: "15px",
                    lg: "14px",
                    xl: "14px",
                  }}
                  fontWeight={"600"}
                  color={"#7F8FA3"}
                  my={2}
                >
                  HFUFCCY
                </Typography>

                <Typography
                  gutterBottom
                  variant="p"
                  component="div"
                  fontSize={{
                    xs: "14px",
                    sm: "14px",
                    md: "14px",
                    lg: "14px",
                    xl: "14px",
                  }}
                  fontWeight={"600"}
                  color={"#7F8FA3"}
                  my={2}
                >
                  456
                </Typography>
                <Typography
                  gutterBottom
                  variant="p"
                  component="div"
                  fontSize={{
                    xs: "14px",
                    sm: "14px",
                    md: "14px",
                    lg: "14px",
                    xl: "14px",
                  }}
                  fontWeight={"600"}
                  color={"#7F8FA3"}
                  my={2}
                >
                  Testing
                </Typography>
              </Grid>{" "}
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          sm={10}
          md={5}
          lg={5}
          xl={5}
          sx={{
            // boxShadow: 1,

            width: "100%",
            p: 4,
            display: "flex",
            justifyContent: "center",
            background: "white",
            borderRadius: 4,
          }}
        >
          <Grid
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              display: "flex",
              justifyItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              gutterBottom
              variant="h2"
              component="div"
              fontWeight={"700"}
              fontSize={{
                md: "14px",
                sm: "14px",
                xs: "14px",
                lg: "16px",
                xl: "16px",
              }}
              color={"#000"}
              textAlign={"center"}
              display={{
                xl: "none",
                lg: "none",
                md: "none",
                sm: "flex",
                xs: "flex",
              }}
            >
              BILL DETAIL
            </Typography>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                justifyItems: "center",
                px: { xl: 6, lg: 6, sm: 2 },
              }}
            >
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                display={"flex"}
                flexDirection={"column"}
              >
                <Typography
                  gutterBottom
                  variant="p"
                  component="div"
                  fontSize={{
                    xs: "13px",
                    sm: "14px",
                    md: "15px",
                    lg: "14px",
                    xl: "14px",
                  }}
                  fontWeight={"600"}
                  color={"#274472"}
                  my={2}
                >
                  SubTotal :
                </Typography>
                <Typography
                  gutterBottom
                  variant="p"
                  component="div"
                  fontSize={{
                    xs: "13px",
                    sm: "14px",
                    md: "15px",
                    lg: "14px",
                    xl: "14px",
                  }}
                  fontWeight={"600"}
                  color={"#274472"}
                  my={2}
                >
                  Earned Points :
                </Typography>
                <Typography
                  gutterBottom
                  variant="p"
                  component="div"
                  fontSize={{
                    xs: "13px",
                    sm: "14px",
                    md: "15px",
                    lg: "14px",
                    xl: "14px",
                  }}
                  fontWeight={"600"}
                  color={"#274472"}
                  my={2}
                >
                  Inaugural Points:
                </Typography>
                <hr />
                <Typography
                  gutterBottom
                  variant="p"
                  component="div"
                  fontSize={{
                    xs: "13px",
                    sm: "14px",
                    md: "15px",
                    lg: "14px",
                    xl: "14px",
                  }}
                  fontWeight={"600"}
                  color={"#274472"}
                  my={2}
                >
                  Grand Total : &nbsp;
                </Typography>
              </Grid>{" "}
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
              >
                <Typography
                  gutterBottom
                  variant="p"
                  component="div"
                  fontSize={{
                    xs: "13px",
                    sm: "14px",
                    md: "15px",
                    lg: "14px",
                    xl: "14px",
                  }}
                  fontWeight={"600"}
                  color={"#7F8FA3"}
                  my={2}
                >
                  &nbsp; + {getComaPrice(20000)}
                </Typography>
                <Typography
                  gutterBottom
                  variant="p"
                  component="div"
                  fontSize={{
                    xs: "13px",
                    sm: "14px",
                    md: "15px",
                    lg: "14px",
                    xl: "14px",
                  }}
                  fontWeight={"600"}
                  color={"#7F8FA3"}
                  my={2}
                >
                  &nbsp; - {getComaPrice(1000)}
                </Typography>
                <Typography
                  gutterBottom
                  variant="p"
                  component="div"
                  fontSize={{
                    xs: "13px",
                    sm: "14px",
                    md: "15px",
                    lg: "14px",
                    xl: "14px",
                  }}
                  fontWeight={"600"}
                  color={"#7F8FA3"}
                  my={2}
                >
                  &nbsp; - {getComaPrice(0)}
                </Typography>
                <hr />
                <Typography
                  gutterBottom
                  variant="p"
                  component="div"
                  fontSize={{
                    xs: "13px",
                    sm: "14px",
                    md: "15px",
                    lg: "14px",
                    xl: "14px",
                  }}
                  fontWeight={"600"}
                  color={"#7F8FA3"}
                  my={2}
                >
                  &nbsp; {getComaPrice(19000)}
                </Typography>
              </Grid>{" "}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              px={{ xl: 5, lg: 5 }}
              display={"flex"}
              justifyContent={"start"}
              alignContent={"center"}
            >
              <Typography
                gutterBottom
                variant="p"
                component="div"
                fontSize={{
                  xs: "13px",
                  sm: "14px",
                  md: "15px",
                  lg: "14px",
                  xl: "14px",
                }}
                fontWeight={"600"}
                color={"green"}
                my={2}
              >
                You Saved {getComaPrice(1000) + " " + "/-"} on this order
              </Typography>
              <div style={{ marginTop: "12px" }}>
                <img src={reward} width={"20px"} height={"20px"} />
                <img src={reward} width={"20px"} height={"20px"} />
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          lg={5}
          xl={5}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            variant="outlined"
            size="small"
            id="place_btn"
            sx={{
              textAlign: "center",
              height: "44px",
              width: "300px",
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "18px",
              letterSpacing: ".01em",
              borderRadius: "12px !important",
              boxShadow: "none !important",
              color: "#000",
              border: "1px solid green",
              "&:hover": {
                border: "1px solid green",
                background: "white",
              },
              background: "white",
              mb: 1,
            }}
          >
            BACK
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
