import React, { useState, useEffect } from 'react';
import HeaderMain from '../../../Components/Header/Header';
import Footer from '../../../Components/Footer/Footer';
import './Saving_Corner.css';
import Apihandler from '../../../Api/Apihandler';
import Loader from '../../../Constants/Loader';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Gold_badge from '../../../Assets/Images/Icons/gold_badge.png';
import Silver_badge from '../../../Assets/Images/Icons/silver_badge.png';
import Platinum_badge from '../../../Assets/Images/Icons/platinum_badge.png';
import RewardCoin from '../../../Assets/Images/Icons/reward_coin.png';
import Booking_img from '../../../Assets/Images/Icons/BOOKING.png';
import payment_img from '../../../Assets/Images/Icons/PAYMENT.png';
import points_img from '../../../Assets/Images/Icons/Asset 9.png';
import { useMediaQuery, Button } from '@mui/material';
import { formatDate } from '../../../Constants/FormatDateTime';
import Modal from '@mui/material/Modal';
import account_icon from '../../../Assets/Images/Icons/account_icon.png';

const Saving_Corner = () => {
	const [loyality, setLoyality] = useState([]);
	const [rewardPoint, setRewardPoint] = useState([]);
	const [Point, setPoint] = useState(2500);
	const [profileData, setProfileData] = useState([]);

	const isSmallScreen = useMediaQuery('(max-width:767px)');

	const profileId = localStorage.getItem('loggedIn');

	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	useEffect(() => {
		_getProfile(profileId);
	}, []);
	const _getProfile = async () => {
		try {
			const response = await Apihandler.getProfile(profileId);
			const data = response.data.data[0];
			setProfileData({
				phone: data.phone,
				first_name: data.user.first_name,
				last_name: data.user.last_name,
				email: data.user.email,
				verification: data.verified_profile,
				membership: data.membership,
				profile_id: data.id,
			});
			getPoints(data.id);
		} catch (error) {
			console.log('profile Data error', error);
		}
	};

	const getPoints = async (id) => {
		const response = await Apihandler.rewardSummary(id);
		try {
			const loyality_data = response.data.loyalty_data;
			const transactions_data = response.data.transactions_data;
			console.log('Reward Summery Error', transactions_data);
			setLoyality({
				balance_points: loyality_data.balance_points,
				business_turnover: loyality_data.business_turnover,
				inaugural_points: loyality_data.inaugural_points,
				inaugural_points_eligible: loyality_data.inaugural_points_eligible,
				total_earned_points: loyality_data.total_earned_points,
				last_updated_points: loyality_data.last_updated_points,
			});
			setRewardPoint(transactions_data);
			setRewardPoint(transactions_data);
		} catch (error) {
			console.log('Reward Summery Error', error);
		}
	};
	console.log('reward point', rewardPoint);

	// document.addEventListener("DOMContentLoaded", function () {
	//   window.addEventListener("scroll", function () {
	//     var scrollTop = window.scrollY || document.documentElement.scrollTop;
	//     document.querySelector(".fixed-content").scrollTop = scrollTop;
	//   });
	// });

	return (
		<div className="savings">
			<Box>
				<Grid
					container
					sx={{
						py: 5,
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						gap: 2,
					}}
				>
					<Grid
						item
						xs={12}
						sm={12}
						md={4}
						lg={4}
						xl={4}
						// sx={{ height: "100vh" }}
					>
						{' '}
						<Typography
							gutterBottom
							variant="h3"
							component="div"
							fontSize={{
								md: '18px',
								sm: '18px',
								xs: '18px',
								lg: '18px',
							}}
							mt={1.5}
							fontWeight={'600'}
							color={'#000'}
							mx={2}
							textAlign={'start'}
							className="rewards_title"
						>
							Earn and redeem rewards
						</Typography>
						<div className="saving_corner">
							<div className="saving_corner_profile">
								<Box sx={{ flexGrow: 1 }}>
									<Grid
										container
										sx={{
											borderBottom: '1px solid #e1e1e1e1',
											py: 2,
											cursor: 'pointer',
										}}
										onClick={handleOpen}
									>
										<Grid
											item
											// xs={4}
											sx={{
												display: 'flex',
												flexDirection: 'row',
												justifyContent: 'end',
											}}
										>
											{profileData.membership && (
												<img
													src={
														profileData.membership === 'Member'
															? Silver_badge
															: profileData.membership === 'Gold'
															? Gold_badge
															: profileData.membership === 'Platinum'
															? Platinum_badge
															: ''
													}
													width={40}
													height={40}
													alt={`${profileData.membership}_badge`}
												/>
											)}
										</Grid>

										<Grid item xs={6}>
											<Typography
												gutterBottom
												variant="h3"
												component="div"
												fontSize={{
													md: '14px',
													sm: '14px',
													xs: '14px',
													lg: '14px',
												}}
												mt={1}
												mx={2}
												fontWeight={'600'}
												color={'#000'}
												textAlign={'start'}
												className="saving_corner_profile_name"
											>
												{`${profileData.first_name} ${profileData.last_name}`}
											</Typography>
											<Typography
												gutterBottom
												variant="h3"
												component="div"
												fontSize={{
													md: '12px',
													sm: '12px',
													xs: '12px',
													lg: '12px',
												}}
												mt={0.5}
												mx={2}
												fontWeight={'600'}
												color={'rgba(0,0,0,0.6)'}
												textAlign={'start'}
												className="saving_corner_membership"
											>
												{profileData.membership === 'Member'
													? 'Silver'
													: profileData.membership}{' '}
												Member
											</Typography>
										</Grid>
									</Grid>
								</Box>

								<Box>
									<Grid
										container
										// sx={{
										//   display: "flex",
										//   flexDirection: "row",
										//   justifyContent: "center",
										// }}
									>
										<Grid item xs={6}>
											<Typography
												gutterBottom
												variant="h3"
												component="div"
												fontSize={{
													md: '13px',
													sm: '13px',
													xs: '13px',
													lg: '13px',
												}}
												mt={1.5}
												p={1}
												fontWeight={'600'}
												color={'#000'}
												textAlign={'start'}
												// className="profile_name"
											>
												Total Earned Points
											</Typography>
										</Grid>
										<Grid item xs={6}>
											<Typography
												gutterBottom
												variant="h3"
												component="div"
												fontSize={{
													md: '13px',
													sm: '13px',
													xs: '13px',
													lg: '13px',
												}}
												mt={1.5}
												py={1}
												fontWeight={'600'}
												color={'#000'}
												textAlign={'start'}
												className="total_earned_points"
											>
												{parseInt(loyality.total_earned_points)}
												<img
													src={RewardCoin}
													width={20}
													height={20}
													alt="reward_coin"
												/>
											</Typography>
										</Grid>
									</Grid>
								</Box>
							</div>

							<div className="reward_points_summary">
								<Typography
									gutterBottom
									variant="h3"
									component="div"
									fontSize={{
										md: '15px',
										sm: '15px',
										xs: '15px',
										lg: '15px',
									}}
									my={1.5}
									fontWeight={'600'}
									color={'#000'}
									textAlign={'start'}
									className=""
								>
									Rewards Points Available Summary
								</Typography>

								<Box>
									<Grid
										container
										sx={{
											borderBottom: '1px dashed #e1e1e1',
											borderTop: '1px solid #e1e1e1',
											py: 1,
										}}
									>
										<Grid xs={6} className="profile_points_info">
											<Typography
												gutterBottom
												variant="h3"
												component="div"
												fontSize={{
													md: '11px',
													sm: '11px',
													xs: '11px',
													lg: '11px',
												}}
												mt={1.5}
												fontWeight={'600'}
												color={'rgba(0, 0, 0, 0.6)'}
												textAlign={'start'}
												className="profile_inaugural"
												sx={{ borderBottom: '1px dashed #fafafa' }}
											>
												Inaugural points{' '}
												<span
													style={{
														fontSize: '7px',
														color: 'white',
														background:
															loyality.inaugural_points_eligible === true
																? 'green'
																: 'red',
														padding: 3,
														borderRadius: 5,
													}}
												>
													{loyality.inaugural_points_eligible === true
														? 'Eligible'
														: 'Not Eligible'}
												</span>
											</Typography>
										</Grid>
										<Grid xs={6} className="coins_info">
											<Typography
												gutterBottom
												variant="h3"
												component="div"
												fontSize={{
													md: '11px',
													sm: '11px',
													xs: '11px',
													lg: '11px',
												}}
												mt={1.5}
												fontWeight={'600'}
												color={'rgba(0,0,0,0.6)'}
												textAlign={'start'}
												className="profile_coin_info profiel_inaugural_coin_info"
											>
												{parseFloat(loyality.inaugural_points)}{' '}
												<img
													src={RewardCoin}
													width={15}
													height={15}
													alt="Reward_coin"
												/>
											</Typography>
										</Grid>
									</Grid>
								</Box>
								<Box>
									<Grid
										container
										sx={{ borderBottom: '1px solid #e1e1e1', py: 1 }}
									>
										<Grid xs={6} className="profile_points_info">
											<Typography
												gutterBottom
												variant="h3"
												component="div"
												fontSize={{
													md: '11px',
													sm: '11px',
													xs: '11px',
													lg: '11px',
												}}
												mt={1.5}
												fontWeight={'600'}
												color={'rgba(0,0,0,0.6)'}
												textAlign={'start'}
												className="profile_earned_point"
											>
												Earned Points
											</Typography>
										</Grid>
										<Grid xs={6} className="coins_info">
											<Typography
												gutterBottom
												variant="h3"
												component="div"
												fontSize={{
													md: '11px',
													sm: '11px',
													xs: '11px',
													lg: '11px',
												}}
												mt={1.5}
												fontWeight={'600'}
												color={'rgba(0,0,0,0.6)'}
												textAlign={'start'}
												className="profile_coin_info profiel_earned_coin_info"
											>
												{parseInt(loyality.balance_points)}{' '}
												<img
													src={RewardCoin}
													width={15}
													height={15}
													alt="Reward_coin"
												/>
											</Typography>
										</Grid>
									</Grid>
								</Box>
								<Box>
									<Grid container sx={{ py: 1 }}>
										<Grid xs={6} className="profile_points_info">
											<Typography
												gutterBottom
												variant="h3"
												component="div"
												fontSize={{
													md: '13px',
													sm: '13px',
													xs: '13px',
													lg: '13px',
												}}
												mt={1.5}
												fontWeight={'900'}
												color={'#000'}
												textAlign={'start'}
												className="profile_available_point"
											>
												Total Available Points
											</Typography>
										</Grid>
										<Grid xs={6} className="coins_info">
											<Typography
												gutterBottom
												variant="h3"
												component="div"
												fontSize={{
													md: '13px',
													sm: '13px',
													xs: '13px',
													lg: '13px',
												}}
												mt={1.5}
												fontWeight={'900'}
												color={'#000'}
												textAlign={'start'}
												letterSpacing={0.5}
												className="profile_coin_info profiel_total_coin_info"
											>
												{parseInt(loyality.inaugural_points) +
													parseInt(loyality.balance_points)}{' '}
												<img
													src={RewardCoin}
													width={15}
													height={15}
													alt="Reward_coin"
												/>
											</Typography>
										</Grid>
									</Grid>
								</Box>
							</div>
						</div>
					</Grid>

					<Grid
						item
						xs={12}
						sm={12}
						md={6}
						lg={6}
						xl={6}
						// sx={{ overflowY: "scroll" }}
					>
						<Typography
							gutterBottom
							variant="h3"
							component="div"
							fontSize={{
								md: '18px',
								sm: '18px',
								xs: '18px',
								lg: '18px',
							}}
							mt={1.5}
							mx={2}
							fontWeight={'600'}
							color={'#000'}
							textAlign={'start'}
							className="ponit_histrory_title"
						>
							Points History
						</Typography>
						<div
							className="point_history"
							style={{
								padding: '10px',
								height: '100vh',
								overflowY: 'auto',
							}}
						>
							{rewardPoint &&
								rewardPoint.length > 0 &&
								rewardPoint.map((item) => (
									<Box>
										<Grid container className="point_history_container">
											<Grid item xs={1}>
												<img
													src={item.payment_request ? payment_img : Booking_img}
													alt={item.purpose}
													width={40}
													heigh={40}
												/>
											</Grid>
											<Grid
												item
												xs={isSmallScreen ? 7 : 9}
												className="points_purpose"
											>
												{isSmallScreen ? (
													<div>
														<Typography
															gutterBottom
															variant="h3"
															component="div"
															fontSize={{
																md: '13px',
																sm: '11px',
																xs: '11px',
																lg: '13px',
															}}
															mt={1.5}
															fontWeight={'900'}
															color={'#000'}
															textAlign={'start'}
															// className="profile_name"
														>
															{item.purpose.split('-')[0]}
														</Typography>
														<Typography
															gutterBottom
															variant="h3"
															component="div"
															fontSize={{
																md: '11px',
																sm: '11px',
																xs: '11px',
																lg: '11px',
															}}
															mt={0.5}
															fontWeight={'600'}
															color={'#000'}
															textAlign={'start'}
															// className="profile_name"
														>
															{item.purpose.split('-')[1]}
														</Typography>
													</div>
												) : (
													<Typography
														gutterBottom
														variant="h3"
														component="div"
														fontSize={{
															md: '13px',
															sm: '13px',
															xs: '13px',
															lg: '13px',
														}}
														mt={1.5}
														fontWeight={'900'}
														color={'#000'}
														textAlign={'start'}
														className="profile_name"
													>
														{item.purpose}
													</Typography>
												)}
												<Typography
													gutterBottom
													variant="h3"
													component="div"
													fontSize={{
														md: '11px',
														sm: '11px',
														xs: '11px',
														lg: '11px',
													}}
													mt={isSmallScreen ? 0.5 : 1}
													fontWeight={'600'}
													color={'rgba(0, 0, 0, 0.6)'}
													textAlign={'start'}
													className="profile_name"
												>
													{formatDate(item.time_of_transaction)}
												</Typography>
											</Grid>
											{/* <Grid item xs={1}>
                        <img
                          src={points_img}
                          alt="point_img"
                          width={20}
                          height={20}
                        />
                      </Grid> */}
											<Grid className="points" item xs={2}>
												<Typography
													gutterBottom
													variant="h3"
													component="div"
													fontSize={{
														md: '13px',
														sm: '10px',
														xs: '10px',
														lg: '13px',
													}}
													mt={1.5}
													fontWeight={'600'}
													color={'green'}
													textAlign={'start'}
													className="profile_name"
												>
													+ {parseInt(item.added_points)} Pts
												</Typography>
												<Typography
													gutterBottom
													variant="h3"
													component="div"
													fontSize={{
														md: '13px',
														sm: '10px',
														xs: '10px',
														lg: '13px',
													}}
													mt={1}
													fontWeight={'600'}
													color={'red'}
													textAlign={'start'}
													className="profile_name"
												>
													- {parseInt(item.points_used)} Pts
												</Typography>
											</Grid>
										</Grid>
									</Box>
								))}
						</div>
					</Grid>
				</Grid>
				<Modal
					open={open}
					onClose={handleClose}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
					className="savings_points"
				>
					<Box
						sx={{
							position: 'absolute',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
							width: { xl: 600, lg: 600, md: 500, sm: 360, xs: 300 },
							bgcolor: 'background.paper',
							boxShadow: 24,
							p: 4,
						}}
					>
						<Typography
							gutterBottom
							variant="h3"
							component="div"
							fontSize={{
								md: '18px',
								sm: '18px',
								xs: '18px',
								lg: '18px',
							}}
							mb={3}
							fontWeight={'600'}
							color={'#003060'}
							mx={2}
							textAlign={'center'}
						>
							Membership Pathway
						</Typography>

						<div
							style={{
								height: 10,
								width: '100%',
								backgroundColor: 'lightgrey',
								borderRadius: 10,
								flexDirection: 'row',
								alignSelf: 'center',
								// marginVertical: 15,
								cursor: 'pointer',
							}}
						>
							<div
								style={{
									height: 10,
									position: 'absolute',
									backgroundColor: '#1C9B8E',
									width: Point >= 5000 ? '90%' : `${(Point / 5000) * 90}%`,

									borderRadius: 10,
								}}
							>
								<div
									style={{
										right: -5,
										top: -8,
										position: 'absolute',
										borderRadius: 20,
										backgroundColor: 'white',
										borderRadius: 15,
										height: 25,
										width: 25,
										borderRadius: 20,
										alignItems: 'center',
										justifyContent: 'center',
									}}
								>
									<img src={account_icon} width={'25px'} height={'25px'} />
								</div>
							</div>
						</div>
						<div
							style={{
								width: '100%',
								display: 'flex',
								justifyContent: 'space-between',
							}}
						>
							<div style={{ textAlign: 'start' }}>
								<Typography
									gutterBottom
									variant="h3"
									component="div"
									fontSize={'13px'}
									mt={1}
									fontWeight={'600'}
									color={'#003060'}
								>
									0
								</Typography>
								<Typography
									gutterBottom
									variant="h3"
									component="div"
									fontSize={'13px'}
									mt={1}
									fontWeight={'600'}
									color={'#003060'}
								>
									Silver
								</Typography>
							</div>
							<div style={{ textAlign: 'center' }}>
								<Typography
									gutterBottom
									variant="h3"
									component="div"
									fontSize={'13px'}
									mt={1}
									fontWeight={'600'}
									color={'#003060'}
								>
									2.5K
								</Typography>
								<Typography
									gutterBottom
									variant="h3"
									component="div"
									fontSize={'13px'}
									mt={1}
									fontWeight={'600'}
									color={'#003060'}
								>
									Gold
								</Typography>
							</div>{' '}
							<div style={{ textAlign: 'end' }}>
								<Typography
									gutterBottom
									variant="h3"
									component="div"
									fontSize={'13px'}
									mt={1}
									fontWeight={'600'}
									color={'#003060'}
								>
									5K
								</Typography>
								<Typography
									gutterBottom
									variant="h3"
									component="div"
									fontSize={'13px'}
									mt={1}
									fontWeight={'600'}
								>
									Platinum
								</Typography>
							</div>
						</div>

						<Grid container xl={12} lg={12} md={12} sm={12} xs={12}>
							<Grid item xl={12} lg={12} md={12} sm={12} xs={12} mt={2}>
								<Grid
									container
									xl={12}
									lg={12}
									md={12}
									sm={12}
									xs={12}
									display={'flex'}
								>
									<Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
										<img src={Silver_badge} width={'25px'} height={'25px'} />
									</Grid>
									<Grid item xl={11} lg={11} md={11} sm={10} xs={10}>
										<Typography
											gutterBottom
											variant="h3"
											component="div"
											fontSize={'16px'}
											fontWeight={'600'}
											color={'#0E86D4'}
											textAlign={'start'}
										>
											Silver
										</Typography>
										<Typography
											gutterBottom
											variant="p"
											component="div"
											fontSize={'12px'}
											fontWeight={'600'}
											color={'#808080'}
											textAlign={'start'}
										>
											Silver users will receive a reward equivalent to 1% of the
											amount they have paid.
										</Typography>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xl={12} lg={12} md={12} sm={12} xs={12} mt={2}>
								<Grid
									container
									xl={12}
									lg={12}
									md={12}
									sm={12}
									xs={12}
									display={'flex'}
								>
									<Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
										<img src={Gold_badge} width={'25px'} height={'25px'} />
									</Grid>
									<Grid item xl={11} lg={11} md={11} sm={10} xs={10}>
										<Typography
											gutterBottom
											variant="h3"
											component="div"
											fontSize={'16px'}
											fontWeight={'600'}
											color={'#0E86D4'}
											textAlign={'start'}
										>
											Gold
										</Typography>
										<Typography
											gutterBottom
											variant="p"
											component="div"
											fontSize={'12px'}
											fontWeight={'600'}
											color={'#000'}
											textAlign={'start'}
										>
											You must required to accumulate 2500 points in order to
											qualify for the gold membership.
										</Typography>
										<Typography
											gutterBottom
											variant="p"
											component="div"
											fontSize={'12px'}
											fontWeight={'600'}
											color={'#808080'}
											textAlign={'start'}
										>
											Gold users will receive a reward equivalent to 2% of the
											amount they have paid.
										</Typography>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xl={12} lg={12} md={12} sm={12} xs={12} mt={2}>
								<Grid
									container
									xl={12}
									lg={12}
									md={12}
									sm={12}
									xs={12}
									display={'flex'}
								>
									<Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
										<img src={Platinum_badge} width={'25px'} height={'25px'} />
									</Grid>
									<Grid item xl={11} lg={11} md={11} sm={10} xs={10}>
										<Typography
											gutterBottom
											variant="h3"
											component="div"
											fontSize={'16px'}
											fontWeight={'600'}
											color={'#0E86D4'}
											textAlign={'start'}
										>
											Platinum
										</Typography>
										<Typography
											gutterBottom
											variant="p"
											component="div"
											fontSize={'12px'}
											fontWeight={'600'}
											color={'#000'}
											textAlign={'start'}
										>
											You must accumulate 5000 points in order to qualify for
											the platinum membership.
										</Typography>
										<Typography
											gutterBottom
											variant="p"
											component="div"
											fontSize={'12px'}
											fontWeight={'600'}
											color={'#808080'}
											textAlign={'start'}
										>
											Platinum users will receive a reward equivalent to 3% of
											the amount they have paid.
										</Typography>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<div style={{ textAlign: 'center' }}>
							<Button
								variant="contained"
								sx={{
									borderRadius: 3,
									background: '#003060',
									'&:hover': { background: '#003060' },
									width: '200px',
									mt: 1.5,
									cursor: 'pointer',
								}}
								onClick={handleClose}
							>
								Close
							</Button>
						</div>
					</Box>
				</Modal>
			</Box>
		</div>
	);
};

export default Saving_Corner;
