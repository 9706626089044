import React, { useState, useEffect } from 'react';
import './Signup.css';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import signup_img from '../../Assets/Images/SIGNUP.png';
import Password_img from '../../Assets/Images/ENTER_PASSWORD.png';
import Otp_img from '../../Assets/Images/ENTER_OTP.png';
import Set_password from '../../Assets/Images/SET_PASSWORD.png';
import Login_img from '../../Assets/Images/LOGIN.png';
import ApiHandler from '../../Api/Apihandler';
import Alert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OtpInput from 'react-otp-input';
import { InputLabel } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Encryption from '../../Constants/Encryption';

const Signup = ({ button }) => {
	const [login, setLogin] = useState(1);
	const [phoneNO, setPhoneNo] = useState();
	const [userName, setUserName] = useState({ firstName: '', lastName: '' });
	const [error, setError] = useState(null);
	const [alertMessage, setAlertMessage] = useState(null);
	const [password, setPassword] = useState();
	const [otp, setOtp] = useState('');
	const [validateUser, setValidateUser] = useState();
	const [loggedUser, setLoggedUser] = useState();
	const navigate = useNavigate();
	const [minutes, setMinutes] = useState(1);
	const [seconds, setSeconds] = useState(30);
	const [showError, setShowError] = useState(false);
	const [verifyId, setVerifyId] = useState(false);
	const [otpAlertMessage, setOtpAlertMessage] = useState(null);
	const [otpVefifyMessage, setOtpVefifyMessage] = useState(null);
	const [newPin, setNewPin] = useState();
	const [confirmPin, setConfirmPin] = useState();
	const [passwordmsg, setPasswordMsg] = useState();
	const [passwordMismatchMsg, setPasswordMismatchMsg] = useState();
	const [profileSuccessMsg, setProfileSuccessMsg] = useState(null);
	const [highlight, setHighlight] = useState(false);
	// const [phoneNumber,setPhoneNumber] = useState('');

	const handleHighlight = (e) => {
		const phoneNumber = e.target.value.replace(/[^0-9]/g, '');
		setPhoneNo(phoneNumber);

		if (
			phoneNumber &&
			phoneNumber.length === 10 &&
			/^[6789]\d{9}$/.test(phoneNumber)
		) {
			setHighlight(true);
		} else {
			setHighlight(false);
		}
	};

	useEffect(() => {
		const interval = setInterval(() => {
			if (seconds > 0) {
				setSeconds(seconds - 1);
			}
			if (seconds === 0) {
				if (minutes === 0) {
					clearInterval(interval);
				} else {
					setSeconds(59);
					setMinutes(minutes - 1);
				}
			}
		}, 1000);

		return () => {
			clearInterval(interval);
		};
	}, [seconds]);

	const notify = () =>
		toast.error('Invalid credentials.', {
			style: {
				fontSize: '13px',
			},
		});
	const mobileNotify = () =>
		toast.error('Enter a valid mobile number.', {
			style: {
				fontSize: '13px',
			},
		});

	const validation = () => {
		if (!/^[6789]\d{9}$/.test(`${phoneNO}`) && phoneNO.length !== 10) {
			setError(1);
			mobileNotify();
		} else {
			setHighlight(true);
			setError(0);
			Checkuser(phoneNO);
		}
	};

	const Checkuser = async () => {
		const data = { phone: phoneNO };
		const response = await ApiHandler.checkUser(data);
		if (response.data.status === 200) {
			const message = 'This Phone Number is already registered. Please Login.';
			setAlertMessage(message);
			setTimeout(() => {
				setAlertMessage(null);
			}, 4000);
			setLogin(2);
		} else {
			if (response.data.status === 404) {
				setLogin(3);
				// setSignUpOtp(true)
			} else {
				setLogin(1);
			}
		}
	};

	const _sendOtp = async (phoneNO) => {
		let sendOtpPayload = {
			flow_id: '2079031d-a1ac-4bbe-bf58-2495bd6e5703',
			to: {
				mobile: `+91${phoneNO}`,
				email: 'test@gmail.com',
			},
		};
		const response = await ApiHandler.sendOtp(sendOtpPayload);
		setLogin(4);
		if (response.ok) {
			const message = 'OTP Sent Successfully';
			setAlertMessage(message);
			setTimeout(() => {
				setAlertMessage(null);
			}, 4000);
			setVerifyId(response.data.data.verify_id);
		}
	};

	const NewUsersendOtp = async (phoneNO) => {
		let sendOtpPayload = {
			flow_id: '2079031d-a1ac-4bbe-bf58-2495bd6e5703',
			to: {
				mobile: `+91${phoneNO}`,
				email: 'test@gmail.com',
			},
		};
		const response = await ApiHandler.sendOtp(sendOtpPayload);
		setLogin(7);
		if (response.ok) {
			const message = 'OTP Sent Successfully';
			setAlertMessage(message);
			setTimeout(() => {
				setAlertMessage(null);
			}, 4000);
			setVerifyId(response.data.data.verify_id);
		}
	};

	const _verifyOtp = async (otp, verifyId, phoneNO) => {
		const verifyData = {
			verify_id: verifyId,
			otp: otp,
		};
		const response = await ApiHandler.verifyOtp(verifyData);
		if (response.ok) {
			const msg = 'Otp Verified Successfully';
			setOtpVefifyMessage(msg);
			setTimeout(() => {
				setOtpVefifyMessage(null);
			}, 5000);
			setLogin(5);
		} else {
			const msg = 'You Enter Wrong Otp';
			setOtpAlertMessage(msg);
			setTimeout(() => {
				setOtpAlertMessage(null);
			}, 6000);
		}
	};

	const NewUserverifyOtp = async (otp, verifyId, phoneNO) => {
		const verifyData = {
			verify_id: verifyId,
			otp: otp,
		};
		const response = await ApiHandler.verifyOtp(verifyData);
		if (response.ok) {
			const msg = 'Otp Verified Successfully';
			setOtpVefifyMessage(msg);
			setTimeout(() => {
				setOtpVefifyMessage(null);
			}, 5000);
			setLogin(6);
		} else {
			const msg = 'You Enter Wrong Otp';
			setOtpAlertMessage(msg);
			setTimeout(() => {
				setOtpAlertMessage(null);
			}, 6000);
		}
	};

	const _forgotPassword = async () => {
		if (newPin === confirmPin) {
			const newPassword = newPin;
			const data = { phone: phoneNO, password: newPassword };
			const response = await ApiHandler.forgotPassword(data);
			if (response.ok) {
				const msg = 'New Passcode Change Successfully';
				setPasswordMsg(msg);
				setTimeout(() => {
					setPasswordMsg(null);
				}, 4000);
				setLogin(2);
			}
		} else {
			const msg = 'Passcodes Must Be Match';
			setPasswordMismatchMsg(msg);
			setTimeout(() => {
				setPasswordMismatchMsg(null);
			}, 4000);
		}
	};

	const handlePasscodeChange = (otp) => {
		const correctedPasscode = otp.replace(/[^0-9]/g, '');
		setPassword(correctedPasscode);
	};

	const handleKeyDown = (e) => {
		if (e.key && !/^[0-9]$/.test(e.key)) {
			e.preventDefault();
		}
		if (e.key === 'Backspace') {
			const updatedPassword = password.slice(0, -1);
			setPassword(updatedPassword);
		}
	};

	const handleOtpChange = (otp) => {
		const correctOtp = otp.replace(/[^0-9]/g, '');
		setOtp(correctOtp);
	};

	const handleOtpDown = (e) => {
		if (e.key && !/^[0-9]$/.test(e.key)) {
			e.preventDefault();
		}
		if (e.key === 'Backspace') {
			const updateOtp = otp.slice(0, -1);
			setOtp(updateOtp);
		}
	};

	const handleNewpinChange = (pin) => {
		const correctPin = pin.replace(/[^0-9]/g, '');
		setNewPin(correctPin);
	};

	const handleNewpinDown = (e) => {
		if (e.key && !/^[0-9]$/.test(e.key)) {
			e.preventDefault();
		}
		if (e.key === 'Backspace') {
			const updateNewpin = newPin.slice(0, -1);
			setNewPin(updateNewpin);
		}
	};

	const handleConfirmpinChange = (confirmPin) => {
		const correctconfirmPin = confirmPin.replace(/[^0-9]/g, '');
		setConfirmPin(correctconfirmPin);
	};

	const handleConfirmpinDown = (e) => {
		if (e.key && !/^[0-9]$/.test(e.key)) {
			e.preventDefault();
		}
		if (e.key === 'Backspace') {
			const updateConfirmpin = confirmPin.slice(0, -1);
			setConfirmPin(updateConfirmpin);
		}
	};

	const resendOTP = (phoneNO) => {
		setMinutes(1);
		setSeconds(30);
		_sendOtp(phoneNO);
	};

	const Registeruser = async () => {
		if (newPin === confirmPin) {
			let newUserPassword = newPin;
			const data = { username: phoneNO, password: newUserPassword };
			const response = await ApiHandler.registerUser(data);

			if (response.ok) {
				localStorage.setItem('userid', response.data.id);
				setLogin(2);
				CreateProfile();
			} else {
				alert('Registration Failed');
			}
		}
	};

	const CreateProfile = async () => {
		const data = {
			phone: phoneNO,
			first_name: userName,
			user: localStorage.getItem('userid'),
			last_name: '',
			email: '',
			gender: '',
			year_of_birth: '',
			lat: '',
			long: '',
			app_version: '20.3.03',
		};
		try {
			const response = await ApiHandler.profileCreate(data);
			if (response.data.status === 200) {
			const msg = ' Profile created successfully';
			setProfileSuccessMsg(msg);
			_updateProfile();
			setTimeout(() => {
				setProfileSuccessMsg(null);
			}, 4000);
		}
		} catch (error) {
			console.log('Error in creating profile : ', error);
		}
		
	};

	const Loginuser = async () => {
		const data = { phone: phoneNO, password: password };
		try {
			const response = await ApiHandler.loginUser(data);
			const data = response.data.data;
			if(data){
				setLoggedUser(data);
				button();
			localStorage.setItem('loggedIn',data[0].id);
			}
			else{
        notify();
			  setError(2);
			}
		} catch (error) {
			console.log("Login Error", error);
		}
	};

	const handleUserName = (e) => {
		const name = e.target.value;
		const [firstName, ...rest] = name.split(' ');
		const lastName = rest.join(' ');

		if (name.trim() === '') {
			setUserName({ firstName: '', lastName: '' });
		} else {
			setUserName((prevName) => ({
				firstName: firstName || (prevName ? prevName.firstName : ''),
				lastName: lastName || (prevName ? prevName.lastName : ''),
			}));
		}
	};

	const _updateProfile = async () => {
		const data = {
			phone: '',
			gender: '',
			year_of_birth: '',
			first_name: userName.firstName,
			lat: '216.321',
			long: '073.310',
			last_name: userName.lastName,
			email: '',
			user: localStorage.getItem('userid'),
		};
		try {
			const response = await ApiHandler.updateProfile(data);
		} catch (error) {
			console.log('Update Profile Error', error);
		}
		
	};

	return (
		<div
			className="signup_container"
			onClick={() => {
				button(true);
			}}
		>
			<Box
				className="signup_modal"
				onClick={(e) => {
					e.stopPropagation();
				}}
			>
				<Grid
					container
					xs={12}
					sm={12}
					xl={12}
					lg={12}
					md={12}
					sx={{
						// columnGap: 3,
						display: 'flex',
						justifyContent: 'center',
						alignContent: 'center',
					}}
				>
					<Grid
						className="signup_content"
						sx={{
							display: {
								xs: 'none',
								sm: 'block',
								md: 'block',
								lg: 'block',
								xl: 'block',
							},
						}}
						xs={12}
						sm={6}
						xl={6}
						lg={6}
						md={6}
					>
						<Typography
							gutterBottom
							variant="h3"
							component="div"
							fontWeight={'700'}
							fontSize={{ lg: '23px', md: '22px', sm: '20px', xs: '18px' }}
							color={'#fff'}
							sx={{ my: 2, py: 3 }}
							textAlign={'center'}
						>
							{login === 1 && 'Welcome Back!'}
							{login === 8 && 'Looks like you are new here!'}
							{login === 2 && 'Enter a passcode'}
							{login === 3 && 'Enter the OTP '}
							{login === 4 && 'Enter the OTP to unlock your account!'}
							{login === 5 && 'Set a passcode to secure your account!'}
							{login === 6 && 'Set a passcode to secure your account!'}
							{login === 7 && 'Time to authenticate!'}
						</Typography>
						<Typography
							gutterBottom
							variant="p"
							component="div"
							fontWeight={'500'}
							fontSize={{ lg: '18px', md: '18px', sm: '18px', xs: '18px' }}
							color={'#fff'}
							sx={{ my: 2, padding: '20px' }}
							textAlign={'center'}
						>
							{login === 8 && 'Sign up with your mobile number to get started'}
							{login === 1 &&
								'Unlock the door to personalized experiences. Log in and step into a world tailored just for you.'}
							{login === 2 &&
								"We're thrilled to have you here again. Your journey continues with us."}
							{login === 3 &&
								'sent to your registered mobile number to proceed. Your security is our priority.'}
							{login === 4 &&
								"We're here to ensure a secure and seamless experience for you."}
							{login === 5 &&
								'Confirm your choice to ensure your information stays protected.'}
							{login === 6 &&
								'Confirm your choice to ensure your information stays protected.'}
							{login === 7 &&
								'Input the OTP received on your mobile and unlock the possibilities that await.'}
						</Typography>
						<div className="img_container">
							<div className="signin_img_container">
								{login === 1 && (
									<img
										src={Login_img}
										className="signin_img"
										alt={'Login_img'}
									/>
								)}
								{login === 2 && (
									<img
										src={Password_img}
										className="signin_img"
										alt={'Password_img'}
									/>
								)}
								{login === 4 && (
									<img
										src={Otp_img}
										className="signin_img"
										alt={'Password_img'}
									/>
								)}
								{login === 3 && (
									<img
										src={Otp_img}
										className="signin_img"
										alt={'Password_img'}
									/>
								)}
								{login === 5 && (
									<img
										src={Set_password}
										className="signin_img"
										alt={'set_Password_img'}
									/>
								)}
								{login === 6 && (
									<img
										src={Set_password}
										className="signin_img"
										alt={'set_Password_img'}
									/>
								)}
								{login === 7 && (
									<img
										src={Otp_img}
										className="signin_img"
										alt={'Password_img'}
									/>
								)}
								{login === 8 && (
									<img
										src={signup_img}
										className="signin_img"
										alt={'Signup_img'}
									/>
								)}
							</div>
						</div>
					</Grid>
					<Grid xs={12} sm={6} xl={6} lg={6} md={6}>
						<div style={{ textAlign: 'end' }}>
							<CloseIcon
								sx={{
									width: '36px',
									height: '36px',
									mt: 1,
									mr: 1,
									borderRadius: '50%',
									backgroundColor: '#F7F7F7',
									cursor: 'pointer',
									padding: 1,
								}}
								onClick={button}
							/>
						</div>
						<div className="signup_form">
							<div>
								<div>
									{error === 1 && (
										<div>
											<Typography
												gutterBottom
												variant="h3"
												component="div"
												fontSize={'22px'}
												fontWeight={700}
												lineHeight={'34px'}
												color={'#002441'}
											>
												<span
													style={{ fontSize: '20px', fontWeight: 'normal' }}
												>
													to <br /> continue
												</span>
											</Typography>
											<InputLabel
												sx={{
													fontWeight: 600,
													fontSize: '14px',
													lineHeight: '22px',
													mb: 1,
													mt: 2.5,
												}}
											>
												Mobile Number
											</InputLabel>
											<TextField
												fullWidth
												type="tel"
												autoComplete="off"
												id="outlined-error-helper-text"
												label=""
												placeholder="Enter Mobile Number"
												size="medium"
												value={phoneNO}
												onChange={(e) => setPhoneNo(e.target.value)}
												variant="outlined"
												sx={{
													border: '1px solid #bebebe',
													borderRadius: '8px',
												}}
											/>
										</div>
									)}
									{error !== 1 && login === 1 && (
										<div>
											<Typography
												gutterBottom
												variant="h3"
												component="div"
												fontSize={'22px'}
												fontWeight={700}
												lineHeight={'34px'}
												color={'#002441'}
											>
												{login === 8 ? 'Sign up' : 'Log in'}{' '}
												<span
													style={{ fontSize: '20px', fontWeight: 'normal' }}
												>
													to <br /> continue
												</span>
											</Typography>
											<InputLabel
												sx={{
													fontWeight: 600,
													fontSize: '14px',
													lineHeight: '22px',
													mb: 1,
													mt: 2.5,
												}}
											>
												Mobile Number
											</InputLabel>{' '}
											<TextField
												autoComplete="off"
												fullWidth
												type="tel"
												id="outlined-basic"
												label=""
												placeholder="Enter Mobile Number"
												size="medium"
												value={phoneNO}
												onChange={(e) => handleHighlight(e)}
												inputProps={{ maxLength: 10 }}
												variant="outlined"
												sx={{
													border: '1px solid #bebebe',
													borderRadius: '8px',
												}}
											/>
										</div>
									)}
									{error === 8 && (
										<div>
											<Typography
												gutterBottom
												variant="h3"
												component="div"
												fontSize={'22px'}
												fontWeight={700}
												lineHeight={'34px'}
												color={'#002441'}
											>
												Sign up{' '}
												<span
													style={{ fontSize: '20px', fontWeight: 'normal' }}
												>
													to <br /> continue
												</span>
											</Typography>
											<InputLabel
												sx={{
													fontWeight: 600,
													fontSize: '14px',
													lineHeight: '22px',
													mb: 1,
													mt: 2.5,
												}}
											>
												Mobile Number
											</InputLabel>
											<TextField
												fullWidth
												autoComplete="off"
												id="outlined-error-helper-text"
												label=""
												placeholder="Enter Mobile Number"
												size="medium"
												value={phoneNO}
												onChange={(e) => setPhoneNo(e.target.value)}
												variant="outlined"
												sx={{
													border: '1px solid #bebebe',
													borderRadius: '8px',
												}}
											/>
										</div>
									)}
									{error !== 8 && login === 8 && (
										<div>
											<Typography
												gutterBottom
												variant="h3"
												component="div"
												fontSize={'22px'}
												fontWeight={700}
												lineHeight={'34px'}
												color={'#002441'}
											>
												Sign up{' '}
												<span
													style={{ fontSize: '20px', fontWeight: 'normal' }}
												>
													to <br /> continue
												</span>
											</Typography>
											<InputLabel
												sx={{
													fontWeight: 600,
													fontSize: '14px',
													lineHeight: '22px',
													mb: 1,
													mt: 2.5,
												}}
											>
												Mobile Number
											</InputLabel>{' '}
											<TextField
												autoComplete="off"
												fullWidth
												id="outlined-basic"
												label=""
												placeholder="Enter Mobile Number"
												size="medium"
												value={phoneNO}
												onChange={(e) => handleHighlight(e)}
												inputProps={{ maxLength: 10 }}
												variant="outlined"
												sx={{
													border: '1px solid #bebebe',
													borderRadius: '8px',
												}}
											/>
										</div>
									)}

									{/* {signUpOtp && (
                  <div className ="signup Otp">
                    <Box>
                      <Grid container>
                        <Grid xs={6}>OTP Sent to {phoneNO}</Grid>
                        <Grid xs={6}>Resend ?</Grid>
                        <div>
                          <TextField fullWidth id="outlined-basic" label= 'Enter Otp' size="small" 
                          variant="standard" />
                        </div>
                      </Grid>
                    </Box>
                  </div>
                )} */}

									{error === 2 && (
										<div>
											<InputLabel
												sx={{
													fontWeight: 600,
													fontSize: '14px',
													lineHeight: '22px',
													mb: 1,
													mt: 2.5,
												}}
											>
												Enter Passcode
											</InputLabel>
											<OtpInput
												value={password}
												onChange={handlePasscodeChange}
												numInputs={6}
												renderSeparator={<span className="otpinputs"> </span>}
												renderInput={(props) => (
													<input
														{...props}
														onKeyDown={handleKeyDown}
														maxLength={1}
														pattern="[0-9]*"
														className="custom-input"
													/>
												)}
											/>
											<div style={{ textAlign: 'end', marginTop: '10px' }}>
												<Typography
													onClick={() => {
														_sendOtp(phoneNO);
														setError(0);
													}}
													gutterBottom
													my={2}
													variant="div"
													textAlign={'end'}
													fontSize={{ xs: 15 }}
													color={'red'}
													sx={{ cursor: 'pointer' }}
												>
													Forgot Password?
												</Typography>
											</div>
										</div>
									)}
									{error !== 2 && login === 2 && (
										<div>
											<InputLabel
												sx={{
													fontWeight: 600,
													fontSize: '14px',
													lineHeight: '22px',
													mb: 1,
													mt: 2.5,
												}}
											>
												Enter Passcode
											</InputLabel>
											<OtpInput
												value={password}
												onChange={handlePasscodeChange}
												numInputs={6}
												renderSeparator={<span className="otpinputs"> </span>}
												isInputNum
												renderInput={(props) => (
													<input
														{...props}
														onKeyDown={handleKeyDown}
														maxLength={1}
														pattern="[0-9]*"
														className="custom-input"
													/>
												)}
											/>
											<div style={{ textAlign: 'end', marginTop: '10px' }}>
												<Typography
													onClick={() => _sendOtp(phoneNO)}
													gutterBottom
													my={2}
													variant="div"
													textAlign={'end'}
													fontSize={{ xs: 15 }}
													color={'red'}
													sx={{ cursor: 'pointer' }}
												>
													Forgot Passcode?
												</Typography>
											</div>
										</div>
									)}

									{error === 3 && <h1>hi</h1>}

									{error !== 3 && login === 3 && (
										<div>
											<InputLabel
												sx={{
													fontWeight: 600,
													fontSize: '14px',
													lineHeight: '22px',
													mb: 1,
												}}
											>
												Mobile Number
											</InputLabel>{' '}
											<TextField
												autoComplete="off"
												fullWidth
												id="outlined-basic"
												label=""
												placeholder="Enter Mobile Number"
												size="medium"
												variant="outlined"
												sx={{
													border: '1px solid #bebebe',
													borderRadius: '8px',
												}}
												value={phoneNO}
												onChange={(e) => setPhoneNo(e.target.value)}
											/>
										</div>
									)}

									{error === 4 && (
										<OtpInput
											value={otp}
											onChange={setOtp}
											numInputs={6}
											renderSeparator={<span className="otpinputs"> </span>}
											renderInput={(props) => (
												<input {...props} className="custom-input" />
											)}
										/>
									)}

									{error !== 4 && login === 4 && (
										<div>
											<Typography
												gutterBottom
												variant="p"
												component="div"
												fontWeight={'500'}
												fontSize={{
													lg: '18px',
													md: '18px',
													sm: '18px',
													xs: '18px',
												}}
												color={'#000'}
												sx={{ my: 5 }}
												textAlign={'center'}
											>
												Please enter the OTP sent to {phoneNO}
											</Typography>

											<OtpInput
												value={otp}
												onChange={handleOtpChange}
												numInputs={6}
												renderSeparator={<span className="otpinputs"> </span>}
												renderInput={(props) => (
													<input
														{...props}
														onKeyDown={handleOtpDown}
														maxLength={1}
														pattern="[0-9]*"
														className="custom-input"
													/>
												)}
											/>
											<div className="countdown-text">
												{seconds > 0 || minutes > 0 ? (
													<Typography>
														Time Remaining:{' '}
														{minutes < 10 ? `0${minutes}` : minutes}:
														{seconds < 10 ? `0${seconds}` : seconds}
													</Typography>
												) : (
													<p>Didn't recieve code?</p>
												)}

												<Button
													disabled={seconds > 0 || minutes > 0}
													style={{
														color:
															seconds > 0 || minutes > 0
																? '#DFE3E8'
																: '#FF5630',
													}}
													onClick={() => resendOTP(phoneNO)}
												>
													Resend OTP
												</Button>
											</div>
										</div>
									)}
									{error === 5 && (
										<OtpInput
											value={otp}
											onChange={setOtp}
											numInputs={6}
											renderSeparator={<span className="otpinputs"> </span>}
											renderInput={(props) => (
												<input {...props} className="custom-input" />
											)}
										/>
									)}

									{error !== 5 && login === 5 && (
										<div>
											<Typography
												gutterBottom
												variant="p"
												component="div"
												fontWeight={'500'}
												fontSize={{
													lg: '18px',
													md: '18px',
													sm: '18px',
													xs: '18px',
												}}
												color={'#000'}
												sx={{ my: 1 }}
												textAlign={'start'}
											>
												New Pin
											</Typography>

											<OtpInput
												value={newPin}
												onChange={handleNewpinChange}
												numInputs={6}
												renderSeparator={<span className="otpinputs"> </span>}
												renderInput={(props) => (
													<input
														{...props}
														onKeyDown={handleNewpinDown}
														maxLength={1}
														pattern="[0-9]*"
														className="custom-input"
													/>
												)}
											/>

											<Typography
												gutterBottom
												variant="p"
												component="div"
												fontWeight={'500'}
												fontSize={{
													lg: '18px',
													md: '18px',
													sm: '18px',
													xs: '18px',
												}}
												color={'#000'}
												sx={{ my: 2 }}
												textAlign={'start'}
											>
												Confirm Pin
											</Typography>

											<OtpInput
												value={confirmPin}
												onChange={handleConfirmpinChange}
												numInputs={6}
												renderSeparator={<span className="otpinputs"> </span>}
												renderInput={(props) => (
													<input
														{...props}
														onKeyDown={handleConfirmpinDown}
														maxLength={1}
														pattern="[0-9]*"
														className="custom-input"
													/>
												)}
											/>
										</div>
									)}
									{error !== 6 && login === 6 && (
										<div>
											<Typography
												gutterBottom
												variant="p"
												component="div"
												fontWeight={'500'}
												fontSize={{
													lg: '18px',
													md: '18px',
													sm: '18px',
													xs: '18px',
												}}
												color={'#000'}
												sx={{ my: 1 }}
												textAlign={'start'}
											>
												Enter Your Name
											</Typography>
											<TextField
												fullWidth
												autoComplete="off"
												id="outlined-basic"
												size="small"
												value={`${userName.firstName} ${userName.lastName}`}
												onChange={handleUserName}
												variant="outlined"
												my={1}
												width={250}
											/>
											<Typography
												gutterBottom
												variant="p"
												component="div"
												fontWeight={'500'}
												fontSize={{
													lg: '18px',
													md: '18px',
													sm: '18px',
													xs: '18px',
												}}
												color={'#000'}
												sx={{ my: 1 }}
												textAlign={'start'}
											>
												New Pin
											</Typography>

											<OtpInput
												value={newPin}
												onChange={handleNewpinChange}
												numInputs={6}
												renderSeparator={<span className="otpinputs"> </span>}
												renderInput={(props) => (
													<input
														{...props}
														onKeyDown={handleNewpinDown}
														maxLength={1}
														pattern="[0-9]*"
														className="custom-input"
													/>
												)}
											/>

											<Typography
												gutterBottom
												variant="p"
												component="div"
												fontWeight={'500'}
												fontSize={{
													lg: '18px',
													md: '18px',
													sm: '18px',
													xs: '18px',
												}}
												color={'#000'}
												sx={{ my: 2 }}
												textAlign={'start'}
											>
												Confirm Pin
											</Typography>

											<OtpInput
												value={confirmPin}
												onChange={handleConfirmpinChange}
												numInputs={6}
												renderSeparator={<span className="otpinputs"> </span>}
												renderInput={(props) => (
													<input
														{...props}
														onKeyDown={handleConfirmpinDown}
														maxLength={1}
														pattern="[0-9]*"
														className="custom-input"
													/>
												)}
											/>
										</div>
									)}
									{error !== 7 && login === 7 && (
										<div>
											<Typography
												gutterBottom
												variant="p"
												component="div"
												fontWeight={'500'}
												fontSize={{
													lg: '18px',
													md: '18px',
													sm: '18px',
													xs: '18px',
												}}
												color={'#000'}
												sx={{ my: 5 }}
												textAlign={'center'}
											>
												Please enter the OTP sent to {phoneNO}
											</Typography>

											<OtpInput
												value={otp}
												onChange={handleOtpChange}
												numInputs={6}
												renderSeparator={<span className="otpinputs"> </span>}
												renderInput={(props) => (
													<input
														{...props}
														onKeyDown={handleOtpDown}
														maxLength={1}
														pattern="[0-9]*"
														className="custom-input"
													/>
												)}
											/>
											<div className="countdown-text">
												{seconds > 0 || minutes > 0 ? (
													<Typography>
														Time Remaining:{' '}
														{minutes < 10 ? `0${minutes}` : minutes}:
														{seconds < 10 ? `0${seconds}` : seconds}
													</Typography>
												) : (
													<p>Didn't recieve code?</p>
												)}

												<Button
													disabled={seconds > 0 || minutes > 0}
													style={{
														color:
															seconds > 0 || minutes > 0
																? '#DFE3E8'
																: '#FF5630',
													}}
													onClick={() => resendOTP(phoneNO)}
												>
													Resend OTP
												</Button>
											</div>
										</div>
									)}
								</div>
								<div>
									<Typography
										gutterBottom
										variant="p"
										component="div"
										fontWeight={'400'}
										fontSize={{
											lg: '14px',
											md: '14px',
											sm: '14px',
											xs: '14px',
										}}
										color={'#878787'}
										sx={{ mt: 4, mb: 2 }}
										textAlign={'start'}
									>
										By clicking continue you agree to the{' '}
										<span style={{ color: 'blue' }}>Terms and Conditions.</span>{' '}
										content policies
									</Typography>
								</div>

								{login === 1 && (
									<div style={{ textAlign: 'center' }}>
										<Button
											variant="outlined"
											size="small"
											disabled={highlight === true ? false : true}
											sx={{
												fontWeight: 600,
												fontSize: '12px',
												textTransform: 'none',
												width: '60%',
												height: 50,
												background: highlight ? '#eb232a' : '#cdd0d4!important',
												borderRadius: '16px',
												cursor: highlight === true ? 'pointer' : 'not-allowed',
												color: 'white',
												'&:hover': {
													background: '#eb232a',
													border: 'none',
												},
												border: 'none',
											}}
											onClick={() => validation()}
										>
											Continue
										</Button>
									</div>
								)}

								{login === 2 && (
									<div style={{ textAlign: 'center' }}>
										<Button
											variant="outlined"
											size="small"
											sx={{
												fontWeight: 600,
												fontSize: '16px',
												textTransform: 'none',
												width: '60%',
												height: 50,
												background: highlight ? '#eb232a' : '#cdd0d4!important',
												borderRadius: '12px',
												color: 'white',
												'&:hover': {
													background: '#eb232a',
													border: 'none',
												},
												border: 'none',
											}}
											onClick={() => Loginuser()}
										>
											Continue
										</Button>
									</div>
								)}

								{login === 3 && (
									<div style={{ textAlign: 'center' }}>
										<Button
											variant="outlined"
											size="small"
											sx={{
												fontWeight: 600,
												fontSize: '12px',
												textTransform: 'none',
												width: '70%',
												height: 50,
												background: '#eb232a',
												borderRadius: '16px',
												color: 'white',
												'&:hover': {
													background: '#eb232a',
													border: 'none',
												},
												border: 'none',
												my: 4,
											}}
											onClick={() => NewUsersendOtp(phoneNO)}
										>
											Requested OTP
										</Button>
										<div style={{ textAlign: 'center' }}>
											<Button
												variant="outlined"
												size="small"
												sx={{
													fontWeight: 600,
													fontSize: '12px',
													textTransform: 'none',
													width: '70%',
													height: 50,
													background:
														'linear-gradient(to right, #2b5876, #4e4376)',
													borderRadius: '16px',
													color: 'white',
													'&:hover': {
														background:
															'linear-gradient(to right, #2b5876, #4e4376)',
													},
													border: 'none',
												}}
												onClick={() => setLogin(1)}
											>
												Existing User ? Log in
											</Button>
										</div>
									</div>
								)}

								{login === 4 && (
									<div style={{ textAlign: 'center' }}>
										<Button
											variant="outlined"
											size="small"
											sx={{
												fontWeight: 600,
												fontSize: '16px',
												textTransform: 'none',
												width: '60%',
												height: 50,
												background: '#eb232a',
												borderRadius: '12px',
												color: 'white',
												'&:hover': {
													background: '#eb232a',
													border: 'none',
												},
												border: 'none',
											}}
											onClick={() => _verifyOtp(otp, verifyId, phoneNO)}
										>
											Verify OTP
										</Button>
									</div>
								)}

								{login === 5 && (
									<div>
										<Button
											variant="outlined"
											size="small"
											sx={{
												fontWeight: 600,
												fontSize: '16px',
												textTransform: 'none',
												width: '60%',
												height: 50,
												background: '#eb232a',
												borderRadius: '12px',
												color: 'white',
												'&:hover': {
													background: '#eb232a',
													border: 'none',
												},
												border: 'none',
											}}
											onClick={() => _forgotPassword()}
										>
											Continue
										</Button>
									</div>
								)}
								{login === 6 && (
									<div style={{ textAlign: 'center' }}>
										<Button
											variant="outlined"
											size="small"
											sx={{
												fontWeight: 600,
												fontSize: '16px',
												textTransform: 'none',
												width: '60%',
												height: 50,
												background: '#eb232a',
												borderRadius: '12px',
												color: 'white',
												'&:hover': {
													background: '#eb232a',
													border: 'none',
												},
												border: 'none',
											}}
											onClick={() => Registeruser()}
										>
											Continue
										</Button>
									</div>
								)}

								{login === 7 && (
									<div style={{ textAlign: 'center' }}>
										<Button
											variant="outlined"
											size="small"
											sx={{
												fontWeight: 600,
												fontSize: '16px',
												textTransform: 'none',
												width: '60%',
												height: 50,
												background: '#eb232a',
												borderRadius: '12px',
												color: 'white',
												'&:hover': {
													background: '#eb232a',
													border: 'none',
												},
												border: 'none',
											}}
											onClick={() => NewUserverifyOtp(otp, verifyId, phoneNO)}
										>
											Verify OTP
										</Button>
									</div>
								)}

								{login === 8 && (
									<div style={{ textAlign: 'center' }}>
										<Button
											variant="outlined"
											size="small"
											disabled={highlight === true ? false : true}
											sx={{
												fontWeight: 600,
												fontSize: '12px',
												textTransform: 'none',
												width: '60%',
												height: 50,
												background: highlight ? '#eb232a' : '#cdd0d4!important',
												borderRadius: '16px',
												cursor: highlight === true ? 'pointer' : 'not-allowed',
												color: 'white',
												'&:hover': {
													background: '#eb232a',
													border: 'none',
												},
												border: 'none',
											}}
											onClick={() => validation()}
										>
											Continue
										</Button>
									</div>
								)}
							</div>
							<div className="new_user_btn">
								{login === 1 && (
									<Button
										variant="text"
										className="new_user"
										size="small"
										sx={{
											fontSize: { xs: '13px' },
											textTransform: 'none',
											width: '100%',
											height: 50,
											textAlign: 'start',
										}}
										onClick={() => setLogin(8)}
									>
										New to Kalyani Motors ? create profile
									</Button>
								)}
							</div>
							<div className="new_user_btn">
								{login === 2 && (
									<Button
										variant="text"
										className="new_user"
										size="small"
										sx={{
											fontSize: { xs: '13px' },
											textTransform: 'none',
											width: '100%',
											height: 50,
											textAlign: 'start',
										}}
										onClick={() => setLogin(1)}
									>
										Already you are in Kalyani Motors ? Login
									</Button>
								)}
							</div>
							<div className="new_user_btn">
								{login === 8 && (
									<Button
										variant="text"
										className="new_user"
										size="small"
										sx={{
											fontSize: { xs: '13px' },
											textTransform: 'none',
											width: '100%',
											height: 50,
											textAlign: 'start',
										}}
										onClick={() => setLogin(1)}
									>
										Already you are in Kalyani Motors ? Login
									</Button>
								)}
							</div>
							{alertMessage && <Alert severity="success">{alertMessage}</Alert>}
							{otpAlertMessage && (
								<Alert severity="error">{otpAlertMessage}</Alert>
							)}
							{otpVefifyMessage && (
								<Alert severity="success">{otpVefifyMessage}</Alert>
							)}
							{passwordmsg && <Alert severity="success">{passwordmsg}</Alert>}
							{passwordMismatchMsg && (
								<Alert severity="error">{passwordMismatchMsg}</Alert>
							)}
							{profileSuccessMsg && (
								<Alert severity="success">{profileSuccessMsg}</Alert>
							)}
							<Encryption phoneNo={phoneNO} password={password} />
						</div>
					</Grid>
				</Grid>
			</Box>
		</div>
	);
};

export default Signup;
