import React, { useState, useEffect } from 'react';
import ApiHandler from '../../../Api/Apihandler';
import './PaymentRequest.css';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { getComaPrice } from '../../../Constants/Comaprice';
// import Profile from "./../ProfileScreens/Profile/Profile";
import Payment from '../../../Assets/Images/Payment_Req.png';
import Paper from '@mui/material/Paper';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import OfflineBoltOutlinedIcon from '@mui/icons-material/OfflineBoltOutlined';
import Skeleton from '@mui/material/Skeleton';
import { useNavigate } from 'react-router-dom';
import NopaymentImage from '../../../Assets/Images/no-payment-req-illustration-01.png';
import { AES, enc } from 'crypto-js';
import { useParams } from 'react-router-dom';
import { formatDate } from '../../../Constants/FormatDateTime';
import { useMediaQuery } from '@mui/material';
import { useLocation } from 'react-router-dom';


const PaymentRequestScreen = () => {
	const [request, setRequest] = useState();
	const loginId = localStorage.getItem('loggedIn');
	const [profileData, setProfileData] = useState([]);
	const [phoneNO, setPhoneNo] = useState();
	const [loader, setLoader] = useState(false);

	const navigate = useNavigate();
	const isSmallScreen = useMediaQuery('(max-width:900px)');

	useEffect(() => {
		setLoader(true);
		_getProfile(loginId);
		_getPaymentRequestList(phoneNO);
	}, [phoneNO]);

	useEffect(() => {
		const encryptedPhoneNo = localStorage.getItem('phone');
		if (encryptedPhoneNo) {
			const decryptedPhone = AES.decrypt(
				encryptedPhoneNo,
				'encryptionSecret'
			).toString(enc.Utf8);
			_getPaymentRequestList(decryptedPhone);
		}
	}, []);

	const _getProfile = async () => {
		try {
			const response = await ApiHandler.getProfile(loginId);
			const data = response.data.data[0];
			setProfileData({
				first_name: data.user.first_name,
				last_name: data.user.last_name,
			});
			setTimeout(() => {
				setLoader(false);
			}, 2000);
		} catch (error) {
			console.log('profile Data error', error);
		}
	};

	const _getPaymentRequestList = async (phone) => {
		try {
			const response = await ApiHandler.getPaymentlist(phone);
			if (response.data.status === 200) {
				const data = response.data.data;
				setRequest(data);
				console.log('data', data);
			}
		} catch (error) {
			console.log('paymentRequestError', error);
		}
	};

	return (
		<div>
			{loader ? (
				Array.from({ length: 1 }).map((index) => (
					<Grid key={index} item xs={12} sm={12} md={12} lg={12} p={1}>
						<Skeleton variant="rectangular" width="100%" height="305px" />
						<Skeleton variant="rounded" style={{ marginTop: '10px' }} />
						<Skeleton
							variant="rounded"
							width="90%"
							style={{ marginTop: '10px' }}
						/>
						<Skeleton
							variant="rounded"
							width="60%"
							style={{ marginTop: '10px' }}
						/>
					</Grid>
				))
			) : (
				<div className="payment_request_screen">
					<Typography
						gutterBottom
						variant="h4"
						component="div"
						fontWeight={'700'}
						fontSize={{ lg: '20px', md: '20px', sm: '20px', xs: '18px' }}
						color={'#0A0A0A'}
						sx={{ my: 2 }}
						textAlign={'center'}
						lineHeight={2}
					>
						Payment Simplified
					</Typography>
					<div className="payment_Request">
						<Box>
							<Grid container className="payment_Request_contianer">
								<Grid xs={12} sm={12} md={4}>
									<Grid container className="payment_request_name_container">
										<Grid xs={6} md={12} className="payment_request_img_grid">
											<div className="payment_req_img_container">
												<img
													src={Payment}
													alt={'payment_img'}
													style={{ width: '100%', height: '100%' }}
												/>
											</div>
										</Grid>
										<Grid
											xs={6}
											md={12}
											className="payment_request_name_grid"
											sx={{ my: { xs: 0, md: 2 } }}
										>
											<Typography
												gutterBottom
												variant="h4"
												component="div"
												fontWeight={'700'}
												fontSize={{
													lg: '15px',
													md: '15px',
													sm: '15px',
													xs: '15px',
												}}
												color={'#424A99'}
												// sx={{ my: 2 }}
												textAlign={'start'}
												lineHeight={2}
											>
												Hi,
											</Typography>
											<Typography
												gutterBottom
												variant="h4"
												component="div"
												fontWeight={'700'}
												fontSize={{
													lg: '15px',
													md: '15px',
													sm: '15px',
													xs: '15px',
												}}
												color={'#424A99'}
												// sx={{ my: 2 }}
												textAlign={'start'}
												lineHeight={2}
											>
												{`${profileData.first_name} ${profileData.last_name}`}
											</Typography>
										</Grid>
									</Grid>
								</Grid>
								<Grid xs={12} sm={12} md={7}>
									<div className="request_container">
										{request && request.length > 0 ? (
											request &&
											request.map((item) => (
												<div>
													{isSmallScreen === true ? (
														<Box className="payment_card">
															<Grid
																container
																className="payment_card_container"
															>
																<Grid
																	item
																	md={6}
																	className={
																		isSmallScreen
																			? 'payment_card_mobile_grid'
																			: 'payment_card_grid'
																	}
																>
																	<Typography
																		gutterBottom
																		variant="h4"
																		component="div"
																		fontWeight={'700'}
																		fontSize={{
																			lg: '15px',
																			md: '15px',
																			sm: '15px',
																			xs: '13px',
																		}}
																		color={'#424A99'}
																		// sx={{ my: 2 }}
																		textAlign={'center'}
																		lineHeight={2}
																	>
																		{item.channel}
																	</Typography>
																	<div>
																		<Typography
																			gutterBottom
																			variant="h4"
																			component="div"
																			fontWeight={'700'}
																			fontSize={{
																				lg: '13px',
																				md: '13px',
																				sm: '13px',
																				xs: '13px',
																			}}
																			color={'#0a0a0a'}
																			// sx={{ my: 2 }}
																			textAlign={'start'}
																			lineHeight={2}
																		>
																			Requested By :{' '}
																			<span
																				style={{
																					color: '#B9BDCE',
																					fontWeight: 500,
																				}}
																			>
																				{item.requested_employee_name}
																			</span>
																		</Typography>
																		<Typography
																			gutterBottom
																			variant="h4"
																			component="div"
																			fontWeight={'700'}
																			fontSize={{
																				lg: '13px',
																				md: '13px',
																				sm: '13px',
																				xs: '13px',
																			}}
																			color={'#0a0a0a'}
																			// sx={{ my: 2 }}
																			textAlign={'start'}
																			lineHeight={2}
																		>
																			Purpose :{' '}
																			<span
																				style={{
																					color: '#B9BDCE',
																					fontWeight: 500,
																				}}
																			>
																				{item.purpose.slice(0, 100)}
																				{item.purpose.length > 100
																					? '...'
																					: null}
																			</span>
																		</Typography>
																		<Typography
																			gutterBottom
																			variant="h4"
																			component="div"
																			fontWeight={'700'}
																			fontSize={{
																				lg: '13px',
																				md: '13px',
																				sm: '13px',
																				xs: '13px',
																			}}
																			color={'#0a0a0a'}
																			// sx={{ my: 2 }}
																			textAlign={'start'}
																			lineHeight={2}
																		>
																			{formatDate(item.created_at)}
																		</Typography>
																	</div>
																</Grid>

																<Grid
																	item
																	md={6}
																	className={
																		isSmallScreen
																			? 'payment_card_mobile_grid Mobile_grid_payment'
																			: 'payment_card_grid'
																	}
																>
																	<div>
																		<Typography
																			variant="h5"
																			sx={{
																				fontSize: 15,
																				// marginRight: 3,
																				fontWeight: '700',
																				textAlign: 'end',
																				my: 2,
																				color:
																					item.payment_status === 'Pending'
																						? '#FF8E4E'
																						: item.payment_status === 'Success'
																							? '#05f300'
																							: '#FE0000',
																			}}
																		>
																			{item.payment_status === 'Pending'
																				? getComaPrice(item.amount)
																				: item.payment_gateway === 'Cashless'
																					? getComaPrice(item.amount)
																					: getComaPrice(item.net_paid)}{' '}
																			/-
																		</Typography>
																		<Typography
																			variant="h6"
																			style={{
																				color:
																					item.payment_status === 'Pending'
																						? '#FF8E4E'
																						: item.payment_status === 'Success'
																							? '#05f300'
																							: '#FE0000',
																				fontSize: 15,
																				fontWeight: '600',
																				paddingLeft: 4,
																			}}
																		>
																			<span style={{ color: '#424A99' }}>
																				Status :{' '}
																			</span>
																			{item.payment_status}
																		</Typography>
																	</div>
																</Grid>
															</Grid>
														</Box>
													) : (
														<Box className="payment_card">
															<Grid
																container
																className="payment_card_container"
															// onClick = {()=>navigate('/payments/PaymentDetails',{state:{item}})}
															>
																<Grid item md={4} className="payment_card_grid" >
																	<Typography
																		gutterBottom
																		variant="h4"
																		component="div"
																		fontWeight={'700'}
																		fontSize={{
																			lg: '15px',
																			md: '15px',
																			sm: '15px',
																			xs: '15px',
																		}}
																		color={'#424A99'}
																		// sx={{ my: 2 }}
																		textAlign={'center'}
																		lineHeight={2}
																	>
																		{item.channel}
																	</Typography>
																</Grid>
																<Grid item md={4} className="payment_card_grid">
																	<div>
																		<Typography
																			gutterBottom
																			variant="h4"
																			component="div"
																			fontWeight={'700'}
																			fontSize={{
																				lg: '13px',
																				md: '13px',
																				sm: '13px',
																				xs: '13px',
																			}}
																			color={'#0a0a0a'}
																			// sx={{ my: 2 }}
																			textAlign={'start'}
																			lineHeight={2}
																		>
																			Requested By :{' '}
																			<span
																				style={{
																					color: '#B9BDCE',
																					fontWeight: 500,
																				}}
																			>
																				{item.requested_employee_name}
																			</span>
																		</Typography>
																		<Typography
																			gutterBottom
																			variant="h4"
																			component="div"
																			fontWeight={'700'}
																			fontSize={{
																				lg: '13px',
																				md: '13px',
																				sm: '13px',
																				xs: '13px',
																			}}
																			color={'#0a0a0a'}
																			// sx={{ my: 2 }}
																			textAlign={'start'}
																			lineHeight={2}
																		>
																			Purpose :{' '}
																			<span
																				style={{
																					color: '#B9BDCE',
																					fontWeight: 500,
																				}}
																			>
																				{item.purpose.slice(0, 20)}
																				{item.purpose.length > 10
																					? '...'
																					: null}
																			</span>
																		</Typography>
																		<Typography
																			gutterBottom
																			variant="h4"
																			component="div"
																			fontWeight={'700'}
																			fontSize={{
																				lg: '13px',
																				md: '13px',
																				sm: '13px',
																				xs: '13px',
																			}}
																			color={'#0a0a0a'}
																			// sx={{ my: 2 }}
																			textAlign={'start'}
																			lineHeight={2}
																		>
																			{formatDate(item.created_at)}
																		</Typography>
																	</div>
																</Grid>
																<Grid
																	item
																	md={4}
																	className="payment_card_grid_right"
																>
																	<div>
																		<Typography
																			variant="h5"
																			sx={{
																				fontSize: 15,
																				// marginRight: 3,
																				fontWeight: '700',
																				textAlign: 'end',
																				my: 2,
																				color:
																					item.payment_status === 'Pending'
																						? '#FF8E4E'
																						: item.payment_status === 'Success'
																							? '#05f300'
																							: '#FE0000',
																			}}
																		>
																			{item.payment_status === 'Pending'
																				? getComaPrice(item.amount)
																				: item.payment_gateway === 'Cashless'
																					? getComaPrice(item.amount)
																					: getComaPrice(item.net_paid)}{' '}
																			/-
																		</Typography>
																		<Typography
																			variant="h6"
																			style={{
																				color:
																					item.payment_status === 'Pending'
																						? '#FF8E4E'
																						: item.payment_status === 'Success'
																							? '#05f300'
																							: '#FE0000',
																				fontSize: 13,
																				fontWeight: '600',
																				paddingLeft: 4,
																				textAlign: 'end',
																			}}
																		>
																			<span style={{ color: '#424A99' }}>
																				Status :{' '}
																			</span>
																			{item.payment_status}
																		</Typography>
																	</div>
																</Grid>
															</Grid>
														</Box>
													)}
												</div>

												// </div>
											))
										) : (
											<div
												style={{
													display: 'flex',
													flexDirection: 'column',
													justifyContent: 'center',
													alignItems: 'center',
												}}
											>
												<img
													src={NopaymentImage}
													width={350}
													height={350}
													alt="nopaymentImg"
												/>
												<Typography
													variant="h6"
													style={{
														fontSize: 15,
														fontWeight: '700',
														marginTop: 5,
													}}
												>
													You Have No Payment Request
												</Typography>
											</div>
										)}
									</div>
								</Grid>
							</Grid>
						</Box>
					</div>
				</div>
			)}
		</div>
	);
};

export default PaymentRequestScreen;
