import React, { useState } from 'react';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router';
import ApiHandler from '../../../Api/Apihandler';
import { Link } from 'react-router-dom';
// import Banner from '../../../Home/components/banner/Banner';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import './Service_Details.css';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Footer from '../../../Components/Footer/Footer';
import { useNavigate } from 'react-router';
import { AES, enc } from 'crypto-js';
import Signup from '../../../Authentication/Signup/Signup';
import Modal from '@mui/material/Modal';
import { useMediaQuery } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { ToastContainer, toast } from 'react-toastify';
import cloud_computing from '../../../Assets/Images/Icons/cloud-computing.png';
import { InputLabel } from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import { getComaPrice } from '../../../Constants/Comaprice';
import Loader from '../../../Constants/Loader';
import { setTimeout } from 'core-js';

export default function ServiceDetailsPage() {
	const [serviceDetails, setServiceDetails] = useState([]);
	const [realtedProduct, setRelatedProduct] = useState([]);
	const [serviceWishlists, setServiceWishLists] = useState(null);
	const [profileWishlist, setProfileWishLists] = useState([]);
	const location = useLocation();
	const { id, itemlist, channel } = location.state || {};
	const [phoneNO, setPhoneNo] = useState();
	const [removeId, setRemoveId] = useState([]);
	const [dummy, setDummy] = useState([]);
	const [modal, setModal] = useState(false);
	const [phoneNumber, setPhoneNumber] = useState('');
	const [password, setPassword] = useState('');
	const [login, setLogin] = useState('');
	const [subTitleId, setSubTitleId] = useState(null);
	const { ReplaceId, ReplaceItem, Replace } = useParams();
	const [clickedMyself, setClickedMySelf] = useState('myself');
	const [profileData, setProfileData] = useState([]);
	const [selectedFile, setSelectedFile] = useState(null);
	const [panVerifyModal, setPanVerifyModal] = useState(false);
	const [btn, setBtn] = useState(null);
	const [basePic, setBasePic] = useState(null);
	const [details, setDetails] = useState([]);
	const [error, setError] = useState(null);
	const [showSpec, setShowSpec] = useState(false);

	const [pandetail, setPanDetail] = useState({
		panNum: '',
		panName: '',
		panDOB: '',
	});
	const [variant, setVariant] = useState([]);
	const [verifyModal, setVerifyModal] = useState(false);
	const [detail, setDetail] = useState([]);

	const ButtonTitle = [
		{ id: '1', title: 'Myself' },
		{ id: '2', title: 'Others' },
	];

	const isSmallScreen = useMediaQuery('(max-width:767px)');
	const navigate = useNavigate();

	const Item = styled(Paper)(({ theme }) => ({
		backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
		...theme.typography.body2,
		padding: theme.spacing(1),
		textAlign: 'center',
		color: theme.palette.text.secondary,
	}));

	useEffect(() => {
		_getServiceDetails(id || ReplaceId);
		_getServiceContent(itemlist || ReplaceItem);
		_getServiceWishList(loginId);
	}, [ReplaceId, ReplaceItem, id]);

	useEffect(() => {
		const encryptedPhoneNo = localStorage.getItem('phone');
		const encryptedPassword = localStorage.getItem('password');

		if (encryptedPhoneNo && encryptedPassword) {
			const decryptedPhone = AES.decrypt(
				encryptedPhoneNo,
				'encryptionSecret'
			).toString(enc.Utf8);
			const decryptedPwd = AES.decrypt(
				encryptedPassword,
				'encryptionSecret'
			).toString(enc.Utf8);

			setPhoneNumber(decryptedPhone);
			setPassword(decryptedPwd);
		}
	});

	useEffect(() => {
		Loginuser();
		_getUrlsubTitile(filterTitle);
		_getProfile(profileId);
	}, [phoneNumber, password]);

	const handleFileChange = (event) => {
		const file = event.target.files[0];

		if (file && file.name === selectedFile?.name) {
			document.getElementById('image-file-input').value = '';
		} else {
			setSelectedFile(file);
			upload();

			const reader = new FileReader();
			reader.onloadend = () => {
				let baseData = reader.result;
				const base64Data = baseData.split(',')[1];
				setBasePic(base64Data);
			};

			reader.readAsDataURL(file);
		}
	};

	const handleDelete = () => {
		setSelectedFile(null);
		document.getElementById('image-file-input').value = '';
	};

	//url purpose
	const filterTitle = Replace.replace(/-/g, '/').replace(/_/g, ' ');
	//url purpose

	const dobDate = new Date(pandetail.panDOB);
	const formattedDate = dobDate.toLocaleDateString('en-GB', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
	});

	const pan_verify = async () => {
		const data = {
			profile_id: intprofile,
			name_as_per_pan: pandetail.panName.toUpperCase(),
			pan_number: 'ABCPV1234D',
			dob: formattedDate.replace(/\//g, '-'),
			pan_image: basePic,
			// verified_pan: "True",
		};
		const response = await ApiHandler.verifyPan(data);
		try {
			if (response.data.status === 200) {
				setPanVerifyModal(false);
				if (btn === 'Myself') {
					const Replace = serviceDetails[0].title
						.replace(/\//g, '-')
						.replace(/ /g, '_');
					navigate(`/serviceebook/${Replace}`, {
						state: {
							id: serviceDetails[0].id,
							channel: channel,
							subtitle: serviceDetails[0].title,
							itemlist: serviceDetails[0].itemlist,
							image: serviceDetails.length > 0 && serviceDetails[0].images[0],
							btnTitle: btn,
							price: serviceDetails[0].price,
							panName: pandetail.panName,
							panNum: 'ABCPV1234D',
							pan_image: basePic,
						},
					});
					setPanVerifyModal(false);
				} else {
					const Replace = serviceDetails[0].title
						.replace(/\//g, '-')
						.replace(/ /g, '_');
					navigate(`/serviceebook/${Replace}`, {
						state: {
							id: serviceDetails[0].id,
							channel: channel,
							subtitle: serviceDetails[0].title,
							itemlist: serviceDetails[0].itemlist,
							image: serviceDetails.length > 0 && serviceDetails[0].images[0],
							btnTitle: btn,
							price: serviceDetails[0].price,
							panName: pandetail.panName,
							panNum: 'ABCPV1234D',
							pan_image: basePic,
						},
					});
					setPanVerifyModal(false);
				}
			}
		} catch (error) {
			console.log('pan verify catch error', error);
		}
	};

	const pan_Name = () => {
		toast.error('Enter valid name as per PAN.');
	};
	const panNumber = () => {
		toast.error('Enter a valid PAN number.');
	};
	const panDateofBirth = () => {
		toast.error('Enter DOB as per PAN.');
	};

	const toastStyle = {
		fontSize: '13px',
	};
	const upload = () => {
		toast.success('Your PAN Image upload successfully!', {
			style: toastStyle,
		});
	};
	const uploadError = () => {
		toast.error('Please Upload PAN Image', {
			style: toastStyle,
		});
	};

	const profileId = localStorage.getItem('loggedIn');

	const intprofile = parseInt(profileId);

	const _getProfile = async () => {
		try {
			const response = await ApiHandler.getProfile(profileId);
			const data = response.data.data[0];
			setProfileData({
				phone: data.phone,
				first_name: data.user.first_name,
				last_name: data.user.last_name,
				email: data.user.email,
				verification: data.verified_profile,
				membership: data.membership,
				profile_id: data.id,
			});
		} catch (error) {
			console.log('Details_profile_Data error', error);
		}
	};

	const _getUrlsubTitile = async () => {
		const data = { title: filterTitle };
		const response = await ApiHandler.getUrlTitle(data);
		try {
			setSubTitleId(response.data.data[0].id);
		} catch (error) {
			console.log('setSubtitleurl error', error);
		}
	};

	const Loginuser = async () => {
		const data = { phone: phoneNumber, password: password };
		const response = await ApiHandler.loginUser(data);
		const loginData = response.data;
		if (response.data.status === 200) {
			closeModal();
			setLogin(loginData);
			localStorage.setItem('loggedIn', response.data.data[0].id);
		} else {
			console.log('error');
		}
	};

	const openModel = () => {
		setModal(true);
	};
	const closeModal = () => {
		setModal(false);
	};

	const _getServiceContent = async (itemlist) => {
		const response = await ApiHandler.getVarientsList(
			'/' + itemlist || ReplaceItem
		);
		const data = response.data.data;
		try {
			setRelatedProduct(data);
		} catch (error) {
			console.log('service related products', error);
		}
	};

	const _getServiceDetails = async (id) => {
		const response = await ApiHandler.getDetails('/' + id || ReplaceId);
		try {
			const data = response.data.data;
			setServiceDetails(data);
			setDetails(data);
		} catch (error) {
			console.log('services details error', error);
		}
	};

	const filterRelatedProducts = realtedProduct.filter((item) => {
		return serviceDetails.length > 0 && item.id !== serviceDetails[0].id;
	});

	const serviceuserCheck = async () => {
		const data = { phone: phoneNumber };
		const response = await ApiHandler.checkUser(data);
		if (response.data.status === 200) {
			if (login.status === 200) {
				serviceWishlist();
			}
		} else {
			if (login.status !== 200 || login.status === 'undefined') {
				openModel();
			}
		}
	};

	const userServiceBookCheck = async (
		subtitle,
		id,
		itemlist,
		image,
		btnTitle,
		phone
	) => {
		const data = { phone: phoneNumber };
		const response = await ApiHandler.checkUser(data);
		if (response.data.status === 200) {
			if (login.status === 200) {
				handleEbook(subtitle, id, itemlist, image, btnTitle, phone);
			}
		} else {
			if (login.status !== 200 || login.status === 'undefined') {
				openModel();
			}
		}
	};
	// const handleEbook = (detail, btnTitle) => {
	// 	const Replace = detail.title.replace(/\//g, '-').replace(/ /g, '_');
	// 	navigate(`/Serviceebook/${Replace}`, { state: { detail, channel } });
	// };

	const handleEbook = (subtitle, id, itemlist, image, btnTitle, phone) => {
		console.log('handlebook', subtitle, id, itemlist, image, btnTitle, phone);
		if (btnTitle === 'Myself' || btn === 'Myself') {
			if (profileData && profileData.verification === true) {
				console.log('data', subtitle);
				const Replace = subtitle.replace(/\//g, '-').replace(/ /g, '_');
				console.log('replace', Replace);
				navigate(`/serviceebook/${Replace}`, {
					state: {
						subtitle,
						id,
						itemlist,
						image,
						btnTitle,
						phone,
						channel,
						price: serviceDetails[0].price,
					},
				});
			} else {
				setPanVerifyModal(true);
			}
		} else if (btnTitle === 'Others' || btn === 'Others') {
			if (profileData && profileData.verification === true) {
				const Replace = subtitle.replace(/\//g, '-').replace(/ /g, '_');
				navigate(`/serviceebook/${Replace}`, {
					state: {
						subtitle,
						id,
						itemlist,
						image,
						btnTitle,
						phone,
						channel,
					},
				});
			} else {
				alert('Please Verify Your Profile First!');
				setPanVerifyModal(true);
			}
		} else {
			setVerifyModal(true);
		}
	};

	const validation = () => {
		if (
			pandetail.panNum.length === 0 ||
			!pandetail.panNum.match('[A-Z]{5}[0-9]{4}[A-Z]{1}')
		) {
			setError(1);
			panNumber();
		} else if (
			pandetail.panName.length === 0 ||
			!pandetail.panName.match('[A-Zs]+')
		) {
			setError(2);
			pan_Name();
		} else if (pandetail.panDOB.length === 0) {
			setError(3);
			panDateofBirth();
		} else if (!selectedFile) {
			setError(4);
			uploadError();
		} else {
			pan_verify();
		}
	};

	// const handleClick = (ReplaceId) => {
	//   // console.log('if', id);
	//   _getServiceDetails(ReplaceId);
	// };

	const loginId = localStorage.getItem('loggedIn');
	const serviceWishlist = async () => {
		const data = {
			profile: loginId,
			item: serviceDetails[0].id,
		};
		const response = await ApiHandler.makeWishList(
			(login.status === 200 ? data : data) || data
		);
		if (response.data.status === 200) {
			setServiceWishLists(serviceDetails[0]?.id);
			_getServiceWishList();
		} else {
			removewishList();
		}
	};

	const _getServiceWishList = async () => {
		const response = await ApiHandler.getWishList(loginId);
		const data = response.data.data;
		try {
			setProfileWishLists(data.map((item) => item.item.id));
			setRemoveId(data.map((item) => item));
			setDummy(data.map((item) => item.id));
		} catch (error) {
			console.log('errorgetwishlist', error);
		}
	};

	const addWishlist = profileWishlist.filter(
		(item) => item === serviceDetails[0]?.id
	);

	const removeWishList = removeId.filter(
		(item) => item.item.id === serviceDetails[0]?.id
	);

	const remove = removeWishList.filter((item) => dummy.includes(item.id));

	const removewishList = async () => {
		const response = await ApiHandler.removeWishList(remove[0]?.id);
		try {
			if (response.data.status === 200) {
				_getServiceWishList();
				setServiceWishLists(null);
			}
		} catch (error) {}
		console.log('wishlist response', response);
	};

	return (
		<div className="services_details">
			<div>
				<div>
					<Modal
						open={modal}
						onClose={closeModal}
						className="address_modal_container"
					>
						<Signup button={closeModal} />
					</Modal>
				</div>
				{/* <Banner /> */}
				<div className="servicedetail_container">
					<div
						style={{
							width: '95%',
						}}
					>
						<Box my={4} sx={{ width: '100%', maxWidth: '100%' }}>
							<Grid spacing={2}>
								{serviceDetails.map((detail, i) => {
									return (
										<Grid container sx={{ justifyContent: 'center' }}>
											<Grid
												key={detail.id}
												xs={12}
												sm={12}
												md={12}
												lg={12}
												xl={12}
											>
												<Card
													sx={{
														display: { lg: 'flex', md: 'flex', xl: 'flex' },
														// justifyContent: 'space-evenly',
														alignItems: 'center',
														background: '#fafafa',
													}}
												>
													<Grid
														item
														xl={4}
														lg={4}
														md={4}
														sm={12}
														xs={12}
														sx={{
															display: 'flex',
															justifyContent: 'center',
															alignContent: 'start',
															textAlign: 'center',
														}}
													>
														<CardMedia
															component="img"
															alt="green iguana"
															image={detail.images[0]}
															sx={{
																height: {
																	xl: 250,
																	lg: 250,
																	md: 250,
																	sm: 150,
																	xs: 150,
																},
																width: {
																	xl: 250,
																	lg: 250,
																	md: 250,
																	sm: 150,
																	xs: 150,
																},
																// boxShadow: 1,
																// bgcolor: (theme) =>
																//   theme.palette.mode === "dark"
																//     ? "#101010"
																//     : "#fff",
																// color: (theme) =>
																//   theme.palette.mode === "dark"
																//     ? "grey.300"
																//     : "grey.800",
																// m: { sm: 2, xs: 2 },

																// borderRadius: 1,
																// backgroundColor: "#fafafa",
																// textAlign: "center",
															}}
														/>
													</Grid>
													<Grid
														item
														xl={7}
														lg={7}
														md={7}
														sm={12}
														xs={12}
														p={0}
														sx={{
															display: 'flex',
															flexDirection: 'column',
															justifyContent: 'center',
															justifyItems: 'center',
															alignItems: 'center',
															alignContent: 'center',
															m: { sm: 2, xs: 2 },
														}}
													>
														<CardContent sx={{ p: '10px' }}>
															<div>
																<Typography
																	gutterBottom
																	variant="h6"
																	component="div"
																	fontSize={{
																		lg: '20px',
																		md: '20px',
																		sm: '15px',
																		xs: '15px',
																	}}
																	fontWeight={600}
																	textAlign={'center'}
																	pt={1}
																>
																	{detail.title}
																</Typography>

																<Typography
																	gutterBottom
																	variant="p"
																	component="div"
																	fontSize={{
																		lg: '15px',
																		md: '15px',
																		sm: '15px',
																		xs: '15px',
																	}}
																	fontWeight={600}
																	sx={{
																		textAlign: { xs: 'center', sm: 'center' },
																	}}
																>
																	<span style={{ color: '#000' }}>
																		Price Starts From :
																	</span>
																	<span
																		style={{
																			color: '#465166',
																			fontSize: '18px',
																			fontWeight: '900',
																			paddingLeft: '10px',
																		}}
																	>
																		{getComaPrice(detail.price)} /-
																	</span>
																</Typography>

																<Typography
																	gutterBottom
																	variant="p"
																	component="div"
																	fontSize={{
																		lg: '15px',
																		md: '15px',
																		sm: '15px',
																		xs: '15px',
																	}}
																	fontWeight={600}
																	textAlign="start"
																>
																	Specifications:
																</Typography>
																{showSpec === false
																	? detail.description
																			.split('\n')
																			.slice(0, 2)
																			.map((point) => {
																				return (
																					<Typography
																						variant="p"
																						component="div"
																						fontWeight={400}
																						fontSize={{
																							lg: '14px',
																							md: '14px',
																							sm: '14px',
																							xs: '14px',
																						}}
																						textAlign="start"
																						lineHeight={2}
																					>
																						{point}
																					</Typography>
																				);
																			})
																	: detail.description
																			.split('\n')
																			.map((point, i) => {
																				return (
																					<Typography
																						variant="p"
																						component="div"
																						fontWeight={400}
																						fontSize={{
																							lg: '14px',
																							md: '14px',
																							sm: '14px',
																							xs: '14px',
																						}}
																						textAlign="start"
																						lineHeight={2}
																					>
																						{point}
																					</Typography>
																				);
																			})}
																<Typography
																	gutterBottom
																	variant="p"
																	component="div"
																	fontSize={{
																		lg: '15px',
																		md: '15px',
																		sm: '15px',
																		xs: '15px',
																	}}
																	my={1}
																	onClick={() => setShowSpec(!showSpec)}
																	fontWeight={600}
																	textAlign="start"
																	color="green"
																	sx={{ cursor: 'pointer' }}
																>
																	{showSpec === false
																		? 'Read More'
																		: 'Show Less'}
																</Typography>
															</div>

															<CardActions
																sx={{
																	display: 'flex',
																	justifyContent: 'space-center',
																	alignContent: 'center',
																	pt: 5,
																}}
															>
																<Button
																	variant="contained"
																	className="serivce_wishlist_button"
																	size="small"
																	sx={{
																		width: isSmallScreen ? '150px' : '200px',
																		background:
																			serviceWishlists ===
																				serviceDetails[0]?.id ||
																			addWishlist[0] === serviceDetails[0]?.id
																				? '#54D969'
																				: '#FF004D',
																		'&:hover': {
																			background:
																				serviceWishlists ===
																					serviceDetails[0]?.id ||
																				addWishlist[0] === serviceDetails[0]?.id
																					? '#54D969'
																					: '#FF004D',
																		},
																	}}
																	onClick={() => {
																		serviceuserCheck(phoneNumber);
																	}}
																>
																	{serviceWishlists === serviceDetails[0]?.id ||
																	addWishlist[0] === serviceDetails[0]?.id
																		? 'Wishlist Added'
																		: 'Add to Wishlist'}
																</Button>
																<Button
																	variant="contained"
																	className="serivce_wishlist_button"
																	size="small"
																	sx={{
																		width: isSmallScreen ? '150px' : '200px',

																		background: '#FF004D',

																		'&:hover': { background: '#FF004D' },
																	}}
																	onClick={() => {
																		userServiceBookCheck(
																			detail.title,
																			detail.id,
																			detail.itemlist,
																			detail.length > 0 && detail.images[0],
																			clickedMyself,
																			phoneNumber
																		);
																	}}
																>
																	Book Now
																</Button>
															</CardActions>
														</CardContent>
													</Grid>
												</Card>
											</Grid>
										</Grid>
									);
								})}
							</Grid>
						</Box>
						<div>
							<Typography
								gutterBottom
								variant="h6"
								component="div"
								fontSize={{
									lg: '12',
									md: '12',
									sm: '12',
									xs: '12',
								}}
								fontWeight={600}
								mx={2}
								my={2}
								textAlign={
									filterRelatedProducts.length < 4 ? 'center' : 'start'
								}
								color="#2e2d2d"
							>
								Related Services
							</Typography>
							<div className="related_scroll_service">
								<Box
									sx={{
										flex: 1,
										display: 'flex',
										justifyContent: 'center',
									}}
								>
									{filterRelatedProducts.map((item, index) => (
										<Link
											to={`/service/${item.id}/${item.itemlist}/${item.title
												.replace(/\//g, '-')
												.replace(/ /g, '_')}`}
										>
											<Grid
												className="realted_card_service"
												xs={4}
												sm={4}
												md={4}
												lg={filterRelatedProducts.length < 4 ? 6 : 4}
												xl={filterRelatedProducts.length < 4 ? 6 : 4}
												onClick={() => {
													window.scrollTo({ top: 0, behavior: 'smooth' });
													_getServiceDetails(item.id);
												}}
											>
												<div style={{}}>
													<div className="related_img_service">
														<img src={item.images[0]} alt={item.image} />
													</div>
													<Typography
														gutterBottom
														variant="h4"
														component="div"
														fontWeight={'600'}
														fontSize={{
															lg: '16px',
															md: '16px',
															sm: '16px',
															xs: '16px',
														}}
														color={'#2e2d2d'}
														sx={{ my: 2 }}
														textAlign={'center'}
													>
														{item.title}
													</Typography>
												</div>
											</Grid>
										</Link>
										// </Grid>
									))}
								</Box>
							</div>
						</div>
					</div>

					<div>
						<Modal
							open={verifyModal}
							aria-labelledby="modal-modal-title"
							aria-describedby="modal-modal-description"
						>
							<Box
								sx={{
									position: 'absolute',
									top: '50%',
									left: '50%',
									transform: 'translate(-50%, -50%)',
									width: { xl: 600, lg: 600, md: 500, sm: 400, xs: 350 },
									bgcolor: 'white',
									boxShadow: 24,
									borderRadius: 3,
								}}
							>
								<div
									style={{
										textAlign: 'end',
									}}
								>
									<CloseIcon
										onClick={() => setVerifyModal(false)}
										sx={{
											width: '34px',
											height: '34px',
											mt: 1,
											mr: 1,
											borderRadius: '50%',
											backgroundColor: '#F7F7F7',
											cursor: 'pointer',
											padding: 1,
										}}
									/>
								</div>
								<Grid container>
									<Grid item xs={12} px={1.5}>
										<Typography
											gutterBottom
											id="modal-modal-title"
											variant="h6"
											component="div"
											color={'#000'}
											fontWeight={'600'}
											textAlign={'center'}
											fontSize={{
												xl: '18px',
												lg: '18px',
												md: '16px',
												sm: '14px',
												xs: '14px',
											}}
										>
											Vehicle Registration Information
										</Typography>
										<Typography
											id="modal-modal-title"
											gutterBottom
											variant="p"
											component="div"
											fontSize={{ xs: '14px', sm: '14px', lg: '14px' }}
											color={'rgba(0,0,0,0.6)'}
											my={1.5}
										>
											<b>NOTE :</b> This information is crucial for booking and
											will be used for vehicle registration. Please provide
											accurate details to ensure a smooth process.
										</Typography>
										<Typography
											id="modal-modal-title"
											gutterBottom
											variant="p"
											component="div"
											fontSize={{
												xs: '14px',
												sm: '14px',
												lg: '14px',
												xl: '14px',
											}}
											color={'rgba(0,0,0,0.6)'}
											mb={1.5}
											fontWeight={'600'}
										>
											Thank you for your cooperation.
										</Typography>
									</Grid>
									<Grid
										item
										xs={12}
										sx={{ display: 'flex', justifyContent: 'space-evenly' }}
									>
										<Button
											variant="contained"
											onClick={() =>
												userServiceBookCheck(
													serviceDetails[0].title,
													serviceDetails[0].id,
													serviceDetails[0].itemlist,
													serviceDetails.length > 0 &&
														serviceDetails[0].images[0],
													ButtonTitle[0].title,
													setBtn('Myself')
												)
											}
											sx={{
												textAlign: 'center',
												height: '44px',
												width: {
													xl: '200px',
													lg: '200px',
													md: '200px',
													sm: '150px',
													xs: '150px',
												},
												fontWeight: 600,
												fontSize: '12px',
												lineHeight: '18px',
												letterSpacing: '.01em',
												borderRadius: '12px !important',
												boxShadow: 'none !important',
												my: 2,
												background:
													' linear-gradient(to right, #141e30, #243b55)',
												textTransform: 'none',
											}}
											fullWidth
										>
											{ButtonTitle[0].title}
										</Button>
										<Button
											variant="contained"
											sx={{
												textAlign: 'center',
												height: '44px',
												width: {
													xl: '200px',
													lg: '200px',
													md: '200px',
													sm: '150px',
													xs: '150px',
												},
												fontWeight: 600,
												fontSize: '12px',
												lineHeight: '18px',
												letterSpacing: '.01em',
												borderRadius: '12px !important',
												boxShadow: 'none !important',
												my: 2,
												background:
													' linear-gradient(to right, #141e30, #243b55)',
												textTransform: 'none',
											}}
											fullWidth
											onClick={() =>
												userServiceBookCheck(
													serviceDetails[0].title,
													serviceDetails[0].id,
													serviceDetails[0].itemlist,
													serviceDetails.length > 0 &&
														serviceDetails[0].images[0],
													ButtonTitle[1].title,
													setBtn('Others')
												)
											}
										>
											{ButtonTitle[1].title}
										</Button>
									</Grid>
								</Grid>
							</Box>
						</Modal>
					</div>

					<div>
						<Modal
							open={panVerifyModal}
							aria-labelledby="modal-modal-title"
							aria-describedby="modal-modal-description"
						>
							<Box
								sx={{
									position: 'absolute',
									top: '50%',
									left: '50%',
									transform: 'translate(-50%, -50%)',
									width: { xl: 700, lg: 700, md: 500, sm: 400, xs: 350 },
									bgcolor: 'white',
									boxShadow: 24,
									borderRadius: 4,
								}}
							>
								<div
									style={{
										textAlign: 'end',
									}}
								>
									<CloseIcon
										onClick={() => setPanVerifyModal(false)}
										sx={{
											width: '34px',
											height: '34px',
											mt: 1,
											mr: 1,
											borderRadius: '50%',
											backgroundColor: '#F7F7F7',
											cursor: 'pointer',
											padding: 1,
										}}
									/>
								</div>
								<Grid container px={4} className="panverify_modal">
									<Grid item xs={12}>
										<Typography
											gutterBottom
											variant="h5"
											component="div"
											fontSize={{
												lg: '20px',
												md: '16px',
												sm: '14px',
												xs: '14px',
											}}
											fontWeight={700}
											color={'ffff'}
											textAlign={'center'}
										>
											Verify Your Identity for Service Booking
										</Typography>{' '}
										<InputLabel
											sx={{
												fontSize: {
													xs: '13px',
													md: '13px',
													lg: '13px',
													fontWeight: 700,
												},
												my: 1.5,

												textAlign: 'start',
											}}
										>
											PAN Number <span style={{ color: 'red' }}>*</span>
										</InputLabel>
										{error === 1 ? (
											<div>
												<TextField
													autoComplete="off"
													fullWidth
													label=""
													id="fullWidth"
													placeholder="Enter Pan Number"
													size="small"
													value={pandetail.panNum}
													onChange={(e) => {
														setPanDetail({
															...pandetail,
															panNum: e.target.value,
														});
													}}
												/>
											</div>
										) : (
											<TextField
												autoComplete="off"
												fullWidth
												label=""
												id="fullWidth"
												placeholder="Enter Pan Number"
												size="small"
												value={pandetail.panNum}
												onChange={(e) => {
													setPanDetail({
														...pandetail,
														panNum: e.target.value,
													});
												}}
											/>
										)}
										<InputLabel
											sx={{
												fontSize: {
													xs: '13px',
													md: '13px',
													lg: '13px',
													fontWeight: 700,
												},
												my: 1.5,
												textAlign: 'start',
											}}
										>
											Enter Name As Per PAN
											<span style={{ color: 'red' }}>*</span>
										</InputLabel>
										{error === 2 ? (
											<div>
												<TextField
													fullWidth
													autoComplete="off"
													label=""
													size="small"
													variant="outlined"
													placeholder="Enter Name"
													value={pandetail.panName}
													onChange={(e) =>
														setPanDetail({
															...pandetail,
															panName: e.target.value,
														})
													}
												/>
											</div>
										) : (
											<TextField
												fullWidth
												autoComplete="off"
												id="outlined-basic"
												label=""
												size="small"
												variant="outlined"
												placeholder="Enter Name"
												value={pandetail.panName}
												onChange={(e) =>
													setPanDetail({
														...pandetail,
														panName: e.target.value,
													})
												}
											/>
										)}
										<InputLabel
											sx={{
												fontSize: {
													xs: '13px',
													md: '13px',
													lg: '13px',
													fontWeight: 700,
												},
												textAlign: 'start',
												mt: 1.5,
												mb: 1,
											}}
										>
											Select Your DOB
											<span style={{ color: 'red' }}>*</span>
										</InputLabel>
										{error === 3 ? (
											<div>
												<LocalizationProvider dateAdapter={AdapterDayjs}>
													<DemoContainer components={['DesktopDatePicker']}>
														<DesktopDatePicker
															className="date_picker"
															label=""
															value={pandetail.panDOB}
															onChange={(newValue) => {
																setPanDetail({
																	...pandetail,
																	panDOB: newValue,
																});
															}}
															sx={{
																width: '100%',
																'& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
																	{
																		borderColor: '#B1B1B1',
																	},
															}}
														/>
													</DemoContainer>
												</LocalizationProvider>
											</div>
										) : (
											<LocalizationProvider dateAdapter={AdapterDayjs}>
												<DemoContainer components={['DesktopDatePicker']}>
													<DesktopDatePicker
														className="date_picker"
														label=""
														value={pandetail.panDOB}
														onChange={(newValue) => {
															setPanDetail({
																...pandetail,
																panDOB: newValue,
															});
														}}
														sx={{
															width: '100%',
															'& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
																{
																	borderColor: '#B1B1B1',
																},
														}}
													/>
												</DemoContainer>
											</LocalizationProvider>
										)}
										{/* {error === 4 ? (
									<InputLabel
										sx={{
											fontSize: {
												xs: '13px',
												md: '13px',
												lg: '13px',
												fontWeight: 700,
											},
											my: 1.5,

											textAlign: 'start',
										}}
									>
										Upload Image<span style={{ color: 'red' }}>*</span>
									</InputLabel>
								) : (
									<div>
										<InputLabel
											sx={{
												fontSize: {
													xs: '13px',
													md: '13px',
													lg: '13px',
													fontWeight: 700,
												},
												my: 1.5,

												textAlign: 'start',
											}}
										>
											Upload Image <span style={{ color: 'red' }}>*</span>
										</InputLabel>
										<Button
											component="label"
											variant="contained"
											startIcon={<CloudUploadIcon />}
											type="file"
										>
											Upload file
										</Button>
									</div>
								)} */}
										{error === 4 ? (
											<div>
												<Box
													pt={1}
													pb={3}
													px={3}
													// border="1px dashed #000000"
													// borderRadius={2}
													textAlign="center"
													sx={{
														display: 'flex',
														flexDirection: 'column',
														justifyContent: 'center',
														alignContent: 'center',
														background: '#f3f3fe',
														mt: 1,
													}}
												>
													<InputLabel
														sx={{
															fontSize: {
																xs: '13px',
																md: '13px',
																lg: '13px',
																fontWeight: 700,
															},
															pb: 1,
															textAlign: 'start',
														}}
													>
														Upload Image <span style={{ color: 'red' }}>*</span>
													</InputLabel>
													<div
														style={{
															border: '1px dashed #000000',
															borderRadius: '3px',
															background: 'white',
															padding: '15px',
														}}
													>
														<div style={{ textAlign: 'center' }}>
															<img
																src={cloud_computing}
																style={{
																	height: '40px',
																	width: '40px',
																}}
															/>
														</div>

														<input
															type="file"
															accept="image/*"
															onChange={handleFileChange}
															style={{ display: 'none' }}
															id="image-file-input"
														/>
														<label htmlFor="image-file-input">
															<Button
																sx={{
																	fontWeight: 600,
																	fontSize: '12px',
																	lineHeight: '18px',
																	letterSpacing: '.01em',
																	textTransform: 'none',
																}}
																component="span"
															>
																Upload a File
															</Button>
														</label>
													</div>
													{selectedFile && (
														<div
															style={{
																display: 'flex',
																justifyContent: 'space-between',
																marginTop: '6px',
																background: '#dae6ff',
															}}
														>
															<Typography variant="subtitle1">
																{selectedFile.name}
															</Typography>
															<DeleteOutlineIcon
																sx={{
																	color: 'red',
																	cursor: 'pointer',
																}}
																onClick={handleDelete}
															/>
														</div>
													)}
												</Box>
											</div>
										) : (
											<div>
												<Box
													pt={1}
													pb={3}
													px={3}
													// border="1px dashed #000000"
													// borderRadius={2}
													textAlign="center"
													sx={{
														display: 'flex',
														flexDirection: 'column',
														justifyContent: 'center',
														alignContent: 'center',
														background: '#f3f3fe',
														mt: 1,
													}}
												>
													<InputLabel
														sx={{
															fontSize: {
																xs: '13px',
																md: '13px',
																lg: '13px',
																fontWeight: 700,
															},
															pb: 1,
															textAlign: 'start',
														}}
													>
														Upload Image <span style={{ color: 'red' }}>*</span>
													</InputLabel>
													<div
														style={{
															border: '1px dashed #000000',
															borderRadius: '3px',
															background: 'white',
															padding: '15px',
														}}
													>
														<div style={{ textAlign: 'center' }}>
															<img
																src={cloud_computing}
																style={{
																	height: '40px',
																	width: '40px',
																}}
															/>
														</div>

														<input
															type="file"
															accept="image/*"
															onChange={handleFileChange}
															style={{ display: 'none' }}
															id="image-file-input"
														/>
														<label htmlFor="image-file-input">
															<Button
																sx={{
																	fontWeight: 600,
																	fontSize: '12px',
																	lineHeight: '18px',
																	letterSpacing: '.01em',
																	textTransform: 'none',
																}}
																component="span"
															>
																Upload a File
															</Button>
														</label>
													</div>
													{selectedFile && (
														<div
															style={{
																display: 'flex',
																justifyContent: 'space-between',
																marginTop: '6px',
																background: '#dae6ff',
															}}
														>
															<Typography variant="subtitle1">
																{selectedFile.name}
															</Typography>
															<DeleteOutlineIcon
																sx={{
																	color: 'red',
																	cursor: 'pointer',
																}}
																onClick={handleDelete}
															/>
														</div>
													)}
												</Box>
											</div>
										)}
										<div style={{ textAlign: 'center' }}>
											<Button
												variant="contained"
												size="medium"
												sx={{
													textAlign: 'center',
													height: '44px',
													width: {
														xl: '250px',
														lg: '250px',
														md: '200px',
														sm: '200px',
														xs: '200px',
													},
													fontWeight: 600,
													fontSize: '12px',
													lineHeight: '18px',
													letterSpacing: '.01em',
													borderRadius: '12px !important',
													boxShadow: 'none !important',
													background:
														' linear-gradient(to right, #141e30, #243b55)',
													my: 1.5,
												}}
												onClick={() => {
													validation();
												}}
											>
												{' '}
												SUBMIT
											</Button>
										</div>
									</Grid>
								</Grid>
							</Box>
						</Modal>
					</div>
				</div>
			</div>
		</div>
	);
}
