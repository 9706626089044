import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

const NavigationItem = ({ title, onClick }) => (
	<Grid item xs={2} sm={2} md={2} lg={2}>
		<Typography
			gutterBottom
			variant="h4"
			component="div"
			fontWeight={'500'}
			fontSize={{
				lg: '13px',
				md: '13px',
				sm: '13px',
				xs: '13px',
			}}
			color={'#474747'}
			sx={{ my: 2 }}
			textAlign={'center'}
			onClick={onClick}
			cursor={'pointer'}
		>
			{title}
		</Typography>
	</Grid>
);

const HeroHeader = () => {
	const scrollIntoView = (id) => {
		const el = document.getElementById(id);
		window.scrollTo({
			top: el.offsetTop - 120,
			behavior: 'smooth',
		});
	};

	class SingleSection {
		constructor(id, title) {
			this.id = id;
			this.title = title;
		}
	}

	const sections = [
		new SingleSection('hero_car_images', 'IMAGES'),
		new SingleSection('hero_car_colors', 'COLORS'),
		new SingleSection('hero_car_specifications', 'SPECIFICATIONS'),
		new SingleSection('hero_car_about', 'ABOUT'),
		new SingleSection('hero_car_description', 'DESCRIPTION'),
	];

	return (
		<Box
			sx={{
				flexGrow: 1,
				position: 'sticky',
				top: 70,
				zIndex: 9,
				mt: 4,
				minHeight: 100,
			}}
		>
			<div className="Over_view_container">
				<Grid
					container
					rowSpacing={2}
					className="details_nav"
					sx={{
						display: {
							xs: 'none',
							sm: 'none',
							md: 'flex',
							lg: 'flex',
							xl: 'flex',
						},
					}}
				>
					<NavigationItem
						title="OVERVIEW"
						onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
					/>
					{sections.map(({ id, title }) => (
						<NavigationItem
							key={id}
							title={title}
							onClick={() => scrollIntoView(id)}
						/>
					))}
				</Grid>
			</div>
		</Box>
	);
};

export default HeroHeader;
