import React, { useState, useEffect, Fragment } from 'react';
import './Details.css';
import { useLocation, useParams, Link } from 'react-router-dom';
import ApiHandler from '../../Api/Apihandler';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import { AES, enc } from 'crypto-js';
import Signup from '../../Authentication/Signup/Signup';
import 'react-medium-image-zoom/dist/styles.css';
import CloseIcon from '@mui/icons-material/Close';
import HeroHeader from '../../Components/Details/HeroHeader';
import HeroCarDetail from '../../Components/Details/HeroCarDetail';
import PanVerifyModal from '../../Components/Modals/PanVerifyModal';
import EmiCalculatorModal from '../../Components/Modals/EmiCalculator';
import BookNowModal from '../../Components/Modals/BookNowModal';

const Details = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const { id, title, cartId } = location.state || {};
	const { Replace, ReplaceId } = useParams();
	const [details, setDetails] = useState([]);
	const [colors, setColors] = useState([]);
	const [variant, setVariant] = useState([]);
	const [selectedItemId, setSelectedItemId] = useState(null);
	const [exRoomPrice, setExRoomPrice] = useState(0);
	const [chgloanAmount, setChgloanAmount] = useState(0);
	const [onroadModel, setOnroadModel] = useState(false);
	const [loader, setLoader] = useState(false);
	const [wishlists, setWishLists] = useState(null);
	const [profileWishlist, setProfileWishLists] = useState([]);
	const [removeId, setRemoveId] = useState([]);
	const [dummy, setDummy] = useState([]);
	const [modal, setModal] = useState(false);
	const [phoneNumber, setPhoneNumber] = useState('');
	const [password, setPassword] = useState('');
	const [login, setLogin] = useState('');
	const [subTitleId, setSubTitleId] = useState(null);
	const [clickedMyself, setClickedMySelf] = useState('myself');
	const [profileData, setProfileData] = useState([]);
	const profileId = localStorage.getItem('loggedIn');
	const [btn, setBtn] = useState(null);

	const [showPanVerifyModal, setShowPanVerifyModal] = useState(false);
	const [showEmiModal, setShowEmiModal] = useState(false);
	const [showBookNowModal, setShowBookNowModal] = useState(false);

	//url purpose
	const ReplaceTitle = Replace.replace(/_/g, ' ');

	//url purpose

	useEffect(() => {
		setLoader(true);
		_getDetails(id || cartId || subTitleId);
		_getwishList(profileId);
		_getProfile(profileId);
	}, [subTitleId]);

	useEffect(() => {
		if (details.length > 0) {
			setSelectedItemId(details[0].title);
		}
	}, [details]);

	useEffect(() => {
		const encryptedPhoneNo = localStorage.getItem('phone');
		const encryptedPassword = localStorage.getItem('password');

		if (encryptedPhoneNo && encryptedPassword) {
			const decryptedPhone = AES.decrypt(
				encryptedPhoneNo,
				'encryptionSecret'
			).toString(enc.Utf8);
			const decryptedPwd = AES.decrypt(
				encryptedPassword,
				'encryptionSecret'
			).toString(enc.Utf8);

			setPhoneNumber(decryptedPhone);
			setPassword(decryptedPwd);
		}
	});

	useEffect(() => {
		Loginuser();
		// _getUrlsubTitile(ReplaceTitle);
	}, [phoneNumber, password, ReplaceTitle]);

	const Loginuser = async () => {
		const data = { phone: phoneNumber, password: password };
		const response = await ApiHandler.loginUser(data);
		const loginData = response.data;
		if (response.data.status === 200) {
			closeModal();
			localStorage.setItem('loggedIn', response.data.data[0].id);
			setLogin(loginData);
		} else {
			console.log('Details_login_error');
		}
	};

	const openModel = () => {
		setModal(true);
	};
	const closeModal = () => {
		setModal(false);
	};

	const userCheck = async () => {
		const data = { phone: phoneNumber };

		const response = await ApiHandler.checkUser(data);
		if (response.data.status === 200) {
			if (login.status === 200) {
				wishlist();
			}
		} else {
			if (login.status !== 200 || login.status === 'undefined') {
				openModel();
			}
		}
	};

	const _getProfile = async () => {
		try {
			const response = await ApiHandler.getProfile(profileId);
			const data = response.data.data[0];
			setProfileData({
				verify: data.verified_profile,
			});
		} catch (error) {
			console.log('Details_profile_Data error', error);
		}
	};

	const userBookCheck = async (subtitle, id, img, itemlist, btnTitle) => {
		const data = { phone: phoneNumber };
		const response = await ApiHandler.checkUser(data);
		if (response.data.status === 200) {
			if (login.status === 200) {
				handleBook(title, id, subtitle, img, itemlist, btnTitle);
			}
		} else {
			if (login.status !== 200 || login.status === 'undefined') {
				openModel();
			}
		}
	};

	const handleBook = (title, id, subtitle, img, itemlist, btnTitle) => {
		if (btnTitle === 'Myself' || btn === 'Myself') {
			if (profileData && profileData.verify === true) {
				const Replace = subtitle.replace(/ /g, '_');
				navigate(`/ebook/${id}/${Replace}`, {
					state: {
						title,
						id,
						channel: variant[0].channel,
						subtitle,
						img,
						itemlist,
						btnTitle,
					},
				});
			} else {
				setShowPanVerifyModal(true);
			}
		} else if (btnTitle === 'Others' || btn === 'Others') {
			if (profileData && profileData.verify === true) {
				const Replace = subtitle.replace(/ /g, '_');
				navigate(`/ebook/${id}/${Replace}`, {
					state: {
						title,
						id,
						channel: variant[0].channel,
						subtitle,
						img,
						itemlist,
						btnTitle,
					},
				});
			} else {
				alert('Please Verify Your Profile First!');
				setShowPanVerifyModal(true);
			}
		} else {
			setShowBookNowModal(true);
		}
	};

	// const _getUrlsubTitile = async () => {
	// 	const data = { subtitle: ReplaceTitle };
	// 	const response = await ApiHandler.getUrlSubTitle(data);
	// 	try {
	// 		setSubTitleId(response.data.data[0].id);
	// 	} catch (error) {}
	// 	console.log('Detailssubtitle url response', response);
	// };

	const _getDetails = async (id) => {
		try {
			const response = await ApiHandler.getDetails(
				'/' + id || cartId || subTitleId
			);
			if (response.data.status === 200) {
				const data = response.data.data;
				setTimeout(() => {
					setLoader(false);
				}, 2000);
				setDetails(data);
				colorDetails(data[0].title);
				_getVarientsList(data[0].itemlist);
				setExRoomPrice(data[0].price);
			}
		} catch (error) {
			console.log('details_get_details error', error);
		}
	};

	const colorDetails = async (title) => {
		try {
			const colorResponse = await ApiHandler.getColorsDetails({ title: title });
			if (colorResponse.data.status === 200) {
				const data = colorResponse.data.data;
				setColors(data);
			}
		} catch (error) {
			console.log('details_get_colorError', error);
		}
	};

	const _getVarientsList = async (itemlist) => {
		const response = await ApiHandler.getVarientsList('/' + itemlist);
		const data = response.data.data;
		if (response.data.status === 200) {
			// setTimeout(()=>{
			//   setLoader(false);
			// },2000)
			setVariant(data);
			// setProfileWishLists(data.map((item) => item.id));
			// setRemoveId(data.map((item) => item));
			// setDummy(data.map((item) => item.id));
		} else {
			console.error('it not working');
		}
	};

	const wishlist = async () => {
		const data = {
			profile: profileId,
			item: details[0].id,
		};
		const response = await ApiHandler.makeWishList(
			(login.status === 200 ? data : data) || data
		);
		if (response.data.status === 200) {
			setWishLists(details[0]?.id);
			_getwishList(profileId);
		} else {
			removewishList();
		}
	};
	const _getwishList = async () => {
		const response = await ApiHandler.getWishList(profileId);
		const data = response.data.data;
		try {
			setProfileWishLists(data.map((item) => item.item.id));
			setRemoveId(data.map((item) => item));
			setDummy(data.map((item) => item.id));
		} catch (error) {
			console.log('details_get_wishlist error', error);
		}
	};

	const removewishList = async () => {
		const response = await ApiHandler.removeWishList(remove[0]?.id);
		try {
			if (response.data.status === 200) {
				_getwishList();
				setWishLists(null);
			}
		} catch (error) {
			console.log('Details_removewishlist_error', error);
		}
	};
	const removeWishList = removeId.filter(
		(item) => item.item.id === details[0]?.id
	);
	const addWishlist = profileWishlist.filter((item) => item === details[0]?.id);
	const remove = removeWishList.filter((item) => dummy.includes(item.id));
	const relatedFilter = variant.filter((item) => item.title !== selectedItemId);

	const handleSelectChange = (event) => {
		const selectedValue = event.target.value;
		const selectedItem = variant.find((item) => item.title === selectedValue);
		setSelectedItemId(selectedItem ? selectedItem.id : null);
		_getDetails(selectedItem.id);
	};

	// const addVariantWishlist = variantChange === details[0]?.id;

	// const navigate = useNavigate();

	// const handleClick = (title,id)=>{
	//   const Replace = title.replace(/ /g,'_');
	//   navigate(`Details/${Replace}`,{state : { title, id}})
	// }

	const onClickModel = () => {
		setOnroadModel(true);
	};
	const handleonRoadClose = () => {
		setOnroadModel(false);
	};

	return (
		<div>
			<div className="Details">
				<div className="Details_Container">
					<div className="title_bar" />
					<Typography
						gutterBottom
						variant="h4"
						component="div"
						fontWeight={'700'}
						fontSize={{ lg: '23px', md: '22px', sm: '20px', xs: '18px' }}
						color={'#484848'}
						sx={{ my: 1, py: 1 }}
						textAlign={'start'}
						id="hero_car_title"
					>
						{details.length > 0 ? details[0].subtitle : null}
					</Typography>
					<HeroHeader />
					<HeroCarDetail
						details={details}
						selectedItemId={selectedItemId}
						onClickModel={onClickModel}
						wishlists={wishlists}
						addWishlist={addWishlist}
						phoneNumber={phoneNumber}
						clickedMyself={clickedMyself}
						userCheck={userCheck}
						handleOpen={() => setShowEmiModal(true)}
						variant={variant}
						handleSelectChange={handleSelectChange}
						userBookCheck={userBookCheck}
					/>
					<Box sx={{ flexGrow: 1, my: 1 }}>
						<Grid>
							<Grid container>
								<Grid xs={6}></Grid>
								<Grid xs={6} sx={{ textAlign: 'center' }}>
									<Modal
										open={onroadModel}
										close={handleonRoadClose}
										className="details_onroad_price"
									>
										<Box className="on_road_modal modal">
											<Grid
												className="onroad_price"
												style={{ paddingTop: '8px' }}
											>
												{/* <Onroadprice backgroundImage={backgroundImage} style={{}}/>
                        <div style={{textAlign:'end'}}>
                          <Button variant="contained" size='small' onClick={handleonRoadClose} >Close</Button>
                        </div> */}
												<div style={{ textAlign: 'end' }}>
													<CloseIcon
														onClick={handleonRoadClose}
														sx={{
															width: '34px',
															height: '34px',
															borderRadius: '50%',
															backgroundColor: '#F7F7F7',
															cursor: 'pointer',
															padding: 1,
															textAlign: 'center',
														}}
													/>
												</div>

												<Typography
													gutterBottom
													variant="p"
													component="div"
													fontWeight={'700'}
													fontSize={{ xs: '14px', sm: '14px', lg: '20px' }}
													color={'rgba(0,0,0)'}
													textAlign={'center'}
												>
													Get On-road price
												</Typography>
												<div
													style={{ display: 'flex', flexDirection: 'column' }}
												>
													<TextField
														id="outlined-basic"
														label="Enter Your Name"
														variant="outlined"
														size="small"
														sx={{ my: 2 }}
													/>
													<TextField
														id="outlined-basic"
														label="Enter Your Phone Number"
														variant="outlined"
														size="small"
														sx={{ my: 2 }}
													/>
													<TextField
														id="outlined-basic"
														// label="Enter Your Phone Number"
														variant="outlined"
														value={
															details.length > 0 ? details[0].subtitle : null
														}
														size="small"
														sx={{ my: 2 }}
													/>
													<div style={{ textAlign: 'center' }}>
														<Button
															variant="contained"
															sx={{
																textAlign: 'center',
																height: '44px',
																width: '250px',
																fontWeight: 600,
																fontSize: '12px',
																lineHeight: '18px',
																letterSpacing: '.01em',
																borderRadius: '12px !important',
																boxShadow: 'none !important',
																my: 2,
																background:
																	' linear-gradient(to right, #141e30, #243b55)',
															}}
															fullWidth
														>
															Check On-Road Price
														</Button>
													</div>
												</div>
											</Grid>
										</Box>
									</Modal>
								</Grid>
							</Grid>
						</Grid>
					</Box>
					<Typography
						id="hero_car_colors"
						gutterBottom
						variant="h4"
						component="div"
						fontWeight={'700'}
						fontSize={{ lg: '23px', md: '23px', sm: '23px', xs: '23px' }}
						color={'#484848'}
						sx={{ my: 2 }}
						textAlign={'start'}
					>
						Colors
					</Typography>
					<div className="car_details_colors">
						<div
							className="car_variant_colors"
							style={{
								display: 'flex',
								flexDirection: 'row',
								overflowX: 'scroll',
								cursor: 'pointer',
								alignItems: 'center',
							}}
						>
							{colors.map((item, clrIndex) => (
								<div key={`${clrIndex}-${clrIndex}`}>
									<Link to={`/car/${item.subtitle.replace(/ /g, '_')}`}>
										<Box sx={{ flexGrow: 1 }}>
											<Grid
												container
												className="variant-color"
												columnGap={1}
												key={item.id}
												onClick={() => {
													_getDetails(item.id);
												}}
												style={{
													...(item.id === details[0].id
														? {
																transform: 'scale(1.1)',
																margin: 15,
																backgroundColor: 'white',
																boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.5)',
																borderRadius: '5px',
														  }
														: {
																margin: 15,
																backgroundColor: 'white',
																boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.5)',
																borderRadius: '5px',
														  }),
												}}
											>
												<Grid>
													<div style={{ width: '100%', height: '70px' }}>
														{item && item.images && item.images[0] && (
															<img
																src={item.images[0]}
																style={{ width: '100%', height: '100%' }}
																alt="Item Image"
															/>
														)}
													</div>
												</Grid>
												<Grid sx={{ alignItems: 'center', display: 'flex' }}>
													<Typography
														gutterBottom
														variant="h4"
														component="div"
														fontWeight={'600'}
														fontSize={{
															lg: '12px',
															md: '12px',
															sm: '12px',
															xs: '12px',
														}}
														color={'#484848'}
														my={0.3}
														textAlign={'center'}
														key={item.id}
													>
														{item.color}
													</Typography>
												</Grid>
											</Grid>
										</Box>
									</Link>
								</div>
							))}
						</div>
					</div>
					<div className="key_specs" id="hero_car_specifications">
						<Typography
							gutterBottom
							variant="h4"
							component="div"
							fontWeight={'700'}
							fontSize={{ lg: '23px', md: '23px', sm: '23px', xs: '23px' }}
							color={'#484848'}
							sx={{ my: 2 }}
							textAlign={'start'}
						>
							Key Specifications
						</Typography>
						<div>
							{details.map((item, dataindex) => (
								<div className="specs_value" key={dataindex}>
									{item.spec && item.spec.length > 0
										? item.spec.map((data, dataIndex) => (
												<div className="specs" key={dataIndex}>
													<Typography
														gutterBottom
														variant="h4"
														component="div"
														fontWeight={'500'}
														fontSize={{
															lg: '13px',
															md: '13px',
															sm: '13px',
															xs: '13px',
														}}
														color={'#aaa'}
														sx={{ my: 1 }}
														textAlign={'start'}
													>
														{data.item_spec}
													</Typography>
													<Typography
														gutterBottom
														variant="h4"
														component="div"
														fontWeight={'700'}
														fontSize={{
															lg: '15px',
															md: '15px',
															sm: '15px',
															xs: '15px',
														}}
														color={'#484848'}
														sx={{ my: 2 }}
														textAlign={'start'}
													>
														{data.value}
													</Typography>
												</div>
										  ))
										: null}
								</div>
							))}
						</div>
						<div className="About_car" id="hero_car_about">
							<Typography
								gutterBottom
								variant="h4"
								component="div"
								fontWeight={'700'}
								fontSize={{ lg: '23px', md: '23px', sm: '23px', xs: '23px' }}
								color={'#484848'}
								sx={{ my: 2 }}
								textAlign={'start'}
							>
								About Car
							</Typography>
							<div className="about">
								<Typography
									gutterBottom
									variant="h4"
									component="div"
									fontWeight={'700'}
									fontSize={{ lg: '15px', md: '15px', sm: '15px', xs: '15px' }}
									color={'#6f6f6f'}
									sx={{ my: 2 }}
									textAlign={'start'}
								>
									About
								</Typography>
								<Typography
									gutterBottom
									variant="h4"
									component="div"
									lineHeight={1.5}
									fontWeight={'500'}
									fontSize={{ lg: '15px', md: '15px', sm: '15px', xs: '15px' }}
									color={'#6f6f6f'}
									sx={{ my: 2 }}
									textAlign={'start'}
								>
									{details.length > 0 ? details[0].about : null}
								</Typography>
							</div>
						</div>
						<div className="Description_car" id="hero_car_description">
							<Typography
								gutterBottom
								variant="h4"
								component="div"
								fontWeight={'700'}
								fontSize={{ lg: '23px', md: '23px', sm: '23px', xs: '23px' }}
								color={'#484848'}
								sx={{ my: 2 }}
								textAlign={'start'}
							>
								Description Car
							</Typography>
							<div className="description">
								<Typography
									gutterBottom
									variant="h4"
									component="div"
									fontWeight={'700'}
									fontSize={{ lg: '15px', md: '15px', sm: '15px', xs: '15px' }}
									color={'#6f6f6f'}
									sx={{ my: 2 }}
									textAlign={'start'}
								>
									Description
								</Typography>
								<Typography
									gutterBottom
									variant="h4"
									component="div"
									fontWeight={'500'}
									lineHeight={1.5}
									fontSize={{ lg: '15px', md: '15px', sm: '15px', xs: '15px' }}
									color={'#6f6f6f'}
									sx={{ my: 2 }}
									textAlign={'start'}
								>
									{details.length > 0 ? details[0].description : null}
								</Typography>
							</div>
							<div className="related_variants">
								<Typography
									gutterBottom
									variant="h4"
									component="div"
									fontWeight={'700'}
									fontSize={{ lg: '23px', md: '23px', sm: '23px', xs: '23px' }}
									color={'#484848'}
									sx={{ my: 2 }}
									textAlign={'start'}
								>
									Related Model Variants...
								</Typography>

								<div className="related-scroll">
									{relatedFilter.map((item, index) => (
										<Box sx={{ flex: 1 }}>
											<Grid className="realted-card" key={index}>
												<Link to={`/car/${item.subtitle.replace(/ /g, '_')}`}>
													<Grid
														xs={4}
														sm={4}
														md={4}
														lg={4}
														xl={4}
														onClick={() => {
															window.scrollTo({ top: 0, behavior: 'smooth' });
															_getDetails(item.id);
														}}
													>
														<div className="related-img_related">
															<img src={item.images[0]} alt={item.image} />
														</div>
														<Typography
															gutterBottom
															variant="h4"
															component="div"
															fontWeight={'600'}
															fontSize={{
																lg: '13px',
																md: '13px',
																sm: '12px',
																xs: '12px',
															}}
															color={'#2e2d2d'}
															sx={{ my: 2 }}
															textAlign={'center'}
														>
															{item.title}
														</Typography>
													</Grid>
												</Link>
											</Grid>
										</Box>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<BookNowModal
				showBookNowModal={showBookNowModal}
				setShowBookNowModal={setShowBookNowModal}
				details={details}
				userBookCheck={userBookCheck}
				phoneNumber={phoneNumber}
				setBtn={setBtn}
			/>

			<Modal
				open={modal}
				onClose={closeModal}
				className="address_modal_container"
			>
				<div>
					<Signup button={closeModal} />
				</div>
			</Modal>

			<PanVerifyModal
				showPanVerifyModal={showPanVerifyModal}
				setShowPanVerifyModal={setShowPanVerifyModal}
				details={details}
				id={id}
				setBtn={setBtn}
			/>

			<EmiCalculatorModal
				showEmiModal={showEmiModal}
				setShowEmiModal={setShowEmiModal}
				details={details}
				exRoomPrice={exRoomPrice}
				chgloanAmount={chgloanAmount}
			/>
		</div>
	);
};
export default Details;
