import React from "react";
import "./Enquery.css";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";

function Enquery() {
  const currencies = [
    {
      value: "USD",
      label: "Book A Test Drive",
    },
    {
      value: "EUR",
      label: "Book Car Evaluation",
    },
    {
      value: "BTC",
      label: "Book A Home Visit",
    },
    {
      value: "JPY",
      label: "Book A Showroom Visit",
    },
  ];
  return (
    <div className="Enquery_main">
      <Typography
        gutterBottom
        variant="h4"
        component="div"
        fontWeight={"700"}
        fontSize={{ lg: "30px", md: "25px", sm: "20px", xs: "18px" }}
        color={"black"}
        sx={{ pb: 2.5 }}
        textAlign={"center"}
      >
        Book Your Enquiry
      </Typography>
      <div
        className="Enquery_image"
        style={{
          backgroundImage:
            "url(" +
            "https://kalyanimotorsadmin.kalyanicrm.com/storage/policy_banner/PHXx6yp8Vn_1649772000.jpg" +
            ")",
          backgroundRepeat: "no-repeat",
          backgroundColor: "transparent",
        }}
      >
        <div className="leftForm">.</div>
        <div className="Enquery_form">
          <p
            style={{
              fontWeight: "700",
              textAlign: "left",
              marginBottom: "10px",
            }}
          >
            Enquiry Form
          </p>
          <p style={{ textAlign: "justify", color: "#000", fontSize: "11px" }}>
            Kindly fill your details to get Maruti Suzuki car on-road price from
            our sales expert.
          </p>
          <TextField
            id="outlined-basic"
            label="Enter Your Name"
            variant="outlined"
            size="small"
            sx={{ my: 2 }}
          />
          <TextField
            id="outlined-basic"
            label="Enter Your Phone Number"
            variant="outlined"
            size="small"
            sx={{ my: 2 }}
          />
          <TextField
            id="outlined-select-currency"
            select
            label="Select"
            defaultValue="EUR"
            size="small"
            sx={{ my: 2 }}
          >
            {currencies.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <Button variant="contained">SUBMIT</Button>
        </div>
      </div>
    </div>
  );
}

export default Enquery;
