import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Carhistory from '../../Assets/Images/CarHistoryVerified.png';
import Brisk from '../../Assets/Images/BriskDocumentation.png';
import Quality from '../../Assets/Images/QualityCheck.png';
import Hassle from '../../Assets/Images/Hasslefree.png';
import DoorStepEvaluation from '../../Assets/Images/Door_Step_Evaluation.png';
import Rctransfer from '../../Assets/Images/Easy_RC_Transfer.png';
import AIbased from '../../Assets/Images/AI_Based_Pricing.png';
import Onpayment from '../../Assets/Images/On-time_Payment.png';
import useMediaQuery from '@mui/material/useMediaQuery';
import './UsedCarComponents.css';

const TrueValue = ({ type }) => {
	const isImage = useMediaQuery('(max-width:900px)');

	class SingleValue {
		constructor(title, description, image) {
			this.title = title;
			this.description = description;
			this.image = image;
		}
	}

	const buyValues = [
		new SingleValue(
			'Car History Verified',
			'We ensure that the car your buying has complete details of the owernership, kilometers run and service record to make sure the you have a vision about the car and its history.',
			Carhistory
		),
		new SingleValue(
			'Quality Check',
			"We undergo a process inspecting and eveluating a vechicle to determine it's condition, safety,exterior, interior and other functionality of the car to ensure you are getting a vechicle that is safe to drive.",
			Quality
		),
		new SingleValue(
			'Brisk Documentation',
			"We have madae your paperwork easier so you don't have to worry. Documents associated with car including registration and insurence everything will be taken care by realationship office.",
			Brisk
		),
		new SingleValue(
			'Hassle free',
			'Now buying a used car has become hassle free with us. Book a test drive to see how the car works on road. Experience a trouble-free buying.',
			Hassle
		),
	];

	const sellValues = [
		new SingleValue(
			'Door Step Evaluation',
			'We make sure your car selling is made easy.Get door step evaluation and provide details of your car so it will help buyers identify the can use our app or website to sell your car.',
			DoorStepEvaluation
		),
		new SingleValue(
			'Easy RC Transfer',
			'Rc Transfer at true value is hassle free. Provide the required documents and leave the rest to us.',
			Rctransfer
		),
		new SingleValue(
			'AI Based Pricing',
			'At True Value we use AL based Pricing to help your the most accurate price for your car and avoid human errors.',
			AIbased
		),
		new SingleValue(
			'On-time payment',
			'we make sure that you recieve the full amount owned for the car and will establish a smooth transaction in future .We ensure that the transaction is complete on time and in professional manner.',
			Onpayment
		),
	];

	const Category = type === 'buy' ? buyValues : sellValues;

	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignContent: 'center',
				alignItems: 'center',
			}}
		>
			<Grid
				container
				lg={12}
				xl={12}
				md={12}
				xs={12}
				sm={12}
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignContent: 'center',
					alignItems: 'center',
					textAlign: 'start',
				}}
			>
				{Category.map((item, index) => (
					<Grid
						item
						xs={11}
						sm={11}
						md={6}
						lg={4}
						xl={4}
						sx={{
							color: '#000000',
							background: 'white',
							textAlign: 'center',
							display: 'flex',
							justifyContent: 'center',
						}}
					>
						<Grid
							container
							className="bottom_cards"
							xl={11}
							lg={11}
							md={11}
							xs={12}
							sm={12}
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								my: { sm: 1, xs: 1 },
								minHeight: { xl: '250px', lg: '270px', md: '260px' },
								py: '10px',
								px: { xl: '30px', lg: '30px', md: '10px' },
							}}
						>
							<Grid item xl={12} lg={12} md={12} xs={4} sm={4}>
								<img
									src={item.image}
									alt={item.title}
									style={{
										width: isImage ? '70px' : '80px',
										height: isImage ? '70px' : '80px',
									}}
								/>
							</Grid>
							<Grid item xl={12} lg={12} md={12} xs={8} sm={8}>
								<Typography
									gutterBottom
									variant="h5"
									component="div"
									fontWeight={600}
									fontSize={'15px'}
									color={'#061E47'}
									py={'5px'}
									textAlign={{
										xl: 'center',
										lg: 'center',
										md: 'center',
										sm: 'start',
										xs: 'start',
									}}
								>
									{item.title}
								</Typography>
								<Typography
									gutterBottom
									variant="p"
									component="div"
									fontWeight={600}
									fontSize={'12px'}
									textAlign={{
										xl: 'center',
										lg: 'center',
										md: 'center',
										sm: 'start',
										xs: 'start',
									}}
									lineHeight={'22px'}
									pb={{ xl: 2, lg: 2, md: 2 }}
									pr={{ xs: 2, sm: 2 }}
								>
									{item.description}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				))}
			</Grid>
		</Box>
	);
};

export default TrueValue;
