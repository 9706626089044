import React from "react";

const Paytm = () => {
  return (
    <div>
      <h1>Paytm</h1>
    </div>
  );
};

export default Paytm;
