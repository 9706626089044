import React, { useState, useEffect } from 'react';
import Banner from '../../Components/Banner/Banner';
import Promotion from '../Home/Home_Componets/Promotion/Promotion';
import Enquery from './Home_Componets/Enquery/Enquery';
import CarSlider from '../Home/Home_Componets/Slider/Slider';
import Faq from '../Home/Home_Componets/Faq/Faq';
import Filtercars from '../Home/Home_Componets/Filtercars/Filtercars';
import Onroadprice from '../Home/Home_Componets/Onroadprice/Onroadrice';
import ServiceMainCards from '../Home/Home_Componets/Service_card/Service_card';
import Loader from '../../Constants/Loader';

const Home = () => {
	const [show, setShow] = useState(true);
	const [modals, setModals] = useState([]);
	useEffect(() => {
		setTimeout(() => {
			setShow(false);
		}, 3000);
	}, []);


	
	const backgroundImages =
		'https://kalyanimotorsadmin.kalyanicrm.com/storage/Enq_banner/C1Foj39bdp_1649336618.jpg';
	return (
		<div style={{ backgroundColor: '#fff' }}>
			<div
				className="body_section"
				style={{
					width: '100%',
					margin: 'auto',
					height: 'auto',
				}}
			>
				<Banner />
				<Promotion />
				<Onroadprice backgroundImage={backgroundImages} modals={modals} />
				<Filtercars />
				<Enquery />
				<CarSlider />
				<ServiceMainCards />
				<Faq />
			</div>
		</div>
	);
};

export default Home;
