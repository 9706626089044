import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Bookcar from '../../Assets/Images/Icons-04.png';
import DoorEvaluation from '../../Assets/Images/Icons-05.png';
import Sellcar from '../../Assets/Images/sell_car_icon3.png';
import Testdrive from '../../Assets/Images/Icons_find_a_car2.png';
import Findcar from '../../Assets/Images/Icons_find_a_car.png';
import Buycar from '../../Assets/Images/Icons_find_a_car3.png';
import './UsedCarComponents.css';

const ThreeStepCard = ({ title, description, image }) => {
	return (
		<Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
			<Grid
				container
				xs={12}
				sm={12}
				md={12}
				lg={12}
				xl={12}
				sx={{
					cursor: 'pointer',
					border: '0.1px solid #eee',
					borderRadius: '16px',
					minHeight: {
						xl: '150px',
						lg: '150px',
						md: '150px',
						sm: '150px',
						xs: '150px',
					},
					display: 'flex',
					justifyContent: 'center',
					alignContent: { xs: 'center', sm: 'center' },
					alignItems: { xs: 'center', sm: 'center' },
					flexDirection: {
						xl: 'column',
						lg: 'column',
						md: 'column',
						sm: 'row',
						xs: 'row',
					},
				}}
			>
				<Grid item xs={5} sm={5} md={12} lg={12} xl={12} my={2}>
					<img
						src={image}
						alt="Book_appoint"
						style={{
							width: '70px',
							height: '70px',
						}}
					/>
				</Grid>
				<Grid
					item
					xs={7}
					sm={7}
					md={12}
					lg={12}
					xl={12}
					sx={{
						backgroundColor: '#f9f9f9',
						borderBottomLeftRadius: {
							xl: '16px',
							lg: '16px',
							md: '16px',
						},
						borderBottomRightRadius: {
							xl: '16px',
							lg: '16px',
							md: '16px',
						},
						borderTopRightRadius: {
							sm: '16px',
							xs: '16px',
							md: '0px',
							lg: '0px',
							xl: '0px',
						},
						borderBottomRightRadius: { sm: '16px', xs: '16px' },
						minHeight: {
							sm: '150px',
							xs: '150px',
							md: '120px',
							lg: '120px',
							xl: '120px',
						},
						display: 'flex',
						justifyContent: 'center',
						alignContent: { xs: 'center', sm: 'center' },
						alignItems: { xs: 'center', sm: 'center' },
						flexDirection: 'column',
						width: '100%',
						height: '100%',
						py: 1,
					}}
					className="find_car_card"
				>
					<div>
						<Typography
							gutterBottom
							variant="h5"
							component="div"
							fontWeight={600}
							fontSize={{
								lg: '16px',
								md: '16px',
								sm: '16px',
								xs: '14px',
							}}
							color={'#000000'}
							textAlign={{
								xs: 'start',
								sm: 'start',
								md: 'center',
								lg: 'center',
								xl: 'center',
							}}
							pl={{ xs: 2, sm: 2 }}
						>
							{title}
						</Typography>
						<Typography
							gutterBottom
							variant="p"
							component="div"
							fontWeight={600}
							fontSize={'12px'}
							color={'#5C5C5C'}
							lineHeight={2}
							textAlign={{
								xs: 'start',
								sm: 'start',
								md: 'center',
								lg: 'center',
								xl: 'center',
							}}
							pl={{ xs: 2, sm: 2 }}
						>
							{description}
						</Typography>
					</div>
				</Grid>
			</Grid>
		</Grid>
	);
};

const ThreeSteps = ({ type }) => {
	class SingleStep {
		constructor(title, description, image) {
			this.title = title;
			this.description = description;
			this.image = image;
		}
	}

	const sellSteps = [
		new SingleStep(
			'Book Appoinment',
			'Provide basic details about your vechicle and contact information',
			Bookcar
		),
		new SingleStep(
			'Free Doorstep Evaluation',
			'Get your vechicle evaluate at your convenience to get the best price.',
			DoorEvaluation
		),
		new SingleStep(
			'Sell Your Car',
			'Hassle-free paper and Quick payments',
			Sellcar
		),
	];

	const buySteps = [
		new SingleStep(
			'Find a Car',
			'Search For a car untill you find a car of your choice.',
			Findcar
		),
		new SingleStep(
			'Free Test Drive',
			'Experience it to belive it - Ride before you buy it.',
			Testdrive
		),
		new SingleStep(
			'Buy It The Way You Want',
			'Pay Fully or get it financed',
			Buycar
		),
	];

	let Category = type === 'buy' ? buySteps : sellSteps;

	return (
		<Box sx={{ display: 'flex', justifyContent: 'center' }}>
			<Grid
				container
				xs={12}
				sm={12}
				md={12}
				lg={12}
				xl={12}
				sx={{
					textAlign: 'center',
					display: 'flex',
					justifyContent: {
						lg: 'center',
						xl: 'center',
						sm: 'center',
						xs: 'center',
					},
					gap: 3,
				}}
			>
				{Category.map((step, index) => (
					<ThreeStepCard
						key={index}
						title={step.title}
						description={step.description}
						image={step.image}
					/>
				))}
			</Grid>
		</Box>
	);
};

export default ThreeSteps;
