import React, { useEffect, useState } from 'react';
import ApiHandler from '../../../Api/Apihandler';
import { useLocation, useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import './Service.css';
import { useNavigate, Link } from 'react-router-dom';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useMediaQuery } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

// const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const Services = () => {
	const [services, SetServices] = useState([]);
	const [content, setContent] = useState([]);
	const [imgId, setImageId] = useState(65);
	const navigate = useNavigate();
	const isSmallScreen = useMediaQuery('(max-width:767px)');
	const theme = useTheme();
	const [activeStep, setActiveStep] = useState(0);
	const [categoriesLoader, setCategoriesLoader] = useState(false);
	const [servicesLoader, setsServicesLoader] = useState(true);
	const location = useLocation();
	const { id } = location.state || {};

	const { ReplaceId, ReplaceTitle } = useParams();

	//url purpose
	const filterTitle =
		ReplaceTitle === undefined ? null : ReplaceTitle.replace(/_/g, ' ');
	const filter = services.filter((item) => item.title === filterTitle);
	const filterId = filter.map((item) => item.id);
	const filterIdInt = parseInt(filterId);
	const varient =
		typeof filterIdInt === 'number' && !isNaN(filterIdInt)
			? Number(filterIdInt)
			: 65;
	const activeId =
		typeof filterIdInt === 'number' && !isNaN(filterIdInt)
			? Number(filterIdInt)
			: 65;
	//url purpose

	useEffect(() => {
		if (services.length == 0) {
			setCategoriesLoader(true);
			_getSeriveTitle(id);
		}
		_getServiceContent(varient);
	}, [id, varient]);

	const handleStepChange = (step) => {
		setActiveStep(step);
	};

	const handleClick = (id, filterIdInt) => {
		setImageId(id, filterIdInt);
	};

	const _getSeriveTitle = async () => {
		try {
			const response = await ApiHandler.getModelList(4);
			const data = response.data.data;
			if (data) {
				SetServices(data);
				const timeout = setTimeout(() => {
					setCategoriesLoader(false);
				}, 500);
				return () => clearTimeout(timeout);
			}
		} catch (error) {
			console.log('get service title', error);
		}
	};

	const _getServiceContent = async (varient) => {
		setsServicesLoader(true);
		try {
			const response = await ApiHandler.getVarientsList('/' + varient);
			const data = response.data.data;
			if (data && services) {
				const timeout = setTimeout(() => {
					setContent(data);
					setsServicesLoader(false);
				}, 500);
				return () => clearTimeout(timeout);
			}
		} catch (error) {
			console.log('service_content_error', error);
		}
	};

	const handleClickDetails = (id, channel, title, itemlist) => {
		// props.history.push('/serviceDetails');
		const Replace = title.replace(/\//g, '-').replace(/ /g, '_');
		navigate(`/service/${Replace}`, {
			state: { id, channel, itemlist },
		});
	};

	return (
		<div className="services">
			<div
				style={{
					position: 'sticky',
					top: isSmallScreen ? 105 : 70,
					zIndex: 99,
				}}
			>
				<div
					className={categoriesLoader ? 'title_scroll_loader' : 'title_scroll'}
				>
					<Grid container rowSpacing={1} sx={{ display: 'flex', gap: '10px' }}>
						{categoriesLoader
							? Array.from({ length: isSmallScreen ? 2 : 7 }).map((index) => (
									<Grid key={index} item xs={6} sm={4} md={4} lg={3}>
										<Skeleton
											// variant="rectangular"
											width="100%"
											height="65px"
											animation="wave"
										/>
									</Grid>
							  ))
							: services &&
							  services.map((item, i) => (
									<Grid item key={item.id} sx={{ minWidth: '200px' }}>
										<Link to={`/allservices/${item.title.replace(/ /g, '_')}`}>
											<Paper
												sx={{
													cursor: 'pointer',
													padding: '10px',
													textAlign: 'center',
													whiteSpace: 'nowrap',
													// '&:hover': {
													// 	backgroundColor: '#eee',
													// },
													marginRight: '17px',
													width: '200px',
													borderRadius: '12px',
													fontSize: '16px',
													background:
														activeId === item.id ? '#002441' : 'white',
													color: activeId === item.id ? 'white' : '#002441',
												}}
												onClick={() => handleClick(item.id, filterIdInt)}
											>
												{item.title}
											</Paper>
										</Link>
									</Grid>
							  ))}
					</Grid>
				</div>
			</div>
			<div
				style={{
					display: 'flex',
					// background: 'linear-gradient(to right, #536976, #292e49)',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						width: '95%',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<Grid container spacing={2} p={2} sx={{ cursor: 'pointer' }}>
						{servicesLoader
							? Array.from({ length: 8 }).map((index) => (
									<Grid key={index} item xs={6} sm={4} md={4} lg={3} p={1}>
										<Skeleton
											variant="rectangular"
											width="100%"
											height="300px"
										/>
										<Skeleton variant="rounded" style={{ marginTop: '10px' }} />
										<Skeleton
											variant="rounded"
											width="90%"
											style={{ marginTop: '10px' }}
										/>
										<Skeleton
											variant="rounded"
											width="60%"
											style={{ marginTop: '10px' }}
										/>
									</Grid>
							  ))
							: content &&
							  content.map((item, i) => {
									return (
										<Grid
											item
											key={item.id}
											xs={12}
											sm={6}
											md={6}
											lg={3}
											xl={3}
										>
											<Card
												onClick={() =>
													handleClickDetails(
														item.id,
														item.channel,
														item.title,
														item.itemlist
													)
												}
												sx={{
													// minHeight: 180,
													height: { sm: '100%', md: '100%', lg: '100%' },
													width: { sm: '100%', md: '100%', lg: '100%' },

													transition: 'transform 1s ease-in-out',
													transition: 'transform 0.4s ease-in',
													'&:hover': {
														position: 'relative',
														transform: isSmallScreen
															? 'scale(1.0)'
															: 'scale(1.05)',
													},
												}}
												className="service_card"
											>
												<CardMedia
													sx={{
														height: {
															xl: 150,
															lg: 150,
															md: 120,
															sm: 100,
															xs: 100,
														},
														width: {
															xl: 150,
															lg: 150,
															md: 120,
															sm: 100,
															xs: 100,
														},
														// textAlign: 'center',
														p: '0px',
														my: 2,
													}}
													component="img"
													image={item.images[0]}
												/>
												<CardContent
													className="servi_card_cecontent"
													sx={{ pb: 0, background: '#fafafa', width: '100%' }}
												>
													<div className="servicecontent" style={{}}>
														<Typography
															gutterBottom
															variant="h5"
															component="div"
															fontSize={{
																lg: '16px',
																md: '14px',
																sm: '14px',
																xs: '14px',
															}}
															fontWeight={700}
															// py={2}
															color={'ffff'}
															textAlign={'center'}
														>
															{' '}
															{item.title}
														</Typography>
														<div
															style={{
																height: isSmallScreen ? 'auto' : 105,
																display: 'flex',
																flexDirection: 'column',
																alignItems: 'flex-start',
															}}
														>
															<Typography
																gutterBottom
																variant="h5"
																component="div"
																fontSize={{
																	lg: '14px',
																	md: '11px',
																	sm: '11px',
																	xs: '11px',
																}}
																fontWeight={700}
																color={'ffff'}
																textAlign={'start'}
															>
																{item.description
																	.split('\n')
																	.slice(0, 2)
																	.map((point, i) => {
																		return (
																			<Typography
																				variant="p"
																				component="div"
																				fontWeight={400}
																				forntSize={{
																					lg: '10px',
																					md: '10px',
																					sm: '10px',
																					xs: '10px',
																				}}
																				lineHeight={1.7}
																			>
																				{point}
																			</Typography>
																		);
																	})}
															</Typography>
														</div>

														<div
															style={{
																height: '40px',
																display: 'flex',
																justifyContent: 'flex-start',
																alignItems: 'flex-end',
															}}
														>
															<Button
																size="small"
																className="knowmorebtn"
																sx={{ color: '#eb232a' }}
															>
																KNOW MORE
																<KeyboardArrowRightIcon />
															</Button>
														</div>
													</div>
												</CardContent>
											</Card>
										</Grid>
									);
							  })}
					</Grid>
				</Box>
			</div>
		</div>
	);
};

export default Services;
