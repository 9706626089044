import React from 'react';
import './Onroadprice.css';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Box, Grid, Button, Typography } from '@mui/material';

const Onroadprice = ({ backgroundImage, modals }) => {
	return (
		<div className="Onroadprice_main">
			<Typography
				gutterBottom
				variant="h4"
				component="div"
				fontWeight={'700'}
				fontSize={{ lg: '30px', md: '25px', sm: '20px', xs: '18px' }}
				color={'black'}
				sx={{ pb: 2.5 }}
				textAlign={'center'}
			>
				Check On-Road Price
			</Typography>
			<div
				className="Onroadprice_image"
				style={{
					backgroundImage: `url(${backgroundImage})`,
					// backgroundSize: 'auto',
					backgroundRepeat: 'no-repeat',
					backgroundColor: 'transparent',
				}}
			>
				<div className="Onroadprice_form">
					<p
						style={{
							fontWeight: '700',
							textAlign: 'left',
							marginBottom: '10px',
						}}
					>
						On-Road Price
					</p>
					<p style={{ textAlign: 'justify', color: '#000', fontSize: '11px' }}>
						Kindly fill your details to get Maruti Suzuki car on-road price from
						our sales expert.
					</p>
					<TextField
						id="outlined-basic"
						label="Enter Your Name"
						variant="outlined"
						size="small"
						sx={{ my: 2 }}
					/>
					<TextField
						id="outlined-basic"
						label="Enter Your Phone Number"
						variant="outlined"
						size="small"
						sx={{ my: 2 }}
					/>
					<TextField
						id="outlined-select-currency"
						select
						label="Select Your Model"
						placeholder="Choose Model"
						defaultValue=""
						size="small"
						sx={{ my: 2 }}
					>
						{modals.length > 0 &&
							modals[0].items.map((modal) => (
								<MenuItem key={modal.id} value={modal.title}>
									{modal.title}
								</MenuItem>
							))}
					</TextField>

					<Button
						variant="contained"
						sx={{
							fontSize: { xs: '12px', sm: '12px', md: '14px' },
							fontWeight: 500,
							letterSpacing: 0.5,
						}}
					>
						Check On-Road Price
					</Button>
				</div>
			</div>
		</div>
	);
};

export default Onroadprice;
