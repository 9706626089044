import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import "./Promotion.css";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import ApiHandler from "../../../../Api/Apihandler";
import Carcard from "../../../../Components/Card/Card";
import Skeleton from "@mui/material/Skeleton";
import { useNavigate } from "react-router-dom";

export default function PrimarySec() {
  const [carlist, setCarList] = useState([]);

  const [activelist, setActiveList] = useState("Popular");
  const [loader, setLoader] = useState(false);
  const listlength = carlist.length;
  useEffect(() => {
    setLoader(true);
    getCarlist("Focused");
  }, []);

  const getCarlist = async (title) => {
    // const data = { title: titles };
    const response = await ApiHandler.getFeaturedData({ title });
    setCarList(response.data.data);
    setLoader(false);
  };

  const isSmallScreen = useMediaQuery("(max-width: 400px)");
  const navigate = useNavigate();

  const handleClick = (id, subtitle) => {
    const Replace = subtitle.replace(/ /g, "_");
    navigate(`/car/${Replace}`, { state: { subtitle, id } });
  };

  return (
    <div className="primarysec_main">
      <Typography
        gutterBottom
        variant="h4"
        component="div"
        fontWeight={"700"}
        fontSize={{ lg: "30px", md: "25px", sm: "20px", xs: "18px" }}
        color={"black"}
        sx={{ my: 2 }}
        textAlign={"center"}
      >
        Maruti Suzuki - New Cars
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            borderRadius: "30px",
            backgroundColor: "#EEF2F6",
            // boxShadow: "2px 4px 2px rgba(0,0,0,0.2)",
            // height: '50px',
            marginTop: "10px",
            // paddingRight: '20px',
            // paddingLeft: '20px',
            marginBottom: "10px",
            padding: 5,
            width: "auto",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            sx={[
              {
                // my: 0.5,
                marginRight: 2,
                fontWeight: 600,
                // fontSize:{xs:'10px',sm:'12px',sm:'12px',sm:'12px',sm:'12px',sm:'12px',},
                borderRadius: "0px",
                py: 1,
                px: isSmallScreen ? 1 : 3,
                "&:hover": { background: "none" },
              },
              activelist == "Popular"
                ? {
                    color: "orangered",
                    background: "#fff",
                    borderRadius: 15,
                    px: isSmallScreen ? 1 : 3,
                    "&:hover": { backgroundColor: "#fff" },
                  }
                : {},
            ]}
            size="small"
            onClick={() => {
              setActiveList("Popular");
              getCarlist("Focused");
            }}
          >
            Popular
          </Button>
          <Button
            sx={[
              {
                marginRight: 2,
                fontWeight: 600,
                borderRadius: "0px",
                py: 1,
                px: isSmallScreen ? 1 : 3,
                "&:hover": { background: "none" },
              },
              activelist == "Primary"
                ? {
                    color: "orangered",
                    backgroundColor: "#fff",
                    borderRadius: 15,
                    px: isSmallScreen ? 1 : 3,
                    "&:hover": { backgroundColor: "#fff" },
                  }
                : {},
            ]}
            size="small"
            onClick={() => {
              setActiveList("Primary");
              getCarlist("Primary");
            }}
          >
            Primary
          </Button>
          <Button
            sx={[
              {
                marginRight: 2,
                fontWeight: 600,
                borderRadius: "0px",
                py: 1,
                px: isSmallScreen ? 1 : 3,
                marginRight: 0,
                "&:hover": { background: "none" },
              },
              activelist == "Secondary"
                ? {
                    color: "orangered",
                    backgroundColor: "#fff",
                    borderRadius: 15,
                    px: isSmallScreen ? 1 : 3,
                    "&:hover": { backgroundColor: "#fff", marginRight: 0 },
                  }
                : {},
            ]}
            size="small"
            onClick={() => {
              setActiveList("Secondary");
              getCarlist("Secondary");
            }}
          >
            Secondary
          </Button>
        </div>
      </div>
      <Box sx={{ marginTop: 2 }}>
        <Grid
          container
          justifyContent={carlist.map.length > 4 ? "start" : "center"}
          sx={{ overflowX: carlist.map.length > 0 ? "auto" : "hidden" }}
          rowSpacing={2}
        >
          {loader
            ? Array.from({ length: 4 }).map((_, index) => (
                <Grid key={index} item xs={12} sm={4} md={4} lg={3} p={1}>
                  <Skeleton variant="rectangular" width="100%" height="305px" />
                  <Skeleton variant="rounded" style={{ marginTop: "10px" }} />
                  <Skeleton
                    variant="rounded"
                    width="90%"
                    style={{ marginTop: "10px" }}
                  />
                  <Skeleton
                    variant="rounded"
                    width="60%"
                    style={{ marginTop: "10px" }}
                  />
                </Grid>
              ))
            : carlist.map((item, index) => (
                <Grid key={index} item xs={12} sm={6} md={4} lg={3} p={1}>
                  <Carcard
                    handleClick={() => {
                      handleClick(item[0].id, item[0].subtitle);
                    }}
                    title={item[0].title}
                    images={item[0].images[0]}
                    price={item[0].price}
                    carTitle={item[0].title}
                  />
                </Grid>
              ))}
        </Grid>
      </Box>
    </div>
  );
}
