import React, { useEffect } from 'react';
import Home from '../src/Pages/Home/Home';
import Variant from '../src/Pages/Variant/Variant';
import Details from '../src/Pages/Details/Details';
import Ebook from '../src/Pages/EBooking/EBook/EBook';
import Ebook_Details from '../src/Pages/EBooking/EBook_Details/EBook_Details';
import {
	BrowserRouter,
	Routes,
	Route,
	useParams,
	useRoutes,
	Outlet,
} from 'react-router-dom';
import Model from '../src/Pages/Model/Model';
import Usedcars from '../src/Pages/Usedcars/Usedcar';
import Buyform from '../src/Pages/Usedcars/Buyform/Buyform';
import Sellform from '../src/Pages/Usedcars/Sellform/Sellform';
import Service from '../src/Pages/Services/Service/Service';
// import Insurence from "./screens/CommonScreens/Channels/Insurence/Insurence";
import ServiceDetails from '../src/Pages/Services/Service_Details/Service_Details';
// import InsurenceCarform from "./screens/CommonScreens/Channels/Insurence/InsurenceCarform";
// import InsurenceDetail from "./screens/CommonScreens/Channels/Insurence/InsurenceDetail/InsurenceDetail";
// import InsurencePlan from "./screens/CommonScreens/Channels/Insurence/InsurencePlan/InsurencePlan";
// import InsurencePlanDetails from "./screens/CommonScreens/Channels/Insurence/InsurencePlanDetails/InsurencePlanDetails";
// import InsurenceAddCovers from "./screens/CommonScreens/Channels/Insurence/InsurenceAddCovers/InsurenceAddCovers";
import ServiceEbook from '../src/Pages/Services/EBooking/EBook/EBook';
// import ServiceEbookDetails from "./screens/CartScreens/ServiceEbookDetails/ServiceEbookDetails";
import Cart from '../src/Pages/Cart/Cart';
import Profile from '../src/Pages/Profile/Profile/Profile';
import ProfileVarification from '../src/Pages/Profile/Panvarification/Panvarification';
import AddressVarification from '../src/Pages/Profile/Address/Address';
import YourOrders from '../src/Pages/Profile/Yourorder/Yourorder';
import PaymentRequestScreen from '../src/Pages/Payment/PaymentRequest/PaymentRequest';
import Cashfree from '../src/Pages/EBooking/Gateways/Cashfree';
import { verifyBookingPayment } from '../src/Pages/EBooking/Gateways/GatewayCommonFunction';
import Razorpay from '../src/Pages/EBooking/Gateways/Razorpay';
// import Allcars from "./screens/CommonScreens/Allcars/Allcars";
// import { CarBooking_Success } from './Components/Success/Success';
import { Payment_Failure } from '../src/Components/Failure/Failure';
import { ServiceBooking_Success } from './Components/PaymentsStatus/Success';
// const LazyHome = React.lazy(() => import('./screens/Home/Home'));
import { Processing_Payment } from '../src/Components/Failure/Failure';
import Saving_Corner from './Pages/Profile/Saving_Corner/Saving_Corner';
import Paytm from './Pages/EBooking/Gateways/Paytm';
import Search from './Pages/Search/Search';
import HeaderMain from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import Layout from './Components/layout';
import PaymentsStatus from './Pages/PaymentsStatus';
import PaymentDetails from './Pages/Payment/PaymentDetails/PaymentDetails';

const App = () => {
	return (
		<BrowserRouter>
			<Layout>
				<Routes>
					<Route
						path="/"
						// element={
						// 	<React.Suspense fallback="Loading...">
						// 		<LazyHome />
						// 	</React.Suspense>
						// }
						element={<Home />}
					/>
					<Route path="/car/:Replace" element={<Details />} />
					<Route path="/ebook/:ReplaceId/:Replace" element={<Ebook />} />
					<Route
						path="/ebookDetails/:ReplaceId/:Replace"
						element={<Ebook_Details />}
					/>
					<Route path="/allcar">
						{/* <Route index element={<Allcars />} /> */}
						<Route path="variant/:Replace" element={<Variant />} />
						<Route path=":ReplaceTitle" element={<Model />} />
					</Route>
					<Route path="/allservices/" element={<Service />}>
						<Route path=":ReplaceTitle" element={<Service />} />
					</Route>
					<Route path="/service/:Replace" element={<ServiceDetails />} />
					<Route
						path="/service/:ReplaceId/:ReplaceItem/:Replace"
						element={<ServiceDetails />}
					/>
					<Route path="/serviceebook/:Replace" element={<ServiceEbook />} />
					{/* <Route path="/serviceebookDetail" element={<ServiceEbookDetails />} /> */}
					{/* <Route path="/allcar/:ReplaceTitle" element={<Model />} />
        <Route path="/allcar/:Replace" element={<Variant />} /> */}
					{/* <Route path="/allcar/:ReplaceId/:Replace" element={<Variant />} /> */}
					<Route path="/usedcars" element={<Usedcars />} />
					<Route path="/usedcars/buyform" element={<Buyform />} />
					<Route path="/usedcars/sellform" element={<Sellform />} />
					{/* <Route path="/insurence" element={<Insurence />} />
        <Route path="/insurancecarform" element={<InsurenceCarform />} />
        <Route path="/insuranceDetails" element={<InsurenceDetail />} />
        <Route path="/insurencePlan" element={<InsurencePlan />} />
        <Route
          path="/insurencePlanDetails"
          element={<InsurencePlanDetails />}
        />
        <Route path="/insurenceAddCovers" element={<InsurenceAddCovers />} /> */}
					<Route path="/cart" element={<Cart />} />
					<Route path="/about/:profile" element={<Profile />} />
					<Route
						path="/about/profilevarification"
						element={<ProfileVarification />}
					/>
					<Route
						path="/about/addressvarification"
						element={<AddressVarification />}
					/>
					<Route path="/about/yourorders" element={<YourOrders />} />
					<Route path="/about/saving_corner" element={<Saving_Corner />} />
					<Route path="/payments/payment_request" element={<PaymentRequestScreen />} />
					<Route path="payment/cashfree" element={<Cashfree />} />
					<Route path="payment/razorpay" element={<Razorpay />} />
					<Route path="payment/paytm" element={<Paytm />} />
					{/* <Route
          path="/cashfree/gateway/process/sim"
          element={<Payment_Success />}
        /> */}
					{/* <Route path="/carbooking_success" element={<CarBooking_Success />} /> */}
					<Route
						path="/servicebooking_success"
						element={<ServiceBooking_Success />}
					/>
					<Route path="/processing_payment" element={<Processing_Payment />} />
					<Route path="/payments/PaymentDetails" element={<PaymentDetails />} />
					<Route path="/payments/:token" element={<PaymentsStatus />} /> 
					<Route path="/search" element={<Search />} />
				</Routes>
			</Layout>
		</BrowserRouter>
	);
};

export default App;
