import * as React from "react";
import Accordion from "@mui/material/Accordion";
import "./Faq.css";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function Faq() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div style={{ paddingBottom: "30px" }} className="faq_main">
      <Typography fontSize={30} fontWeight={700} my={2}>
        Common FAQ
      </Typography>
      <Accordion
        expanded={expanded === "panel1"}
        sx={{
          backgroundColor: "secondary",
          my: 0.5,
          borderRadius: 2,
        }}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ fontWeight: "600", flexShrink: 1 }}>
            Why do I Choose Kalyani Motors services?
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="accordion_details">
          <Typography>
            Kalyani Motors excels in giving you a greater experience for vehicle
            service. Everything from billing, procurements of parts, and quality
            checks is our responsibility and will be regularly updated to you.
            The best part? We offer a wide variety of high-quality car services
            without burning a hole in their pocket.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        sx={{
          backgroundColor: "secondary",
          my: 0.5,
          borderRadius: 2,
        }}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography sx={{ fontWeight: "600", flexShrink: 1 }}>
            What services can Kalyani Motors provide for my car in Bangalore?
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="accordion_details">
          <Typography>
            Kalyani Motors excels in giving you a cakewalk experience for
            vehicle service. Everything from billing, procurement of parts and
            quality checks is our responsibility and will be regularly updated
            to you. The best part? We offer a wide variety of high quality car
            services without burning a hole in their pocket.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        sx={{
          backgroundColor: "secondary",
          my: 0.5,
          borderRadius: 2,
        }}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ fontWeight: "600", flexShrink: 1 }}>
            How much would I should spend to get a basic car service done at
            Kalyani Motors in Bangalore?
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="accordion_details">
          <Typography>
            The very basic service package at Kalyani Motors costs as low as
            Rs.1999/- . To know more, call us at 9876543210. You can also book
            an appointment from our website or Android app.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel4"}
        sx={{
          backgroundColor: "secondary",
          my: 0.5,
          borderRadius: 2,
        }}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{ flexShrink: 1, fontWeight: "600" }}>
            How good is Kalyani Motor's presence in Bangalore & Hyderabad?
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="accordion_details">
          <Typography>
            Kalyani Motors has 42+ touchpoints available across Bangalore and
            Hyderabad that include showrooms, True value service center, body
            repair center and driving schools.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel5"}
        sx={{
          backgroundColor: "secondary",
          my: 0.5,
          borderRadius: 2,
        }}
        onChange={handleChange("panel5")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
        >
          <Typography sx={{ flexShrink: 1, fontWeight: "600" }}>
            Would there be any extra charges in addition to the prescribed
            service rates?
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="accordion_details">
          <Typography>
            No. You only pay for the service you receive. We believe in more for
            less which is why Kalyani Motors services are upto 40% off from
            standard authorized service centres.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel6"}
        sx={{
          backgroundColor: "secondary",
          my: 0.5,
          borderRadius: 2,
        }}
        onChange={handleChange("panel6")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6bh-content"
          id="panel6bh-header"
        >
          <Typography sx={{ flexShrink: 1, fontWeight: "600" }}>
            What is the basic duration for a car service from Kalyani Motors?
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="accordion_details">
          <Typography>
            Service requires anywhere between 4-6 hours depending on the type of
            package you opt for.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel7"}
        sx={{
          backgroundColor: "secondary",
          my: 0.5,
          borderRadius: 2,
        }}
        onChange={handleChange("panel7")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel7bh-content"
          id="panel7bh-header"
        >
          <Typography sx={{ flexShrink: 1, fontWeight: "600" }}>
            How good is the service equipment at Kalyani Motors workshops?
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="accordion_details">
          <Typography>
            Pampering your car is our speciality. Only high end and well rated
            equipment specialized for your car needs is used in all Kalyani
            Motors centres. OBD2 Diagnostic scanner and Automatic AC gas
            recharging apparatus are some to name a few.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel8"}
        sx={{
          backgroundColor: "secondary",
          my: 0.5,
          borderRadius: 2,
        }}
        onChange={handleChange("panel8")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel8bh-content"
          id="panel8bh-header"
        >
          <Typography sx={{ flexShrink: 1, fontWeight: "600" }}>
            I am facing an issue with my car after service, What do I do?
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="accordion_details">
          <Typography>
            We are all about customer satisfaction and hence we sincerely
            apologise for your unfortunate experience. Kalyani Motors provides a
            1 month/1000km warranty on all our services. Rest assured, a 24*7
            dedicated customer service team is always ready to help you out.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel9"}
        sx={{
          backgroundColor: "secondary",
          my: 0.5,
          borderRadius: 2,
        }}
        onChange={handleChange("panel9")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel9bh-content"
          id="panel9bh-header"
        >
          <Typography sx={{ flexShrink: 1, fontWeight: "600" }}>
            I don’t exactly find the service option I am looking for, Can I get
            help?
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="accordion_details">
          <Typography>
            Of course, If you let us know your requirements, we will custom
            quote the best options and price for you. Go to the Kalyani Motors
            website now!
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
