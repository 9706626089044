import React, { useState, useEffect } from 'react';
import { Await, useLocation } from 'react-router';
import {
	Typography,
	Grid,
	Box,
	Button,
	useMediaQuery,
	InputLabel,
	TextField,
	FormControl,
	MenuItem,
	Select,
	Modal,
	ListItem,
	ListItemText,
	List,
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import ApiHandler from '../../../../Api/Apihandler';
import AddressPopup from '../../../../Components/Modal/Modal';
import './EBook.css';
// import dayjs from "dayjs";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { useNavigate } from 'react-router';
import Footer from '../../../../Components/Footer/Footer';
import CloseIcon from '@mui/icons-material/Close';
import cloud_computing from '../../../../Assets/Images/Icons/cloud-computing.png';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import jwtEncode from 'jwt-encode';

export default function ServiceEbook() {
	const location = useLocation();

	const {
		subtitle,
		id,
		itemlist,
		btnTitle,
		phone,
		channel,
		cartTitle,
		cartId,
		cartImage,
		cartColor,
		cartChannel,
		image,
		price,
		panName,
		panNum,
		pan_image,
	} = location.state || {};

	const navigate = useNavigate();

	const [stepNo, setStepNo] = useState(1);
	const [city, setCity] = useState([]);
	const [cityLocation, setCityLocation] = useState([]);
	const [cityName, setCityName] = useState([]);
	const [cityLocationName, setCityLocationName] = useState([]);
	const [error, setError] = useState(null);
	const [carModel, setCarModel] = useState([]);
	const [modal, setModal] = useState(false);
	const [AddressList, setAddressList] = useState([]);
	const [addressId, setAddressId] = useState();
	const [addressListModal, setAddressListModal] = useState(false);
	const [autoFill, setAutoFill] = useState();
	const [selectedDate, setSelectedDate] = useState(null);
	const [selectedTime, setSelectedTime] = useState(null);
	const [basePic, setBasePic] = useState(null);

	const [users, setUsers] = useState({
		name: '',
		email: '',
		phonenumber: '',
	});

	const [otherUsers, setOtherUsers] = useState({
		name: '',
		email: '',
		phonenumber: '',
		pan_number: '',
		Relationship: '',
		pan_img: '',
	});

	const [outlet, setOutlet] = useState({
		city: '',
		locationCity: '',
		address: '',
		pincode: '',
		employeeCode: '',
		employeeName: '',
	});

	const [addAdress, setAddAddress] = useState({
		doorNo: '',
		area: '',
		city: '',
		landmark: '',
		pincode: '',
	});

	const [carDetail, setCarDetail] = useState({
		registrationNo: '',
		carModel: '',
		carVariant: '',
	});

	const [selectedFile, setSelectedFile] = useState(null);

	useEffect(() => {
		const id = localStorage.getItem('loggedIn');
		_getCity();
		_getAddress(id);
		setAddressId(id);
		_getProfile(loginId);
	}, []);

	const handleFileChange = (event) => {
		const file = event.target.files[0];

		if (file && file.name === selectedFile?.name) {
			document.getElementById('image-file-input').value = '';
		} else {
			setSelectedFile(file);
			upload();

			const reader = new FileReader();
			reader.onloadend = () => {
				let baseData = reader.result;
				const base64Data = baseData.split(',')[1];
				setBasePic(base64Data);
			};

			reader.readAsDataURL(file);
		}
	};

	const handleDelete = () => {
		setSelectedFile(null);
		document.getElementById('image-file-input').value = '';
	};

	const [profileData, setProfileData] = useState([]);
	const loginId = localStorage.getItem('loggedIn');

	const isSmallScreen = useMediaQuery('(max-width:600px)');
	const isbuyerScreen = useMediaQuery('(max-width:425px)');

	const _getAddress = async (id) => {
		const response = await ApiHandler.getAddress(id);
		try {
			const data = response.data.data;
			setAddressList(data);
		} catch (error) {
			console.log('getAdresserroe service', error);
		}
	};

	const _getModel = async () => {
		const response = await ApiHandler.getVarientsList('/' + 45);

		try {
			const data = response.data.data;
			setCarModel(data);
		} catch (error) {
			console.log('carmodal', error);
		}
	};

	const _getProfile = async () => {
		try {
			const response = await ApiHandler.getProfile(loginId);
			const data = response.data.data;
			setProfileData(data);
		} catch (error) {
			console.log('profile Data error', error);
		}
	};

	const toastStyle = {
		fontSize: '13px',
	};

	const name = () => {
		toast.error('Enter a name');
	};
	const mobile = () => {
		toast.error('Enter a valid mobile number.');
	};
	const email = () => {
		toast.error('Enter a valid email.');
	};
	const panNumber = () => {
		toast.error('Enter Valid PAN number', { style: toastStyle });
	};
	const relationship = () => {
		toast.error('Please enter a Relationship', { style: toastStyle });
	};

	const upload = () => {
		toast.success('Your PAN Image upload successfully!', {
			style: toastStyle,
		});
	};
	const uploadError = () => {
		toast.error('Please Upload PAN Image', {
			style: toastStyle,
		});
	};

	const validation = () => {
		if (
			users.name.length === 0 &&
			profileData[0].user.first_name.length === 0
		) {
			setError(1);
			name();
		} else if (
			!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\./i.test(
				users.email || profileData[0].user.email
			)
		) {
			setError(2);
			email();
		} else if (
			!/^\d{10}$/.test(
				(profileData[0] && profileData[0].phone) || users.phonenumber
			)
		) {
			setError(3);
			mobile();
		} else {
			setError(0);
			setStepNo(2);

			if (otherUsers.name.length === 0) {
				setError(10);
				name();
			} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\./i.test(otherUsers.email)) {
				setError(11);
				email();
			} else if (
				otherUsers.pan_number.length === 0 ||
				!otherUsers.pan_number.match('[A-Z]{5}[0-9]{4}[A-Z]{1}')
			) {
				setError(12);
				panNumber();
			} else if (otherUsers.Relationship.length === 0) {
				setError(13);
				relationship();
			} else if (!/^\d{10}$/.test(otherUsers.phonenumber)) {
				setError(14);
				mobile();
			} else if (!selectedFile) {
				setError(15);
				uploadError();
			} else {
				setError(0);
				setStepNo(2);
			}
		}
	};

	const registrationNo = () => {
		toast.error('Enter a valid registration number.');
	};

	const carModelSelect = () => {
		toast.error('Choose a car model.');
	};
	const carVariant = () => {
		toast.error('Enter a car variant');
	};

	const carDetailValidation = () => {
		if (carDetail.registrationNo.length !== 10) {
			setError(4);
			registrationNo();
		} else if (carDetail.carModel.length === 0) {
			setError(5);
			carModelSelect();
		} else if (carDetail.carVariant.length === 0) {
			setError(6);
			carVariant();
		} else {
			setError(0);
			setStepNo(3);
		}
	};

	const outletcity = () => toast.error('Select a city.');
	const outletlocation = () => toast.error('Select a outlet location');

	const _getCity = async () => {
		const response = await ApiHandler.getOutletCity();
		const data = response.data.data;
		try {
			setCity(data);
		} catch (error) {
			console.log('outletcityerror', error);
		}
	};

	const _getOutletLocation = async (city, channel, cartChannel) => {
		const response = await ApiHandler.getOutletLocation(
			city,
			channel || cartChannel
		);
		const data = response.data.data;
		try {
			setCityLocation(data);
		} catch (error) {
			console.log('citylocation', error);
		}
	};

	const addressValidation = () => {
		toast.error('Enter an address');
	};
	const pincodeValidation = () => {
		toast.error(' Enter a PIN code');
	};

	const dateValidation = () => {
		toast.error('Select a date');
	};

	const timeValidation = () => {
		toast.error('Select a time');
	};
	const refferedId = () => {
		toast.error('Please Enter a Employee Id');
	};

	const outletValidation = () => {
		if (outlet.city.length === 0) {
			setError(7);
			outletcity();
		} else if (outlet.locationCity.length === 0) {
			setError(8);
			outletlocation();
		} else if (selectedDate === null) {
			setError(11);
			dateValidation();
		} else if (selectedTime === null) {
			setError(12);
			timeValidation();
		} else if (outlet.address.length === 0 && !autoFill) {
			setError(9);
			addressValidation();
		}
		// else if (
		// 	!/^[0-9]*\d$/.test(outlet.pincode) ||
		// 	outlet.pincode.length !== 6
		// ) {
		// 	setError(10);
		// 	pincodeValidation();
		// }
		else if (
			outlet.employeeName.length > 0 &&
			outlet.employeeCode.length === 0
		) {
			setError(16);
			refferedId();
		} else {
			setError(0);
			handleBooking();
		}
	};

	const handleSelectChange = (event) => {
		const value = event.target.value;
		const selectedCity = city.filter((item) => item.id === value);
		setOutlet({ ...outlet, city: value });
		setCityName(selectedCity[0].title);
		_getOutletLocation(value, channel, cartChannel);
	};

	const handleOuletChange = (event) => {
		const value = event.target.value;
		const selectedCityLocation = cityLocation.filter(
			(item) => item.id === value
		);
		setOutlet({ ...outlet, locationCity: event.target.value });
		setCityLocationName(selectedCityLocation[0].name);
	};

	const handleCarModelChange = (event) => {
		const value = event.target.value;
		const selectedCarModel = carModel.filter((item) => {
			return item.title === value;
		});
		setCarDetail({ ...carDetail, carModel: value });
	};

	const _updateProfile = async () => {
		// const loginId = localStorage.getItem('loggedIn');
		const data = {
			phone: profileData[0] && profileData[0].phone,
			gender: profileData[0] && profileData[0].gender,
			year_of_birth: profileData[0] && profileData[0].year_of_birth,
			first_name:
				(profileData[0] && profileData[0].user.first_name) || users.name,
			lat: '216.321',
			long: '073.310',
			last_name: profileData[0] && profileData[0].user.last_name,
			email: (profileData[0] && profileData[0].user.email) || users.email,
			user: profileData[0] && profileData[0].user.id,
		};

		const response = await ApiHandler.updateProfile(data);
		try {
			if (response.data.status === 200) {
				// console.log('profile change', response.data);
			}
		} catch (error) {
			console.log('profile edit error', error);
		}
	};

	function formatDate(dateString) {
		const options = {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
		};

		const date = new Date(dateString);

		const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(
			date
		);

		// Split the formatted date using "/" and rearrange it in "year-month-day" format
		const parts = formattedDate.split('/');
		const formattedString = `${parts[2]}-${parts[1]}-${parts[0]}`;

		return formattedString;
	}

	function formatTime(dateString) {
		const options = {
			hour: 'numeric',
			minute: 'numeric',
			second: 'numeric',
			timeZone: 'UTC',
		};

		const date = new Date(dateString);
		const formattedTime = new Intl.DateTimeFormat('en-GB', options).format(
			date
		);

		return formattedTime;
	}

	const userId = parseInt(profileData[0]);

	const handleBooking = async () => {
		const serviceBookingData = {
			profile: profileData[0].id,
			itemlist: itemlist,
			created_at: '',
			payment_status: 'Success',
			payment_id: '',
			amount: 0,
			source: 'Service',
			item_description: id,
			address: autoFill.id,
			name: (profileData[0] && profileData[0].user.first_name) || users.name,
			email:
				users.email || (profileData[0] && profileData[0].user.email.trim()),
			mobile: users.phonenumber || (profileData[0] && profileData[0].phone),
			profilePanCard: null,
			profilePanName: null,
			profileBookedFor: btnTitle,
			profileRelationship: null,
			otherPanNumber: null,
			panPicOthers: null,
			booking_id: null,
			city: cityName.trim(),
			outlet: cityLocationName.trim(),
			model: carDetail.carModel.trim(),
			varient: carDetail.carVariant.trim(),
			color: 'Solid Fire Red',
			pickup_slot: formatDate(selectedDate) + ' ' + formatTime(selectedTime),
			// pickup_slot: "2023-04-19 10:10:00",
			item_price: 2500,
			employee_id: outlet.employeeCode,
			referred_by: outlet.employeeName,
			cust_id_or_reg_no: carDetail.registrationNo,
			loyalti_transaction_type: 'Add',
			gross_amount: 0,
			e_point: 0,
			i_point: 0,
			cgst_sgst: 0,
		};
		console.log('payload', serviceBookingData);
		const encoded_token = jwtEncode(serviceBookingData, 'secret');
		const tokenData = {
			token: encoded_token,
		};
		console.log('token', tokenData);
		const serviceResponse = await ApiHandler.carBookingJWT(tokenData);
		console.log('service response', serviceResponse);

		if (serviceResponse.data.status === 200) {
			// alert('Booking Success');
			navigate('/servicebooking_success');
		} else {
			console.log(
				'ebooking all details payment mode not getting',
				serviceResponse.data
			);
		}
	};

	return (
		<div>
			<Box sx={{ backgroundColor: '#eee' }}>
				<Grid
					container
					xs={12}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignContent: 'center',
					}}
				>
					<Grid
						item
						xs={12}
						sm={12}
						md={11}
						lg={10}
						xl={10}
						sx={{ backgroundColor: 'white', my: { xl: 10, lg: 10, md: 10 } }}
					>
						{' '}
						<Typography
							gutterBottom
							variant="h4"
							component="div"
							fontWeight={'700'}
							fontSize={{ lg: '23px', md: '22px', sm: '20px', xs: '18px' }}
							color={'#484848'}
							sx={{
								my: { lg: 2, xl: 2, md: 2 },
								py: { lg: 3, xl: 2, md: 2 },
								pt: { sm: 5, xs: 5 },
							}}
							textAlign={'center'}
							lineHeight={2}
						>
							E-Booking Services {cartTitle || subtitle}
						</Typography>
						<Box sx={{ flexGrow: 1 }}>
							<Grid
								container
								sx={{
									flexWrap: isSmallScreen ? 'wrap-reverse' : 'wrap',
									padding: isSmallScreen ? '10px' : '0px',
								}}
							>
								<Grid xs={12} lg={7} sm={12} md={6} className="mui">
									<Grid container>
										<Grid
											xs={4}
											className="tab_container"
											sx={{ flexDirection: 'column', my: 2 }}
										>
											<Button
												variant="contained"
												size="small"
												className="ebook_tab"
												onClick={() => setStepNo(1)}
												sx={{
													fontSize: {
														xs: '12px',
														sm: '12px',
														md: '14px',
														textTransform: 'none',
														marginTop: 10,
													},
													fontWeight: 500,
													letterSpacing: 0.5,
													backgroundImage:
														stepNo === 1
															? 'linear-gradient(60deg, #29323c 0%, #485563 100%)'
															: '#808080',
													backgroundColor: '#708090',
													'&:hover': { backgroundColor: '#708090' },
												}}
											>
												Step 1
											</Button>
										</Grid>
										<Grid xs={4} className="tab_container">
											<Button
												variant="contained"
												size="small"
												className="ebook_tab"
												onClick={() => validation()}
												sx={{
													fontSize: {
														xs: '12px',
														sm: '12px',
														md: '14px',
														textTransform: 'none',
														marginTop: 10,
													},
													fontWeight: 500,
													letterSpacing: 0.5,
													backgroundImage:
														stepNo === 2
															? 'linear-gradient(60deg, #29323c 0%, #485563 100%)'
															: '#708090',
													backgroundColor: '#708090',
													'&:hover': { backgroundColor: '#708090' },
												}}
											>
												Step 2
											</Button>
										</Grid>
										<Grid xs={4} className="tab_container">
											<Button
												variant="contained"
												size="small"
												className="ebook_tab"
												sx={{
													fontSize: {
														xs: '12px',
														sm: '12px',
														md: '14px',
														textTransform: 'none',
														marginTop: 10,
													},
													fontWeight: 500,
													letterSpacing: 0.5,
													backgroundImage:
														stepNo === 3
															? 'linear-gradient(60deg, #29323c 0%, #485563 100%)'
															: '#708090',
													backgroundColor: '#708090',
													'&:hover': { backgroundColor: '#708090' },
												}}
											>
												Step 3
											</Button>
										</Grid>
									</Grid>
									{stepNo === 1 && (
										<div
											// style={{
											//   display: "flex",
											//   justifyContent: "center",
											//   alignItems: "center",
											//   flexDirection: "column",
											//   height: "auto",
											//   overflowY: "scroll",
											// }}
											className="Others_container_personal_Details"
										>
											<Typography
												gutterBottom
												variant="h4"
												component="div"
												fontWeight={'700'}
												fontSize={{
													lg: '20px',
													md: '20px',
													sm: '20px',
													xs: '18px',
												}}
												color={'#484848'}
												sx={{ my: 2 }}
												textAlign={'center'}
											>
												PERSONAL DETAILS <span style={{ color: 'red' }}>*</span>
											</Typography>
											{btnTitle === 'Others' ? (
												<Box
													className="e-book_personal_details"
													sx={{
														display: 'flex',
														justifyContent: 'center',
														alignItems: 'center',
													}}
												>
													<Grid
														container
														spacing={2}
														style={{ justifyContent: 'center' }}
													>
														<Grid item xs={12}>
															<Grid
																container
																spacing={2}
																style={{ justifyContent: 'center' }}
															>
																<Grid item xs={12} sm={5} xl={5} md={5} lg={5}>
																	<InputLabel
																		sx={{
																			fontSize: {
																				xs: '13px',
																				md: '13px',
																				lg: '13px',
																				fontWeight: 700,
																			},
																			mb: 1,
																		}}
																	>
																		Name*
																	</InputLabel>
																	{error === 10 ? (
																		<div>
																			{/* <ToastContainer
																				position="top-right"
																				autoClose={5000}
																				hideProgressBar={false}
																				newestOnTop={false}
																				closeOnClick
																				rtl={false}
																				pauseOnFocusLoss
																				draggable
																				pauseOnHover
																				placeholder="Name"
																				progressStyle={{
																					background:
																						'linear-gradient(to right, #aa4b6b, #6b6b83, #3b8d99)',
																				}}
																				theme="light"
																			/> */}
																			<TextField
																				fullWidth
																				autoComplete="off"
																				id="outlined-basic"
																				label=""
																				size="small"
																				placeholder="Name"
																				value={otherUsers.name}
																				onChange={(e) => {
																					const inputValue = e.target.value;
																					const correctInput =
																						inputValue.replace(
																							/[^A-za-z\s]/g,
																							''
																						);
																					if (!correctInput.startsWith(' ')) {
																						setOtherUsers({
																							...otherUsers,
																							name: correctInput,
																						});
																					}
																				}}
																				variant="outlined"
																			/>
																		</div>
																	) : (
																		<TextField
																			fullWidth
																			autoComplete="off"
																			id="outlined-basic"
																			label=""
																			size="small"
																			placeholder="Name"
																			value={otherUsers.name}
																			onChange={(e) => {
																				const inputValue = e.target.value;
																				const correctInput = inputValue.replace(
																					/[^A-za-z\s]/g,
																					''
																				);
																				if (!correctInput.startsWith(' ')) {
																					setOtherUsers({
																						...otherUsers,
																						name: correctInput,
																					});
																				}
																			}}
																			variant="outlined"
																		/>
																	)}
																</Grid>
																<Grid item xs={12} sm={5} xl={5} md={5} lg={5}>
																	<InputLabel
																		sx={{
																			fontSize: {
																				xs: '13px',
																				md: '13px',
																				lg: '13px',
																				fontWeight: 700,
																			},
																			mb: 1,
																		}}
																	>
																		Email*
																	</InputLabel>
																	{error === 11 ? (
																		<div>
																			<TextField
																				fullWidth
																				autoComplete="off"
																				label=""
																				size="small"
																				variant="outlined"
																				placeholder="Email"
																				value={otherUsers.email}
																				onChange={(e) =>
																					setOtherUsers({
																						...otherUsers,
																						email: e.target.value,
																					})
																				}
																			/>
																		</div>
																	) : (
																		<TextField
																			fullWidth
																			autoComplete="off"
																			id="outlined-basic"
																			label=""
																			size="small"
																			variant="outlined"
																			placeholder="Email"
																			value={otherUsers.email}
																			onChange={(e) =>
																				setOtherUsers({
																					...otherUsers,
																					email: e.target.value,
																				})
																			}
																		/>
																	)}
																</Grid>
																<Grid item xs={12} sm={5} xl={5} md={5} lg={5}>
																	<InputLabel
																		sx={{
																			fontSize: {
																				xs: '13px',
																				md: '13px',
																				lg: '13px',
																				fontWeight: 700,
																			},
																			mb: 1,
																		}}
																	>
																		PAN Number*
																	</InputLabel>
																	{error === 12 ? (
																		<div>
																			<TextField
																				fullWidth
																				autoComplete="off"
																				label=""
																				size="small"
																				variant="outlined"
																				placeholder="PAN Number"
																				value={otherUsers.pan_number}
																				onChange={(e) => {
																					const input = e.target.value;
																					const sanIniInput = input.replace(
																						/[^A-Za-z0-9]/g,
																						''
																					);
																					if (sanIniInput.length <= 10) {
																						setOtherUsers({
																							...otherUsers,
																							pan_number:
																								sanIniInput.toUpperCase(),
																						});
																					}
																				}}
																			/>
																		</div>
																	) : (
																		<TextField
																			fullWidth
																			autoComplete="off"
																			id="outlined-basic"
																			label=""
																			size="small"
																			variant="outlined"
																			placeholder="PAN Number"
																			value={otherUsers.pan_number}
																			onChange={(e) => {
																				const input = e.target.value;
																				const sanIniInput = input.replace(
																					/[^A-Za-z0-9]/g,
																					''
																				);
																				if (sanIniInput.length <= 10) {
																					setOtherUsers({
																						...otherUsers,
																						pan_number:
																							sanIniInput.toUpperCase(),
																					});
																				}
																			}}
																		/>
																	)}
																</Grid>
																<Grid item xs={12} sm={5} xl={5} md={5} lg={5}>
																	<InputLabel
																		sx={{
																			fontSize: {
																				xs: '13px',
																				md: '13px',
																				lg: '13px',
																				fontWeight: 700,
																			},
																			mb: 1,
																		}}
																	>
																		Relationship*
																	</InputLabel>
																	{error === 13 ? (
																		<div>
																			<TextField
																				fullWidth
																				autoComplete="off"
																				label=""
																				size="small"
																				variant="outlined"
																				placeholder="Relationship"
																				value={otherUsers.Relationship}
																				onChange={(e) => {
																					const input = e.target.value;
																					const correctInput = input.replace(
																						/[^A-za-z\s]/g,
																						''
																					);
																					if (!correctInput.startsWith(' ')) {
																						setOtherUsers({
																							...otherUsers,
																							Relationship: correctInput,
																						});
																					}
																				}}
																			/>
																		</div>
																	) : (
																		<TextField
																			fullWidth
																			autoComplete="off"
																			id="outlined-basic"
																			label=""
																			size="small"
																			placeholder="Relationship"
																			variant="outlined"
																			value={otherUsers.Relationship}
																			onChange={(e) => {
																				const input = e.target.value;
																				const correctInput = input.replace(
																					/[^A-za-z\s]/g,
																					''
																				);
																				if (!correctInput.startsWith(' ')) {
																					setOtherUsers({
																						...otherUsers,
																						Relationship: correctInput,
																					});
																				}
																			}}
																		/>
																	)}
																</Grid>
																<Grid item xs={12} sm={8} xl={8} md={8} lg={8}>
																	<InputLabel
																		sx={{
																			fontSize: {
																				xs: '13px',
																				md: '13px',
																				lg: '13px',
																				fontWeight: 700,
																			},
																			mb: 1,
																		}}
																	>
																		Phone Number*
																	</InputLabel>
																	{error === 14 ? (
																		<div style={{ marginRight: 4 }}>
																			<TextField
																				fullWidth
																				autoComplete="off"
																				label=""
																				size="small"
																				variant="outlined"
																				placeholder="Phone Number"
																				value={otherUsers.phonenumber}
																				onChange={(e) => {
																					const input = e.target.value;
																					const sanIniInput = input.replace(
																						/[^0-9]/g,
																						''
																					);
																					setOtherUsers({
																						...otherUsers,
																						phonenumber: sanIniInput,
																					});
																				}}
																			/>
																		</div>
																	) : (
																		<TextField
																			fullWidth
																			autoComplete="off"
																			id="outlined-basic"
																			label=""
																			size="small"
																			variant="outlined"
																			placeholder="Phone Number"
																			value={otherUsers.phonenumber}
																			inputProps={{ maxLength: 10 }}
																			onChange={(e) => {
																				const input = e.target.value;
																				const sanIniInput = input.replace(
																					/[^0-9]/g,
																					''
																				);
																				setOtherUsers({
																					...otherUsers,
																					phonenumber: sanIniInput,
																				});
																			}}
																		/>
																	)}
																</Grid>
																<Grid
																	item
																	xs={12}
																	sm={10}
																	xl={10}
																	md={10}
																	lg={10}
																>
																	{error === 15 ? (
																		<div>
																			<Box
																				pt={1}
																				pb={3}
																				px={3}
																				// border="1px dashed #000000"
																				// borderRadius={2}
																				textAlign="center"
																				sx={{
																					display: 'flex',
																					flexDirection: 'column',
																					justifyContent: 'center',
																					alignContent: 'center',
																					background: '#f3f3fe',
																					mt: 1,
																				}}
																			>
																				<InputLabel
																					sx={{
																						fontSize: {
																							xs: '13px',
																							md: '13px',
																							lg: '13px',
																							fontWeight: 700,
																						},
																						pb: 1,
																						textAlign: 'start',
																					}}
																				>
																					Upload Image{' '}
																					<span style={{ color: 'red' }}>
																						*
																					</span>
																				</InputLabel>
																				<div
																					style={{
																						border: '1px dashed #000000',
																						borderRadius: '3px',
																						background: 'white',
																						padding: '15px',
																					}}
																				>
																					<div style={{ textAlign: 'center' }}>
																						<img
																							src={cloud_computing}
																							style={{
																								height: '40px',
																								width: '40px',
																							}}
																						/>
																					</div>

																					<input
																						type="file"
																						accept="image/*"
																						onChange={handleFileChange}
																						style={{ display: 'none' }}
																						id="image-file-input"
																					/>
																					<label htmlFor="image-file-input">
																						<Button
																							sx={{
																								fontWeight: 600,
																								fontSize: '12px',
																								lineHeight: '18px',
																								letterSpacing: '.01em',
																								textTransform: 'none',
																							}}
																							component="span"
																						>
																							Upload a File
																						</Button>
																					</label>
																				</div>
																				{selectedFile && (
																					<div
																						style={{
																							display: 'flex',
																							justifyContent: 'space-between',
																							marginTop: '6px',
																							background: '#dae6ff',
																						}}
																					>
																						<Typography variant="subtitle1">
																							{selectedFile.name}
																						</Typography>
																						<DeleteOutlineIcon
																							sx={{
																								color: 'red',
																								cursor: 'pointer',
																							}}
																							onClick={handleDelete}
																						/>
																					</div>
																				)}
																			</Box>
																		</div>
																	) : (
																		<div>
																			<Box
																				pt={1}
																				pb={3}
																				px={3}
																				// border="1px dashed #000000"
																				// borderRadius={2}
																				textAlign="center"
																				sx={{
																					display: 'flex',
																					flexDirection: 'column',
																					justifyContent: 'center',
																					alignContent: 'center',
																					background: '#f3f3fe',
																					mt: 1,
																				}}
																			>
																				<InputLabel
																					sx={{
																						fontSize: {
																							xs: '13px',
																							md: '13px',
																							lg: '13px',
																							fontWeight: 700,
																						},
																						pb: 1,
																						textAlign: 'start',
																					}}
																				>
																					Upload Image{' '}
																					<span style={{ color: 'red' }}>
																						*
																					</span>
																				</InputLabel>
																				<div
																					style={{
																						border: '1px dashed #000000',
																						borderRadius: '3px',
																						background: 'white',
																						padding: '15px',
																					}}
																				>
																					<div style={{ textAlign: 'center' }}>
																						<img
																							src={cloud_computing}
																							style={{
																								height: '40px',
																								width: '40px',
																							}}
																						/>
																					</div>

																					<input
																						type="file"
																						accept="image/*"
																						onChange={handleFileChange}
																						style={{ display: 'none' }}
																						id="image-file-input"
																					/>
																					<label htmlFor="image-file-input">
																						<Button
																							sx={{
																								fontWeight: 600,
																								fontSize: '12px',
																								lineHeight: '18px',
																								letterSpacing: '.01em',
																								textTransform: 'none',
																							}}
																							component="span"
																						>
																							Upload a File
																						</Button>
																					</label>
																				</div>
																				{selectedFile && (
																					<div
																						style={{
																							display: 'flex',
																							justifyContent: 'space-between',
																							marginTop: '6px',
																							background: '#dae6ff',
																						}}
																					>
																						<Typography variant="subtitle1">
																							{selectedFile.name}
																						</Typography>
																						<DeleteOutlineIcon
																							sx={{
																								color: 'red',
																								cursor: 'pointer',
																							}}
																							onClick={handleDelete}
																						/>
																					</div>
																				)}
																			</Box>
																		</div>
																	)}
																</Grid>
															</Grid>
														</Grid>
														<Grid container></Grid>
													</Grid>
												</Box>
											) : (
												<Box
													className="e-book_personal_details"
													sx={{
														display: 'flex',
														justifyContent: 'center',
														alignItems: 'center',
													}}
												>
													<Grid
														container
														spacing={2}
														style={{ justifyContent: 'center' }}
													>
														<Grid item xs={12}>
															<Grid
																container
																spacing={2}
																style={{ justifyContent: 'center' }}
															>
																<Grid item xs={12} sm={5} xl={5} md={5} lg={5}>
																	<InputLabel
																		sx={{
																			fontSize: {
																				xs: '13px',
																				md: '13px',
																				lg: '13px',
																				fontWeight: 700,
																			},
																			mb: 1,
																		}}
																	>
																		Name*
																	</InputLabel>
																	{error === 1 ? (
																		<div>
																			<TextField
																				fullWidth
																				autoComplete="off"
																				id="outlined-basic"
																				label=""
																				size="small"
																				value={
																					(profileData[0] &&
																						profileData[0].user.first_name) ||
																					users.name
																				}
																				// onChange={(e) =>
																				//   setUsers({
																				//     ...users,
																				//     name: e.target.value,
																				//   })
																				// }
																				onChange={(e) => {
																					const newValue = e.target.value;
																					setUsers((prevUserDetail) => ({
																						...prevUserDetail,
																						first_name: newValue,
																					}));
																					if (profileData[0]) {
																						const updatedProfileData = [
																							...profileData,
																						];
																						updatedProfileData[0].user.first_name =
																							newValue;
																						setProfileData(updatedProfileData);
																					}
																				}}
																				variant="outlined"
																			/>
																		</div>
																	) : (
																		<TextField
																			fullWidth
																			autoComplete="off"
																			id="outlined-basic"
																			label=""
																			size="small"
																			value={
																				(profileData[0] &&
																					profileData[0].user.first_name) ||
																				users.name
																			}
																			// onChange={(e) =>
																			// 	setUsers({
																			// 		...users,
																			// 		name: e.target.value,
																			// 	})
																			// }
																			onChange={(e) => {
																				const newValue = e.target.value;
																				setUsers((prevUserDetail) => ({
																					...prevUserDetail,
																					first_name: newValue,
																				}));
																				if (profileData[0]) {
																					const updatedProfileData = [
																						...profileData,
																					];
																					updatedProfileData[0].user.first_name =
																						newValue;
																					setProfileData(updatedProfileData);
																				}
																			}}
																			variant="outlined"
																		/>
																	)}
																</Grid>
																<Grid item xs={12} sm={5} xl={5} md={5} lg={5}>
																	<InputLabel
																		sx={{
																			fontSize: {
																				xs: '13px',
																				md: '13px',
																				lg: '13px',
																				fontWeight: 700,
																			},
																			mb: 1,
																		}}
																	>
																		Email*
																	</InputLabel>
																	{error === 2 ? (
																		<div>
																			<TextField
																				fullWidth
																				autoComplete="off"
																				label=""
																				size="small"
																				variant="outlined"
																				value={
																					(profileData[0] &&
																						profileData[0].user.email) ||
																					users.email
																				}
																				// onChange={(e) =>
																				// 	setUsers({
																				// 		...users,
																				// 		email: e.target.value,
																				// 	})
																				// }

																				onChange={(e) => {
																					const newValue = e.target.value;
																					setUsers((prevUserDetail) => ({
																						...prevUserDetail,
																						email: newValue,
																					}));

																					if (profileData[0]) {
																						const updatedProfileData = [
																							...profileData,
																						];
																						updatedProfileData[0].user.email =
																							newValue;
																						setProfileData(updatedProfileData);
																					}
																				}}
																			/>
																		</div>
																	) : (
																		<TextField
																			fullWidth
																			autoComplete="off"
																			id="outlined-basic"
																			label=""
																			size="small"
																			variant="outlined"
																			value={
																				(profileData[0] &&
																					profileData[0].user.email) ||
																				users.email
																			}
																			onChange={(e) => {
																				const newValue = e.target.value;
																				setUsers((prevUserDetail) => ({
																					...prevUserDetail,
																					email: newValue,
																				}));
																				if (profileData[0]) {
																					const updatedProfileData = [
																						...profileData,
																					];
																					updatedProfileData[0].user.email =
																						newValue;
																					setProfileData(updatedProfileData);
																				}
																			}}
																		/>
																	)}
																</Grid>
															</Grid>
														</Grid>
														<Grid item xs={12} sm={11} xl={6} md={6} lg={6}>
															<InputLabel
																sx={{
																	fontSize: {
																		xs: '13px',
																		md: '13px',
																		lg: '13px',
																		fontWeight: 700,
																	},
																	mb: 1,
																}}
															>
																Phone Number*
															</InputLabel>
															{error === 3 ? (
																<div style={{ marginRight: 4 }}>
																	<TextField
																		fullWidth
																		autoComplete="off"
																		label=""
																		size="small"
																		variant="outlined"
																		value={
																			(profileData[0] &&
																				profileData[0].phone) ||
																			users.phonenumber
																		}
																		onChange={(e) =>
																			setUsers({
																				...users,
																				phonenumber: e.target.value,
																			})
																		}
																		// onChange={(e) => {
																		// 	const newValue = e.target.value;
																		// 	setUsers((prevUserDetail) => ({
																		// 		...prevUserDetail,
																		// 		phonenumber: newValue,
																		// 	}));

																		// 	if (profileData[0]) {
																		// 		const updatedProfileData = [
																		// 			...profileData,
																		// 		];
																		// 		updatedProfileData[0].phone = newValue;
																		// 		setProfileData(updatedProfileData);
																		// 	}
																		// }}
																	/>
																</div>
															) : (
																<TextField
																	fullWidth
																	autoComplete="off"
																	id="outlined-basic"
																	label=""
																	size="small"
																	variant="outlined"
																	inputProps={{ maxLength: 10 }}
																	value={
																		(profileData[0] && profileData[0].phone) ||
																		users.phonenumber
																	}
																	onChange={(e) =>
																		setUsers({
																			...users,
																			phonenumber: e.target.value,
																		})
																	}
																	// onChange={(e) => {
																	// 	const newValue = e.target.value;
																	// 	setUsers((prevUserDetail) => ({
																	// 		...prevUserDetail,
																	// 		phonenumber: newValue,
																	// 	}));

																	// 	if (profileData[0]) {
																	// 		const updatedProfileData = [...profileData];
																	// 		updatedProfileData[0].phone = newValue;
																	// 		setProfileData(updatedProfileData);
																	// 	}
																	// }}
																/>
															)}
														</Grid>
													</Grid>
												</Box>
											)}
											<div>
												<Typography
													gutterBottom
													variant="h4"
													component="div"
													fontWeight={'700'}
													fontSize={{
														lg: '14px',
														md: '14px',
														sm: '14px',
														xs: '14px',
													}}
													color={'#484848'}
													sx={{ mt: 4 }}
													textAlign={'center'}
												>
													The fields markes as * are mandatory
												</Typography>
											</div>

											<div className="ebook_btn_service">
												<Button
													variant="contained"
													size="small"
													className="ebook_submit_service"
													onClick={() => {
														// handlePersonalClick();
														validation();
														_updateProfile();
														_getModel(45);
													}}
													sx={{
														textTransform: 'none',
														marginTop: 4,
														fontWeight: 500,
														letterSpacing: 0.5,
														fontWeight: 500,
														letterSpacing: 0.5,
														textAlign: 'center',
														height: '44px',
														width: isSmallScreen ? '150px' : '200px',
														fontWeight: 600,
														fontSize: '12px',
														lineHeight: '18px',
														letterSpacing: '.01em',
														borderRadius: '12px !important',
														boxShadow: 'none !important',
													}}
												>
													Save & Continue
												</Button>
											</div>
										</div>
									)}
									{stepNo === 2 && (
										<div className="otherdetails_container_service">
											{' '}
											<div
												style={{
													display: 'flex',
													justifyContent: 'start',
													alignItems: 'start',
													paddingTop: 10,
												}}
											>
												<Box sx={{ flexGrow: 1, gap: 10 }}>
													<Grid container>
														<Grid xs={2} sx={{}}>
															<div className="numberCount_service">
																<span style={{ color: 'white' }}>1</span>
															</div>
														</Grid>
														<Grid
															xs={10}
															sx={{
																display: 'flex',
																alignItems: 'start',
																flexDirection: 'column',
															}}
															pr={{ sm: 2, xs: 2 }}
														>
															<Typography
																gutterBottom
																variant="h4"
																component="div"
																fontWeight={'700'}
																fontSize={{
																	lg: '16px',
																	md: '16px',
																	sm: '16px',
																	xs: '16px',
																}}
																color={'#484848'}
																textAlign={'start'}
																my={1}
															>
																SERVICE DETAILS
																<span style={{ color: 'red' }}>*</span>
															</Typography>
															<div style={{ width: '100%' }}>
																<InputLabel
																	sx={{
																		fontSize: {
																			xs: '13px',
																			md: '13px',
																			lg: '13px',
																			fontWeight: 700,
																		},
																		mb: 1,
																	}}
																>
																	Your Service Name*
																</InputLabel>
																<FormControl
																	sx={{
																		width: {
																			xs: '100%',
																			sm: '60%',
																			md: '60%',
																			xl: '60%',
																			lg: '60%',
																		},
																	}}
																>
																	<TextField
																		id="outlined-basic"
																		defaultValue={cartTitle || subtitle}
																		autoComplete="off"
																		label=""
																		variant="outlined"
																		size="small"
																	/>
																</FormControl>
															</div>
														</Grid>
													</Grid>
												</Box>
											</div>
											<div
												style={{
													display: 'flex',
													justifyContent: 'start',
													alignItems: 'start',
													paddingTop: 10,
												}}
											>
												<Box sx={{ flexGrow: 1, gap: 10 }}>
													<Grid container>
														<Grid xs={2} sx={{}}>
															<div className="numberCount_service">
																<span style={{ color: 'white' }}>2</span>
															</div>
														</Grid>
														<Grid
															xs={10}
															sx={{
																display: 'flex',
																alignItems: 'start',
																flexDirection: 'column',
															}}
															pr={{ sm: 2, xs: 2 }}
														>
															<Typography
																gutterBottom
																variant="h4"
																component="div"
																fontWeight={'700'}
																fontSize={{
																	lg: '16px',
																	md: '16px',
																	sm: '16px',
																	xs: '16px',
																}}
																color={'#484848'}
																textAlign={'start'}
																my={1}
															>
																CAR DETAILS
																<span style={{ color: 'red' }}>*</span>
															</Typography>
															<div style={{ width: '100%' }}>
																<InputLabel
																	sx={{
																		fontSize: {
																			xs: '13px',
																			md: '13px',
																			lg: '13px',
																			fontWeight: 700,
																		},
																		mb: 1,
																	}}
																>
																	Registration No*
																</InputLabel>

																{error === 4 ? (
																	<div>
																		<TextField
																			id="outlined-basic"
																			autoComplete="off"
																			label=""
																			variant="outlined"
																			size="small"
																			value={carDetail.registrationNo}
																			onChange={(e) => {
																				const input = e.target.value;
																				const sanitizedInput = input.replace(
																					/[^A-Za-z0-9]/g,
																					''
																				);
																				if (sanitizedInput.length <= 10) {
																					setCarDetail({
																						...carDetail,
																						registrationNo:
																							sanitizedInput.toUpperCase,
																					});
																				}
																			}}
																			sx={{
																				width: {
																					xs: '100%',
																					sm: '60%',
																					md: '60%',
																					xl: '60%',
																					lg: '60%',
																				},
																			}}
																		/>
																	</div>
																) : (
																	<TextField
																		id="outlined-basic"
																		autoComplete="off"
																		label=""
																		variant="outlined"
																		size="small"
																		value={carDetail.registrationNo}
																		onChange={(e) => {
																			const input = e.target.value;
																			const sanitizedInput = input.replace(
																				/[^A-Za-z0-9]/g,
																				''
																			);
																			if (sanitizedInput.length <= 10) {
																				setCarDetail({
																					...carDetail,
																					registrationNo:
																						sanitizedInput.toLocaleUpperCase(),
																				});
																			}
																			// setCarDetail({
																			//   ...carDetail,
																			//   registrationNo: e.target.value,
																			// });
																		}}
																		sx={{
																			width: {
																				xs: '100%',
																				sm: '60%',
																				md: '60%',
																				xl: '60%',
																				lg: '60%',
																			},
																		}}
																	/>
																)}
															</div>
															<div style={{ width: '100%' }}>
																<InputLabel
																	sx={{
																		fontSize: {
																			xs: '13px',
																			md: '13px',
																			lg: '13px',
																			fontWeight: 700,
																		},
																		mb: 1,
																	}}
																>
																	Select Your Model*
																</InputLabel>
																{error === 5 ? (
																	<div>
																		<FormControl
																			sx={{
																				width: {
																					xs: '100%',
																					sm: '60%',
																					md: '60%',
																					xl: '60%',
																					lg: '60%',
																				},
																			}}
																		>
																			<Select
																				onChange={(event) => {
																					handleCarModelChange(event);
																				}}
																				displayEmpty
																				value={carDetail.carModel}
																				size="small"
																				inputProps={{
																					'aria-label': 'Without label',
																				}}
																				MenuProps={{
																					PaperProps: {
																						sx: { maxHeight: 200 },
																					},
																				}}
																			>
																				{carModel.map((model, i) => {
																					return (
																						<MenuItem
																							key={model.id}
																							value={model.title}
																						>
																							{model.title}
																						</MenuItem>
																					);
																				})}
																			</Select>
																		</FormControl>
																	</div>
																) : (
																	<FormControl
																		sx={{
																			width: {
																				xs: '100%',
																				sm: '60%',
																				md: '60%',
																				xl: '60%',
																				lg: '60%',
																			},
																		}}
																	>
																		<Select
																			onChange={(event) => {
																				handleCarModelChange(event);
																			}}
																			displayEmpty
																			value={carDetail.carModel}
																			size="small"
																			inputProps={{
																				'aria-label': 'Without label',
																			}}
																			MenuProps={{
																				PaperProps: { sx: { maxHeight: 200 } },
																			}}
																		>
																			{carModel.map((model, i) => {
																				return (
																					<MenuItem
																						key={model.id}
																						value={model.title}
																					>
																						{model.title}
																					</MenuItem>
																				);
																			})}
																		</Select>
																	</FormControl>
																)}
															</div>
															<div style={{ width: '100%' }}>
																<InputLabel
																	sx={{
																		fontSize: {
																			xs: '13px',
																			md: '13px',
																			lg: '13px',
																			fontWeight: 700,
																		},
																		mb: 1,
																		mt: 1,
																	}}
																>
																	Car Variant*
																</InputLabel>
																{error === 6 ? (
																	<div>
																		<FormControl
																			sx={{
																				width: {
																					xs: '100%',
																					sm: '60%',
																					md: '60%',
																					xl: '60%',
																					lg: '60%',
																				},
																			}}
																		>
																			<TextField
																				id="outlined-basic"
																				autoComplete="off"
																				label=""
																				variant="outlined"
																				size="small"
																				value={carDetail.carVariant}
																				onChange={(e) => {
																					const input = e.target.value;
																					if (!input.startsWith(' ')) {
																						setCarDetail({
																							...carDetail,
																							carVariant: input,
																						});
																					}
																				}}
																			/>
																		</FormControl>
																	</div>
																) : (
																	<FormControl
																		sx={{
																			width: {
																				xs: '100%',
																				sm: '60%',
																				md: '60%',
																				xl: '60%',
																				lg: '60%',
																			},
																		}}
																	>
																		<TextField
																			id="outlined-basic"
																			autoComplete="off"
																			label=""
																			variant="outlined"
																			size="small"
																			value={carDetail.carVariant}
																			onChange={(e) => {
																				const input = e.target.value;
																				if (!input.startsWith(' ')) {
																					setCarDetail({
																						...carDetail,
																						carVariant: input,
																					});
																				}
																			}}
																		/>
																	</FormControl>
																)}
															</div>
															<div>
																<Typography
																	gutterBottom
																	variant="h4"
																	component="div"
																	fontWeight={'700'}
																	fontSize={{
																		lg: '14px',
																		md: '14px',
																		sm: '14px',
																		xs: '14px',
																	}}
																	color={'#484848'}
																	sx={{ mt: 2 }}
																	textAlign={'start'}
																>
																	The fields markes as * are mandatory
																</Typography>
															</div>
															<div className="ebook_btn_service">
																<Button
																	variant="contained"
																	size="small"
																	className="ebook_submit_service"
																	onClick={() => {
																		carDetailValidation();
																	}}
																	sx={{
																		textTransform: 'none',
																		marginTop: 4,
																		fontWeight: 500,
																		letterSpacing: 0.5,
																		fontWeight: 500,
																		letterSpacing: 0.5,
																		textAlign: 'center',
																		height: '44px',
																		width: isSmallScreen ? '150px' : '200px',
																		fontWeight: 600,
																		fontSize: '12px',
																		lineHeight: '18px',
																		letterSpacing: '.01em',
																		borderRadius: '12px !important',
																		boxShadow: 'none !important',
																	}}
																>
																	{' '}
																	Confirm & Continue
																</Button>
															</div>
														</Grid>
													</Grid>
												</Box>
											</div>
										</div>
									)}
									{stepNo === 3 && (
										<div className="otherdetails_container_service">
											<Typography
												gutterBottom
												variant="h4"
												component="div"
												fontWeight={'700'}
												fontSize={{
													lg: '20px',
													md: '20px',
													sm: '20px',
													xs: '18px',
												}}
												color={'#484848'}
												sx={{ my: 2, py: 3 }}
												textAlign={'center'}
											>
												OTHERS DETAILS <span style={{ color: 'red' }}>*</span>
											</Typography>
											<div
												style={{
													display: 'flex',
													justifyContent: 'space-between',
													alignItems: 'stretch',
													flexDirection: 'column',
													gap: 10,
												}}
											>
												<Box
													sx={{ flexGrow: 1, gap: 10 }}
													pr={{ sm: 2, xs: 2 }}
												>
													<Grid container>
														<Grid xs={2} sx={{}}>
															<div className="numberCount_service">
																<span style={{ color: 'white' }}>1</span>
															</div>
														</Grid>
														<Grid
															xs={10}
															sx={{
																display: 'flex',
																alignItems: 'start',
																flexDirection: 'column',
															}}
														>
															<Typography
																gutterBottom
																variant="h4"
																component="div"
																fontWeight={'700'}
																fontSize={{
																	lg: '16px',
																	md: '16px',
																	sm: '16px',
																	xs: '16px',
																}}
																color={'#484848'}
																textAlign={'start'}
																my={1}
															>
																OUTLET DETAILS{' '}
																<span style={{ color: 'red' }}>*</span>
															</Typography>
															<Box
																sx={{
																	width: {
																		xs: '100%',
																		sm: '60%',
																		md: '60%',
																		xl: '60%',
																		lg: '60%',
																	},
																}}
															>
																{' '}
																<InputLabel
																	sx={{
																		fontSize: {
																			xs: '13px',
																			md: '13px',
																			lg: '13px',
																			fontWeight: 700,
																		},
																		my: 1,
																	}}
																>
																	Select Your City*
																</InputLabel>
																{error === 7 ? (
																	<FormControl fullwidth sx={{ width: '100%' }}>
																		<Select
																			displayEmpty
																			size="small"
																			value={outlet.city}
																			inputProps={{
																				'aria-label': 'Without label',
																			}}
																			onChange={(event) =>
																				handleSelectChange(event)
																			}
																			MenuProps={{
																				PaperProps: { sx: { maxHeight: 200 } },
																			}}
																			autoComplete="true"
																		>
																			{city.map((item, index) => (
																				<MenuItem
																					key={item.id}
																					value={item.title}
																				>
																					{item.title}
																				</MenuItem>
																			))}
																		</Select>
																	</FormControl>
																) : (
																	<FormControl sx={{ width: '100%' }}>
																		<Select
																			displayEmpty
																			size="small"
																			value={outlet.city}
																			inputProps={{
																				'aria-label': 'Without label',
																			}}
																			onChange={(event) =>
																				handleSelectChange(event)
																			}
																			MenuProps={{
																				PaperProps: { sx: { maxHeight: 200 } },
																			}}
																			autoComplete="true"
																		>
																			{city.map((item, index) => (
																				<MenuItem
																					key={item.id}
																					value={item.id}
																					cities={item.title}
																				>
																					{item.title}
																				</MenuItem>
																			))}
																		</Select>
																	</FormControl>
																)}
															</Box>
															<Box
																sx={{
																	width: {
																		xs: '100%',
																		sm: '60%',
																		md: '60%',
																		xl: '60%',
																		lg: '60%',
																	},
																}}
															>
																<InputLabel
																	sx={{
																		fontSize: {
																			xs: '13px',
																			md: '13px',
																			lg: '13px',
																			fontWeight: 700,
																		},
																		my: 1,
																	}}
																>
																	Select Your Outlet*
																</InputLabel>
																{error === 8 ? (
																	<FormControl sx={{ width: '100%' }}>
																		<Select
																			onChange={handleOuletChange}
																			displayEmpty
																			value={outlet.locationCity}
																			size="small"
																			inputProps={{
																				'aria-label': 'Without label',
																			}}
																			MenuProps={{
																				PaperProps: { sx: { maxHeight: 200 } },
																			}}
																		>
																			{cityLocation.map((item, index) => (
																				<MenuItem value={item.id} key={item.id}>
																					{item.name}
																				</MenuItem>
																			))}
																		</Select>
																	</FormControl>
																) : (
																	<FormControl sx={{ width: '100%' }}>
																		<Select
																			onChange={handleOuletChange}
																			displayEmpty
																			value={outlet.locationCity}
																			size="small"
																			inputProps={{
																				'aria-label': 'Without label',
																			}}
																			MenuProps={{
																				PaperProps: { sx: { maxHeight: 200 } },
																			}}
																		>
																			{cityLocation &&
																				cityLocation.map((item, index) => (
																					<MenuItem
																						value={item.id}
																						key={item.id}
																					>
																						{item.name}
																					</MenuItem>
																				))}
																		</Select>
																	</FormControl>
																)}
															</Box>
															<Typography
																gutterBottom
																variant="h4"
																component="div"
																fontWeight={'600'}
																fontSize={{
																	lg: '13px',
																	md: '13px',
																	sm: '13px',
																	xs: '13px',
																}}
																color={'#484848'}
																sx={{ my: 1 }}
																textAlign={'start'}
															>
																The fields markes as * are mandatory
															</Typography>
														</Grid>
													</Grid>
												</Box>
											</div>
											<div
												style={{
													display: 'flex',
													justifyContent: 'start',
													alignItems: 'start',
												}}
											>
												<Box sx={{ flexGrow: 1, gap: 10, my: 2 }}>
													<Grid container>
														<Grid xs={2}>
															<div className="numberCount_service">
																<span style={{ color: 'white' }}>2</span>
															</div>
														</Grid>
														<Grid xs={10}>
															<div>
																<Box
																	sx={{
																		display: 'flex',
																		alignItems: isbuyerScreen
																			? 'none'
																			: 'center',
																		justifyContent: 'space-between',
																	}}
																>
																	{' '}
																	<Typography
																		gutterBottom
																		variant="h4"
																		component="div"
																		fontWeight={'700'}
																		fontSize={{
																			lg: '16px',
																			md: '16px',
																			sm: '16px',
																			xs: '16px',
																		}}
																		color={'#484848'}
																		textAlign={'start'}
																		my={1}
																	>
																		BUYER DETAILS{' '}
																		<span style={{ color: 'red' }}>*</span>
																	</Typography>
																	<div>
																		<Button
																			variant="contained"
																			size="small"
																			className="ebook_address_btn_service"
																			onClick={() => setAddressListModal(true)}
																			sx={{
																				fontSize: {
																					xs: '12px',
																					sm: '12px',
																					md: '14px',
																				},
																				fontWeight: 500,
																				letterSpacing: 0.5,
																				margin: isbuyerScreen ? 0.2 : 1,
																				textTransform: 'none',
																				p: isbuyerScreen ? 0.5 : 0.5,
																			}}
																		>
																			Address List
																		</Button>
																		<Button
																			variant="contained"
																			size="small"
																			className="ebook_address_btn_service"
																			onClick={() => setModal(true)}
																			sx={{
																				fontSize: {
																					xs: '12px',
																					sm: '12px',
																					md: '14px',
																				},
																				fontWeight: 500,
																				letterSpacing: 0.5,
																				margin: isbuyerScreen ? 0.2 : 1,
																				textTransform: 'none',
																			}}
																		>
																			+ Add
																		</Button>
																	</div>
																	<div>
																		<Modal
																			open={modal}
																			className="address_modal_container"
																			onClose={() => setModal(false)}
																		>
																			<AddressPopup
																				button={() => setModal(false)}
																			/>
																		</Modal>
																	</div>
																	<Modal
																		open={addressListModal}
																		className="address_modal_container"
																	>
																		<div
																			className="addressList_modal_service"
																			onClick={() => setAddressListModal(false)}
																		>
																			<div
																				className="addressList_service"
																				onClick={(e) => {
																					e.stopPropagation();
																				}}
																			>
																				<div style={{ textAlign: 'end' }}>
																					<CloseIcon
																						onClick={() => {
																							setAddressListModal(false);
																						}}
																					/>
																				</div>
																				<List>
																					{AddressList.profile_address.map(
																						(item, index) => (
																							<ListItem
																								onClick={() => {
																									setAutoFill(item);
																									setAddressListModal(false);
																								}}
																								sx={{
																									display: 'flex',
																									justifyContent: 'center',
																								}}
																							>
																								<div className="list_service">
																									<Box sx={{ flexGrow: 1 }}>
																										<ListItemText
																											primary={
																												<span
																													style={{
																														fontSize: 21,
																														fontWeight: '700',
																														paddingTop: 12,
																														paddingBottom: 12,
																													}}
																												>
																													<span></span>{' '}
																													<span>
																														{
																															item.type_of_address
																														}
																													</span>
																												</span>
																											}
																										/>

																										<ListItemText
																											sx={{ my: 1 }}
																											primary={
																												<span>
																													{item.door_no}
																													{','}
																													{item.street}
																													{','}
																													{item.area}
																													{','}
																													{item.city}
																													{','}
																													{item.landmark}
																													{' - '}
																													{item.pincode}
																												</span>
																											}
																										/>
																									</Box>
																								</div>
																							</ListItem>
																						)
																					)}
																				</List>
																			</div>
																		</div>
																	</Modal>
																</Box>
																<Box sx={{ mt: 2 }} pr={{ sm: 2, xs: 2 }}>
																	<InputLabel
																		sx={{
																			fontSize: {
																				xs: '13px',
																				md: '13px',
																				lg: '13px',
																				fontWeight: 700,
																			},
																			mb: 1,
																		}}
																	>
																		Address*
																	</InputLabel>

																	{error === 9 ? (
																		<FormControl
																			sx={{
																				width: {
																					xs: '100%',
																					sm: '60%',
																					md: '60%',
																					xl: '60%',
																					lg: '60%',
																				},
																			}}
																		>
																			<TextField
																				fullWidth
																				label=""
																				variant="outlined"
																				size="medium"
																				value={outlet.address}
																				onChange={(e) =>
																					setOutlet({
																						...outlet,
																						address: e.target.value,
																					})
																				}
																				onClick={() =>
																					setAddressListModal(true)
																				}
																				multiline
																				autoComplete="off"
																			/>
																		</FormControl>
																	) : autoFill ? (
																		<FormControl
																			sx={{
																				width: {
																					xs: '100%',
																					sm: '60%',
																					md: '60%',
																					xl: '60%',
																					lg: '60%',
																				},
																			}}
																		>
																			<TextField
																				id="outlined-basic"
																				label=""
																				variant="outlined"
																				size="medium"
																				defaultValue={outlet.address}
																				value={
																					autoFill
																						? `${autoFill.door_no || ''}, ${
																								autoFill.street || ''
																						  }`
																						: outlet.address
																				}
																				onChange={(e) =>
																					setOutlet({
																						...outlet,
																						address: e.target.value,
																					})
																				}
																				onClick={() =>
																					setAddressListModal(true)
																				}
																				multiline
																				autoComplete="off"
																			/>
																		</FormControl>
																	) : (
																		<FormControl
																			sx={{
																				width: {
																					xs: '100%',
																					sm: '60%',
																					md: '60%',
																					xl: '60%',
																					lg: '60%',
																				},
																			}}
																		>
																			<TextField
																				id="outlined-basic"
																				label=""
																				variant="outlined"
																				size="medium"
																				// defaultValue={outlet.address}
																				value={
																					autoFill
																						? `${autoFill.door_no || ''}, ${
																								autoFill.street || ''
																						  }`
																						: outlet.address
																				}
																				onChange={(e) =>
																					setOutlet({
																						...outlet,
																						address: e.target.value,
																					})
																				}
																				onClick={() =>
																					setAddressListModal(true)
																				}
																				multiline
																				autoComplete="off"
																			/>
																		</FormControl>
																	)}
																</Box>
																<Typography
																	gutterBottom
																	variant="h4"
																	component="div"
																	fontWeight={'600'}
																	fontSize={{
																		lg: '13px',
																		md: '13px',
																		sm: '13px',
																		xs: '13px',
																	}}
																	color={'#484848'}
																	sx={{ my: 2 }}
																	textAlign={'start'}
																>
																	The fields marked with * are mandatory
																</Typography>
															</div>
														</Grid>
													</Grid>
												</Box>
											</div>
											<div
												style={{
													display: 'flex',
													justifyContent: 'start',
													alignItems: 'start',
												}}
											>
												<Box sx={{ flexGrow: 1, gap: 10 }}>
													<Grid container>
														<Grid xs={2} sx={{}}>
															<div className="numberCount_service">
																<span style={{ color: 'white' }}>3</span>
															</div>
														</Grid>
														<Grid
															xs={10}
															sx={{
																display: 'flex',
																alignItems: 'start',
																flexDirection: 'column',
															}}
															pr={{ sm: 2, xs: 2 }}
														>
															<Typography
																gutterBottom
																variant="h4"
																component="div"
																fontWeight={'700'}
																fontSize={{
																	lg: '16px',
																	md: '16px',
																	sm: '16px',
																	xs: '16px',
																}}
																color={'#484848'}
																textAlign={'start'}
																my={1}
															>
																REFERENCE{' '}
															</Typography>
															<div style={{ width: '100%' }}>
																<InputLabel
																	sx={{
																		fontSize: {
																			xs: '13px',
																			md: '13px',
																			lg: '13px',
																			fontWeight: 700,
																		},
																		mb: 1,
																	}}
																>
																	Referred By (optional)
																</InputLabel>
																<FormControl
																	sx={{
																		width: {
																			xs: '100%',
																			sm: '60%',
																			md: '60%',
																			xl: '60%',
																			lg: '60%',
																		},
																	}}
																>
																	<TextField
																		id="outlined-basic"
																		autoComplete="off"
																		label=""
																		variant="outlined"
																		size="small"
																		value={outlet.employeeName}
																		onChange={(e) => {
																			const input = e.target.value;
																			const correctInput = input.replace(
																				/[^A-Za-z\s]/g,
																				''
																			);
																			if (!correctInput.startsWith(' ')) {
																				setOutlet({
																					...outlet,
																					employeeName: correctInput,
																				});
																			}
																		}}
																	/>
																</FormControl>
																<InputLabel
																	sx={{
																		fontSize: {
																			xs: '13px',
																			md: '13px',
																			lg: '13px',
																			fontWeight: 700,
																		},
																		mb: 1,
																	}}
																>
																	Employee Id(optional)
																</InputLabel>
																<FormControl
																	sx={{
																		width: {
																			xs: '100%',
																			sm: '60%',
																			md: '60%',
																			xl: '60%',
																			lg: '60%',
																		},
																	}}
																>
																	{error === 16 ? (
																		<div>
																			<TextField
																				id="outlined-basic"
																				autoComplete="off"
																				fullWidth
																				value={outlet.employeeCode}
																				onChange={(e) => {
																					const input = e.target.value;
																					const correctInput = input.replace(
																						/[^A-za-z0-9]/g,
																						''
																					);
																					if (
																						!correctInput.startsWith(' ') &&
																						correctInput.length <= 11
																					) {
																						setOutlet({
																							...outlet,
																							employeeCode: correctInput,
																						});
																					}
																				}}
																				label=""
																				variant="outlined"
																				size="small"
																			/>
																		</div>
																	) : (
																		<TextField
																			id="outlined-basic"
																			autoComplete="off"
																			fullWidth
																			value={outlet.employeeCode}
																			onChange={(e) => {
																				const input = e.target.value;
																				const correctInput = input.replace(
																					/[^A-za-z0-9]/g,
																					''
																				);
																				if (
																					!correctInput.startsWith(' ') &&
																					correctInput.length <= 11
																				) {
																					setOutlet({
																						...outlet,
																						employeeCode: correctInput,
																					});
																				}
																			}}
																			label=""
																			variant="outlined"
																			size="small"
																		/>
																	)}
																</FormControl>

																<div style={{ width: '100%' }}>
																	<InputLabel
																		sx={{
																			fontSize: {
																				xs: '13px',
																				md: '13px',
																				lg: '13px',
																				fontWeight: 700,
																			},
																			mb: 1,
																		}}
																	>
																		Select Date
																	</InputLabel>
																	{error === 11 ? (
																		<div>
																			<LocalizationProvider
																				dateAdapter={AdapterDayjs}
																			>
																				<DemoContainer
																					components={['DesktopDatePicker']}
																				>
																					<DesktopDatePicker
																						disablePast
																						label=""
																						value={selectedDate}
																						onChange={(newValue) =>
																							setSelectedDate(newValue)
																						}
																						slotProps={{
																							textField: { size: 'small' },
																						}}
																						sx={{
																							width: {
																								xs: '100%',
																								sm: '60%',
																								md: '60%',
																								xl: '60%',
																								lg: '60%',
																							},
																						}}
																					/>
																				</DemoContainer>
																			</LocalizationProvider>
																		</div>
																	) : (
																		<LocalizationProvider
																			dateAdapter={AdapterDayjs}
																		>
																			<DemoContainer
																				components={['DesktopDatePicker']}
																			>
																				<DesktopDatePicker
																					label=""
																					disablePast
																					value={selectedDate}
																					onChange={(newValue) =>
																						setSelectedDate(newValue)
																					}
																					slotProps={{
																						textField: { size: 'small' },
																					}}
																					sx={{
																						width: {
																							xs: '100%',
																							sm: '60%',
																							md: '60%',
																							xl: '60%',
																							lg: '60%',
																						},
																					}}
																				/>
																			</DemoContainer>
																		</LocalizationProvider>
																	)}
																</div>

																<div style={{ width: '100%' }}>
																	<InputLabel
																		sx={{
																			fontSize: {
																				xs: '13px',
																				md: '13px',
																				lg: '13px',
																				fontWeight: 700,
																			},
																			mb: 1,
																			mt: 1.5,
																		}}
																	>
																		Select Time
																	</InputLabel>
																	{error === 12 ? (
																		<div>
																			<LocalizationProvider
																				dateAdapter={AdapterDayjs}
																			>
																				<DemoContainer
																					components={['DesktopTimePicker']}
																				>
																					<DesktopTimePicker
																						label=""
																						value={selectedTime}
																						onChange={(newValue) =>
																							setSelectedTime(newValue)
																						}
																						slotProps={{
																							textField: { size: 'small' },
																						}}
																						sx={{
																							width: {
																								xs: '100%',
																								sm: '60%',
																								md: '60%',
																								xl: '60%',
																								lg: '60%',
																							},
																						}}
																					/>
																				</DemoContainer>
																			</LocalizationProvider>
																		</div>
																	) : (
																		<LocalizationProvider
																			dateAdapter={AdapterDayjs}
																		>
																			<DemoContainer
																				components={['DesktopTimePicker']}
																			>
																				<DesktopTimePicker
																					disablePast
																					label=""
																					value={selectedTime}
																					onChange={(newValue) =>
																						setSelectedTime(newValue)
																					}
																					slotProps={{
																						textField: { size: 'small' },
																					}}
																					sx={{
																						width: {
																							xs: '100%',
																							sm: '60%',
																							md: '60%',
																							xl: '60%',
																							lg: '60%',
																						},
																					}}
																				/>
																			</DemoContainer>
																		</LocalizationProvider>
																	)}
																</div>
															</div>
														</Grid>
													</Grid>
												</Box>
											</div>
											<div className="disclaimer_service">
												<Typography
													gutterBottom
													variant="h4"
													component="div"
													fontWeight={'700'}
													fontSize={{
														lg: '13px',
														md: '13px',
														sm: '13px',
														xs: '13px',
													}}
													color={'#484848'}
													// textAlign={'start'}
													my={1}
													display={'flex'}
													justifyContent={'ceter'}
													alignContent={'center'}
													mt={3}
												>
													Disclaimer:
												</Typography>
												<Typography
													gutterBottom
													variant="h4"
													component="div"
													fontWeight={'500'}
													letterSpacing={0.5}
													lineHeight={1.5}
													fontSize={{
														lg: '11px',
														md: '11px',
														sm: '11px',
														xs: '11px',
													}}
													color={'#484848'}
													// textAlign={'center'}
													my={1}
													display={'flex'}
													justifyContent={'ceter'}
													alignContent={'center'}
												>
													I agree that by clicking the ‘Submit’ button below, I
													am explicitly soliciting a call and message via
													whatsapp and any other medium from Kalyani Motors Pvt
													Ltd. or its partners on my ‘Mobile’.
												</Typography>
												<Typography
													gutterBottom
													variant="h4"
													component="div"
													fontWeight={'700'}
													fontSize={{
														lg: '13px',
														md: '13px',
														sm: '13px',
														xs: '13px',
													}}
													color={'#484848'}
													// textAlign={'center'}
													my={1}
													display={'flex'}
													justifyContent={'ceter'}
													alignContent={'center'}
												>
													*Prices/Schemes prevailing at the time of invoice
													/bill shall be applicable.
												</Typography>
											</div>
											<div className="ebook_btn_service">
												<Button
													variant="contained"
													size="small"
													className="ebook_submit_service"
													onClick={() => {
														outletValidation();
													}}
													sx={{
														textTransform: 'none',
														marginTop: 3,
														fontWeight: 500,
														letterSpacing: 0.5,
														fontWeight: 500,
														letterSpacing: 0.5,
														height: '44px',
														width: isSmallScreen ? '150px' : '200px',
														fontWeight: 600,
														fontSize: '12px',
														lineHeight: '18px',
														letterSpacing: '.01em',
														borderRadius: '12px !important',
														boxShadow: 'none !important',
													}}
												>
													Submit & Review
												</Button>
											</div>
										</div>
									)}
								</Grid>
								<Grid
									xs={12}
									lg={5}
									sm={12}
									md={6}
									sx={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<img
										src={image || cartImage}
										style={{
											width: isSmallScreen ? '180px' : '300px',
											height: isSmallScreen ? '180px' : '300px',
										}}
									/>
								</Grid>
							</Grid>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</div>
	);
}
