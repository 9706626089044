import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';

const BuySellToggleBtn = ({ activeList, setActiveList, label }) => {
	const isSmallScreen = useMediaQuery('(max-width: 400px)');

	return (
		<Button
			sx={[
				{
					fontWeight: 600,
					borderRadius: 15,
					px: isSmallScreen ? 1 : 3,
					color: '#fff',
					'&:hover': { background: 'none' },
				},
				activeList == label
					? {
							color: 'orangered',
							backgroundColor: '#fff',
							'&:hover': { backgroundColor: '#fff' },
					  }
					: {},
			]}
			size="small"
			onClick={() => setActiveList(label)}
		>
			{label}
		</Button>
	);
};

export default BuySellToggleBtn;
