import React, { useEffect, useState, lazy } from 'react';
import HeaderMain from '../Header/Header';
import Footer from '../Footer/Footer';
import Loader from '../../Constants/Loader';
import { ToastContainer } from 'react-toastify';

export default function Layout({ children }) {
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const timeout = setTimeout(() => {
			setLoading(false);
		}, 2000);

		return () => clearTimeout(timeout);
	}, []);

	useEffect(() => {
		document.body.style.overflow = loading ? 'hidden' : 'auto';
		return () => {
			document.body.style.overflow = 'auto';
		};
	}, [loading]);
	return (
		<>
			{loading && <Loader />}
			<HeaderMain />
			{children}
			<Footer />
			<ToastContainer
				position="top-right"
				autoClose={2500}
				newestOnTop
				closeOnClick
				rtl={false}
				closeButton={false}
				pauseOnFocusLoss
				draggable={false}
				pauseOnHover={false}
				bodyStyle={{
					textAlign: 'left',
					paddingRight: '10px',
					paddingLeft: '8px',
					gap: '4px',
					maxWidth: '300px',
					fontFamily: 'Poppins, sans-serif',
				}}
				toastStyle={{
					minHeight: '50px',
					borderRadius: '8px',
				}}
				style={{ width: 'auto' }}
			/>
		</>
	);
}
