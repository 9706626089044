import React, { useState, useEffect } from 'react';
import './EBook.css';
import { useNavigate } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { getComaPrice } from '../../../Constants/Comaprice';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import ApiHandler from '../../../Api/Apihandler';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import useMediaQuery from '@mui/material/useMediaQuery';
import AddressPopup from '../../../Components/Modal/Modal';
import { List, ListItem, ListItemText } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import HeaderMain from '../../../Components/Header/Header';
import Footer from '../../../Components/Footer/Footer';
import CloseIcon from '@mui/icons-material/Close';
import cloud_computing from '../../../Assets/Images/Icons/cloud-computing.png';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const Ebook = ({ route, addressClick }) => {
	const button = route?.params ?? {};
	const [addEbookNew, setAddEbookNew] = useState(false);
	const location = useLocation();
	const { Replace, ReplaceId } = useParams();
	const {
		title,
		id,
		channel,
		cartTitle,
		cartId,
		cartImage,
		cartColor,
		cartChannel,
		cartAmount,
		subtitle,
		img,
		itemlist,
		btnTitle,
	} = location.state || {};
	const { addNew } = route?.props ?? {};
	const [details, setDetails] = useState([]);
	const [stepNo, setStepNo] = useState(1);
	const [modal, setModal] = useState(false);
	const [colors, setColors] = useState([]);
	const [priceList, setPriceList] = useState([]);
	const [payment, setPayment] = useState(5000);
	const [cityName, setCityName] = useState([]);
	const [city, setCity] = useState([]);
	const [cityLocation, setCityLocation] = useState([]);
	const [cityLocationName, setCityLocationName] = useState([]);
	const rows = [];
	const [addressId, setAddressId] = useState();
	const [error, setError] = useState(null);
	const [AddressList, setAddressList] = useState([]);
	const [addressListModal, setAddressListModal] = useState(false);
	const [autoFill, setAutoFill] = useState();
	const [basePic, setBasePic] = useState(null);

	const [selectedFile, setSelectedFile] = useState(null);

	const handleFileChange = (event) => {
		const file = event.target.files[0];

		if (file && file.name === selectedFile?.name) {
			document.getElementById('image-file-input').value = '';
		} else {
			setSelectedFile(file);
			upload();

			const reader = new FileReader();
			reader.onloadend = () => {
				let baseData = reader.result;
				const base64Data = baseData.split(',')[1];
				setBasePic(base64Data);
			};

			reader.readAsDataURL(file);
		}
	};

	const handleDelete = () => {
		setSelectedFile(null);
		document.getElementById('image-file-input').value = '';
	};

	const toastStyle = {
		fontSize: '13px',
	};

	const name = () => toast.error('Please enter a name', { style: toastStyle });
	const mobile = () =>
		toast.error('Please enter a valid mobile number', { style: toastStyle });
	const email = () =>
		toast.error('Please enter a valid email id', { style: toastStyle });
	const outletcity = () =>
		toast.error('Please choose a city', { style: toastStyle });
	const outletlocation = () =>
		toast.error('Please choose a location', { style: toastStyle });
	const address = () =>
		toast.error('Please enter an address', { style: toastStyle });
	const pincode = () =>
		toast.error('Please enter a pincode', { style: toastStyle });

	const panNumber = () => {
		toast.error('Enter Valid PAN number', { style: toastStyle });
	};
	const relationship = () => {
		toast.error('Please enter a Relationship', { style: toastStyle });
	};
	const refferedId = () => {
		toast.error('Please enter a Employee Id', { style: toastStyle });
	};
	const upload = () => {
		toast.success('Your PAN Image upload successfully!', { style: toastStyle });
	};
	const uploadError = () => {
		toast.error('Please Upload PAN Image', {
			style: toastStyle,
		});
	};

	const [users, setUsers] = useState({
		name: '',
		email: '',
		phonenumber: '',
	});

	const [otherUsers, setOtherUsers] = useState({
		name: '',
		email: '',
		phonenumber: '',
		pan_number: '',
		Relationship: '',
		pan_img: '',
	});

	const [outlet, setOutlet] = useState({
		city: '',
		locationCity: '',
		address: '',
		pincode: '',
		employeeCode: '',
		employeeName: '',
	});
	// const [addAdress, setAddAddress] = useState({
	//   doorNo: "",
	//   area: "",
	//   city: "",
	//   landmark: "",
	//   pincode: "",
	// });

	const [profileData, setProfileData] = useState([]);
	const loginId = localStorage.getItem('loggedIn');

	useEffect(() => {
		_getdetails(id || ReplaceId);
		_getpricelist(channel, cartChannel);
		_getCity();
		_getAddress(loginId);
		setAddressId(loginId);
		_getProfile(loginId);
	}, [ReplaceId]);

	const _getProfile = async () => {
		try {
			const response = await ApiHandler.getProfile(loginId);
			const data = response.data.data;
			setProfileData(data);
		} catch (error) {
			console.log('profile Data error', error);
		}
	};

	const isSmallScreen = useMediaQuery('(max-width:900px)');
	const isbuyerScreen = useMediaQuery('(max-width:425px)');

	const _getdetails = async (id, ReplaceId) => {
		const response = await ApiHandler.getDetails('/' + id || ReplaceId);
		const data = response.data.data;
		try {
			setDetails(data);
			colorDetails(data[0].title);
		} catch (error) {
			console.log('ebook_get_details error', error);
		}
	};

	const colorDetails = async (title) => {
		try {
			const colorResponse = await ApiHandler.getColorsDetails({ title: title });
			if (colorResponse.data.status === 200) {
				const data = colorResponse.data.data;
				setColors(data);
			}
		} catch (error) {
			console.log('Ebook_get_colorError', error);
		}
	};

	const _getpricelist = async (channel, cartChannel) => {
		const response = await ApiHandler.getPriceList(channel || cartChannel);
		const data = response.data.data;
		try {
			setPriceList(data);
		} catch (error) {
			console.log('Ebook_getpricelist error', error);
		}
	};
	const _getCity = async () => {
		const response = await ApiHandler.getOutletCity();
		const data = response.data.data;
		try {
			setCity(data);
		} catch (error) {
			console.log('Ebook_getoutlet error', error);
		}
	};

	const _getOutletLocation = async (city, channel, cartChannel) => {
		const response = await ApiHandler.getOutletLocation(
			city,
			channel || cartChannel
		);
		const data = response.data.data;
		try {
			setCityLocation(data);
		} catch (error) {
			console.log('Ebook_getOutletLocation error', error);
		}
	};

	const handleSelectChange = (event) => {
		const value = event.target.value;
		const selectedCity = city.filter((item) => item.id === value);
		setOutlet({ ...outlet, city: value });
		setCityName(selectedCity[0].title);
		_getOutletLocation(value, channel, cartChannel);
	};

	const handleOuletChange = (event) => {
		const value = event.target.value;
		const selectedCityLocation = cityLocation.filter(
			(item) => item.id === value
		);
		setOutlet({ ...outlet, locationCity: event.target.value });
		setCityLocationName(selectedCityLocation[0].name);
	};

	const handlePayment = (event) => {
		const paymentId = priceList.filter((item) => item.price === payment.price);
	};

	const navigate = useNavigate();
	const validation = () => {
		if (btnTitle === 'Myself') {
			if (
				users.name.length === 0 &&
				profileData[0].user.first_name.length === 0
			) {
				setError(1);
				name();
			} else if (
				!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\./i.test(
					users.email || profileData[0].user.email
				)
			) {
				setError(2);
				email();
			} else if (
				!/^\d{10}$/.test(
					(profileData[0] && profileData[0].phone) || users.phonenumber
				)
			) {
				setError(3);
				mobile();
			} else {
				setStepNo(2);
				setError(0);
			}
		} else {
			if (otherUsers.name.length === 0) {
				setError(8);
				name();
			} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\./i.test(otherUsers.email)) {
				setError(9);
				email();
			} else if (otherUsers.pan_number.length === 0) {
				setError(10);
				panNumber();
			} else if (otherUsers.Relationship.length === 0) {
				setError(11);
				relationship();
			} else if (!/^\d{10}$/.test(otherUsers.phonenumber)) {
				setError(12);
				mobile();
			} else if (!selectedFile) {
				setError(13);
				uploadError();
			} else {
				setStepNo(2);
				setError(0);
			}
		}
	};

	const _getAddress = async (id) => {
		const response = await ApiHandler.getAddress(id);
		try {
			const data = response.data.data;
			setAddressList(data);
		} catch (error) {
			console.log('Ebook_getAddress error', error);
		}
	};

	const outletValidation = () => {
		if (outlet.city.length === 0) {
			setError(4);
			outletcity();
		} else if (outlet.locationCity.length === 0) {
			setError(5);
			outletlocation();
		} else if (outlet.address.length === 0 && !autoFill) {
			setError(6);
			address();
		} else if (
			outlet.employeeName.length > 0 &&
			outlet.employeeCode.length === 0
		) {
			setError(14);
			refferedId();
		} else {
			setError(0);
			navigate(`/ebookDetails/${ReplaceId}/${Replace}`, {
				state: {
					name:
						users.name ||
						`${profileData[0] && profileData[0].user.first_name} ${
							profileData[0] && profileData[0].user.last_name
						}`,
					email: users.email || (profileData[0] && profileData[0].user.email),
					mobile: users.phonenumber || (profileData[0] && profileData[0].phone),
					variant: cartTitle || details[0].title,
					color: cartColor || details[0].color,
					cities: cityName,
					locations: cityLocationName,
					cdoor_no: autoFill.door_no,
					cpincode: autoFill.pincode,
					cstreet: autoFill.street,
					clandmark: autoFill.landmark,
					carea: autoFill.area,
					ccity: autoFill.city,
					payment: payment,
					images: cartImage || details[0].images[0],
					empName: outlet.employeeName,
					empCode: outlet.employeeCode,
					amount: cartAmount || (details && details[0].price),
					addressId: autoFill.id,
					itemlist: itemlist,
					description: id,
					others_name: otherUsers.name,
					others_email: otherUsers.email,
					others_mobile: otherUsers.phonenumber,
					others_pan: otherUsers.pan_number,
					others_relationship: otherUsers.Relationship,
					booked_for: btnTitle,
					others_panImg: basePic,
				},
			});
		}
	};

	const _updateProfile = async () => {
		const data = {
			phone: profileData[0] && profileData[0].phone,
			gender: profileData[0] && profileData[0].gender,
			year_of_birth: profileData[0] && profileData[0].year_of_birth,
			first_name:
				(profileData[0] && profileData[0].user.first_name) || users.name,
			lat: '216.321',
			long: '073.310',
			last_name: profileData[0] && profileData[0].user.last_name,
			email: (profileData[0] && profileData[0].user.email) || users.email,
			user: profileData[0] && profileData[0].user.id,
		};

		const response = await ApiHandler.updateProfile(data);
		try {
			if (response.data.status === 200) {
				// console.log('profile change', response);
				// console.log('profile change', response.data);
				// window.alert(`Profile ${response.data.message}`);
			}
		} catch (error) {
			console.log('Ebook_profile edit error', error);
		}
	};

	return (
		<div className="Ebook_screen">
			<Box sx={{ backgroundColor: '#eee' }}>
				<Grid
					container
					xs={12}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignContent: 'center',
					}}
				>
					<Grid
						item
						xs={12}
						sm={12}
						md={11}
						lg={10}
						xl={10}
						sx={{ backgroundColor: 'white', my: { xl: 6, lg: 6, md: 6 } }}
					>
						<Typography
							gutterBottom
							variant="h4"
							component="div"
							fontWeight={'700'}
							fontSize={{ lg: '23px', md: '22px', sm: '20px', xs: '18px' }}
							color={'#484848'}
							sx={{
								my: { lg: 2, xl: 2, md: 2 },
								py: { lg: 3, xl: 2, md: 2 },
								pt: { sm: 5, xs: 5 },
							}}
							textAlign={'center'}
							lineHeight={2}
						>
							E-Booking {subtitle || cartTitle || Replace}
						</Typography>
						<Box sx={{ flexGrow: 1 }}>
							<Grid
								container
								sx={{
									flexWrap: isSmallScreen ? 'wrap-reverse' : 'wrap',
									padding: isSmallScreen ? '10px' : '0px',
								}}
							>
								<Grid xs={12} lg={7} sm={12} md={6} className="mui">
									<Grid container>
										<Grid
											xs={4}
											className="tab_container"
											sx={{ flexDirection: 'column', my: 2 }}
										>
											<Button
												variant="contained"
												size="small"
												className="ebook_tab"
												onClick={() => setStepNo(1)}
												sx={{
													fontSize: {
														xs: '12px',
														sm: '12px',
														md: '14px',
														textTransform: 'none',
														marginTop: 10,
													},
													fontWeight: 500,
													letterSpacing: 0.5,
													backgroundImage:
														stepNo === 1
															? 'linear-gradient(60deg, #29323c 0%, #485563 100%)'
															: '#808080',
													backgroundColor: '#708090',
													'&:hover': { backgroundColor: '#708090' },
												}}
											>
												Step 1
											</Button>
										</Grid>
										<Grid xs={4} className="tab_container">
											<Button
												variant="contained"
												size="small"
												className="ebook_tab"
												onClick={() => validation()}
												sx={{
													fontSize: {
														xs: '12px',
														sm: '12px',
														md: '14px',
														textTransform: 'none',
														marginTop: 10,
													},
													fontWeight: 500,
													letterSpacing: 0.5,
													backgroundImage:
														stepNo === 2
															? 'linear-gradient(60deg, #29323c 0%, #485563 100%)'
															: '#708090',
													backgroundColor: '#708090',
													'&:hover': { backgroundColor: '#708090' },
												}}
											>
												Step 2
											</Button>
										</Grid>
										<Grid xs={4} className="tab_container">
											<Button
												variant="contained"
												size="small"
												className="ebook_tab"
												sx={{
													fontSize: {
														xs: '12px',
														sm: '12px',
														md: '14px',
														textTransform: 'none',
														marginTop: 10,
													},
													fontWeight: 500,
													letterSpacing: 0.5,
													backgroundImage:
														stepNo === 3
															? 'linear-gradient(60deg, #29323c 0%, #485563 100%)'
															: '#708090',
													backgroundColor: '#708090',
													'&:hover': { backgroundColor: '#708090' },
												}}
											>
												Step 3
											</Button>
										</Grid>
									</Grid>
									{stepNo === 1 && (
										<div
											// style={{
											//   display: "flex",
											//   justifyContent: "center",
											//   alignItems: "center",
											//   flexDirection: "column",
											//   height: "auto",
											// }}
											className="ebook_personal_Details"
										>
											<Typography
												gutterBottom
												variant="h4"
												component="div"
												fontWeight={'700'}
												fontSize={{
													lg: '20px',
													md: '20px',
													sm: '20px',
													xs: '18px',
												}}
												color={'#484848'}
												sx={{ my: 3 }}
												textAlign={'center'}
											>
												PERSONAL DETAILS <span style={{ color: 'red' }}>*</span>
											</Typography>
											{btnTitle === 'Others' ? (
												<Box
													className="e-book_personal_details"
													sx={{
														display: 'flex',
														justifyContent: 'center',
														alignItems: 'center',
													}}
												>
													<Grid
														container
														spacing={2}
														style={{ justifyContent: 'center' }}
													>
														<Grid item xs={12}>
															<Grid
																container
																spacing={2}
																style={{ justifyContent: 'center' }}
															>
																<Grid item xs={12} sm={5} xl={5} md={5} lg={5}>
																	<InputLabel
																		sx={{
																			fontSize: {
																				xs: '13px',
																				md: '13px',
																				lg: '13px',
																				fontWeight: 700,
																			},
																			mb: 1,
																		}}
																	>
																		Name*
																	</InputLabel>
																	{error === 8 ? (
																		<div>
																			<TextField
																				fullWidth
																				autoComplete="off"
																				id="outlined-basic"
																				label=""
																				size="small"
																				placeholder="Name"
																				value={otherUsers.name}
																				onChange={(e) => {
																					const inputValue = e.target.value;
																					const correctInput =
																						inputValue.replace(
																							/[^A-za-z\s]/g,
																							''
																						);
																					if (!correctInput.startsWith(' ')) {
																						setOtherUsers({
																							...otherUsers,
																							name: correctInput,
																						});
																					}
																				}}
																				variant="outlined"
																			/>
																		</div>
																	) : (
																		<TextField
																			fullWidth
																			autoComplete="off"
																			id="outlined-basic"
																			label=""
																			size="small"
																			placeholder="Name"
																			value={otherUsers.name}
																			onChange={(e) => {
																				const inputValue = e.target.value;
																				const correctInput = inputValue.replace(
																					/[^A-za-z\s]/g,
																					''
																				);
																				if (!correctInput.startsWith(' ')) {
																					setOtherUsers({
																						...otherUsers,
																						name: correctInput,
																					});
																				}
																			}}
																			variant="outlined"
																		/>
																	)}
																</Grid>
																<Grid item xs={12} sm={5} xl={5} md={5} lg={5}>
																	<InputLabel
																		sx={{
																			fontSize: {
																				xs: '13px',
																				md: '13px',
																				lg: '13px',
																				fontWeight: 700,
																			},
																			mb: 1,
																		}}
																	>
																		Email*
																	</InputLabel>
																	{error === 9 ? (
																		<div>
																			<TextField
																				fullWidth
																				autoComplete="off"
																				label=""
																				size="small"
																				variant="outlined"
																				placeholder="Email"
																				value={otherUsers.email}
																				onChange={(e) =>
																					setOtherUsers({
																						...otherUsers,
																						email: e.target.value,
																					})
																				}
																			/>
																		</div>
																	) : (
																		<TextField
																			fullWidth
																			autoComplete="off"
																			id="outlined-basic"
																			label=""
																			size="small"
																			variant="outlined"
																			placeholder="Email"
																			value={otherUsers.email}
																			onChange={(e) =>
																				setOtherUsers({
																					...otherUsers,
																					email: e.target.value,
																				})
																			}
																		/>
																	)}
																</Grid>
																<Grid item xs={12} sm={5} xl={5} md={5} lg={5}>
																	<InputLabel
																		sx={{
																			fontSize: {
																				xs: '13px',
																				md: '13px',
																				lg: '13px',
																				fontWeight: 700,
																			},
																			mb: 1,
																		}}
																	>
																		PAN Number*
																	</InputLabel>
																	{error === 10 ? (
																		<div>
																			<TextField
																				fullWidth
																				autoComplete="off"
																				label=""
																				size="small"
																				variant="outlined"
																				placeholder="PAN Number"
																				value={otherUsers.pan_number}
																				onChange={(e) => {
																					const input = e.target.value;
																					const sanIniInput = input.replace(
																						/[^A-Za-z0-9]/g,
																						''
																					);
																					if (sanIniInput.length <= 10) {
																						setOtherUsers({
																							...otherUsers,
																							pan_number:
																								sanIniInput.toUpperCase(),
																						});
																					}
																				}}
																			/>
																		</div>
																	) : (
																		<TextField
																			fullWidth
																			autoComplete="off"
																			id="outlined-basic"
																			label=""
																			size="small"
																			variant="outlined"
																			placeholder="PAN Number"
																			value={otherUsers.pan_number}
																			onChange={(e) => {
																				const input = e.target.value;
																				const sanIniInput = input.replace(
																					/[^A-Za-z0-9]/g,
																					''
																				);
																				if (sanIniInput.length <= 10) {
																					setOtherUsers({
																						...otherUsers,
																						pan_number:
																							sanIniInput.toUpperCase(),
																					});
																				}
																			}}
																		/>
																	)}
																</Grid>
																<Grid item xs={12} sm={5} xl={5} md={5} lg={5}>
																	<InputLabel
																		sx={{
																			fontSize: {
																				xs: '13px',
																				md: '13px',
																				lg: '13px',
																				fontWeight: 700,
																			},
																			mb: 1,
																		}}
																	>
																		Relationship*
																	</InputLabel>
																	{error === 11 ? (
																		<div>
																			<TextField
																				fullWidth
																				autoComplete="off"
																				label=""
																				size="small"
																				variant="outlined"
																				placeholder="Relationship"
																				value={otherUsers.Relationship}
																				onChange={(e) => {
																					const input = e.target.value;
																					const correctInput = input.replace(
																						/[^A-za-z\s]/g,
																						''
																					);
																					if (!correctInput.startsWith(' ')) {
																						setOtherUsers({
																							...otherUsers,
																							Relationship: correctInput,
																						});
																					}
																				}}
																			/>
																		</div>
																	) : (
																		<TextField
																			fullWidth
																			autoComplete="off"
																			id="outlined-basic"
																			label=""
																			size="small"
																			placeholder="Relationship"
																			variant="outlined"
																			value={otherUsers.Relationship}
																			onChange={(e) => {
																				const input = e.target.value;
																				const correctInput = input.replace(
																					/[^A-za-z\s]/g,
																					''
																				);
																				if (!correctInput.startsWith(' ')) {
																					setOtherUsers({
																						...otherUsers,
																						Relationship: correctInput,
																					});
																				}
																			}}
																		/>
																	)}
																</Grid>
																<Grid item xs={12} sm={8} xl={8} md={8} lg={8}>
																	<InputLabel
																		sx={{
																			fontSize: {
																				xs: '13px',
																				md: '13px',
																				lg: '13px',
																				fontWeight: 700,
																			},
																			mb: 1,
																		}}
																	>
																		Phone Number*
																	</InputLabel>
																	{error === 12 ? (
																		<div style={{ marginRight: 4 }}>
																			<TextField
																				fullWidth
																				autoComplete="off"
																				label=""
																				size="small"
																				variant="outlined"
																				placeholder="Phone Number"
																				value={otherUsers.phonenumber}
																				onChange={(e) => {
																					const input = e.target.value;
																					const sanIniInput = input.replace(
																						/[^0-9]/g,
																						''
																					);
																					setOtherUsers({
																						...otherUsers,
																						phonenumber: sanIniInput,
																					});
																				}}
																			/>
																		</div>
																	) : (
																		<TextField
																			fullWidth
																			autoComplete="off"
																			id="outlined-basic"
																			label=""
																			size="small"
																			variant="outlined"
																			placeholder="Phone Number"
																			value={otherUsers.phonenumber}
																			inputProps={{ maxLength: 10 }}
																			onChange={(e) => {
																				const input = e.target.value;
																				const sanIniInput = input.replace(
																					/[^0-9]/g,
																					''
																				);
																				setOtherUsers({
																					...otherUsers,
																					phonenumber: sanIniInput,
																				});
																			}}
																		/>
																	)}
																</Grid>
																<Grid
																	item
																	xs={12}
																	sm={10}
																	xl={10}
																	md={10}
																	lg={10}
																>
																	{error === 13 ? (
																		<div>
																			<Box
																				pt={1}
																				pb={3}
																				px={3}
																				// border="1px dashed #000000"
																				// borderRadius={2}
																				textAlign="center"
																				sx={{
																					display: 'flex',
																					flexDirection: 'column',
																					justifyContent: 'center',
																					alignContent: 'center',
																					background: '#f3f3fe',
																					mt: 1,
																				}}
																			>
																				<InputLabel
																					sx={{
																						fontSize: {
																							xs: '13px',
																							md: '13px',
																							lg: '13px',
																							fontWeight: 700,
																						},
																						pb: 1,
																						textAlign: 'start',
																					}}
																				>
																					Upload Image{' '}
																					<span style={{ color: 'red' }}>
																						*
																					</span>
																				</InputLabel>
																				<div
																					style={{
																						border: '1px dashed #000000',
																						borderRadius: '3px',
																						background: 'white',
																						padding: '15px',
																					}}
																				>
																					<div style={{ textAlign: 'center' }}>
																						<img
																							src={cloud_computing}
																							style={{
																								height: '40px',
																								width: '40px',
																							}}
																						/>
																					</div>

																					<input
																						type="file"
																						accept="image/*"
																						onChange={handleFileChange}
																						style={{ display: 'none' }}
																						id="image-file-input"
																					/>
																					<label htmlFor="image-file-input">
																						<Button
																							sx={{
																								fontWeight: 600,
																								fontSize: '12px',
																								lineHeight: '18px',
																								letterSpacing: '.01em',
																								textTransform: 'none',
																							}}
																							component="span"
																						>
																							Upload a File
																						</Button>
																					</label>
																				</div>
																				{selectedFile && (
																					<div
																						style={{
																							display: 'flex',
																							justifyContent: 'space-between',
																							marginTop: '6px',
																							background: '#dae6ff',
																						}}
																					>
																						<Typography variant="subtitle1">
																							{selectedFile.name}
																						</Typography>
																						<DeleteOutlineIcon
																							sx={{
																								color: 'red',
																								cursor: 'pointer',
																							}}
																							onClick={handleDelete}
																						/>
																					</div>
																				)}
																			</Box>
																		</div>
																	) : (
																		<div>
																			<Box
																				pt={1}
																				pb={3}
																				px={3}
																				// border="1px dashed #000000"
																				// borderRadius={2}
																				textAlign="center"
																				sx={{
																					display: 'flex',
																					flexDirection: 'column',
																					justifyContent: 'center',
																					alignContent: 'center',
																					background: '#f3f3fe',
																					mt: 1,
																				}}
																			>
																				<InputLabel
																					sx={{
																						fontSize: {
																							xs: '13px',
																							md: '13px',
																							lg: '13px',
																							fontWeight: 700,
																						},
																						pb: 1,
																						textAlign: 'start',
																					}}
																				>
																					Upload Image{' '}
																					<span style={{ color: 'red' }}>
																						*
																					</span>
																				</InputLabel>
																				<div
																					style={{
																						border: '1px dashed #000000',
																						borderRadius: '3px',
																						background: 'white',
																						padding: '15px',
																					}}
																				>
																					<div style={{ textAlign: 'center' }}>
																						<img
																							src={cloud_computing}
																							style={{
																								height: '40px',
																								width: '40px',
																							}}
																						/>
																					</div>

																					<input
																						type="file"
																						accept="image/*"
																						onChange={handleFileChange}
																						style={{ display: 'none' }}
																						id="image-file-input"
																					/>
																					<label htmlFor="image-file-input">
																						<Button
																							sx={{
																								fontWeight: 600,
																								fontSize: '12px',
																								lineHeight: '18px',
																								letterSpacing: '.01em',
																								textTransform: 'none',
																							}}
																							component="span"
																						>
																							Upload a File
																						</Button>
																					</label>
																				</div>
																				{selectedFile && (
																					<div
																						style={{
																							display: 'flex',
																							justifyContent: 'space-between',
																							marginTop: '6px',
																							background: '#dae6ff',
																						}}
																					>
																						<Typography variant="subtitle1">
																							{selectedFile.name}
																						</Typography>
																						<DeleteOutlineIcon
																							sx={{
																								color: 'red',
																								cursor: 'pointer',
																							}}
																							onClick={handleDelete}
																						/>
																					</div>
																				)}
																			</Box>
																		</div>
																	)}
																</Grid>
															</Grid>
														</Grid>
													</Grid>
												</Box>
											) : (
												<Box
													className="e-book_personal_details"
													sx={{
														display: 'flex',
														justifyContent: 'center',
														alignItems: 'center',
													}}
												>
													<Grid
														container
														spacing={2}
														style={{ justifyContent: 'center' }}
													>
														<Grid item xs={12}>
															<Grid
																container
																spacing={2}
																style={{ justifyContent: 'center' }}
															>
																<Grid item xs={12} sm={5} xl={5} md={5} lg={5}>
																	<InputLabel
																		sx={{
																			fontSize: {
																				xs: '13px',
																				md: '13px',
																				lg: '13px',
																				fontWeight: 700,
																			},
																			mb: 1,
																		}}
																	>
																		Name*
																	</InputLabel>
																	{error === 1 ? (
																		<div>
																			<TextField
																				fullWidth
																				autoComplete="off"
																				id="outlined-basic"
																				label=""
																				size="small"
																				value={
																					`${
																						profileData[0] &&
																						profileData[0].user.first_name
																					} ${
																						profileData[0] &&
																						profileData[0].user.last_name
																					}` || users.name
																				}
																				
																				variant="outlined"
																			/>
																		</div>
																	) : (
																		<TextField
																			fullWidth
																			autoComplete="off"
																			id="outlined-basic"
																			label=""
																			size="small"
																			value={
																				`${
																					profileData[0] &&
																					profileData[0].user.first_name
																				} ${
																					profileData[0] &&
																					profileData[0].user.last_name
																				}` || users.name
																			}
																			variant="outlined"
																		/>
																	)}
																</Grid>
																<Grid item xs={12} sm={5} xl={5} md={5} lg={5}>
																	<InputLabel
																		sx={{
																			fontSize: {
																				xs: '13px',
																				md: '13px',
																				lg: '13px',
																				fontWeight: 700,
																			},
																			mb: 1,
																		}}
																	>
																		Email*
																	</InputLabel>
																	{error === 2 ? (
																		<div>
																			<TextField
																				fullWidth
																				autoComplete="off"
																				label=""
																				size="small"
																				variant="outlined"
																				value={
																					(profileData[0] &&
																						profileData[0].user.email) ||
																					users.email
																				}
																			

																				onChange={(e) => {
																					const newValue = e.target.value;
																					setUsers((prevUserDetail) => ({
																						...prevUserDetail,
																						email: newValue,
																					}));

																					if (profileData[0]) {
																						const updatedProfileData = [
																							...profileData,
																						];
																						updatedProfileData[0].user.email =
																							newValue;
																						setProfileData(updatedProfileData);
																					}
																				}}
																			/>
																		</div>
																	) : (
																		<TextField
																			fullWidth
																			autoComplete="off"
																			id="outlined-basic"
																			label=""
																			size="small"
																			variant="outlined"
																			value={
																				(profileData[0] &&
																					profileData[0].user.email) ||
																				users.email
																			}
																			onChange={(e) => {
																				const newValue = e.target.value;
																				setUsers((prevUserDetail) => ({
																					...prevUserDetail,
																					email: newValue,
																				}));
																				if (profileData[0]) {
																					const updatedProfileData = [
																						...profileData,
																					];
																					updatedProfileData[0].user.email =
																						newValue;
																					setProfileData(updatedProfileData);
																				}
																			}}
																		/>
																	)}
																</Grid>
															</Grid>
														</Grid>
														<Grid item xs={12} sm={11} xl={6} md={6} lg={6}>
															<InputLabel
																sx={{
																	fontSize: {
																		xs: '13px',
																		md: '13px',
																		lg: '13px',
																		fontWeight: 700,
																	},
																	mb: 1,
																}}
															>
																Phone Number*
															</InputLabel>
															{error === 3 ? (
																<div style={{ marginRight: 4 }}>
																	<TextField
																		fullWidth
																		autoComplete="off"
																		label=""
																		size="small"
																		variant="outlined"
																		value={
																			(profileData[0] &&
																				profileData[0].phone) ||
																			users.phonenumber
																		}
																		onChange={(e) =>
																			setUsers({
																				...users,
																				phonenumber: e.target.value,
																			})
																		}
																		// onChange={(e) => {
																		// 	const newValue = e.target.value;
																		// 	setUsers((prevUserDetail) => ({
																		// 		...prevUserDetail,
																		// 		phonenumber: newValue,
																		// 	}));

																		// 	if (profileData[0]) {
																		// 		const updatedProfileData = [
																		// 			...profileData,
																		// 		];
																		// 		updatedProfileData[0].phone = newValue;
																		// 		setProfileData(updatedProfileData);
																		// 	}
																		// }}
																	/>
																</div>
															) : (
																<TextField
																	fullWidth
																	autoComplete="off"
																	id="outlined-basic"
																	label=""
																	size="small"
																	variant="outlined"
																	inputProps={{ maxLength: 10 }}
																	value={
																		(profileData[0] && profileData[0].phone) ||
																		users.phonenumber
																	}
																	onChange={(e) =>
																		setUsers({
																			...users,
																			phonenumber: e.target.value,
																		})
																	}
																	// onChange={(e) => {
																	// 	const newValue = e.target.value;
																	// 	setUsers((prevUserDetail) => ({
																	// 		...prevUserDetail,
																	// 		phonenumber: newValue,
																	// 	}));

																	// 	if (profileData[0]) {
																	// 		const updatedProfileData = [...profileData];
																	// 		updatedProfileData[0].phone = newValue;
																	// 		setProfileData(updatedProfileData);
																	// 	}
																	// }}
																/>
															)}
														</Grid>
													</Grid>
												</Box>
											)}

											<div>
												<Typography
													gutterBottom
													variant="h4"
													component="div"
													fontWeight={'700'}
													fontSize={{
														lg: '14px',
														md: '14px',
														sm: '14px',
														xs: '14px',
													}}
													color={'#484848'}
													sx={{ mt: 4 }}
													textAlign={'center'}
												>
													The fields markes as * are mandatory
												</Typography>
											</div>

											<div className="ebook_btn">
												<Button
													variant="contained"
													size="small"
													className="ebook_submit"
													onClick={() => {
														// handlePersonalClick();
														validation();
														_updateProfile();
													}}
													sx={{
														textAlign: 'center',
														height: '44px',
														width: {
															xl: '250px',
															lg: '250px',
															md: '200px',
															sm: '200px',
															xs: '200px',
														},
														fontWeight: 600,
														fontSize: '12px',
														lineHeight: '18px',
														letterSpacing: '.01em',
														borderRadius: '12px !important',
														boxShadow: 'none !important',
														my: 2,
														background:
															' linear-gradient(to right, #141e30, #243b55)',
													}}
												>
													Save & Continue
												</Button>
											</div>
										</div>
									)}
									{stepNo === 2 && (
										<div
											style={{
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
												flexDirection: 'column',
												height: 'auto',
											}}
										>
											<Typography
												gutterBottom
												variant="h4"
												component="div"
												fontWeight={'700'}
												fontSize={{
													lg: '20px',
													md: '20px',
													sm: '20px',
													xs: '18px',
												}}
												color={'#484848'}
												sx={{ my: 2 }}
												textAlign={'start'}
											>
												CAR DETAILS <span style={{ color: 'red' }}>*</span>
											</Typography>
											<Box
												sx={{
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
												}}
											>
												<Grid container spacing={2}>
													<Grid item xs={12} sm={12} xl={6} md={6} lg={6}>
														<InputLabel
															sx={{
																fontSize: {
																	xs: '13px',
																	md: '13px',
																	lg: '13px',
																	fontWeight: 700,
																},
																mb: 1,
															}}
														>
															Your Car Variant*
														</InputLabel>
														<FormControl fullWidth>
															<TextField
																id="outlined-basic"
																label=""
																size="small"
																variant="outlined"
																defaultValue={
																	details ? details[0].title : cartTitle
																}
															/>
														</FormControl>
													</Grid>
													<Grid item xs={12} sm={12} xl={6} md={6} lg={6}>
														<InputLabel
															sx={{
																fontSize: {
																	xs: '13px',
																	md: '13px',
																	lg: '13px',
																	fontWeight: 700,
																},
																mb: 1,
															}}
														>
															Your Variant Color*
														</InputLabel>
														<FormControl fullWidth>
															<TextField
																fullwidth
																id="outlined-basic"
																label=""
																size="small"
																variant="outlined"
																defaultValue={
																	details ? details[0].color : cartColor
																}
															/>
														</FormControl>
													</Grid>
												</Grid>
											</Box>
											<div>
												<Typography
													gutterBottom
													variant="h4"
													component="div"
													fontWeight={'700'}
													fontSize={{
														lg: '14px',
														md: '14px',
														sm: '14px',
														xs: '14px',
													}}
													color={'#484848'}
													sx={{ mt: 4 }}
													textAlign={'start'}
												>
													The fields markes as * are mandatory
												</Typography>
											</div>
											<div className="ebook_btn">
												<Button
													variant="contained"
													size="small"
													className="ebook_submit"
													onClick={() => setStepNo(3)}
													sx={{
														textAlign: 'center',
														height: '44px',
														width: {
															xl: '250px',
															lg: '250px',
															md: '200px',
															sm: '200px',
															xs: '200px',
														},
														fontWeight: 600,
														fontSize: '12px',
														lineHeight: '18px',
														letterSpacing: '.01em',
														borderRadius: '12px !important',
														boxShadow: 'none !important',
														my: 2,
														background:
															' linear-gradient(to right, #141e30, #243b55)',
													}}
												>
													{' '}
													Confirm & Continue
												</Button>
											</div>
										</div>
									)}
									{stepNo === 3 && (
										<div className="otherdetails_container" style={{}}>
											<Typography
												gutterBottom
												variant="h4"
												component="div"
												fontWeight={'700'}
												fontSize={{
													lg: '20px',
													md: '20px',
													sm: '20px',
													xs: '18px',
												}}
												color={'#484848'}
												sx={{ my: 2, py: 3 }}
												textAlign={'center'}
											>
												OTHERS DETAILS <span style={{ color: 'red' }}>*</span>
											</Typography>
											<div
												style={{
													display: 'flex',
													justifyContent: 'space-between',
													alignItems: 'stretch',
													flexDirection: 'column',
													gap: 10,
												}}
											>
												<Box sx={{ flexGrow: 1, gap: 10 }}>
													<Grid container>
														<Grid xs={2} sx={{}}>
															<div className="numberCount">
																<span style={{ color: 'white' }}>1</span>
															</div>
														</Grid>
														<Grid
															xs={10}
															sx={{
																display: 'flex',
																alignItems: 'start',
																flexDirection: 'column',
															}}
														>
															<Typography
																gutterBottom
																variant="h4"
																component="div"
																fontWeight={'700'}
																fontSize={{
																	lg: '16px',
																	md: '16px',
																	sm: '16px',
																	xs: '16px',
																}}
																color={'#484848'}
																textAlign={'start'}
																my={1}
															>
																OUTLET DETAILS{' '}
																<span style={{ color: 'red' }}>*</span>
															</Typography>

															<Box
																sx={{
																	width: {
																		xs: '100%',
																		sm: '60%',
																		md: '60%',
																		xl: '60%',
																		lg: '60%',
																	},
																}}
															>
																<InputLabel
																	sx={{
																		fontSize: {
																			xs: '13px',
																			md: '13px',
																			lg: '13px',
																			fontWeight: 700,
																		},
																		my: 1,
																	}}
																>
																	Select Your City*
																</InputLabel>

																{error === 4 ? (
																	<FormControl fullwidth sx={{ width: '100%' }}>
																		<Select
																			displayEmpty
																			size="small"
																			value={outlet.city}
																			inputProps={{
																				'aria-label': 'Without label',
																			}}
																			onChange={(event) =>
																				handleSelectChange(event)
																			}
																			MenuProps={{
																				PaperProps: {
																					sx: { maxHeight: 200, fontSize: 10 },
																				},
																			}}
																			autoComplete="true"
																		>
																			{' '}
																			<MenuItem value="" disabled>
																				Choose a city
																			</MenuItem>
																			{city.map((item, index) => (
																				<MenuItem key={item.id} value={item.id}>
																					{item.title}
																				</MenuItem>
																			))}
																		</Select>
																	</FormControl>
																) : (
																	<FormControl sx={{ width: '100%' }}>
																		<Select
																			displayEmpty
																			size="small"
																			value={outlet.city}
																			inputProps={{
																				'aria-label': 'Without label',
																			}}
																			onChange={(event) =>
																				handleSelectChange(event)
																			}
																			MenuProps={{
																				PaperProps: {
																					sx: { maxHeight: 200, fontSize: 10 },
																				},
																			}}
																			autoComplete="true"
																		>
																			{' '}
																			<MenuItem value="" disabled>
																				Choose a city
																			</MenuItem>
																			{city.map((item, index) => (
																				<MenuItem
																					key={item.id}
																					value={item.id}
																					cities={item.title}
																				>
																					{item.title}
																				</MenuItem>
																			))}
																		</Select>
																	</FormControl>
																)}
															</Box>
															<Box
																sx={{
																	width: {
																		xs: '100%',
																		sm: '60%',
																		md: '60%',
																		xl: '60%',
																		lg: '60%',
																	},
																}}
															>
																<InputLabel
																	sx={{
																		fontSize: {
																			xs: '13px',
																			md: '13px',
																			lg: '13px',
																			fontWeight: 700,
																		},
																		my: 1,
																	}}
																>
																	Select Your Outlet*
																</InputLabel>

																{error === 5 ? (
																	<FormControl sx={{ width: '100%' }}>
																		<Select
																			onChange={handleOuletChange}
																			displayEmpty
																			value={outlet.locationCity}
																			size="small"
																			inputProps={{
																				'aria-label': 'Without label',
																			}}
																			MenuProps={{
																				PaperProps: { sx: { maxHeight: 200 } },
																			}}
																		>
																			<MenuItem value="" disabled>
																				Choose a outlets
																			</MenuItem>
																			{cityLocation.map((item, index) => (
																				<MenuItem value={item.id} key={item.id}>
																					{item.name}
																				</MenuItem>
																			))}
																		</Select>
																	</FormControl>
																) : (
																	<FormControl sx={{ width: '100%' }}>
																		<Select
																			onChange={handleOuletChange}
																			displayEmpty
																			value={outlet.locationCity}
																			size="small"
																			inputProps={{
																				'aria-label': 'Without label',
																			}}
																			MenuProps={{
																				PaperProps: { sx: { maxHeight: 200 } },
																			}}
																		>
																			<MenuItem value="" disabled>
																				Choose a outlets
																			</MenuItem>
																			{cityLocation.map((item, index) => (
																				<MenuItem value={item.id} key={item.id}>
																					{item.name}
																				</MenuItem>
																			))}
																		</Select>
																	</FormControl>
																)}
															</Box>

															<Typography
																gutterBottom
																variant="h4"
																component="div"
																fontWeight={'600'}
																fontSize={{
																	lg: '13px',
																	md: '13px',
																	sm: '13px',
																	xs: '13px',
																}}
																color={'#484848'}
																sx={{ my: 1 }}
																textAlign={'start'}
															>
																The fields markes as * are mandatory
															</Typography>
														</Grid>
													</Grid>
												</Box>
											</div>
											<div
												style={{
													display: 'flex',
													justifyContent: 'start',
													alignItems: 'start',
												}}
											>
												<Box sx={{ flexGrow: 1, gap: 10, my: 2 }}>
													<Grid container>
														<Grid xs={2} sx={{}}>
															<div className="numberCount">
																<span style={{ color: 'white' }}>2</span>
															</div>
														</Grid>
														<Grid xs={10}>
															<div>
																<Box
																	sx={{
																		display: 'flex',
																		alignItems: isbuyerScreen
																			? 'none'
																			: 'center',
																		justifyContent: 'space-between',
																	}}
																>
																	<Typography
																		gutterBottom
																		variant="h4"
																		component="div"
																		fontWeight={'700'}
																		fontSize={{
																			lg: '16px',
																			md: '16px',
																			sm: '16px',
																			xs: '16px',
																		}}
																		color={'#484848'}
																		textAlign={'start'}
																		my={1}
																	>
																		BUYER DETAILS{' '}
																		<span style={{ color: 'red' }}>*</span>
																	</Typography>
																	<div>
																		<Button
																			variant="contained"
																			size="small"
																			className="ebook_address_btn"
																			onClick={() => setAddressListModal(true)}
																			sx={{
																				fontSize: {
																					xs: '12px',
																					sm: '12px',
																					md: '14px',
																				},
																				fontWeight: 500,
																				letterSpacing: 0.5,
																				margin: isbuyerScreen ? 0.2 : 1,
																				textTransform: 'none',
																				p: isbuyerScreen ? 0.5 : 0.5,
																			}}
																		>
																			Address List
																		</Button>
																		<Button
																			variant="contained"
																			size="small"
																			className="ebook_address_btn"
																			onClick={() => {
																				setModal(true);
																				// addressClick();
																				setAddEbookNew(true);
																			}}
																			sx={{
																				fontSize: {
																					xs: '12px',
																					sm: '12px',
																					md: '14px',
																				},
																				fontWeight: 500,
																				letterSpacing: 0.5,
																				margin: isbuyerScreen ? 0.2 : 1,
																				textTransform: 'none',
																			}}
																		>
																			+ Add
																		</Button>
																	</div>

																	<Modal
																		open={addressListModal}
																		className="address_modal_container"
																	>
																		<div
																			className="addressList_modal_service"
																			onClick={() => setAddressListModal(false)}
																		>
																			<div
																				className="addressList_service"
																				onClick={(e) => {
																					e.stopPropagation();
																				}}
																			>
																				<div style={{ textAlign: 'end' }}>
																					<CloseIcon
																						onClick={() =>
																							setAddressListModal(false)
																						}
																					/>
																				</div>

																				<List>
																					{AddressList.profile_address.map(
																						(item, index) => (
																							<ListItem
																								onClick={() => {
																									setAutoFill(item);
																									setAddressListModal(false);
																								}}
																								sx={{
																									justifyContent: 'center',
																									display: 'flex',
																								}}
																							>
																								<div className="list_service">
																									<Box sx={{ flexGrow: 1 }}>
																										<ListItemText
																											primary={
																												<span
																													style={{
																														fontSize: 21,
																														fontWeight: '700',
																														paddingTop: 12,
																														paddingBottom: 12,
																													}}
																												>
																													<span></span>{' '}
																													<span>
																														{
																															item.type_of_address
																														}
																													</span>
																												</span>
																											}
																										/>

																										<ListItemText
																											sx={{ my: 1 }}
																											primary={
																												<span>
																													{item.door_no}
																													{','}
																													{item.street}
																												</span>
																											}
																										/>
																									</Box>
																								</div>
																							</ListItem>
																						)
																					)}
																				</List>
																			</div>
																		</div>
																	</Modal>

																	<div onClick={() => setModal(false)}>
																		<Modal
																			open={modal}
																			className="address_modal_container"
																			onClose={() => setModal(false)}
																		>
																			<AddressPopup
																				button={() => setModal(false)}
																				addEbookNew={addEbookNew}
																				setModal={setModal}
																				_getAddress={_getAddress}
																			/>
																		</Modal>
																	</div>
																</Box>
																<Box sx={{ mt: 2 }}>
																	<InputLabel
																		sx={{
																			fontSize: {
																				xs: '13px',
																				md: '13px',
																				lg: '13px',
																				fontWeight: 700,
																			},
																			mb: 1,
																		}}
																	>
																		Address*
																	</InputLabel>
																	<FormControl
																		sx={{
																			width: {
																				xs: '100%',
																				sm: '60%',
																				md: '60%',
																				xl: '60%',
																				lg: '60%',
																			},
																		}}
																	>
																		{error === 6 && !autoFill ? (
																			<div>
																				<TextField
																					fullWidth
																					label=""
																					variant="outlined"
																					size="small"
																					// defaultValue={outlet.address}
																					value={
																						autoFill
																							? `${autoFill.door_no || ''}, ${
																									autoFill.street || ''
																							  }`
																							: outlet.address
																					}
																					onChange={(e) =>
																						setOutlet({
																							...outlet,
																							address: e.target.value,
																						})
																					}
																					onClick={() =>
																						setAddressListModal(true)
																					}
																					multiline
																					autoComplete="off"
																				/>
																			</div>
																		) : (
																			<TextField
																				fullWidth
																				label=""
																				variant="outlined"
																				size="medium"
																				// defaultValue={outlet.address}
																				value={
																					autoFill
																						? `${autoFill.door_no || ''}, ${
																								autoFill.street || ''
																						  }`
																						: outlet.address
																				}
																				onChange={(e) =>
																					setOutlet({
																						...outlet,
																						address: e.target.value,
																					})
																				}
																				onClick={() =>
																					setAddressListModal(true)
																				}
																				autoComplete="off"
																				multiline
																			/>
																		)}
																	</FormControl>
																</Box>
																<Typography
																	gutterBottom
																	variant="h4"
																	component="div"
																	fontWeight={'600'}
																	fontSize={{
																		lg: '12px',
																		md: '12px',
																		sm: '12px',
																		xs: '12px',
																	}}
																	color={'#484848'}
																	sx={{ mb: 2, mt: 1 }}
																	textAlign={'start'}
																>
																	The fields marked with * are mandatory
																</Typography>
															</div>
														</Grid>
													</Grid>
												</Box>
											</div>
											<div
												style={{
													display: 'flex',
													justifyContent: 'start',
													alignItems: 'start',
												}}
											>
												<Box sx={{ flexGrow: 1, gap: 10 }}>
													<Grid container>
														<Grid xs={2} sx={{}}>
															<div className="numberCount">
																<span style={{ color: 'white' }}>3</span>
															</div>
														</Grid>
														<Grid
															xs={10}
															sx={{
																display: 'flex',
																alignItems: 'start',
																flexDirection: 'column',
															}}
														>
															<Typography
																gutterBottom
																variant="h4"
																component="div"
																fontWeight={'700'}
																fontSize={{
																	lg: '16px',
																	md: '16px',
																	sm: '16px',
																	xs: '16px',
																}}
																color={'#484848'}
																textAlign={'start'}
																my={1}
															>
																REFERENCE{' '}
																<span
																	style={{
																		color: '#484848',
																		fontWeight: '500',
																	}}
																>
																	(optional)
																</span>
															</Typography>
															<div style={{ width: '100%' }}>
																<InputLabel
																	sx={{
																		fontSize: {
																			xs: '13px',
																			md: '13px',
																			lg: '13px',
																			fontWeight: 700,
																		},
																		mb: 1,
																	}}
																>
																	Referred By (optional)
																</InputLabel>
																<FormControl
																	sx={{
																		width: {
																			xs: '100%',
																			sm: '60%',
																			md: '60%',
																			xl: '60%',
																			lg: '60%',
																		},
																	}}
																>
																	<TextField
																		id="outlined-basic"
																		value={outlet.employeeName}
																		onChange={(e) => {
																			const input = e.target.value;
																			const correctInput = input.replace(
																				/[^A-Za-z\s]/g,
																				''
																			);
																			if (!correctInput.startsWith(' ')) {
																				setOutlet({
																					...outlet,
																					employeeName: correctInput,
																				});
																			}
																		}}
																		autoComplete="off"
																		label=""
																		variant="outlined"
																		size="small"
																	/>
																</FormControl>
																<InputLabel
																	sx={{
																		fontSize: {
																			xs: '13px',
																			md: '13px',
																			lg: '13px',
																			fontWeight: 700,
																		},
																		mb: 1,
																	}}
																>
																	Employee Id(optional)
																</InputLabel>
																<FormControl
																	sx={{
																		width: {
																			xs: '100%',
																			sm: '60%',
																			md: '60%',
																			xl: '60%',
																			lg: '60%',
																		},
																	}}
																>
																	{error === 14 ? (
																		<div>
																			<TextField
																				id="outlined-basic"
																				autoComplete="off"
																				value={outlet.employeeCode}
																				fullWidth
																				onChange={(e) => {
																					const input = e.target.value;
																					const correctInput = input.replace(
																						/[^A-za-z0-9]/g,
																						''
																					);
																					if (
																						!correctInput.startsWith(' ') &&
																						correctInput.length <= 11
																					) {
																						setOutlet({
																							...outlet,
																							employeeCode: correctInput,
																						});
																					}
																				}}
																				label=""
																				variant="outlined"
																				size="small"
																			/>
																		</div>
																	) : (
																		<TextField
																			id="outlined-basic"
																			autoComplete="off"
																			value={outlet.employeeCode}
																			fullWidth
																			onChange={(e) => {
																				const input = e.target.value;
																				const correctInput = input.replace(
																					/[^A-za-z0-9]/g,
																					''
																				);
																				if (
																					!correctInput.startsWith(' ') &&
																					correctInput.length <= 11
																				) {
																					setOutlet({
																						...outlet,
																						employeeCode: correctInput,
																					});
																				}
																			}}
																			label=""
																			variant="outlined"
																			size="small"
																		/>
																	)}
																</FormControl>
															</div>
														</Grid>
													</Grid>
												</Box>
											</div>

											{/* <Box
												sx={{
													flexGrow: 1,
												}}
											>
												<Grid
													container
													// xs={11}
													// sm={11}
													// md={12}
													// xl={12}
													// lg={12}
													// sx={{
													//   display: "flex",
													//   justifyContent: "center",
													//   alignContent: "center",
													// }}
													// gap={2}
												>
													<Grid xs={2} sx={{}}>
														<div className="numberCount">
															<span style={{ color: 'white' }}>4</span>
														</div>
													</Grid>
													<Grid
														xs={10}
														sx={{
															display: 'flex',
															alignItems: 'start',
															flexDirection: 'column',
														}}
													>
														{' '}
														<Typography
															gutterBottom
															variant="h4"
															component="div"
															fontWeight={'700'}
															fontSize={{
																lg: '16px',
																md: '16px',
																sm: '16px',
																xs: '16px',
															}}
															color={'#484848'}
															textAlign={'start'}
															my={1}
														>
															ADVANCE PAYMENT
															<span
																style={{
																	color: 'RED',
																	fontWeight: '500',
																}}
															>
																*
															</span>
														</Typography>
														<div
															style={{
																display: 'flex',
																width: '100%',
																gap: '10px',
																margin: '20px 0px',
															}}
														>
															{priceList.map((item, index) => (
																<Grid
																	item
																	xs={4}
																	sm={4}
																	md={4}
																	xl={4}
																	lg={4}
																	key={item.id}
																	sx={{
																		display: 'flex',
																		flexDirection: 'row',
																		flexWrap: 'wrap',
																		// alignItems: "center",
																		rowGap: 5,
																	}}
																>
																	<Button
																		variant="contained"
																		size="small"
																		className={
																			payment === item.price
																				? 'payment_btn'
																				: 'price_btn'
																		}
																		onClick={() => setPayment(item.price)}
																		sx={{
																			fontSize: { xs: '13px' },
																			textTransform: 'none',
																			width: 'auto',
																			height: 'auto',
																		}}
																	>
																		{getComaPrice(item.price)} /-
																	</Button>
																</Grid>
															))}
														</div>
													</Grid>
												</Grid>
											</Box> */}

											<Box>
												<Grid container xl={12} lg={12} md={12} sm={12} xs={12}>
													<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
														<Grid
															container
															xl={12}
															lg={12}
															md={12}
															sm={12}
															xs={12}
														>
															<Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
																<div className="numberCount">
																	<span style={{ color: 'white' }}>4</span>
																</div>
															</Grid>
															<Grid
																item
																xl={10}
																lg={10}
																md={10}
																sm={10}
																xs={10}
															>
																<Typography
																	gutterBottom
																	variant="h4"
																	component="div"
																	fontWeight={'700'}
																	fontSize={{
																		lg: '16px',
																		md: '16px',
																		sm: '16px',
																		xs: '16px',
																	}}
																	color={'#484848'}
																	textAlign={'start'}
																	my={1}
																>
																	ADVANCE PAYMENT
																	<span
																		style={{
																			color: 'RED',
																			fontWeight: '500',
																		}}
																	>
																		*
																	</span>
																</Typography>

																<Grid
																	container
																	xl={12}
																	lg={12}
																	md={12}
																	sm={12}
																	xs={12}
																	rowGap={2}
																>
																	{priceList.map((item, index) => (
																		<Grid
																			item
																			xs={5}
																			sm={4}
																			md={4}
																			xl={3}
																			lg={3}
																			key={item.id}
																		>
																			<Button
																				variant="contained"
																				size="small"
																				className={
																					payment === item.price
																						? 'payment_btn'
																						: 'price_btn'
																				}
																				onClick={() => setPayment(item.price)}
																				sx={{
																					fontSize: '13px',
																				}}
																			>
																				{getComaPrice(item.price)} /-
																			</Button>
																		</Grid>
																	))}
																</Grid>
															</Grid>
														</Grid>
													</Grid>
												</Grid>
											</Box>

											<div className="disclaimer">
												<Typography
													gutterBottom
													variant="h4"
													component="div"
													fontWeight={'bold'}
													fontSize={{
														lg: '13px',
														md: '13px',
														sm: '13px',
														xs: '13px',
													}}
													color={'#484848'}
													textAlign={'start'}
													my={1}
												>
													NOTE :
												</Typography>
												<Typography
													gutterBottom
													variant="h4"
													component="div"
													fontWeight={'500'}
													letterSpacing={0.5}
													lineHeight={1.5}
													fontSize={{
														lg: '11px',
														md: '11px',
														sm: '11px',
														xs: '11px',
													}}
													color={'#484848'}
													textAlign={'start'}
													my={1}
												>
													Please choose your desired advance payment from the
													options below to book this vehicle, It's important to
													note that the advance booking amount and the on-road
													price are different. on-road price of the vehicle many
													over time, At the time of invoice or billing, to
													ensure a smooth delivery and registration process, For
													more information, Please contact our relationship
													manager after completing this bokking transaction.
												</Typography>
												{/* <Typography
                          gutterBottom
                          variant="h4"
                          component="div"
                          fontWeight={"700"}
                          fontSize={{
                            lg: "13px",
                            md: "13px",
                            sm: "13px",
                            xs: "13px",
                          }}
                          color={"#484848"}
                          textAlign={"start"}
                          my={1}
                        >
                          *Prices/Schemes prevailing at the time of invoice
                          /bill shall be applicable.
                        </Typography> */}
											</div>
											<div className="ebook_btn">
												<Button
													variant="contained"
													size="small"
													className="ebook_submit"
													onClick={() => outletValidation()}
													sx={{
														textAlign: 'center',
														height: '44px',
														width: {
															xl: '250px',
															lg: '250px',
															md: '200px',
															sm: '200px',
															xs: '200px',
														},
														fontWeight: 600,
														fontSize: '12px',
														lineHeight: '18px',
														letterSpacing: '.01em',
														borderRadius: '12px !important',
														boxShadow: 'none !important',
														my: 2,
														background:
															' linear-gradient(to right, #141e30, #243b55)',
													}}
												>
													{' '}
													Submit & Review
												</Button>
											</div>
										</div>
									)}
								</Grid>
								<Grid
									xs={12}
									lg={5}
									sm={12}
									md={6}
									sx={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<img
										src={img || cartImage || details[0].images[0]}
										style={{
											width: isSmallScreen ? '300px' : '380px',
											height: isSmallScreen ? '200px' : '250px',
										}}
									/>
								</Grid>
							</Grid>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</div>
	);
};

export default Ebook;
