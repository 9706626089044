import React, { useState, useEffect } from 'react';
import './Modal.css';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import ApiHandler from '../../Api/Apihandler';
import { ToastContainer, toast } from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';
// import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";

// const libraries = ["places"];
// const mapContainerStyle = {
//   width: "100vw",
//   height: "100vh",
// };
// const center = {
//   lat: 7.2905715, // default latitude
//   lng: 80.6337262, // default longitude
// };

const AddressPopup = (props, { button }, route) => {
	const [addressTypes, setAddressTypes] = useState('Home');
	const profileId = localStorage.getItem('loggedIn');
	const {
		typeofAddress,
		adddoorNo,
		addStreet,
		addArea,
		addCity,
		addLandmark,
		addPincode,
		addId,
		setModal,
		_getAddress,
		setSelectedAddress,
		addNew,
		addEbookNew,
		setAddressType,
	} = props;

	const [addressId, setAddressId] = useState();
	const [addAdress, setAddAddress] = useState({
		addressType: typeofAddress ? typeofAddress : addressTypes,
		doorNo: adddoorNo,
		// area: addArea ? addArea : '',
		// city: addCity ? addCity : '',
		// landmark: addLandmark ? addLandmark : '',
		// pincode: addPincode ? addPincode : '',
		street:
			(addStreet ? addStreet + ', ' : '') +
			(addArea ? addArea + ', ' : '') +
			(addCity ? addCity + ', ' : '') +
			(addLandmark ? addLandmark + ', ' : '') +
			(addPincode ? addPincode : ''),

		lat: '12311.223',
		long: '13.5234',
	});
	const id = localStorage.getItem('loggedIn');
	useEffect(() => {
		setAddressId(id);
		setAddressTypes(typeofAddress);
	}, []);

	console.log('street', addAdress.street);

	const [errorNo, setErrorNo] = useState(null);
	const type = () => toast.error('please choose a address type');
	const door_no = () => toast.error('please enter a vaild door no');
	const streetName = () => toast.error('please enter a valid street name');
	// const areaName = () => toast.error("please enter a valid area name");
	// const cityName = () => toast.error("please enter a valid city name");
	// const landMark = () => toast.error("please enter a valid landmark");
	// const pincode = () => toast.error("please enter a valid pincode");

	const addressValidation = () => {
		if (!addAdress.addressType) {
			setErrorNo(6);
			type();
		} else if (addAdress.doorNo.length === 0) {
			setErrorNo(1);
			door_no();
		} else if (addAdress.street.length === 0) {
			setErrorNo(2);
			streetName();
		}
		// else if (addAdress.area.length === 0) {
		//   setErrorNo(3);
		//   areaName();
		// } else if (addAdress.city.length === 0) {
		//   setErrorNo(4);
		//   cityName();
		// } else if (addAdress.landmark.length === 0) {
		//   setErrorNo(5);
		//   landMark();
		// } else if (
		//   addAdress.pincode.length !== 6 ||
		//   !/^[0-9]*\d$/.test(addAdress.pincode)
		// ) {
		//   setErrorNo(6);
		//   pincode();
		// }
		else {
			createAddress(profileId);
			_getAddress(id);
		}
	};

	const createAddress = async () => {
		const data = {
			profile: profileId,
			type_of_address: addressTypes,
			door_no: addAdress.doorNo,
			street: addAdress.street,
			lat: addAdress.lat,
			long: addAdress.long,
		};

		const response = await ApiHandler.createAddress(profileId, data);
		if (response.data.status === 200) {
			setModal(false);
			_getAddress(id);
			alert('Address was Successfully Stored');
			console.log('address', response);
		}
	};

	const _updateAddress = async () => {
		const data = {
			type_of_address: addressTypes,
			door_no: addAdress.doorNo,
			street: addAdress.street,
			area: addAdress.area,
			landmark: addAdress.landmark,
			city: addAdress.city,
			pincode: addAdress.pincode,
			lat: addAdress.lat,
			long: addAdress.long,
		};
		const response = await ApiHandler.modifyAddress(addId, data);
		try {
			const data = response.data.data;
			setAddAddress(data);
			setModal(false);
			_getAddress(id);
			alert('address was successfully updated');
			setSelectedAddress(null);
		} catch (error) {
			console.log('address update catch', error);
		}
	};
	const [center, setCenter] = useState({ lat: 0, lng: 0 });
	const [address, setAddress] = useState('');

	const apiKey = 'AIzaSyAH76PzrzUmCDKzbQFI9bqveYlWTlR9kX8';

	//google map

	// const { isLoaded, loadError } = useLoadScript({
	//   googleMapsApiKey: "AIzaSyAH76PzrzUmCDKzbQFI9bqveYlWTlR9kX8",
	//   libraries,
	// });

	// if (loadError) {
	//   return <div>Error loading maps</div>;
	// }

	// if (!isLoaded) {
	//   return <div>Loading maps</div>;
	// }

	//google map
	return (
		<div>
			<Box
				className="address_modal"
				// onClick={button}
			>
				<Grid className="add_address" onClick={(e) => e.stopPropagation()}>
					<Grid container xl={12} lg={12} md={12} sm={12} xs={12}>
						{/* <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
              <Button
                variant="contained"
                size="small"
                className={
                  addressTypes === "Home" || typeofAddress === "undefined"
                    ? "modal_address_btn"
                    : "modal_btn"
                }
                sx={{
                  mr: 1,
                  textAlign: "center",
                  height: "36px",
                  width: {
                    xl: "100px",
                    lg: "100px",
                    md: "80px",
                    sm: "50px",
                    xs: "50px",
                  },
                  fontWeight: 600,
                  fontSize: "12px",
                  lineHeight: "18px",
                  letterSpacing: ".01em",
                  borderRadius: "5px !important",
                  boxShadow: "none !important",
                  m: 1,
                  background:
                    "linear-gradient(to top, #09203f 0%, #537895 100%)",
                }}
                onClick={() => setAddressTypes("Home")}
              >
                Home
              </Button>
              <Button
                variant="contained"
                size="small"
                className={
                  addressTypes === "Office" ? "modal_address_btn" : "modal_btn"
                }
                sx={{
                  mr: 1,
                  textAlign: "center",
                  height: "36px",
                  width: {
                    xl: "100px",
                    lg: "100px",
                    md: "80px",
                    sm: "50px",
                    xs: "50px",
                  },
                  fontWeight: 600,
                  fontSize: "12px",
                  lineHeight: "18px",
                  letterSpacing: ".01em",
                  borderRadius: "5px !important",
                  boxShadow: "none !important",
                  m: 1,
                  background:
                    "linear-gradient(to top, #09203f 0%, #537895 100%)",
                }}
                onClick={() => setAddressTypes("Office")}
              >
                Office
              </Button>
              <Button
                variant="contained"
                size="small"
                className={
                  addressTypes === "Others" ? "modal_address_btn" : "modal_btn"
                }
                sx={{
                  mr: 1,
                  textAlign: "center",
                  height: "36px",
                  width: {
                    xl: "100px",
                    lg: "100px",
                    md: "80px",
                    sm: "50px",
                    xs: "50px",
                  },
                  fontWeight: 600,
                  fontSize: "12px",
                  lineHeight: "18px",
                  letterSpacing: ".01em",
                  borderRadius: "5px !important",
                  boxShadow: "none !important",
                  m: 1,
                  background:
                    "linear-gradient(to top, #09203f 0%, #537895 100%)",
                }}
                onClick={() => setAddressTypes("Others")}
              >
                Others
              </Button>
            </Grid> */}
						<Grid
							item
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							textAlign={'end'}
						>
							<CloseIcon
								sx={{
									width: {
										xl: '36px',
										lg: '36px',
										md: '30px',
										sm: '30px',
										xs: '30px',
									},
									height: {
										xl: '36px',
										lg: '36px',
										md: '30px',
										sm: '30px',
										xs: '30px',
									},
									borderRadius: '50%',
									backgroundColor: '#F7F7F7',
									cursor: 'pointer',
									padding: 1,
									mt: 1,
								}}
								onClick={() => setModal(false)}
							/>
						</Grid>
					</Grid>
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<InputLabel
							sx={{
								fontSize: {
									xs: '13px',
									md: '13px',
									lg: '13px',
									fontWeight: 600,
								},
								mt: 2,
								mb: 1,
							}}
						>
							Type Your Door No*
						</InputLabel>
						{errorNo === 1 ? (
							<div>
								<TextField
									fullWidth
									value={addAdress.doorNo}
									label=""
									variant="outlined"
									size="small"
									onChange={(e) =>
										setAddAddress({ ...addAdress, doorNo: e.target.value })
									}
								/>
							</div>
						) : (
							<TextField
								id="outlined-basic"
								value={addAdress.doorNo}
								label=""
								variant="outlined"
								size="small"
								onChange={(e) =>
									setAddAddress({ ...addAdress, doorNo: e.target.value })
								}
							/>
						)}

						<InputLabel
							sx={{
								fontSize: {
									xs: '13px',
									md: '13px',
									lg: '13px',
									fontWeight: 600,
								},
								mt: 2,
								mb: 1,
							}}
						>
							Type Your Street*
						</InputLabel>
						{errorNo === 2 ? (
							<div>
								<TextField
									fullWidth
									value={addAdress.street}
									label=""
									variant="outlined"
									size="small"
									onChange={(e) =>
										setAddAddress({ ...addAdress, street: e.target.value })
									}
								/>
							</div>
						) : (
							<TextField
								id="outlined-basic"
								value={addAdress.street === null ? null : addAdress.street}
								label=""
								variant="outlined"
								size="small"
								onChange={(e) =>
									setAddAddress({ ...addAdress, street: e.target.value })
								}
							/>
						)}

						{/* <InputLabel
              sx={{
                fontSize: {
                  xs: "13px",
                  md: "13px",
                  lg: "13px",
                  fontWeight: 600,
                },
                mt: 1,
                mb: 1,
              }}
            >
              Type Your Area*
            </InputLabel>
            {errorNo === 3 ? (
              <div>
                <ToastContainer
                  position="top-right"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  progressStyle={{
                    background:
                      "linear-gradient(to right, #aa4b6b, #6b6b83, #3b8d99)",
                  }}
                  theme="light"
                />
                <TextField
                  fullWidth
                  label=""
                  variant="outlined"
                  size="small"
                  value={
                    (addAdress.street === null) === null ? null : addAdress.area
                  }
                  onChange={(e) =>
                    setAddAddress({ ...addAdress, street: e.target.value })
                  }
                />
              </div>
            ) : (
              <TextField
                id="outlined-basic"
                label=""
                variant="outlined"
                size="small"
                value={addAdress.area === null ? null : addAdress.area}
                onChange={(e) =>
                  setAddAddress({ ...addAdress, area: e.target.value })
                }
                // sx={{ my: 2 }}
              />
            )}

            <InputLabel
              sx={{
                fontSize: {
                  xs: "13px",
                  md: "13px",
                  lg: "13px",
                  fontWeight: 600,
                },
                mt: 1,
                mb: 1,
              }}
            >
              Type Your City*
            </InputLabel>
            {errorNo === 4 ? (
              <div>
                <ToastContainer
                  position="top-right"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  progressStyle={{
                    background:
                      "linear-gradient(to right, #aa4b6b, #6b6b83, #3b8d99)",
                  }}
                  theme="light"
                />
                <TextField
                  fullWidth
                  label=""
                  variant="outlined"
                  size="small"
                  value={addAdress.city === null ? null : addAdress.city}
                  onChange={(e) =>
                    setAddAddress({ ...addAdress, city: e.target.value })
                  }
                />
              </div>
            ) : (
              <TextField
                id="outlined-basic"
                label=""
                variant="outlined"
                size="small"
                value={addAdress.city === null ? null : addAdress.city}
                onChange={(e) =>
                  setAddAddress({ ...addAdress, city: e.target.value })
                }
              />
            )}

            <InputLabel
              sx={{
                fontSize: {
                  xs: "13px",
                  md: "13px",
                  lg: "13px",
                  fontWeight: 600,
                },
                mt: 1,
                mb: 1,
              }}
            >
              Type Your Landmark*
            </InputLabel>
            {errorNo === 5 ? (
              <div>
                <ToastContainer
                  position="top-right"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  progressStyle={{
                    background:
                      "linear-gradient(to right, #aa4b6b, #6b6b83, #3b8d99)",
                  }}
                  theme="light"
                />
                <TextField
                  fullWidth
                  label=""
                  variant="outlined"
                  size="small"
                  value={
                    addAdress.landmark === null ? null : addAdress.landmark
                  }
                  onChange={(e) =>
                    setAddAddress({ ...addAdress, landmark: e.target.value })
                  }
                />
              </div>
            ) : (
              <TextField
                id="outlined-basic"
                label=""
                variant="outlined"
                size="small"
                value={addAdress.landmark === null ? null : addAdress.landmark}
                onChange={(e) =>
                  setAddAddress({ ...addAdress, landmark: e.target.value })
                }
              />
            )}

            <InputLabel
              sx={{
                fontSize: {
                  xs: "13px",
                  md: "13px",
                  lg: "13px",
                  fontWeight: 600,
                },
                mt: 1,
                mb: 1,
              }}
            >
              Type Your Pincode*
            </InputLabel>
            {errorNo === 6 ? (
              <div>
                <ToastContainer
                  position="top-right"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  progressStyle={{
                    background:
                      "linear-gradient(to right, #aa4b6b, #6b6b83, #3b8d99)",
                  }}
                  theme="light"
                />
                <TextField
                  fullWidth
                  label=""
                  variant="outlined"
                  size="small"
                  value={addAdress.pincode === null ? null : addAdress.pincode}
                  onChange={(e) =>
                    setAddAddress({ ...addAdress, pincode: e.target.value })
                  }
                />
              </div>
            ) : (
              <TextField
                id="outlined-basic"
                label=""
                variant="outlined"
                size="small"
                value={addAdress.pincode === null ? null : addAdress.pincode}
                onChange={(e) =>
                  setAddAddress({ ...addAdress, pincode: e.target.value })
                }
              />
            )} */}
						<div style={{ textAlign: 'end', marginTop: 10 }}>
							<Button
								variant="contained"
								size="small"
								className="modal_address"
								onClick={() => {
									if (addNew || addEbookNew) {
										addressValidation();
									} else {
										_updateAddress(addId);
									}
								}}
								sx={{
									textAlign: 'center',
									height: '44px',
									width: {
										xl: '250px',
										lg: '250px',
										md: '150px',
										sm: '150px',
										xs: '150px',
									},
									fontWeight: 600,
									fontSize: '12px',
									lineHeight: '18px',
									letterSpacing: '.01em',
									borderRadius: '12px !important',
									boxShadow: 'none !important',
									my: 1,
									background: ' linear-gradient(to right, #141e30, #243b55)',
								}}
							>
								Save
							</Button>
						</div>
					</div>

					{/* <div style={{ height: "400px", width: "100%" }}>
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              zoom={10}
              center={center}
            >
              <Marker position={center} />
            </GoogleMap>
          </div> */}
				</Grid>
			</Box>
		</div>
	);
};

export default AddressPopup;
