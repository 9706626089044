import React, { useState, useEffect } from "react";
import "./Filtercars.css";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import ApiHandler from "../../../../Api/Apihandler";
import Carcard from "../../../../Components/Card/Card";
import Skeleton from "@mui/material/Skeleton";
import { useNavigate } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "transprarent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Filtercars = () => {
  const [model, setModel] = useState("");
  const [variant, setVariant] = useState("");
  const [carlist, setCarList] = useState([]);
  const [variantList, setVariantList] = useState([]);
  const [loader, setLoader] = useState(false);
  const id = 1;

  useEffect(() => {
    setLoader(true);
    getCarlist();
  }, []);

  const getVariantlist = async (id) => {
    const response = await ApiHandler.getVarientsList("/" + id);
    try {
      const data = response.data.data;
      setVariantList(data);
    } catch (error) {
      console.log("varianterror", error);
    }
  };

  const getCarlist = async () => {
    const response = await ApiHandler.getModelList(id);
    setCarList(response.data.data);
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  };

  const handleChange = (event) => {
    setModel(event.target.value);
    const findModel = carlist.find((model, i) => {
      return model.title === event.target.value;
    });
    getVariantlist(findModel.id);
  };

  const handleVariantChange = (event) => {
    setVariant(event.target.value);
  };

  const combinedValue = [];

  if (model && carlist.some((item) => item.title === model)) {
    combinedValue.push(carlist.find((item) => item.title === model));
  }

  if (variant && variantList.some((item) => item.title === variant)) {
    combinedValue.push(variantList.find((item) => item.title === variant));
  }

  const navigate = useNavigate();
  const handleNavigateClick = (title, id) => {
    const Replace = title.replace(/ /g, "_");
    navigate(`/allcar/variant/${Replace}`, {
      state: { title, id },
      replace: true,
    });
  };

  const handleNavigateFilterDataClick = (title, id) => {
    const Replace = title.replace(/ /g, "_");
    navigate(`/car/${Replace}`, { state: { title, id } });
  };

  const filterdata = variantList.filter((item) => item.title === variant);

  return (
    <div className="filterCars">
      <Typography
        gutterBottom
        variant="h4"
        component="div"
        fontWeight={"700"}
        fontSize={{ lg: "30px", md: "25px", sm: "20px", xs: "18px" }}
        color={"black"}
        sx={{ my: 1 }}
        textAlign={"center"}
      >
        Choose your car
      </Typography>
      <Box sx={{ mx: 1 }}>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          marginTop={2}
          marginBottom={2}
        >
          <Grid item xs={6} sm={6} md={3} lg={3} xl={3} sx={{ width: "100%" }}>
            <Item>
              <FormControl fullWidth>
                <InputLabel size="small" id="demo-simple-select-label">
                  Model
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={model}
                  label="Model"
                  size="medium"
                  onChange={handleChange}
                  sx={{ height: 40 }}
                >
                  {carlist.map((model) => (
                    <MenuItem key={model.title} value={model.title}>
                      {model.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Item>
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
            <Item>
              <FormControl fullWidth>
                <InputLabel size="small" id="demo-simple-select-label">
                  Variant
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={variant}
                  label="Name"
                  size="medium"
                  sx={{ height: 40 }}
                  onChange={handleVariantChange}
                >
                  {variantList.map((variant) => (
                    <MenuItem key={variant.title} value={variant.title}>
                      {variant.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Item>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Item>
              <Autocomplete
                multiple
                size="small"
                id="tags-outlined"
                options={combinedValue}
                getOptionLabel={(option) => option.title || option}
                value={combinedValue}
                onChange={(event, newValue) => {
                  const selectedModel = newValue.find((item) =>
                    carlist.some((model) => model.title === item.title)
                  );
                  const selectedVariant = newValue.find((item) =>
                    variantList.some((variant) => variant.title === item.title)
                  );

                  setModel(selectedModel ? selectedModel.id : null);
                  setVariant(selectedVariant ? selectedVariant.title : null);
                }}
                filterSelectedOptions
                renderOption={(props, option) => (
                  <li {...props} key={option.title}>
                    {option.title}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choosen Value"
                    placeholder="Filter"
                  />
                )}
              />
            </Item>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ marginTop: 2 }}>
        <Grid container rowSpacing={2}>
          {loader === true &&
            Array.from({ length: 8 }).map((_, index) => (
              <Grid
                key={`skeleton-${index}`}
                item
                xs={6}
                sm={4}
                md={4}
                lg={3}
                p={1}
              >
                <Skeleton variant="rectangular" width="100%" height="305px" />
                <Skeleton variant="rounded" style={{ marginTop: "10px" }} />
                <Skeleton
                  variant="rounded"
                  width="90%"
                  style={{ marginTop: "10px" }}
                />
                <Skeleton
                  variant="rounded"
                  width="60%"
                  style={{ marginTop: "10px" }}
                />
              </Grid>
            ))}
          {carlist.length > 0 && variantList.length === 0
            ? carlist.map((item, index) => (
                <Grid key={index} item xs={6} sm={4} md={4} lg={3} p={1}>
                  <Carcard
                    handleClick={() => handleNavigateClick(item.title, item.id)}
                    title={item.title}
                    images={item.images[0]}
                    price={item.price}
                    carTitle={item.title}
                    style={{ cursor: "pointer" }}
                  />
                </Grid>
              ))
            : carlist.length > 0 &&
              variantList.length > 0 &&
              filterdata.length === 0
            ? variantList.map((item, index) => (
                <Grid key={index} item xs={6} sm={4} md={4} lg={3} p={1}>
                  <Carcard
                    handleClick={() =>
                      handleNavigateFilterDataClick(item.subtitle, item.id)
                    }
                    title={item.title}
                    images={item.images[0]}
                    price={item.price}
                    style={{ cursor: "pointer" }}
                  />
                </Grid>
              ))
            : filterdata.map((item, index) => (
                <Grid key={index} item xs={6} sm={4} md={4} lg={3} p={1}>
                  <Carcard
                    handleClick={() =>
                      handleNavigateFilterDataClick(item.subtitle, item.id)
                    }
                    title={item.title}
                    images={item.images[0]}
                    price={item.price}
                    style={{ cursor: "pointer" }}
                  />
                </Grid>
              ))}
        </Grid>
      </Box>
    </div>
  );
};
export default Filtercars;
