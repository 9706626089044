import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import './PaymentDetails.css'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Payment from '../../../Assets/Images/payment.png';
import { getComaPrice } from '../../../Constants/Comaprice';
import { formatDate } from '../../../Constants/FormatDateTime';
import redeem_icon from '../../../Assets/Images/Icons/redeem_icon.png';
import Button from '@mui/material/Button';
import Apihandler from '../../../Api/Apihandler';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import Coin from '../../../Assets/Images/Gifs/Coin.gif';
import TextField from '@mui/material/TextField';
import redeem_points from '../../../Assets/Images/Icons/redeem_points.png';
import SuccessFully_done from '../../../Assets/Images/Gifs/payment_successful.gif';
import reward from '../../../Assets/Images/reward.png';
import Divider from '@mui/material/Divider';

import redeem_right_icon from '../../../Assets/Images/Icons/redeem_right_icon.png';
import Variant from './../../Variant/Variant';

const titleStyle = {
  gutterBottom: true,
  variant: "h4",
  component: "div",
  fontWeight: 700,
  fontSize: { lg: '20px', md: '20px', sm: '20px', xs: '18px' },
  sx: { my: 2, textAlign: 'center', lineHeight: 2, color: "#424A99" },
};

const contentTitle = {
  ...titleStyle,
  fontSize: { lg: '18px', md: '18px', sm: '18px', xs: '18px' },
  sx: { my: 2, textAlign: 'start', lineHeight: 2, color: "#000" }
}

const name = {
  ...titleStyle,
  sx: { my: 1, textAlign: 'center', color: "#424A99" }
}

const channel = {
  ...titleStyle,
  fontSize: { lg: '15px', md: '15px', sm: '15px', xs: '15px' },
  sx: { my: 0, textAlign: 'start', lineHeight: 2, color: "#000" },
}
const desc = {
  ...channel,
  fontWeight: 500,
  sx: { my: 0.5, textAlign: 'start', lineHeight: 2, color: "#000" },
}
const descP = {
  ...desc,
  component: "span",
  fontSize: { lg: '13px', md: '13px', sm: '13px', xs: '13px' },
  sx: { my: 1, textAlign: 'start', lineHeight: 2, color: "#B9BDD6", display: "inline-block", }
}

const redeem = {
  ...titleStyle,
  variant: "span",
  fontSize: { lg: '18px', md: '18px', sm: '18px', xs: '18px' },
  sx: { mx: 2, my: 0, textAlign: 'start', lineHeight: 2, color: "violet", display: "inline-block" }
}

const descText = {
  display: "inline-block",
  minWidth: "140px",
};




const PaymentDetails = () => {
  const location = useLocation()
  const { item } = location.state || {}
  const [modal, setModal] = useState(false);
  const [grandTotal, setGrandTotal] = useState();
  const [saving, setSaving] = useState('');
  const [redeemPoint, setRedeemPoint] = useState([]);
  console.log('redeemPoint: ', redeemPoint);
  const [error, setError] = useState(null);
  const [redeemSuccess, setRedeemSuccess] = useState(false);
  console.log('item: ', item);

  const status = {
    ...contentTitle,
    component: "span",
    sx: { color: item.payment_status === 'Pending' ? '#FF8E4E' : item.payment_status === 'Success' ? '#05f300' : '#FE0000' }
  }

  const loginId = localStorage.getItem('loggedIn');
  useEffect(() => {
    _getLoyalityPoint(loginId);
  }, []);
  useEffect(() => {
    let points = 0;
    redeemPoint.map((item) => {
      if (item.claimed) {
        points = points + +item.selectedPoints;
      }
    });
    setSaving(points);
    console.log('points', points);
    let befGST = item.amount - points;
    setGrandTotal(befGST);
  }, [redeemPoint]);

  const openRedeemSuccess = () => {
    setRedeemSuccess(true);
    setTimeout(() => {
      setRedeemSuccess(false);
    }, 3000);
  };

  const handleNewCar = (title, points, selectedPoints) => {
    let newarray = redeemPoint.map((ite, i) => {
      if (title == ite.subtitle) {
        if (selectedPoints > points || selectedPoints === 0) {
          setError(i);
          return { ...ite, claimed: false };
        } else {
          return { ...ite, claimed: !ite.claimed };
        }
      } else {
        return ite;
      }
    });
    setRedeemPoint(newarray);
  };

  const _getLoyalityPoint = async (loginId) => {
    const response = await Apihandler.getLoyalityPoint(loginId);
    if (response.data.status !== 200) {
    } else {
      console.log('loyalt data', response.data.data);
      response.data.data &&
        response.data.data.length >= 0 &&
        setRedeemPoint([
          {
            title: 'Earned Points',
            subtitle: 'EarnedPoint',
            claimed: false,
            points: response.data.data[0].balance_points,
            selectedPoints: 0,
          },
          {
            title: 'Inaugural Points',
            subtitle: 'InauguralPoint',
            claimed: false,
            points: response.data.data[0].inaugural_points,
            selectedPoints: 0,
          },
        ]);
      // setLoyalityPoint(response.data.data);
    }
  };

  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };


  return (
    <div>
      <Typography {...titleStyle}>
        Payment Simplified
      </Typography>
      <Box>
        <Grid container className='payment_details_main_container'>
          <Grid xs={12} sm={12} md={4}>
            <Grid container>
              <Grid xs={6} sm={6} md={12}>
                <div className='img_con'>
                  <img src={Payment} alt="payment" width={'100%'} height={'100%'} />
                </div>
              </Grid>
              <Grid xs={6} sm={6} md={12}>
                <Typography {...name}>
                  Hi,
                </Typography>
                <Typography {...name}>
                  {item.name}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12} sm={12} md={6}>
            <Grid container>
              <Grid xs={6}>
                <Typography {...contentTitle}>
                  Payment Request
                </Typography>
              </Grid>
              <Grid xs={6}>
                <Typography {...contentTitle}>
                  Status : <Typography {...status}>{item.payment_status}</Typography>
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid xs={6}>
                <Typography {...channel}>
                  {item.channel}
                </Typography>
                <Typography {...desc}>
                  <Typography {...descText}>Requested By :</Typography>
                  <Typography {...descP}>{item.requested_employee_name}</Typography>
                </Typography>
                <Typography {...desc}>
                  <Typography {...descText}>Registration No :</Typography>
                  <Typography {...descP}>{item.register_no_or_cust_id}</Typography>
                </Typography>
                <Typography {...desc}>
                  <Typography {...descText}>Reference No :</Typography>
                  <Typography {...descP}>{item.reference_no}</Typography>
                </Typography>
                <Typography {...desc}>
                  <Typography {...descText}>Purpose :</Typography>
                  <Typography {...descP}>{item.purpose}</Typography>
                </Typography>

              </Grid>
              <Grid xs={6}>
                <Typography {...status}>
                  {getComaPrice(item.amount)}
                </Typography>
              </Grid>
            </Grid>
            <Box>
              <Typography {...contentTitle}>
                Savings Corner
              </Typography>
              <Grid container>
                <Grid xs={6}>
                  <img src={redeem_icon} style={{ width: '20px', height: '20px' }} />
                  <Typography {...redeem}>Redeem Points</Typography>
                  {redeemPoint && redeemPoint.length !== 0 && (
                    <>
                      {redeemPoint.map((item) => (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                          key={item.title}
                        >
                          {item.claimed === true && (
                            <Grid
                              container
                              sx={{
                                display: 'flex',
                                justifyContent: 'start',
                                alignContent: 'center',
                              }}
                            >
                              <Grid
                                xs={11}
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'start',
                                  alignContent: 'center',
                                  my: 1,
                                }}
                              >
                                <img
                                  src={redeem_right_icon}
                                  style={{
                                    width: '15px',
                                    height: '16px',
                                  }}
                                  alt="Redeem Icon"
                                />
                                &nbsp;
                                <Typography
                                  gutterBottom
                                  variant="h4"
                                  component="div"
                                  fontWeight={'700'}
                                  fontSize={'14px'}
                                  color={'#000'}
                                  sx={{
                                    px: { xl: 1, lg: 1, md: 1 },
                                  }}
                                  textAlign={'start'}
                                >
                                  {item.title}
                                </Typography>
                                <Typography
                                  gutterBottom
                                  variant="h4"
                                  component="div"
                                  fontWeight={'700'}
                                  fontSize={'14px'}
                                  color={'green'}
                                  ml={1}
                                  textAlign={'start'}
                                >
                                  {getComaPrice(item.selectedPoints)}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                        </Box>
                      ))}
                    </>
                  )}
                </Grid>
                <Grid xs={6}>
                  <Button variant="contained" textAlign="center" onClick={openModal}>Apply Offer</Button>
                  {redeemPoint && redeemPoint.length !== 0 && (
                    <>
                      {redeemPoint.map(
                        (item, index) =>
                          item.claimed === true && (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'end',
                              }}
                            >
                              <Button
                                key={index}
                                onClick={() => {
                                  handleNewCar(
                                    item.subtitle,
                                    item.points,
                                    item.selectedPoints
                                  );
                                }}
                                variant="text"
                                size="small"
                                className=""
                                sx={{
                                  fontSize: {
                                    xs: '12px',
                                    sm: '12px',
                                    md: '12px',
                                    lg: '13px',
                                    xl: '13px',
                                  },
                                  fontWeight: 600,
                                  letterSpacing: 0.5,
                                  py: 1,
                                  color: 'red',
                                  textTransform: 'none',
                                }}
                              >
                                Remove
                              </Button>
                            </div>
                          )
                      )}
                    </>
                  )}
                </Grid>
              </Grid>

            </Box>
            <Box>
              <Typography {...contentTitle}>Billing Summary</Typography>
              <Box sx={{ flexGrow: 1 }} className="billing_summery">
                <Grid container>
                  <Grid xs={6} md={6} sm={6} lg={6} xl={6}>
                    <Typography
                      gutterBottom
                      variant="p"
                      component="div"
                      fontWeight={'700'}
                      fontSize={{
                        lg: '13px',
                        md: '13px',
                        sm: '13px',
                        xs: '13px',
                      }}
                      color={'#000'}
                      sx={{ my: 1, p: 1, pl: { xl: 10, lg: 10 } }}
                      textAlign={'start'}
                    >
                      Subtotal
                    </Typography>
                  </Grid>
                  <Grid xs={6} md={6} sm={6} lg={6} xl={6}>
                    <Typography
                      gutterBottom
                      variant="p"
                      component="div"
                      fontWeight={'500'}
                      fontSize={{
                        lg: '13px',
                        md: '13px',
                        sm: '13px',
                        xs: '13px',
                      }}
                      color={'#484848'}
                      sx={{ my: 1, p: 1 }}
                      textAlign={'center'}
                    >
                      + {getComaPrice(item.amount)}
                    </Typography>
                  </Grid>
                  <Grid xs={6} md={6} sm={6} lg={6} xl={6}>
                    <Typography
                      gutterBottom
                      variant="p"
                      component="div"
                      fontWeight={'700'}
                      fontSize={{
                        lg: '13px',
                        md: '13px',
                        sm: '13px',
                        xs: '13px',
                      }}
                      color={'#000'}
                      sx={{ p: 1, pl: { xl: 10, lg: 10 } }}
                      textAlign={'start'}
                    >
                      Total Saving
                    </Typography>
                  </Grid>
                  <Grid xs={6} md={6} sm={6} lg={6} xl={6}>
                    <Typography
                      gutterBottom
                      variant="p"
                      component="div"
                      fontWeight={'500'}
                      fontSize={{
                        lg: '13px',
                        md: '13px',
                        sm: '13px',
                        xs: '13px',
                      }}
                      color={'green'}
                      textAlign={'center'}
                      sx={{ p: 1 }}
                    >
                      - {getComaPrice(saving)}
                    </Typography>
                  </Grid>
                  <Divider
                    sx={{
                      border: '1px solid black',
                      width: '100%',
                      my: 2,
                    }}
                  />
                  <Grid xs={6} md={6} sm={6} lg={6} xl={6}>
                    <Typography
                      gutterBottom
                      variant="p"
                      component="div"
                      fontWeight={'700'}
                      fontSize={{
                        xl: '16px',
                        lg: '16px',
                        md: '14px',
                        sm: '14px',
                        xs: '14px',
                      }}
                      color={'#000'}
                      sx={{ p: 1, pl: { xl: 10, lg: 10 } }}
                      textAlign={'start'}
                    >
                      Grand Total
                    </Typography>
                  </Grid>
                  <Grid xs={6} md={6} sm={6} lg={6} xl={6}>
                    <Typography
                      gutterBottom
                      variant="p"
                      component="div"
                      fontWeight={'600'}
                      fontSize={{
                        xl: '16px',
                        lg: '16px',
                        md: '13px',
                        sm: '13px',
                        xs: '13px',
                      }}
                      color={'#484848'}
                      textAlign={'center'}
                      sx={{ p: 1 }}
                    >
                      {getComaPrice(grandTotal)}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              {saving !== 0 && (
                <div style={{ display: 'flex' }}>
                  <Typography
                    gutterBottom
                    variant="p"
                    component="div"
                    fontWeight={'600'}
                    fontSize={'14px'}
                    color={'#10BC10'}
                    textAlign={'start'}
                    sx={{ p: 1 }}
                    pl={{ xl: 10, lg: 10, sm: 2, xs: 2 }}
                  >
                    You Will Save {getComaPrice(saving)} on this order
                  </Typography>
                  <div style={{ marginTop: '6px' }}>
                    <img src={reward} width={'20px'} height={'20px'} />
                    <img src={reward} width={'20px'} height={'20px'} />
                  </div>
                </div>
              )}
            </Box>
            {item.payment_status === "Pending" && (
              <Button>Pay Now</Button>
            )}
            <Button>Back</Button>
          </Grid>
        </Grid>
      </Box>
      <Modal
        open={modal}
        onClose={closeModal}
        className="redeem_point_container"
      >
        <div className="redeem_point">
          <div
            style={{
              // background: '#cf9fff',
              textAlign: 'end',
              // borderRadius: '10px',
              borderTopRightRadius: '20px',
              borderTopLeftRadius: '20px',
            }}
          >
            <CloseIcon
              sx={{ mr: 1, mt: 1, cursor: 'pointer' }}
              onClick={closeModal}
            />
          </div>
          <h3 className="redeem_heading">Redemption Points</h3>

          <Box>
            <Grid
              container
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {redeemPoint && redeemPoint.length !== 0 && (
                <>
                  {redeemPoint.map((item, index) => (
                    <Grid
                      item
                      xs={11}
                      sx={{
                        boxShadow: 1,
                        my: 2,
                        textAlign: 'center',
                        borderRadius: 3,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                      }}
                    >

                      <div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            paddingTop: 15,
                            paddingBottom: 5,
                          }}
                        >
                          {/* <ReceiptPercentIcon
                                      size={20}
                                      color={"blue"}
                                    /> */}
                          <img
                            src={redeem_points}
                            style={{
                              widht: '20px',
                              height: '20px',
                              marginRight: '5px',
                            }}
                          />
                          <Typography
                            style={{
                              fontSize: 18,
                              fontWeight: '600',
                            }}
                          >
                            {'Your ' +
                              item.title +
                              ': ' +
                              Math.floor(item.points)}
                          </Typography>
                          <img
                            src={Coin}
                            style={{
                              width: 65,
                              height: 40,
                              objectFit: 'contain',
                              // marginLeft: -10,
                            }}
                          />
                        </div>
                        {item.points.toFixed(0) == 0 ? null : (
                          <Typography
                            style={{
                              color: 'green',
                              paddingTop: 10,
                            }}
                          >
                            You are Saving {Math.floor(item.points)}{' '}
                            With This Points
                          </Typography>
                        )}

                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-evenly',
                            paddingTop: 20,
                            paddingBottom: 15,
                          }}
                        >
                          <TextField
                            id="standard-basic"
                            label=""
                            variant="outlined"
                            size="small"
                            disabled={item.claimed}
                            value={item.selectedPoints}
                            onChange={(e) => {
                              let updated = redeemPoint.map(
                                (ite, i) => {
                                  if (i === index) {
                                    return {
                                      ...ite,
                                      selectedPoints: e.target.value
                                        .split('.')[0]
                                        .replace(/[^0-9 ]/g, '')
                                        .trim(),
                                    };
                                  } else {
                                    return ite;
                                  }
                                }
                              );
                              setRedeemPoint(updated);
                              setError(null);
                            }}
                            placeholder="Enter Points To Redeem"
                            placeholderTextColor="#8c8c8c"
                            style={{
                              color: item.claimed ? '#aaa' : 'black',
                              borderBottomColor: 'grey',
                            }}
                            autoCorrect={false}
                            autoComplete="off"
                          />
                          {item.claimed ? (
                            <Button
                              className="OfferCoin_Added"
                              variant="contained"
                              onClick={() => {
                                handleNewCar(
                                  item.subtitle,
                                  item.points,
                                  item.selectedPoints
                                );
                              }}
                              sx={{
                                marginBottom: '10px',
                              }}
                            >
                              Added
                            </Button>
                          ) : (
                            <Button
                              className="OfferCoin_Add"
                              variant="contained"
                              onClick={() => {
                                openRedeemSuccess();
                                closeModal();
                                handleNewCar(
                                  item.subtitle,
                                  item.points,
                                  item.selectedPoints
                                );
                              }}
                              sx={{
                                marginBottom: '10px',
                              }}
                            >
                              Add
                            </Button>
                          )}
                        </div>
                        {error === index ? (
                          <Typography
                            style={{
                              marginLeft: 20,
                              marginVertical: 1,
                              fontSize: 14,
                              fontWeight: '600',
                              color: 'red',
                              textAlign: 'start',
                            }}
                          >
                            Please Enter Valid Points&nbsp;
                          </Typography>
                        ) : (
                          <></>
                        )}
                      </div>

                    </Grid>
                  ))}
                </>
              )}
            </Grid>
          </Box>
        </div>
      </Modal>
      <Modal
        open={redeemSuccess}
        onClose={openRedeemSuccess}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            boxShadow: 24,
            width: { xl: 450, lg: 450, md: 450, sm: 450, xs: 350 },
            background: 'white',
            py: { xl: 5, lg: 5, md: 5, sm: 4, xs: 4 },
            px: { xl: 8, lg: 8, md: 5, sm: 4, xs: 4 },
            borderRadius: 4,
          }}
        >
          <Grid container xs={12}>
            <Grid item textAlign={'center'} xs={12}>
              <img src={SuccessFully_done} width={'120px'} height={'100px'} />
              <Typography
                gutterBottom
                variant="h4"
                component="div"
                fontWeight={'700'}
                fontSize={'20px'}
                color={'#000'}
              >
                You Saved {getComaPrice(saving)}
              </Typography>
              <Typography
                gutterBottom
                variant="h4"
                component="div"
                fontWeight={'700'}
                fontSize={'15px'}
                color={'#444444'}
                my={1.5}
              >
                With this reward points
              </Typography>
              <Typography
                gutterBottom
                variant="h4"
                component="div"
                fontWeight={'700'}
                fontSize={'12px'}
                color={'#274472'}
              >
                Wow! Thanks
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  )
}

export default PaymentDetails
