import React, { useEffect, useState } from "react";
import "./Slider.css";
import Slider from "react-slick";
import ApiHandler from "../../../../Api/Apihandler";
import { getComaPrice } from "../../../../Constants/Comaprice";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";
function SampleNextArrow(props) {
  const { className, style, onClick } = props;

  return (
    <div
      className={className}
      style={{
        ...style,
        height: "80px",
        width: "40px",
        backgroundColor: "white",
        borderRadius: "6px",
        color: "black",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: "-2px -4px 3px rgba(0,0,0,0.2)",
        right: 0,
      }}
      onClick={onClick}
    />
  );
}
function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        height: "80px",
        width: "40px",
        backgroundColor: "white",
        borderRadius: "6px",
        color: "black",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: "4px 4px 4px 3px rgba(0,0,0,0.2)",
        left: 0,
        zIndex: 20,
      }}
      onClick={onClick}
    />
  );
}
function CarSlider() {
  const [carList, setCarList] = useState([]);
  const [slider, setSlider] = useState(4);
  const [loader, setLoader] = useState(false);
  const isFontSize = useMediaQuery("(max-width:500px)");
  const navigate = useNavigate();
  useEffect(() => {
    const handleSet = () => {
      const newSlider = window.innerWidth < 768 ? 2 : 4;
      setSlider(newSlider);
    };
    handleSet();
    window.addEventListener("resize", handleSet);
    return () => {
      window.removeEventListener("resize", handleSet);
    };
  });

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slider,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    showIndicator: false,
    dots: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  useEffect(() => {
    setLoader(true);
    fetchcars();
  }, []);
  const fetchcars = async () => {
    const response = await ApiHandler.getSmallItemData(1);
    setCarList(response.data.data);
    setLoader(false);
  };

  const handleClick = (title, id) => {
    const Replace = title.replace(/ /g, "_");
    navigate(`/allcar/variant/${Replace}`, { state: { title, id } });
  };

  return (
    <div className="slider_main">
      <Slider {...settings}>
        {carList.map((item, _, index) => {
          <Grid
            item
            key={`Skeleton-${index}`}
            xs={6}
            sm={4}
            md={4}
            lg={3}
            p={1}
          >
            <Skeleton variant="rectangular" width="100%" height="305px" />
            <Skeleton variant="rounded" style={{ marginTop: "10px" }} />
            <Skeleton
              variant="rounded"
              width="90%"
              style={{ marginTop: "10px" }}
            />
            <Skeleton
              variant="rounded"
              width="60%"
              style={{ marginTop: "10px" }}
            />
          </Grid>;

          return (
            <div>
              {loader ? (
                Array.from({ length: item.length }).map((_, index) => (
                  <Grid key={index} item xs={6} sm={4} md={4} lg={3} p={1}>
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height="305px"
                    />
                    <Skeleton variant="rounded" style={{ marginTop: "10px" }} />
                    <Skeleton
                      variant="rounded"
                      width="90%"
                      style={{ marginTop: "10px" }}
                    />
                    <Skeleton
                      variant="rounded"
                      width="60%"
                      style={{ marginTop: "10px" }}
                    />
                  </Grid>
                ))
              ) : (
                <div
                  className="slider_card_main"
                  onClick={() => handleClick(item.title, item.id)}
                >
                  <div className="slider_card">
                    <img
                      src={item.images[0]}
                      className="card_img"
                      alt={item.title}
                    />
                    <div className="card_content">
                      {/* <p className="title" style={{fonsize:{xs:'10px',sm:'10px'}}}>{item.title}</p> */}
                      <Typography
                        gutterBottom
                        variant="p"
                        component="div"
                        fontWeight={"700"}
                        fontSize={{
                          xs: "9px",
                          sm: "11px",
                          md: "14px",
                          lg: "12px",
                        }}
                        color={"black"}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        gutterBottom
                        variant="p"
                        component="div"
                        fontWeight={"600"}
                        fontSize={{ xs: "9px", sm: "13px" }}
                        color={"green"}
                      >
                        {getComaPrice(item.price)}
                      </Typography>
                      <Typography
                        gutterBottom
                        variant="p"
                        component="div"
                        fontWeight={"400"}
                        fontSize={{ xs: "9px", sm: "13px" }}
                        color={"grey"}
                      >
                        Ex-ShowRoom Price
                      </Typography>
                    </div>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

export default CarSlider;
