import React, { useState, useEffect } from 'react';
import './Variant.css';
import Carcard from '../../Components/Card/Card';
import Typography from '@mui/material/Typography';
import ApiHandler from '../../Api/Apihandler';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useLocation, useParams } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import { useNavigate } from 'react-router-dom';

const Variant = ({ props }) => {
	const [variants, setvariant] = useState([]);
	const [loader, setLoader] = useState(true);
	const [urlId, setUrlId] = useState(null);
	const location = useLocation();
	const { id, title } = location.state || {};
	const { Replace, ReplaceId } = useParams();

	//url purpose
	const ReplaceTitle = Replace.replace(/_/g, ' ');
	const parts = ReplaceTitle.slice(13);
	const filterTitle = variants.filter((item) => item.title === parts);
	const filterId = filterTitle.map((item) => item.id);
	const filterInt = Number(filterId);
	//url purpose

	const navigate = useNavigate();

	const handleClick = (id, subtitle) => {
		const Replace = subtitle.replace(/ /g, '_');
		navigate(`/car/${Replace}`, { state: { subtitle, id } });
	};

	useEffect(() => {
		// geturlTitle(ReplaceTitle);
		const _getVarientsList = async (id, urlId) => {
			try {
				const response = await ApiHandler.getVarientsList('/' + id || urlId);
				const data = response?.data?.data;
				if (data) {
					setvariant(data);
					const timeout = setTimeout(() => {
						setLoader(false);
					}, 1200);
					return () => clearTimeout(timeout);
				}
			} catch (error) {
				console.log(error);
			}
		};

		_getVarientsList(id || urlId);
	}, [id, urlId]);

	// const geturlTitle = async () => {
	// 	const data = { title: ReplaceTitle };
	// 	const response = await ApiHandler.getUrlTitle(data);
	// 	try {
	// 		setUrlId(response.data.data[0].id);
	// 	} catch (error) {
	// 		console.log('urlTitle error', error);
	// 	}
	// };

	return (
		<div>
			<div className="Variant_title_bar"></div>
			<div className="Variant-heading">
				<Typography
					gutterBottom
					variant="h4"
					component="div"
					fontWeight={'700'}
					fontSize={{ lg: '30px', md: '25px', sm: '20px', xs: '18px' }}
					color={'#282828'}
					sx={{ my: 2, mx: 5 }}
					textAlign={'start'}
				>
					{ReplaceTitle}
				</Typography>
			</div>
			<Box
				sx={{ marginTop: 10, width: '96%', margin: 'auto', marginBottom: 5 }}
			>
				<Grid container rowSpacing={2}>
					{loader
						? Array.from({ length: 8 }).map((index) => (
								<Grid key={index} item xs={6} sm={4} md={4} lg={3} p={1}>
									<Skeleton variant="rectangular" width="100%" height="305px" />
									<Skeleton variant="rounded" style={{ marginTop: '10px' }} />
									<Skeleton
										variant="rounded"
										width="90%"
										style={{ marginTop: '10px' }}
									/>
									<Skeleton
										variant="rounded"
										width="60%"
										style={{ marginTop: '10px' }}
									/>
								</Grid>
						  ))
						: variants.map((item, index) => (
								<Grid key={index} item xs={6} sm={4} md={4} lg={3} p={1}>
									<Carcard
										handleClick={() => handleClick(item.id, item.subtitle)}
										title={item.title}
										images={item.images[0]}
										price={item.ex_price.toFixed(2)}
										carTitle={item.title}
									/>
								</Grid>
						  ))}
				</Grid>
			</Box>
		</div>
	);
};
export default Variant;
