import React, { useState } from 'react';
import './Failure.css';
import PaymentError_gif from '../../Assets/Images/Gifs/payment_error.gif';
import { useNavigate } from 'react-router';
import HeaderMain from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import { Box, Grid, Typography, Button } from '@mui/material';
import { getComaPrice } from '../../Constants/Comaprice';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import failure_gif from '../../Assets/Images/Gifs/failure_gif.gif';
import processing_payment_img from '../../Assets/Images/processing_payment.gif';
import reward from '../../Assets/Images/reward.png';
import make_payment from '../../Assets/Images/make_payment.jpg';
import { Modal, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// export function Payment_Failure() {
// 	const navigate = useNavigate();
// 	return (
// 		<>
// 			<Box
// 				sx={{
// 					display: 'flex',
// 					flexDirection: 'column',
// 					justifyContent: 'center',
// 					alignContent: 'center',
// 					mx: 2,
// 					background: '#fafafa',
// 					my: 1,
// 				}}
// 			>
// 				<Grid
// 					container
// 					xs={12}
// 					sm={12}
// 					md={12}
// 					lg={12}
// 					xl={12}
// 					sx={{
// 						display: 'flex',
// 						justifyContent: 'center',
// 						flexDirection: 'column',
// 						justifyItems: 'center',
// 						alignContent: 'center',
// 						alignItems: 'center',
// 					}}
// 				>
// 					<Grid
// 						item
// 						xs={12}
// 						sm={12}
// 						md={12}
// 						lg={12}
// 						xl={12}
// 						sx={{
// 							display: 'flex',
// 							justifyContent: 'center',
// 							flexDirection: 'column',
// 							justifyItems: 'center',
// 							alignContent: 'center',
// 							alignItems: 'center',
// 						}}
// 					>
// 						<div style={{ textAlign: 'center', marginTop: 1, marginBottom: 1 }}>
// 							<img
// 								src={PaymentError_gif}
// 								alt="paymentError gif"
// 								width={'200px'}
// 								height={'135px'}
// 							/>
// 						</div>
// 						<Typography
// 							gutterBottom
// 							variant="h2"
// 							component="div"
// 							fontWeight={'700'}
// 							fontSize={{
// 								md: '14px',
// 								sm: '14px',
// 								xs: '14px',
// 								lg: '20px',
// 								xl: '20px',
// 							}}
// 							color={'#E80000'}
// 							textAlign={'center'}
// 							my={1}
// 						>
// 							{getComaPrice(100000) + ' ' + '/-'}
// 						</Typography>
// 						<Typography
// 							gutterBottom
// 							variant="h4"
// 							component="div"
// 							fontWeight={'600'}
// 							fontSize={{
// 								md: '14px',
// 								sm: '14px',
// 								xs: '14px',
// 								lg: '16px',
// 								xl: '16px',
// 							}}
// 							color={'#000'}
// 							textAlign={'center'}
// 						>
// 							Payment Failed
// 						</Typography>

// 						<Typography
// 							gutterBottom
// 							variant="h2"
// 							component="div"
// 							fontWeight={'700'}
// 							fontSize={{
// 								md: '14px',
// 								sm: '14px',
// 								xs: '14px',
// 								lg: '20px',
// 								xl: '20px',
// 							}}
// 							color={'#000'}
// 							textAlign={'center'}
// 						>
// 							Kalyani Motors Pvt Ltd.
// 						</Typography>
// 						<Typography
// 							gutterBottom
// 							variant="p"
// 							component="div"
// 							fontWeight={'500'}
// 							fontSize={{
// 								md: '14px',
// 								sm: '14px',
// 								xs: '14px',
// 								lg: '16px',
// 								xl: '16px',
// 							}}
// 							color={'#000'}
// 							textAlign={'center'}
// 						>
// 							Created At: 11/11/2023 11:20
// 						</Typography>
// 					</Grid>
// 					<Grid
// 						item
// 						xs={11}
// 						sm={11}
// 						md={11}
// 						lg={11}
// 						xl={11}
// 						sx={{
// 							boxShadow: 1,
// 							my: 1,
// 							width: '100%',
// 							py: 4,
// 							display: 'flex',
// 							justifyContent: 'center',
// 							background: 'white',
// 							px: 1.5,
// 						}}
// 					>
// 						<Grid
// 							container
// 							xs={12}
// 							sm={12}
// 							md={12}
// 							lg={12}
// 							xl={12}
// 							sx={{
// 								display: 'flex',
// 								justifyItems: 'center',
// 								justifyContent: 'center',
// 							}}
// 						>
// 							<Grid
// 								item
// 								xs={12}
// 								sm={12}
// 								md={6}
// 								lg={2}
// 								xl={2}
// 								sx={{
// 									display: 'flex',
// 									// justifyContent: {
// 									// 	xl: 'end',
// 									// 	lg: 'end',
// 									// 	md: 'end',
// 									// 	sm: 'center',
// 									// 	xs: 'center',
// 									// },
// 									// px: 4,
// 									justifyContent: 'center',
// 									py: 2,
// 								}}
// 							>
// 								{' '}
// 								<Typography
// 									gutterBottom
// 									variant="p"
// 									component="div"
// 									fontSize={{
// 										xs: '13px',
// 										sm: '14px',
// 										md: '15px',
// 										lg: '14px',
// 										xl: '14px',
// 									}}
// 									fontWeight={'600'}
// 									color={'#274472'}
// 								>
// 									Name :&nbsp;
// 								</Typography>
// 								<Typography
// 									gutterBottom
// 									variant="p"
// 									component="div"
// 									fontSize={{
// 										xs: '13px',
// 										sm: '14px',
// 										md: '15px',
// 										lg: '14px',
// 										xl: '14px',
// 									}}
// 									fontWeight={'600'}
// 									color={'#274472'}
// 								>
// 									Uma
// 								</Typography>
// 							</Grid>
// 							<Grid
// 								item
// 								xs={12}
// 								sm={12}
// 								md={6}
// 								lg={4}
// 								xl={4}
// 								sx={{
// 									display: 'flex',
// 									// justifyContent: {
// 									// 	xl: 'start',
// 									// 	lg: 'start',
// 									// 	md: 'start',
// 									// 	sm: 'center',
// 									// 	xs: 'center',
// 									// },
// 									// px: 4,
// 									justifyContent: 'center',
// 									py: 2,
// 								}}
// 							>
// 								<Typography
// 									gutterBottom
// 									variant="p"
// 									component="div"
// 									fontSize={{
// 										xs: '13px',
// 										sm: '14px',
// 										md: '15px',
// 										lg: '14px',
// 										xl: '14px',
// 									}}
// 									fontWeight={'600'}
// 									color={'#274472'}
// 								>
// 									Transaction No :&nbsp; &nbsp;
// 								</Typography>
// 								<Typography
// 									gutterBottom
// 									variant="p"
// 									component="div"
// 									fontSize={{
// 										xs: '13px',
// 										sm: '14px',
// 										md: '15px',
// 										lg: '14px',
// 										xl: '14px',
// 									}}
// 									fontWeight={'600'}
// 									color={'#274472'}
// 								>
// 									No Transaction Number
// 								</Typography>
// 							</Grid>
// 							<Grid
// 								item
// 								xs={12}
// 								sm={12}
// 								md={6}
// 								lg={3}
// 								xl={3}
// 								sx={{
// 									display: 'flex',
// 									// justifyContent: {
// 									// 	xl: 'end',
// 									// 	lg: 'end',
// 									// 	md: 'end',
// 									// 	sm: 'center',
// 									// 	xs: 'center',
// 									// },
// 									// px: 4,
// 									justifyContent: 'center',
// 									py: 2,
// 								}}
// 							>
// 								<Typography
// 									gutterBottom
// 									variant="p"
// 									component="div"
// 									fontSize={{
// 										xs: '13px',
// 										sm: '14px',
// 										md: '15px',
// 										lg: '14px',
// 										xl: '14px',
// 									}}
// 									fontWeight={'600'}
// 									color={'#274472'}
// 								>
// 									Booked Item :&nbsp;
// 								</Typography>
// 								<Typography
// 									gutterBottom
// 									variant="p"
// 									component="div"
// 									fontSize={{
// 										xs: '13px',
// 										sm: '14px',
// 										md: '15px',
// 										lg: '14px',
// 										xl: '14px',
// 									}}
// 									fontWeight={'600'}
// 									color={'#274472'}
// 								>
// 									Maruti Suzuki Celerio
// 								</Typography>
// 							</Grid>
// 							<Grid
// 								item
// 								xs={12}
// 								sm={12}
// 								md={6}
// 								lg={3}
// 								xl={3}
// 								sx={{
// 									display: 'flex',
// 									// justifyContent: {
// 									// 	xl: 'start',
// 									// 	lg: 'start',
// 									// 	md: 'start',
// 									// 	sm: 'center',
// 									// 	xs: 'center',
// 									// },
// 									// px: 4,
// 									justifyContent: 'center',
// 									py: 2,
// 								}}
// 							>
// 								<Typography
// 									gutterBottom
// 									variant="p"
// 									component="div"
// 									fontSize={{
// 										xs: '13px',
// 										sm: '14px',
// 										md: '15px',
// 										lg: '14px',
// 										xl: '14px',
// 									}}
// 									fontWeight={'600'}
// 									color={'#274472'}
// 								>
// 									Item Color : &nbsp;
// 								</Typography>
// 								<Typography
// 									gutterBottom
// 									variant="p"
// 									component="div"
// 									fontSize={{
// 										xs: '13px',
// 										sm: '14px',
// 										md: '15px',
// 										lg: '14px',
// 										xl: '14px',
// 									}}
// 									fontWeight={'600'}
// 									color={'#274472'}
// 									textAlign={'end'}
// 								>
// 									Blue
// 								</Typography>
// 							</Grid>
// 						</Grid>
// 					</Grid>
// 					<Button
// 						variant="outlined"
// 						size="small"
// 						id="place_btn"
// 						sx={{
// 							textAlign: 'center',
// 							height: '44px',
// 							width: {
// 								xl: '250px',
// 								lg: '250px',
// 								md: '200px',
// 								sm: '200px',
// 								xs: '200px',
// 							},
// 							fontWeight: 600,
// 							fontSize: '12px',
// 							lineHeight: '18px',
// 							letterSpacing: '.01em',
// 							borderRadius: '12px !important',
// 							boxShadow: 'none !important',
// 							mt: 1,
// 							color: '#E80000',
// 							border: '1px solid #E80000',
// 							'&:hover': {
// 								border: '1px solid #E80000',
// 								background: 'white',
// 							},
// 							background: 'white',
// 						}}
// 						onClick={() => navigate('/')}
// 					>
// 						Back
// 					</Button>
// 				</Grid>
// 			</Box>
// 		</>
// 	);
// }

export function Failure_Screen(props) {
	const { handleFailureClose, title } = props;
	return (
		<Box
			sx={{
				position: 'absolute',
				top: '50%',
				left: '50%',
				transform: 'translate(-50%, -50%)',
				boxShadow: 24,
				width: { xl: 500, lg: 500, md: 450, sm: 450, xs: 350 },
				background: 'white',
			}}
			className="failure_modal"
		>
			<Grid
				container
				sx={{
					display: 'flex',
					justifyContent: 'end',
					alignContent: 'center',
					textAlign: 'end',
				}}
			>
				<Grid
					item
					sx={{
						display: 'flex',
						justifyContent: 'end',
						alignContent: 'center',
						textAlign: 'end',
						// p: 1,
						cursor: 'pointer',
					}}
				>
					<CloseOutlinedIcon onClick={handleFailureClose} />
				</Grid>
			</Grid>
			<Grid
				container
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignContent: 'center',
					flexDirection: 'column',
					py: 2,
				}}
			>
				<Grid
					item
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignContent: 'center',
						flexDirection: 'column',
					}}
				>
					<img
						src={failure_gif}
						alt="success icon"
						width={'150px'}
						height={'150px'}
						style={{ textAlign: 'center' }}
					/>
					<Typography
						gutterBottom
						variant="h5"
						component="div"
						fontSize={{ xs: 13, sm: 13, md: 16, lg: 18, xl: 18 }}
						fontWeight={'700'}
						textAlign={'center'}
					>
						{title}
					</Typography>
				</Grid>
			</Grid>
		</Box>
	);
}

export function Processing_Payment() {
	const navigate = useNavigate();
	const [paymentModal, setPaymentModal] = useState(false);

	const openPaymentModal = () => {
		setPaymentModal(true);
	};
	const closePaymentModal = () => {
		setPaymentModal(false);
	};

	return (
		<Box>
			<Grid
				container
				xs={12}
				sm={12}
				md={12}
				lg={12}
				xl={12}
				textAlign={'center'}
			>
				<Grid
					item
					xs={12}
					sm={12}
					md={12}
					lg={12}
					xl={12}
					sx={{
						display: 'flex',
						justifyContent: 'space-around',
						alignContent: 'center',
						mt: 2.5,
					}}
				>
					<img
						src="https://kalyanimotors.com/static/media/kalyani_dark.5bca4adb5ec73478b2ce.png"
						alt="kalyani_log"
						width={'120px'}
						height={'50px'}
					/>

					<Typography
						gutterBottom
						variant="h5"
						component="div"
						fontSize={{ xs: 13, sm: 13, md: 16, lg: 18, xl: 18 }}
						fontWeight={'700'}
						textAlign={'center'}
						color={'#003B73'}
						mt={1}
					>
						PAYMENT RECEIPT
					</Typography>
				</Grid>
				<Grid
					item
					xs={12}
					sm={12}
					md={12}
					lg={12}
					xl={12}
					sx={{ textAlign: 'center' }}
				>
					<div>
						<img
							src={processing_payment_img}
							alt="processing_payment"
							width={'250px'}
							height={'150px'}
						/>
					</div>
					<Typography
						gutterBottom
						variant="h5"
						component="div"
						fontSize={{ xs: 16, sm: 16, md: 16, lg: 18, xl: 18 }}
						fontWeight={'700'}
						textAlign={'center'}
						color={'#F9D876'}
						mt={4}
					>
						Payment Pending
					</Typography>
					<Typography
						gutterBottom
						variant="h5"
						component="div"
						fontSize={'14px'}
						fontWeight={'700'}
						textAlign={'center'}
						color={'#666666'}
						my={1.2}
					>
						Payment Request From
					</Typography>
					<Typography
						gutterBottom
						variant="h5"
						component="div"
						fontSize={{ xs: 17, sm: 17, md: 17, lg: 18, xl: 18 }}
						fontWeight={'700'}
						textAlign={'center'}
						color={'#003B73'}
					>
						Kalyani Motos Pvt. Ltd.
					</Typography>
				</Grid>

				<Grid
					item
					xs={12}
					sm={12}
					md={12}
					lg={12}
					xl={12}
					display={'flex'}
					flexDirection={{
						xl: 'row',
						lg: 'row',
						md: 'column',
						sm: 'column',
						xs: 'column',
					}}
					justifyContent={'center'}
					justifyItems={'center'}
					alignContent={'center'}
				>
					<div style={{ textAlign: 'center' }}>
						<Button
							variant="outlined"
							size="small"
							id="place_btn"
							sx={{
								textAlign: 'center',
								height: '44px',
								width: {
									xl: '250px',
									lg: '250px',
									md: '300px',
									sm: '300px',
									xs: '300px',
								},
								fontWeight: 600,
								fontSize: '12px',
								lineHeight: '18px',
								letterSpacing: '.01em',
								borderRadius: '12px !important',
								boxShadow: 'none !important',
								mt: 2,
								color: 'green',
								border: '1px solid green',
								'&:hover': {
									border: '1px solid green',
									background: 'white',
								},
								background: 'white',
								mx: { xl: 1, lg: 1 },
								textTransform: 'none',
							}}
							onClick={openPaymentModal}
						>
							Re-Book
						</Button>
					</div>
					<div style={{ textAlign: 'center' }}>
						<Button
							variant="outlined"
							size="small"
							id="place_btn"
							sx={{
								textAlign: 'center',
								height: '44px',
								width: {
									xl: '250px',
									lg: '250px',
									md: '300px',
									sm: '300px',
									xs: '300px',
								},
								fontWeight: 600,
								fontSize: '12px',
								lineHeight: '18px',
								letterSpacing: '.01em',
								borderRadius: '12px !important',
								boxShadow: 'none !important',
								mt: 2,
								color: '#BF8422',
								border: '1px solid #BF8422',
								'&:hover': {
									border: '1px solid #BF8422',
									background: 'white',
								},
								background: 'white',
								mx: { xl: 1, lg: 1 },
								textTransform: 'none',
							}}
							onClick={() => navigate('/')}
						>
							Back
						</Button>
					</div>
				</Grid>
			</Grid>

			<div>
				<Modal
					open={paymentModal}
					onClose={closePaymentModal}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<Box
						sx={{
							position: 'absolute',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
							width: { xl: 550, lg: 550, md: 500, sm: 400, xs: 300 },
							bgcolor: 'white',
							boxShadow: 24,
							borderRadius: 3,
							display: 'flex',
							flexDirection: 'column',

							textAlign: 'center',
						}}
						className="reasonModal"
					>
						<div
							style={{
								textAlign: 'end',
							}}
						>
							<CloseIcon
								onClick={closePaymentModal}
								sx={{
									width: '34px',
									height: '34px',
									mt: 1,
									mr: 1,
									borderRadius: '50%',
									backgroundColor: '#F7F7F7',
									cursor: 'pointer',
									padding: 1,
								}}
							/>
						</div>
						<div>
							<img src={make_payment} width={'200px'} height={'200px'} />
						</div>

						<Typography
							sx={{
								fontSize: '14px',
								fontWeight: '600',
								textAlign: 'center',
								color: '#000',
								mb: 2,
								mx: 1,
							}}
						>
							<b style={{ fontSize: '16px' }}> NOTE:</b> Payment Will Happen
							According to Kalyani Motors Pvt. Ltd. Terms and Conditions.
						</Typography>
						<div style={{ textAlign: 'center' }}>
							<Button
								variant="contained"
								sx={{
									textAlign: 'center',
									height: '44px',
									width: '250px',
									fontWeight: 600,
									fontSize: '12px',
									lineHeight: '18px',
									letterSpacing: '.01em',
									borderRadius: '12px !important',
									boxShadow: 'none !important',
									my: 1,
									background: '#5CD85A',
									'&:hover': {
										background: '#5CD85A',
									},
								}}
								fullWidth
							>
								Make Payment
							</Button>
						</div>
						<div style={{ textAlign: 'center' }}>
							<Button
								variant="contained"
								sx={{
									textAlign: 'center',
									height: '44px',
									width: '250px',
									fontWeight: 600,
									fontSize: '12px',
									lineHeight: '18px',
									letterSpacing: '.01em',
									borderRadius: '12px !important',
									boxShadow: 'none !important',
									my: 1,
									background: '#FF0F39',
									'&:hover': {
										background: '#FF0F39',
									},
								}}
								fullWidth
							>
								Close
							</Button>
						</div>
					</Box>
				</Modal>
			</div>
		</Box>
	);
}

export function Payment_Failure() {
	return (
		<Box sx={{ background: '#fafafa' }}>
			<Grid
				container
				xs={12}
				sm={12}
				md={12}
				lg={12}
				xl={12}
				textAlign={'center'}
			>
				<Grid
					item
					xs={12}
					sm={12}
					md={12}
					lg={12}
					xl={12}
					sx={{
						display: 'flex',
						justifyContent: 'space-around',
						alignContent: 'center',
						mt: 2.5,
					}}
				>
					<img
						src="https://kalyanimotors.com/static/media/kalyani_dark.5bca4adb5ec73478b2ce.png"
						alt="kalyani_log"
						width={'120px'}
						height={'50px'}
					/>

					<Typography
						gutterBottom
						variant="h5"
						component="div"
						fontSize={{ xs: 13, sm: 13, md: 16, lg: 18, xl: 18 }}
						fontWeight={'700'}
						textAlign={'center'}
						color={'#003B73'}
						mt={1}
					>
						PAYMENT RECEIPT
					</Typography>
				</Grid>
			</Grid>
			<Grid
				container
				xs={12}
				sm={12}
				md={12}
				lg={12}
				xl={12}
				sx={{
					display: 'flex',
					justifyContent: 'center',
					flexDirection: 'column',
					justifyItems: 'center',
					alignContent: 'center',
					alignItems: 'center',
				}}
			>
				<Grid
					item
					xs={12}
					sm={12}
					md={12}
					lg={12}
					xl={12}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						flexDirection: 'column',
						justifyItems: 'center',
						alignContent: 'center',
						alignItems: 'center',
					}}
				>
					<div style={{ textAlign: 'center', marginTop: 1, marginBottom: 1 }}>
						<img
							src={PaymentError_gif}
							alt="success gif"
							width={'140px'}
							height={'100px'}
						/>
					</div>
					<Typography
						gutterBottom
						variant="h2"
						component="div"
						fontWeight={'700'}
						fontSize={{
							md: '14px',
							sm: '14px',
							xs: '14px',
							lg: '20px',
							xl: '20px',
						}}
						color={'red'}
						textAlign={'center'}
						my={1}
					>
						{getComaPrice(20000) + ' ' + '/-'}
					</Typography>

					<Typography
						gutterBottom
						variant="h2"
						component="div"
						fontWeight={'700'}
						fontSize={{
							md: '14px',
							sm: '14px',
							xs: '14px',
							lg: '20px',
							xl: '20px',
						}}
						color={'#000'}
						textAlign={'center'}
					>
						Payment Failed
					</Typography>
				</Grid>
			</Grid>
			<Grid
				container
				xs={12}
				sm={12}
				md={12}
				lg={12}
				xl={12}
				sx={{
					display: {
						xl: 'flex',
						lg: 'flex',
						md: 'flex',
						sm: 'none',
						xs: 'none',
					},
					justifyContent: 'center',
					flexDirection: 'row',
					justifyItems: 'center',
					alignContent: 'center',
					alignItems: 'center',
					gap: 4,
				}}
			>
				<Grid
					item
					xs={12}
					sm={12}
					md={5}
					lg={5}
					xl={5}
					sx={{
						mt: 3,
						width: '100%',
						display: 'flex',
						justifyContent: 'center',
					}}
				>
					<Typography
						gutterBottom
						variant="h2"
						component="div"
						fontWeight={'700'}
						fontSize={{
							md: '14px',
							sm: '14px',
							xs: '14px',
							lg: '16px',
							xl: '16px',
						}}
						color={'#000'}
						textAlign={'center'}
					>
						BOOKING DETAILS
					</Typography>
				</Grid>
				<Grid
					item
					xs={12}
					sm={12}
					md={5}
					lg={5}
					xl={5}
					sx={{
						mt: 3,
						width: '100%',

						display: 'flex',
						justifyContent: 'center',
					}}
				>
					<Typography
						gutterBottom
						variant="h2"
						component="div"
						fontWeight={'700'}
						fontSize={{
							md: '14px',
							sm: '14px',
							xs: '14px',
							lg: '16px',
							xl: '16px',
						}}
						color={'#000'}
						textAlign={'center'}
					>
						PAYMENT DETAILS
					</Typography>
				</Grid>
			</Grid>

			<Grid
				container
				xs={12}
				sm={12}
				md={12}
				lg={12}
				xl={12}
				sx={{
					display: 'flex',
					justifyContent: 'center',
					flexDirection: 'row',
					justifyItems: 'center',
					alignContent: 'center',
					alignItems: 'center',
					columnGap: 4,
					rowGap: { lg: 0, xl: 0, md: 4, sm: 4, xs: 4 },
				}}
			>
				<Grid
					item
					xs={12}
					sm={10}
					md={5}
					lg={5}
					xl={5}
					sx={{
						// boxShadow: 1,
						my: 2,
						width: '100%',
						p: 4,
						display: 'flex',
						justifyContent: 'center',
						background: 'white',
						borderRadius: 4,
					}}
				>
					<Grid
						container
						xs={12}
						sm={12}
						md={12}
						lg={12}
						xl={12}
						sx={{
							display: 'flex',
							justifyItems: 'center',
							justifyContent: 'center',
						}}
					>
						<Typography
							gutterBottom
							variant="h2"
							component="div"
							fontWeight={'700'}
							fontSize={{
								md: '14px',
								sm: '14px',
								xs: '14px',
								lg: '16px',
								xl: '16px',
							}}
							color={'#000'}
							textAlign={'center'}
							display={{
								xl: 'none',
								lg: 'none',
								md: 'none',
								sm: 'flex',
								xs: 'flex',
							}}
						>
							BOOKING DETAIL
						</Typography>
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							lg={12}
							xl={12}
							sx={{
								display: 'flex',
								justifyContent: 'center',
								justifyItems: 'center',
								px: { xl: 6, lg: 6, sm: 2 },
							}}
						>
							<Grid
								item
								xs={6}
								sm={6}
								md={6}
								lg={6}
								xl={6}
								display={'flex'}
								flexDirection={'column'}
							>
								<Typography
									gutterBottom
									variant="p"
									component="div"
									fontSize={{
										xs: '13px',
										sm: '14px',
										md: '15px',
										lg: '14px',
										xl: '14px',
									}}
									fontWeight={'600'}
									color={'#274472'}
									my={2}
								>
									Requested By :
								</Typography>
								<Typography
									gutterBottom
									variant="p"
									component="div"
									fontSize={'15px'}
									fontWeight={'500'}
									color={'#274472'}
									my={2}
								>
									Created At :
								</Typography>
								<Typography
									gutterBottom
									variant="p"
									component="div"
									fontSize={{
										xs: '13px',
										sm: '14px',
										md: '15px',
										lg: '14px',
										xl: '14px',
									}}
									fontWeight={'600'}
									color={'#274472'}
									my={2}
								>
									Customer ID/ Reg.No :
								</Typography>

								<Typography
									gutterBottom
									variant="p"
									component="div"
									fontSize={{
										xs: '13px',
										sm: '14px',
										md: '15px',
										lg: '14px',
										xl: '14px',
									}}
									fontWeight={'600'}
									color={'#274472'}
									my={2}
								>
									Reference No :
								</Typography>
								<Typography
									gutterBottom
									variant="p"
									component="div"
									fontSize={{
										xs: '13px',
										sm: '14px',
										md: '15px',
										lg: '14px',
										xl: '14px',
									}}
									fontWeight={'600'}
									color={'#274472'}
									my={2}
								>
									Purpose :
								</Typography>
							</Grid>{' '}
							<Grid
								item
								xs={6}
								sm={6}
								md={6}
								lg={6}
								xl={6}
								display={'flex'}
								flexDirection={'column'}
								justifyContent={{
									xl: 'center',
									lg: 'center',
									md: 'center',
									sm: 'center',
									xs: 'center',
								}}
							>
								<Typography
									gutterBottom
									variant="p"
									component="div"
									fontSize={{
										xs: '13px',
										sm: '14px',
										md: '15px',
										lg: '14px',
										xl: '14px',
									}}
									fontWeight={'600'}
									color={'#7F8FA3'}
									my={2}
								>
									Admin
								</Typography>
								<Typography
									gutterBottom
									variant="p"
									component="div"
									fontSize={'15px'}
									fontWeight={'500'}
									color={'#7F8FA3'}
									my={2}
								>
									02/01/2024 (11:34:06)
								</Typography>
								<Typography
									gutterBottom
									variant="p"
									component="div"
									fontSize={{
										xs: '13px',
										sm: '14px',
										md: '15px',
										lg: '14px',
										xl: '14px',
									}}
									fontWeight={'600'}
									color={'#7F8FA3'}
									my={2}
								>
									HFUFCCY
								</Typography>

								<Typography
									gutterBottom
									variant="p"
									component="div"
									fontSize={{
										xs: '14px',
										sm: '14px',
										md: '14px',
										lg: '14px',
										xl: '14px',
									}}
									fontWeight={'600'}
									color={'#7F8FA3'}
									my={2}
								>
									456
								</Typography>
								<Typography
									gutterBottom
									variant="p"
									component="div"
									fontSize={{
										xs: '14px',
										sm: '14px',
										md: '14px',
										lg: '14px',
										xl: '14px',
									}}
									fontWeight={'600'}
									color={'#7F8FA3'}
									my={2}
								>
									Testing
								</Typography>
							</Grid>{' '}
						</Grid>
					</Grid>
				</Grid>

				<Grid
					item
					xs={12}
					sm={10}
					md={5}
					lg={5}
					xl={5}
					sx={{
						// boxShadow: 1,

						width: '100%',
						p: 4,
						display: 'flex',
						justifyContent: 'center',
						background: 'white',
						borderRadius: 4,
					}}
				>
					<Grid
						container
						xs={12}
						sm={12}
						md={12}
						lg={12}
						xl={12}
						sx={{
							display: 'flex',
							justifyItems: 'center',
							justifyContent: 'center',
						}}
					>
						<Typography
							gutterBottom
							variant="h2"
							component="div"
							fontWeight={'700'}
							fontSize={{
								md: '14px',
								sm: '14px',
								xs: '14px',
								lg: '16px',
								xl: '16px',
							}}
							color={'#000'}
							textAlign={'center'}
							display={{
								xl: 'none',
								lg: 'none',
								md: 'none',
								sm: 'flex',
								xs: 'flex',
							}}
						>
							BILL DETAIL
						</Typography>
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							lg={12}
							xl={12}
							sx={{
								display: 'flex',
								justifyContent: 'center',
								justifyItems: 'center',
								px: { xl: 6, lg: 6, sm: 2 },
							}}
						>
							<Grid
								item
								xs={6}
								sm={6}
								md={6}
								lg={6}
								xl={6}
								display={'flex'}
								flexDirection={'column'}
							>
								<Typography
									gutterBottom
									variant="p"
									component="div"
									fontSize={{
										xs: '13px',
										sm: '14px',
										md: '15px',
										lg: '14px',
										xl: '14px',
									}}
									fontWeight={'600'}
									color={'#274472'}
									my={2}
								>
									SubTotal :
								</Typography>
								<Typography
									gutterBottom
									variant="p"
									component="div"
									fontSize={{
										xs: '13px',
										sm: '14px',
										md: '15px',
										lg: '14px',
										xl: '14px',
									}}
									fontWeight={'600'}
									color={'#274472'}
									my={2}
								>
									Earned Points :
								</Typography>
								<Typography
									gutterBottom
									variant="p"
									component="div"
									fontSize={{
										xs: '13px',
										sm: '14px',
										md: '15px',
										lg: '14px',
										xl: '14px',
									}}
									fontWeight={'600'}
									color={'#274472'}
									my={2}
								>
									Inaugural Points:
								</Typography>
								<hr />
								<Typography
									gutterBottom
									variant="p"
									component="div"
									fontSize={{
										xs: '13px',
										sm: '14px',
										md: '15px',
										lg: '14px',
										xl: '14px',
									}}
									fontWeight={'600'}
									color={'#274472'}
									my={2}
								>
									Grand Total : &nbsp;
								</Typography>
							</Grid>{' '}
							<Grid
								item
								xs={6}
								sm={6}
								md={6}
								lg={6}
								xl={6}
								display={'flex'}
								flexDirection={'column'}
								justifyContent={'center'}
							>
								<Typography
									gutterBottom
									variant="p"
									component="div"
									fontSize={{
										xs: '13px',
										sm: '14px',
										md: '15px',
										lg: '14px',
										xl: '14px',
									}}
									fontWeight={'600'}
									color={'#7F8FA3'}
									my={2}
								>
									&nbsp; + {getComaPrice(20000)}
								</Typography>
								<Typography
									gutterBottom
									variant="p"
									component="div"
									fontSize={{
										xs: '13px',
										sm: '14px',
										md: '15px',
										lg: '14px',
										xl: '14px',
									}}
									fontWeight={'600'}
									color={'#7F8FA3'}
									my={2}
								>
									&nbsp; - {getComaPrice(1000)}
								</Typography>
								<Typography
									gutterBottom
									variant="p"
									component="div"
									fontSize={{
										xs: '13px',
										sm: '14px',
										md: '15px',
										lg: '14px',
										xl: '14px',
									}}
									fontWeight={'600'}
									color={'#7F8FA3'}
									my={2}
								>
									&nbsp; - {getComaPrice(0)}
								</Typography>
								<hr />
								<Typography
									gutterBottom
									variant="p"
									component="div"
									fontSize={{
										xs: '13px',
										sm: '14px',
										md: '15px',
										lg: '14px',
										xl: '14px',
									}}
									fontWeight={'600'}
									color={'#7F8FA3'}
									my={2}
								>
									&nbsp; {getComaPrice(19000)}
								</Typography>
							</Grid>{' '}
						</Grid>
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							lg={12}
							xl={12}
							px={{ xl: 5, lg: 5 }}
							display={'flex'}
							justifyContent={'start'}
							alignContent={'center'}
						>
							<Typography
								gutterBottom
								variant="p"
								component="div"
								fontSize={{
									xs: '13px',
									sm: '14px',
									md: '15px',
									lg: '14px',
									xl: '14px',
								}}
								fontWeight={'600'}
								color={'green'}
								my={2}
							>
								You Saved {getComaPrice(1000) + ' ' + '/-'} on this order
							</Typography>
							<div style={{ marginTop: '12px' }}>
								<img src={reward} width={'20px'} height={'20px'} />
								<img src={reward} width={'20px'} height={'20px'} />
							</div>
						</Grid>
					</Grid>
				</Grid>

				<Grid
					item
					xs={12}
					sm={12}
					md={5}
					lg={5}
					xl={5}
					sx={{
						display: 'flex',
						justifyContent: 'center',
					}}
				>
					<Button
						variant="outlined"
						size="small"
						id="place_btn"
						sx={{
							textAlign: 'center',
							height: '44px',
							width: '300px',
							fontWeight: 600,
							fontSize: '12px',
							lineHeight: '18px',
							letterSpacing: '.01em',
							borderRadius: '12px !important',
							boxShadow: 'none !important',
							color: '#000',
							border: '1px solid red',
							'&:hover': {
								border: '1px solid red',
								background: 'white',
							},
							background: 'white',
							mb: 1,
						}}
					>
						BACK
					</Button>
				</Grid>
			</Grid>
		</Box>
	);
}
