import React from 'react';
import Typography from '@mui/material/Typography';

const SectionTitle = ({ type, variant }) => {
	const desc =
		variant == 'title'
			? `${type} in 3 Easy Steps`
			: `why ${type} at true value ?`;

	const titleFs = { lg: '30px', md: '20px', sm: '20px', xs: '20px' };

	const subTitleFs = {
		xl: '20px',
		lg: '20px',
		md: '16px',
		sm: '16px',
		xs: '16px',
	};

	return (
		<div>
			<Typography
				gutterBottom
				variant="h4"
				component="div"
				fontWeight={'700'}
				fontSize={variant == 'title' ? titleFs : subTitleFs}
				color={'#282828'}
				sx={{
					my: 3,
					mx: 2,
					textTransform: variant == 'title' ? 'capitalize' : 'uppercase',
				}}
				textAlign={'center'}
			>
				{desc}
			</Typography>
		</div>
	);
};

export default SectionTitle;
