export function getComaPrice(number) {
  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
  });
  let formattedNumber = formatter.format(number);
  formattedNumber = formattedNumber.replace(/₹/, "₹ ");

  return formattedNumber;
}
