import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SectionTitle from './SectionTitle';
import ThreeSteps from './ThreeSteps';
import TrueValue from './TrueValue';
import './UsedCarComponents.css';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const BuyAndSellSection = ({ type }) => {
	const navigate = useNavigate();
	const location = useLocation();

	const { filterInt, id } = location.state || {};

	const handleNavigate = () => {
		if (type === 'buy') {
			navigate('/usedcars/buyform', { state: { filterInt, id } });
		} else {
			navigate('/usedcars/sellform');
		}
	};

	return (
		<div className="usedcars_container">
			<SectionTitle type={type} variant={'title'} />
			<ThreeSteps type={type} />
			<Box sx={{ textAlign: 'center', my: 5 }}>
				<Button
					variant="contained"
					className="enquirybtn"
					onClick={handleNavigate}
				>
					Start {type === 'buy' ? 'Enquiry' : 'Selling'}
				</Button>
			</Box>
			<SectionTitle type={type} variant={'subTitle'} />
			<TrueValue type={type} />
		</div>
	);
};

export default BuyAndSellSection;
