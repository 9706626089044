import React, { useEffect, useCallback } from "react";
// import ApiHandler from "../../api/ApiHandler";
// import useRazorpay from "react-razorpay";
import { useLocation } from "react-router-dom";

const Razorpay = () => {
  // const [Razorpay] = useRazorpay();
  const location = useLocation();
  const { data } = location.state || {};

  // const verifyBookingPayment = async (
  //   booking_id,
  //   source,
  //   gateway,
  //   status,
  //   order_id
  // ) => {
  //   const response = await ApiHandler.verifyCashFreeBookingPayment(
  //     booking_id,
  //     source,
  //     gateway,
  //     status,
  //     order_id
  //   );
  //   console.log("response ", response.data);
  //   if (response.data.status === 200) {
  //     if (response.data.data.booking_id.payment_status === "Success") {
  //       navigation.navigate("BookingSuccess", response.data.data);
  //       // } else if (response.data.data.booking_id.payment_status === 'Pending') {
  //     } else {
  //       navigation.navigate("BookingFailure", response.data.data);
  //     }
  //   }
  //   console.log("response successful", response.data);
  // };

  // const verifyRazorPayment = async (
  //   payment_gateway,
  //   payment_request_id,
  //   payment_status,
  //   transaction_id
  // ) => {
  //   const formData = {
  //     payment_gateway: payment_gateway,
  //     payment_request_id: payment_request_id,
  //     payment_status: payment_status,
  //     transaction_id: transaction_id,
  //   };
  //   const response = await ApiHandler.verifyCashFreePayment(formData);
  //   console.log("response of verification", response.data);
  //   if (response.data.status === 200) {
  //     if (response.data.data.payment_status === "Success") {
  //       navigation.navigate("paymentDetails", { data: response.data.data });
  //       // } else if (response.data.data.payment_status === 'Error') {
  //     } else {
  //       navigation.navigate("paymentDetails", { data: response.data.data });
  //     }
  //   }
  // };

  // var options = {
  //   description: data.purpose,
  //   image:
  //     "https://kalyanimotors.com/static/media/kalyani_dark.5bca4adb5ec73478b2ce.png",
  //   currency: "INR",
  //   key: data.api_key,
  //   amount: Math.floor(data.grandTotal * 100).toFixed(2),
  //   name: "KALYANI MOTORS PVT LTD",
  //   prefill: {
  //     email: data.email,
  //     contact:
  //       data.source === "paymentList"
  //         ? data.phone
  //         : data.mob_no,
  //     name: data.name,
  //   },
  //   theme: { color: "darkblue" },
  // };

  var options = {
    description: "Hii this is the description ",
    image:
      "https://kalyanimotors.com/static/media/kalyani_dark.5bca4adb5ec73478b2ce.png",
    currency: "INR",
    key: "rzp_test_yXj2yDq5WgJz1Z",
    amount: "5000" * 100,
    name: "KALYANI MOTORS PVT LTD",
    prefill: {
      email: "example@gmail.com",
      contact: "9976866379",
      name: "rajesh",
    },
    theme: { color: "darkblue" },
  };
  const pay = new window.Razorpay(options);
  pay.open();
  // .then(async (data) => {
  //   if (params_data.source == "paymentList") {
  //     let beforeVerify_body = {
  //       crm_profile: user.profile_id,
  //       payment_instance: params_data.id,
  //       booking_instance: "",
  //       transaction_reference_no: data.razorpay_payment_id,
  //       transaction_status: "Success",
  //       mode_of_payment: params_data.payment_gateway,
  //       transaction_charge: 0,
  //       total_transaction_amount: params_data.net_paid,
  //       remarks: "",
  //     };
  //     const response = await ApiHandler.beforeVerify(beforeVerify_body);
  //     verifyRazorPayment(
  //       params_data.payment_gateway,
  //       params_data.id,
  //       "Success",
  //       data.razorpay_payment_id
  //     );
  //     console.log("before verify response", response);
  //   } else if (params_data.source === "NewCar") {
  //     console.log("consoling in newcar section");
  //     let beforeVerify_body = {
  //       crm_profile: user.profile_id,
  //       payment_instance: "",
  //       booking_instance: params_data.Booking_id,
  //       transaction_reference_no: data.razorpay_payment_id,
  //       transaction_status: "Success",
  //       mode_of_payment: params_data.payment_gateway,
  //       transaction_charge: 0,
  //       total_transaction_amount: params_data.net_paid,
  //       remarks: "",
  //     };
  //     const response = await ApiHandler.beforeVerify(beforeVerify_body);
  //     console.log("before verify response", response);

  //     verifyBookingPayment(
  //       params_data.Booking_id,
  //       params_data.source,
  //       params_data.payment_gateway,
  //       "Success",
  //       data.razorpay_payment_id
  //     );
  //   } else if (params_data.source === "Service") {
  //     let beforeVerify_body = {
  //       crm_profile: user.profile_id,
  //       payment_instance: "",
  //       booking_instance: params_data.Booking_id,
  //       transaction_reference_no: data.razorpay_payment_id,
  //       transaction_status: "Success",
  //       mode_of_payment: params_data.payment_gateway,
  //       transaction_charge: 0,
  //       total_transaction_amount: params_data.net_paid,
  //       remarks: "",
  //     };
  //     const response = await ApiHandler.beforeVerify(beforeVerify_body);
  //     console.log("before verify response", response);
  //     verifyBookingPayment(
  //       params_data.Booking_id,
  //       params_data.source,
  //       params_data.payment_gateway,
  //       "Success",
  //       data.razorpay_payment_id
  //     );
  //   }
  // })
  // .catch(async (error) => {
  //   console.log("Error", error);
  //   console.log("error.error.metadata.payment_id", error.error);
  //   console.log("catch block data", params_data);
  //   if (params_data.source == "paymentList") {
  //     let beforeVerify_body = {
  //       crm_profile: user.profile_id,
  //       payment_instance: params_data.id,
  //       booking_instance: "",
  //       transaction_reference_no: error.error.metadata.payment_id,
  //       transaction_status: "Failed",
  //       mode_of_payment: params_data.payment_gateway,
  //       transaction_charge: 0,
  //       total_transaction_amount: params_data.net_paid,
  //       remarks: "",
  //     };
  //     const response = await ApiHandler.beforeVerify(beforeVerify_body);
  //     verifyRazorPayment(
  //       params_data.payment_gateway,
  //       params_data.id,
  //       "Failed",
  //       error.error.metadata.payment_id
  //     );
  //   } else if (params_data.source === "NewCar") {
  //     let beforeVerify_body = {
  //       crm_profile: user.profile_id,
  //       payment_instance: params_data.id,
  //       booking_instance: "",
  //       transaction_reference_no: error.error.metadata.payment_id,
  //       transaction_status: "Failed",
  //       mode_of_payment: params_data.payment_gateway,
  //       transaction_charge: 0,
  //       total_transaction_amount: params_data.net_paid,
  //       remarks: "",
  //     };
  //     const response = await ApiHandler.beforeVerify(beforeVerify_body);
  //     verifyBookingPayment(
  //       params_data.Booking_id,
  //       params_data.source,
  //       params_data.payment_gateway,
  //       "Failed",
  //       error.error.metadata.payment_id
  //     );
  //   } else if (params_data.source === "Service") {
  //     let beforeVerify_body = {
  //       crm_profile: user.profile_id,
  //       payment_instance: params_data.id,
  //       booking_instance: "",
  //       transaction_reference_no: error.error.metadata.payment_id,
  //       transaction_status: "Failed",
  //       mode_of_payment: params_data.payment_gateway,
  //       transaction_charge: 0,
  //       total_transaction_amount: params_data.net_paid,
  //       remarks: "",
  //     };
  //     const response = await ApiHandler.beforeVerify(beforeVerify_body);
  //     verifyBookingPayment(
  //       params_data.Booking_id,
  //       params_data.source,
  //       params_data.payment_gateway,
  //       "Failed",
  //       error.error.metadata.payment_id
  //     );
  //   }
  // });
  console.log("options in rozarpay", options);
};

export default Razorpay;
