import * as React from 'react';
import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import './Footer.css';
import { Link } from 'react-router-dom';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import Playstore from '../../Assets/Images/download.png';
import Appstore from '../../Assets/Images/Appstore.png';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import ApiHandler from '../../Api/Apihandler';
import Signup from '../../Authentication/Signup/Signup';

import {
	ContactPhone,
	Instagram,
	Login,
	Mail,
	Phone,
	YouTube,
} from '@mui/icons-material';
import { Modal, TextField, Button } from '@mui/material';
import { AES, enc } from 'crypto-js';
import CloseIcon from '@mui/icons-material/Close';

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: 'center',
	color: theme.palette.text.secondary,
}));

export default function Footer() {
	const [open, setOpen] = useState(false);
	const [thankOpen, setThankOpen] = useState(false);
	const [title, setTitle] = useState(false);
	const [login, setLogin] = useState('');
	const [modal, setModal] = useState(false);
	const [phoneNumber, setPhoneNumber] = useState('');
	const [password, setPassword] = useState('');
	const [contact, setContact] = useState(false);
	const [aboutKal, setAboutKal] = useState(false);
	const [whyKal, setWhyKal] = useState(false);
	const [Quality, setQuality] = useState(false);
	const [privacy, setPrivacy] = useState(false);
	const [Cancellation, setCancellation] = useState(false);
	const [terms_condition, setTermsCondition] = useState(false);
	const [awards, setAwards] = useState(false);
	const [gallery, setGallery] = useState(false);
	const [driving, setDriving] = useState(false);
	const navigate = useNavigate();

	const thankyouOpen = () => setThankOpen(true);
	const thankyouClose = () => setThankOpen(false);

	const openContactModal = () => {
		setContact(true);
	};
	const closeContactModal = () => {
		setContact(false);
	};

	const openModal = () => {
		setModal(true);
	};

	const closeModal = () => {
		setModal(false);
	};

	useEffect(() => {
		const encryptedPhoneNo = localStorage.getItem('phone');
		const encryptedPassword = localStorage.getItem('password');
		if (encryptedPhoneNo && encryptedPassword) {
			const decryptedPhone = AES.decrypt(
				encryptedPhoneNo,
				'encryptionSecret'
			).toString(enc.Utf8);
			const decryptedPwd = AES.decrypt(
				encryptedPassword,
				'encryptionSecret'
			).toString(enc.Utf8);
			Loginuser(decryptedPhone, decryptedPwd);
		}
	});

	const Loginuser = async (decryptedPhone, decryptedPwd) => {
		const data = { phone: decryptedPhone, password: decryptedPwd };
		try {
			const response = await ApiHandler.loginUser(data);
			if (response.data.status === 200) {
			const loginData = response.data.status;
			localStorage.setItem('loggedIn', response.data.data[0].id);
			setLogin(loginData);
		} else {
			openModal();
		}
		} catch (error) {
			console.log("Error in fetching user details", error);
		}
	};

	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 365,
		bgcolor: 'white',
		boxShadow: 24,
		borderRadius: 3,
	};

	return (
		<Box sx={{ flexGrow: 1 }}>
			<div>
				<Modal
					open={modal}
					onClose={closeModal}
					className="address_modal_container"
				>
					<div>
						<Signup button={closeModal} />
					</div>
				</Modal>
			</div>
			<Grid container sx={{ backgroundColor: '#071C2C', padding: '30px' }}>
				<Grid item xs={6} sm={3} md={3} lg={3} xl={3} sx={{}}>
					<div className="list_body">
						<h3 className="list_heading">HOME</h3>
						<ul>
							<li className="footer_listitem">
								<Link to="/#" relative="path" style={{ fontSize: '14px' }}>
									Home
								</Link>
							</li>
						</ul>
					</div>
				</Grid>
				<Grid item xs={6} sm={3} md={3} lg={3} xl={3} sx={{}}>
					<div className="list_body">
						<h3 className="list_heading">NEW CARS</h3>
						<ul>
							<li className="footer_listitem">
								<Link
									to={`/allcar/Arena`}
									relative="path"
									style={{ fontSize: '14px' }}
								>
									Arena
								</Link>
							</li>
							<li className="footer_listitem">
								<Link
									to={`/allcar/Nexa`}
									relative="path"
									style={{ fontSize: '14px' }}
								>
									Nexa
								</Link>
							</li>
							{/* <li className="footer_listitem">
                <Link>Commercial Cars</Link>
              </li> */}
						</ul>
					</div>
				</Grid>
				<Grid item xs={6} sm={3} md={3} lg={3} xl={3} sx={{}}>
					<div className="list_body">
						<h3 className="list_heading">ABOUT US</h3>
						<ul>
							<li className="footer_listitem">
								<Link
									to=""
									relative="path"
									onClick={() => {
										openContactModal();
										setTitle(false);
									}}
									style={{ fontSize: '14px' }}
								>
									Contact Us
								</Link>
							</li>
							<li
								className="footer_listitem"
								onClick={() => {
									if (login !== 200) {
										Loginuser();
									}
								}}
							>
								<Link
									to={login === 200 && '/about/About_Kalyani'}
									relative="path"
									style={{ cursor: 'pointer', fontSize: '14px' }}
								>
									About Kalyani
								</Link>
							</li>
							<li
								className="footer_listitem"
								onClick={() => {
									if (login !== 200) {
										Loginuser();
									}
								}}
							>
								<Link
									to={login === 200 && '/about/Why_Kalyani_Motors'}
									relative="path"
									style={{ cursor: 'pointer', fontSize: '14px' }}
								>
									Why Kalyani Motors
								</Link>
							</li>
							<li
								className="footer_listitem"
								onClick={() => {
									if (login !== 200) {
										Loginuser();
									}
								}}
							>
								<Link
									to={login === 200 && '/about/Quality_Policy'}
									relative="path"
									style={{ cursor: 'pointer', fontSize: '14px' }}
								>
									Quality Policy
								</Link>
							</li>
							<li
								className="footer_listitem"
								onClick={() => {
									if (login !== 200) {
										Loginuser();
									}
								}}
							>
								<Link
									to={login === 200 && '/about/Privacy_Policy'}
									relative="path"
									style={{ cursor: 'pointer', fontSize: '14px' }}
								>
									Privacy Policy
								</Link>
							</li>
							<li
								className="footer_listitem"
								onClick={() => {
									if (login !== 200) {
										Loginuser();
									}
								}}
							>
								<Link
									to={login === 200 && '/about/Cancellation_And_Refund_Policy'}
									relative="path"
									style={{ cursor: 'pointer', fontSize: '14px' }}
								>
									Cancellation And Refund Policy
								</Link>
							</li>
							<li
								className="footer_listitem"
								onClick={() => {
									if (login !== 200) {
										Loginuser();
									}
								}}
							>
								<Link
									to={login === 200 && '/about/Terms_And_Conditions'}
									relative="path"
									style={{ cursor: 'pointer', fontSize: '14px' }}
								>
									Terms_And_Conditions
								</Link>
							</li>
							<li
								className="footer_listitem"
								onClick={() => {
									if (login !== 200) {
										Loginuser();
									}
								}}
							>
								<Link
									to={login == 200 && '/about/Awards_and_Achievements'}
									style={{ cursor: 'pointer', fontSize: '14px' }}
								>
									Awards And Achievements
								</Link>
							</li>
							<li
								className="footer_listitem"
								onClick={() => {
									if (login !== 200) {
										Loginuser();
									}
								}}
							>
								<Link
									to={login === 200 && '/about/Maruti_Driving_School'}
									style={{ cursor: 'pointer', fontSize: '14px' }}
								>
									Maruti Driving School
								</Link>
							</li>

							<li
								className="footer_listitem"
								onClick={() => {
									if (login !== 200) {
										Loginuser();
									}
								}}
							>
								<Link
									to={login === 200 ? '/about/Gallery' : '#'}
									style={{ cursor: 'pointer', fontSize: '14px' }}
								>
									Gallery
								</Link>
							</li>
						</ul>
					</div>
				</Grid>
				<Grid item xs={6} sm={3} md={3} lg={3} xl={3} sx={{}}>
					<div className="list_body">
						<h3 className="list_heading">OUR SERVICES</h3>
						<ul>
							<li className="footer_listitem">
								<Link to="/#" relative="path" style={{ fontSize: '14px' }}>
									Sales
								</Link>
							</li>
							<li className="footer_listitem">
								<Link
									to={'/allservices'}
									relative="path"
									style={{ fontSize: '14px' }}
								>
									Services
								</Link>
							</li>
							<li className="footer_listitem">
								<Link
									to="/usedcars"
									relative="path"
									style={{ fontSize: '14px' }}
								>
									True Value
								</Link>
							</li>
							{/* <li className="footer_listitem">
                <Link
                  to={"/insurence"}
                  relative="path"
                  style={{ fontSize: "14px" }}
                >
                  Insurance
                </Link>
              </li> */}
							<li
								className="footer_listitem"
								onClick={() => {
									openContactModal();
									setTitle(true);
								}}
							>
								<Link to="#" style={{ fontSize: '14px' }}>
									Careers At Kalyani
								</Link>
							</li>
						</ul>
					</div>
				</Grid>
			</Grid>

			<div>
				<Modal
					open={contact}
					onClose={closeContactModal}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<Box sx={style}>
						<div
							style={{
								textAlign: 'end',
							}}
						>
							<CloseIcon
								onClick={closeContactModal}
								sx={{
									width: '34px',
									height: '34px',
									mt: 1,
									mr: 1,
									borderRadius: '50%',
									backgroundColor: '#F7F7F7',
									cursor: 'pointer',
									padding: 1,
								}}
							/>
						</div>
						<Typography
							sx={{
								fontSize: 16,
								fontWeight: '600',
								textAlign: 'center',
								color: '#333',
								mx: 2,
								mb: 2,
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							{title ? 'FIND CAREER AT KALYANI MOTORS' : 'CONTACT US'}
						</Typography>
						<div
							style={{
								width: '90%',
								margin: 'auto',
							}}
						>
							<TextField
								fullWidth
								label=""
								variant="outlined"
								size="small"
								placeholder="Name"
								sx={{ my: 2 }}
							/>
							<TextField
								fullWidth
								label=""
								variant="outlined"
								size="small"
								placeholder="Phone Number"
								sx={{ my: 2 }}
							/>
							<TextField
								fullWidth
								label=""
								variant="outlined"
								size="medium"
								sx={{ my: 2 }}
								placeholder="Message"
							/>
						</div>
						<div style={{ textAlign: 'center' }}>
							<Button
								variant="contained"
								sx={{
									textAlign: 'center',
									height: '44px',
									width: '250px',
									fontWeight: 600,
									fontSize: '12px',
									lineHeight: '18px',
									letterSpacing: '.01em',
									borderRadius: '12px !important',
									boxShadow: 'none !important',
									my: 2,
									background: ' linear-gradient(to right, #141e30, #243b55)',
								}}
								fullWidth
								onClick={() => {
									closeContactModal();
									thankyouOpen();
								}}
							>
								{title ? 'Find Career at Kalyani Motors' : 'Submit'}
							</Button>
						</div>
					</Box>
				</Modal>
			</div>

			<div>
				<Modal
					open={thankOpen}
					onClose={thankyouClose}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<Box sx={style}>
						<Typography id="modal-modal-description" sx={{ px: 2, pt: 4 }}>
							Thank you for your interest in we will get in touch with you
							soon...
						</Typography>
						<div
							style={{
								display: 'flex',
								justifyContent: 'flex-end',
								paddingTop: 20,
							}}
						>
							<Button
								variant="text"
								onClick={thankyouClose}
								sx={{
									fontSize: '14px',
									fontWeight: '700',
									textTransform: 'none',
									color: '#2193b0',
									cursor: 'pointer',
								}}
							>
								Close
							</Button>
						</div>
					</Box>
				</Modal>
			</div>

			<Grid
				container
				sx={{
					backgroundColor: '#282828',
					padding: '10px',
				}}
				display={{
					sm: 'none',
					xs: 'none',
					lg: 'list-item',
					md: 'list-item',
					xl: 'list-item',
				}}
			>
				<div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
					<Grid item my={2} sx={{ cursor: 'pointer' }}>
						<div>
							<img
								src={
									'https://www.kalyanimotors.com/static/media/kalyani_light.1aa9da94294cc7262a59.png'
								}
								style={{
									height: '60px',
									width: '150px',
									objectFit: 'cover',
									cursor: 'pointer',
								}}
								onClick={() => navigate('/')}
							/>
						</div>
					</Grid>
					<Grid item xs={12} sm={12} my={2}>
						<div
							style={{
								display: 'flex',
								color: 'white',
								justifyContent: 'center',
								alignItems: 'center',
								paddingBottom: 10,
							}}
						>
							<Phone className="phone_icon" /> &nbsp; &nbsp;
							<p>Phone Number :</p>
							&nbsp;
							<p>
								{' '}
								<a href="tel:	9014600700" style={{ color: 'white' }}>
									{' '}
									9014600700
								</a>
							</p>
						</div>
						<div
							style={{
								display: 'flex',
								color: 'white',
								justifyContent: 'center',
								alignItems: 'center',
								marginTop: 10,
								paddingLeft: 10,
								paddingBottom: 10,
							}}
						>
							&nbsp;
							<Mail className="mail_icon" />
							&nbsp;&nbsp;
							<p>Email : </p>
							&nbsp;
							<a
								href="mailto: support.blr@kalyanimotors.com"
								style={{ color: 'white' }}
							>
								support.blr@kalyanimotors.com
							</a>
						</div>
					</Grid>
					<Grid
						item
						xs={12}
						sm={12}
						my={2}
						sx={{ color: 'white', textAlign: 'center' }}
					>
						<p style={{ paddingBottom: 20 }}>Bangalore</p>
						<div
							className="container"
							style={{
								display: 'flex',
								justifyContent: 'space-evenly',
								alignItems: 'center',
							}}
						>
							<FacebookIcon className="item" />

							<TwitterIcon className="item" />

							{/* <Link to={'https://www.instagram.com/kalyanimotors.karnataka/'}> */}
							<Instagram className="item" />
							{/* </Link> */}

							<YouTube className="item" />
						</div>
					</Grid>
					<Grid item xs={12} sm={12} my={2}>
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-evenly',
								paddingTop: 10,
							}}
						>
							<a href="#">
								<img
									src={Playstore}
									alt="playstore"
									style={{ width: 150, height: 44 }}
								/>
							</a>
							<a href="#">
								<img
									src={Appstore}
									alt="appstore"
									style={{ width: 150, height: 44 }}
								/>
							</a>
						</div>
					</Grid>
				</div>
				<Grid sx={{ color: '#FFF', textAlign: 'center' }}>
					<Typography
						gutterBottom
						variant="h4"
						component="div"
						fontWeight={'600'}
						lineHeight={1.5}
						fontSize={{ lg: '15px', md: '15px', sm: '15px', xs: '13px' }}
						color={'white'}
						sx={{ mt: 5 }}
						textAlign={'center'}
					>
						COPYRIGHT © 2022 KALYANI MOTORS PVT LTD, BANGALORE, INDIA | Designed
						by: Kalyani Motors
					</Typography>
				</Grid>
			</Grid>
			<Grid
				container
				sx={{
					backgroundColor: '#282828',
					padding: '10px',
				}}
				display={{ lg: 'none', xl: 'none', md: 'none' }}
			>
				<Grid item xs={12} sm={12} p={0}>
					<div>
						<img
							src={
								'https://www.kalyanimotors.com/static/media/kalyani_light.1aa9da94294cc7262a59.png'
							}
							style={{ height: '60px', width: '150px', objectFit: 'cover' }}
						/>
					</div>
				</Grid>
				<Grid item xs={12} sm={12} my={1.5}>
					<div
						style={{
							display: 'flex',
							color: 'white',
							justifyContent: 'center',
							alignItems: 'center',
							padding: 2,
						}}
					>
						<Phone className="phone_icon" /> &nbsp;&nbsp;
						<p>Phone Number :</p>
						&nbsp;
						<p> 9014600700</p>
					</div>
					<div
						style={{
							display: 'flex',
							color: 'white',
							justifyContent: 'center',
							alignItems: 'center',
							padding: 1,
						}}
					>
						<Mail className="mail_icon" /> &nbsp;&nbsp;
						<p>Email : </p> &nbsp; <p>support.blr@kalyanimotors.com</p>
					</div>
				</Grid>
				<Grid
					item
					xs={12}
					sm={12}
					my={1.5}
					sx={{ color: 'white', textAlign: 'center' }}
				>
					<p style={{ marginBottom: 22 }}>Bangalore</p>
					<div
						className="container"
						style={{
							display: 'flex',
							justifyContent: 'space-evenly',
							alignItems: 'center',
						}}
					>
						<FacebookIcon className="item" />

						<TwitterIcon className="item" />

						<Instagram className="item" />

						<YouTube className="item" />
					</div>
				</Grid>
				<Grid item xs={12} sm={12} my={1.5}>
					<div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
						<a href="#">
							<img
								src={Playstore}
								alt="playstore"
								style={{ width: 150, height: 44 }}
							/>
						</a>
						<a href="#">
							<img
								src={Appstore}
								alt="appstore"
								style={{ width: 150, height: 44 }}
							/>
						</a>
					</div>
				</Grid>

				<Grid item xs={12} sm={12} sx={{ color: '#FFF', textAlign: 'center' }}>
					<Typography
						gutterBottom
						variant="h4"
						component="div"
						fontWeight={'700'}
						lineHeight={1.5}
						fontSize={{ lg: '17px', md: '16px', sm: '15px', xs: '13px' }}
						color={'white'}
						sx={{ mt: 5 }}
						textAlign={'center'}
					>
						COPYRIGHT © 2022 KALYANI MOTORS PVT LTD, BANGALORE, INDIA | Designed
						by: Kalyani Motors
					</Typography>
				</Grid>
			</Grid>
		</Box>
	);
}
