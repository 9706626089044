export const formatDate = (dateString) => {
  if (!dateString) {
    return "N/A";
  }

  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZone: "UTC",
  };

  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    return "Invalid Date";
  }

  date.setHours(date.getHours() + 5);
  date.setMinutes(date.getMinutes() + 30);

  const formattedDate = new Intl.DateTimeFormat("en-GB", options).format(date);

  const formattedString = formattedDate.replace(/\//g, "-");
  const finalString = `${formattedString}`;

  return finalString;
};

export const normalFormatDate = (dateString) => {
  if (!dateString) {
    return "N/A";
  }

  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZone: "UTC",
  };

  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    return "Invalid Date";
  }
  
  const formattedDate = new Intl.DateTimeFormat("en-GB", options).format(date);

  const formattedString = formattedDate.replace(/\//g, "-");
  const finalString = `${formattedString}`;

  return finalString;
};
