import React from 'react'
import { PaymentsStatus as _PaymentStatus } from '../../Components/PaymentsStatus/Success'
import { useLocation } from 'react-router-dom'
import { PaymentsRequestStatus } from '../../Components/PaymentsStatus/Success'

const PaymentsStatusPage = () => {
  const location = useLocation();
  const path = location.pathname
  return (
    <>
      {path.includes("/payments/payment_request") === true ?  <PaymentsRequestStatus /> : <_PaymentStatus />}
    </>
    
   
  )
}

export default PaymentsStatusPage

