import React, { useState, useEffect } from 'react';
import './Sellform.css';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RadioGroup from '@mui/material/RadioGroup';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ApiHandler from '../../../Api/Apihandler';
import { Calendar } from 'primereact/calendar';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import { AES, enc } from 'crypto-js';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useNavigate } from 'react-router';
// import 'primeicons/primeicons.css';
import { Success_Screen } from '../../../Components/PaymentsStatus/Success';
import { Failure_Screen } from '../../../Components/Failure/Failure';
import AddressPopup from '../../../Components/Modal/Modal';
import { List, ListItem, ListItemText } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const steps = [
	'Vehicle Information',
	'Contact Information',
	'Vehicle Evaluation Information',
];

const SellForm = () => {
	const [activeStep, setActiveStep] = useState(0);
	const [brand, setBrand] = useState('');
	const [model, setModel] = useState('');
	const [date, setDate] = useState(null);
	const [door, setDoor] = useState(false);
	const [showroom, setShowroom] = useState(false);
	const [error, setError] = useState(null);
	const [VechileModelId, setVechileModelId] = useState(null);
	const [vechileModel, setVechileModel] = useState([]);
	const [variant, setVariant] = useState([]);
	const [slot, setSlot] = useState(null);
	const [decryptedData, setDecryptedData] = useState('');
	const [contactModal, setContactModal] = useState(false);
	const [referred, setReferred] = useState(false);
	const [sheduleTime, setSheduleTime] = useState();

	const [profileData, setProfileData] = useState(null);
	const loginId = localStorage.getItem('loggedIn');
	const [autoFill, setAutoFill] = useState();
	const [AddressList, setAddressList] = useState([]);
	const [addressListModal, setAddressListModal] = useState(false);
	const [outlet, setOutlet] = useState({
		address: '',
		pincode: '',
	});

	const _getProfile = async () => {
		try {
			const response = await ApiHandler.getProfile(loginId);
			const data = response.data.data[0];
			console.log('data: ', data);
			setProfileData(data);
			_getAddress(data.id);
		} catch (error) {
			console.log('profile Data error', error);
		}
	};

	const _getAddress = async (id) => {
		const response = await ApiHandler.getAddress(id);
		console.log('re', response);
		try {
			const data = response.data.data;
			setAddressList(data);
		} catch (error) {
			console.log('Ebook_getAddress error', error);
		}
	};

	// const [variant, setVariant] = useState('');
	const [vechileInfo, setVechileInfo] = useState({
		vechileNumber: '',
		vechileBrand: '',
		vechileModel: '',
		vechileVariant: '',
		vechileRegistration: '',
		name: '',
		mobileNumber: '',
		address: '',
		pincode: '',
		evaluation_type: '',
		shedule: '',
	});

	//sucess modal code

	const [open, setOpen] = useState(false);
	const handleSuccessOpen = () => setOpen(true);
	const handleSuccessClose = () => {
		setOpen(false);
		navigate('/');
	};

	// failure model code

	const [failure, setFailure] = useState(false);
	const handleFailureOpen = () => setFailure(true);
	const handleFailureClose = () => {
		setFailure(false);
	};

	useEffect(() => {
		_getBrand();
		_getModel(VechileModelId);
	}, [VechileModelId]);

	useEffect(() => {
		_getProfile();
	}, []);

	const handleNext = () => {
		Validation();
		// setActiveStep((prevStep) => prevStep + 1);
	};

	const handleOpen = () => setContactModal(true);
	const handleClose = () => setContactModal(false);

	const handleBack = () => {
		setActiveStep((prevStep) => prevStep - 1);
	};

	const handleSlotClick = (clickedTime, clickedTitle) => {
		setSheduleTime(clickedTitle);
		const isClickable = filterTime.some(
			(item) => parseInt(clickedTime) >= parseInt(item.time)
		);

		if (isClickable) {
			setSlot(clickedTime);
		}
	};

	const vachileNumberError = () =>
		toast.error('Enter the Valid vehicle number.', {
			style: {
				fontSize: '13px',
			},
		});
	const vachileBrandError = () =>
		toast.error('Choose your Brand', {
			style: {
				fontSize: '13px',
			},
		});
	const vachileModelError = () =>
		toast.error('Choose your Model', {
			style: {
				fontSize: '13px',
			},
		});
	const vachileVariantError = () =>
		toast.error('Enter a Variant', {
			style: {
				fontSize: '13px',
			},
		});
	const vachileRegistration = () =>
		toast.error('Enter registration year.', {
			style: {
				fontSize: '13px',
			},
		});
	const name = () =>
		toast.error('Enter your name', {
			style: {
				fontSize: '13px',
			},
		});
	const mobileNumber = () =>
		toast.error('Enter a valid mobile number.', {
			style: {
				fontSize: '13px',
			},
		});
	const address = () =>
		toast.error('Enter an address', {
			style: {
				fontSize: '13px',
			},
		});
	const pincode = () =>
		toast.error('Enter a PIN code.', {
			style: {
				fontSize: '13px',
			},
		});

	class SingleSlot {
		constructor(title, id, time) {
			this.title = title;
			this.id = id;
			this.time = time;
		}
	}

	const slots = [
		new SingleSlot('9 AM - 10 AM', '1', '10'),
		new SingleSlot('10 AM - 11 AM', '2', '11'),
		new SingleSlot('11 AM - 12 PM', '3', '12'),
		new SingleSlot('12 PM - 1 PM', '4', '13'),
		new SingleSlot('2 PM - 3 PM', '5', '15'),
		new SingleSlot('3 PM - 4 PM', '6', '16'),
		new SingleSlot('4 PM - 5 PM', '7', '17'),
		new SingleSlot('5 PM - 6 PM', '8', '18'),
	];

	const navigate = useNavigate();

	const sellCarForm = async () => {
		const data = {
			address: vechileInfo.address,
			evaluation_type: vechileInfo.evaluation_type,
			mobile_no: vechileInfo.mobileNumber,
			name: vechileInfo.name,
			pincode: vechileInfo.pincode,
			scheduled_at: date,
			sheduleTime,
			vehicle_model: model,
			vehicle_number: vechileInfo.vechileNumber,
			vehicle_variant: vechileInfo.vechileVariant,
			year_of_registration: vechileInfo.vechileRegistration,
			brand: brand,
		};

		const response = await ApiHandler.sellcarForm(data);
		if (response.data.status == 200) {
			// alert('successfull our Employee contact You');
			// navigate('/');
			handleSuccessOpen();
		} else {
			// alert('Message', 'Error While Submiting Your Information');
			handleFailureOpen();
		}
	};

	const Validation = () => {
		if (vechileInfo.vechileNumber.length !== 10) {
			vachileNumberError();
			setError(1);
		} else if (brand.length === 0) {
			vachileBrandError();
			setError(2);
		} else if (model.length === 0) {
			vachileModelError();
			setError(3);
		} else if (vechileInfo.vechileVariant.length === 0) {
			vachileVariantError();
			setError(4);
		} else if (
			vechileInfo.vechileRegistration.length != 4 ||
			!/^[0-9]*\d$/.test(vechileInfo.vechileRegistration) ||
			vechileInfo.vechileRegistration > new Date().getFullYear()
		) {
			vachileRegistration();
			setError(5);
		} else {
			setActiveStep((prevStep) => prevStep + 1);
		}
	};

	const contactValidation = () => {
		if (
			(profileData.user.first_name.length === 0 &&
				vechileInfo.name.length === 0) ||
			profileData.user.first_name.length === 0
		) {
			name();
			setError(6);
		} else if (profileData.phone.length < 10) {
			mobileNumber();
			setError(7);
		} else if (
			(profileData?.profile_address[0].length === 0 &&
				vechileInfo.address.length === 0) ||
			profileData.profile_address[0].length === 0
		) {
			address();
			setError(8);
		}
		// else if (
		// 	profileData[0] &&
		// 	profileData[0].profile_address[0].street.split('-')[1].length !== 6 &&
		// 	vechileInfo.pincode.length !== 6
		// ) {
		// 	pincode();
		// 	setError(9);
		// }
		else {
			setActiveStep((prevStep) => prevStep + 1);
		}
	};

	const handleChange = (event) => {
		setBrand(event.target.value);
		_getModel(VechileModelId);
	};

	const handleModelChange = (event) => {
		setModel(event.target.value);
	};
	const today = new Date();
	const minSelectableDate = today;
	const todayTime = new Date().getHours();
	const filterTime = slots.filter((item) => item.time > todayTime);

	const _getBrand = async () => {
		const response = await ApiHandler.getModelList(3);
		try {
			const data = response.data.data;
			setVechileModel(data);
		} catch (error) {
			console.log('error', error);
		}
	};

	const _getModel = async () => {
		const response = await ApiHandler.getVarientsList('/' + VechileModelId);
		try {
			const data = response.data.data;
			setVariant(data);
		} catch (error) {
			console.log('error', error);
		}
	};

	const handleEvaluationTypeChange = (e) => {
		const selectedValue = e.target.value;
		if (selectedValue === vechileInfo.evaluation_type) {
			// If the clicked radio button is already selected, uncheck it
			setVechileInfo({
				...vechileInfo,
				evaluation_type: '', // unselect
			});
			// Reset other states if needed
			setDoor(false);
			setShowroom(false);
		} else {
			// Otherwise, update the selection
			setVechileInfo({
				...vechileInfo,
				evaluation_type: selectedValue,
			});
			// Update other states if needed
			if (selectedValue === '(Doorstep)') {
				setDoor(true);
				setShowroom(false);
			} else if (selectedValue === 'showroom') {
				setDoor(false);
				setShowroom(true);
			}
		}
	};

	const evaluationStepValidation = () => {
		if (!vechileInfo.evaluation_type) {
			setError(12);
			toast.error('Please select an evaluation type');
		} else if (!date) {
			setError(10);
			toast.error('Please select an evaluation date');
		} else if (!slot) {
			setError(11);
			toast.error('Please select an evaluation slot');
		} else {
			setError(0);
			// Call the sellCarForm function directly here instead of inside an else block
			sellCarForm();
		}
	};

	return (
		<div
			className="stepper sellForm"
			style={{
				background: '#eee',
			}}
		>
			{activeStep === 0 && (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						justifyItems: 'center',
					}}
				>
					<div className="stepperSubDiv">
						<Stepper activeStep={activeStep} alternativeLabel>
							{steps.map((label, index) => (
								<Step key={label}>
									<StepLabel>{label}</StepLabel>
								</Step>
							))}
						</Stepper>

						<Typography
							gutterBottom
							variant="h4"
							component="div"
							fontWeight={'700'}
							fontSize={{ lg: '20px', md: '20px', sm: '20px', xs: '20px' }}
							color={'#282828'}
							textAlign={'center'}
						>
							Vehicle Information
						</Typography>
						<Grid
							container
							item
							xs={12}
							sm={12}
							md={12}
							lg={12}
							xl={12}
							rowGap={2}
							columnGap={{ xl: 8, lg: 8, md: 4 }}
							sx={{
								display: 'flex',
								justifyContent: 'center',
								mt: 2,
							}}
						>
							{error === 1 ? (
								<Grid item xs={11} sm={11} md={5} lg={4} xl={4} mt={1.5}>
									<InputLabel
										sx={{
											color: '#000',
											mb: 1,
											fontSize: '15px',
											fontWeight: 500,
										}}
									>
										Vehicle Number
									</InputLabel>
									<TextField
										fullWidth
										id="outlined-basic"
										label=""
										autoComplete="off"
										placeholder="Enter Your Vehicle Number"
										variant="outlined"
										size="small"
										value={vechileInfo.vechileNumber}
										onChange={(e) => {
											const input = e.target.value;
											const sanitizedInput = input.replace(/[^A-Za-z0-9]/g, '');
											if (sanitizedInput.length <= 10) {
												setVechileInfo({
													...vechileInfo,
													vechileNumber: sanitizedInput.toUpperCase(),
												});
											}
										}}
									/>
								</Grid>
							) : (
								<Grid item xs={11} sm={11} md={5} lg={4} xl={4} mt={1.5}>
									<InputLabel
										sx={{
											color: '#000',
											mb: 1,
											fontSize: '15px',
											fontWeight: 500,
										}}
									>
										Vehicle Number
									</InputLabel>
									<TextField
										fullWidth
										id="outlined-basic"
										autoComplete="off"
										label=""
										placeholder="Enter Your Vehicle Number"
										variant="outlined"
										size="small"
										value={vechileInfo.vechileNumber}
										onChange={(e) => {
											const input = e.target.value;
											const sanitizedInput = input.replace(/[^A-Za-z0-9]/g, '');
											if (sanitizedInput.length <= 10) {
												setVechileInfo({
													...vechileInfo,
													vechileNumber: sanitizedInput.toUpperCase(),
												});
											}
										}}
									/>
								</Grid>
							)}

							{error === 2 ? (
								<Grid item xs={11} sm={11} md={5} lg={4} xl={4} mt={1.5}>
									<InputLabel
										sx={{
											color: '#000',
											mb: 1,
											fontSize: '15px',
											fontWeight: 500,
										}}
									>
										Vehicle Brand
									</InputLabel>
									<FormControl fullWidth size="small">
										<Select
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											value={brand}
											label=""
											placeholder="Select Your Brand"
											sx={{ py: 0.5 }}
											size="small"
											inputProps={{
												'aria-label': 'Without label',
											}}
											onChange={handleChange}
											MenuProps={{
												PaperProps: {
													sx: { maxHeight: 200, fontSize: 10 },
												},
											}}
										>
											{' '}
											<MenuItem value="" disabled>
												Choose a Brand
											</MenuItem>
											{vechileModel.map((item) => (
												<MenuItem
													value={item.title}
													key={item.id}
													onClick={() => setVechileModelId(item.id)}
												>
													{item.title}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
							) : (
								<Grid item xs={11} sm={11} md={5} lg={4} xl={4} mt={1.5}>
									<InputLabel
										sx={{
											color: '#000',
											mb: 1,
											fontSize: '15px',
											fontWeight: 500,
										}}
									>
										Vehicle Brand
									</InputLabel>
									<FormControl fullwidth sx={{ width: '100%' }}>
										<Select
											displayEmpty
											size="small"
											value={brand}
											inputProps={{
												'aria-label': 'Without label',
											}}
											onChange={handleChange}
											MenuProps={{
												PaperProps: {
													sx: { maxHeight: 200, fontSize: 10 },
												},
											}}
											autoComplete="false"
										>
											{' '}
											<MenuItem value="" disabled>
												Choose a Brand
											</MenuItem>
											{vechileModel.map((item) => (
												<MenuItem
													key={item.id}
													value={item.title}
													onClick={() => setVechileModelId(item.id)}
												>
													{item.title}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
							)}

							{error === 3 ? (
								<Grid item xs={11} sm={11} md={5} lg={4} xl={4} mt={1.5}>
									<InputLabel
										sx={{
											color: '#000',
											mb: 1,
											fontSize: '15px',
											fontWeight: 500,
										}}
									>
										Vehicle Model
									</InputLabel>
									<FormControl fullWidth>
										{/* <InputLabel>Select Your Model</InputLabel> */}
										<Select
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											value={model}
											label=""
											size="medium"
											sx={{ py: 0.5 }}
											inputProps={{
												'aria-label': 'Without label',
											}}
											onChange={handleModelChange}
											MenuProps={{
												PaperProps: {
													sx: { maxHeight: 200, fontSize: 10 },
												},
											}}
											autoComplete="false"
										>
											<MenuItem value="" disabled>
												Choose a Model
											</MenuItem>
											{variant &&
												Array.isArray(variant) &&
												variant.map((item) => (
													<MenuItem key={item.title} value={item.title}>
														{item.title}
													</MenuItem>
												))}
										</Select>
									</FormControl>
								</Grid>
							) : (
								<Grid item xs={11} sm={11} md={5} lg={4} xl={4} mt={1.5}>
									<InputLabel
										sx={{
											color: '#000',
											mb: 1,
											fontSize: '15px',
											fontWeight: 500,
										}}
									>
										Vehicle Model
									</InputLabel>
									<FormControl fullwidth sx={{ width: '100%' }}>
										<Select
											displayEmpty
											value={model}
											size="small"
											sx={{ py: 0.5 }}
											inputProps={{
												'aria-label': 'Without label',
											}}
											onChange={handleModelChange}
											MenuProps={{
												PaperProps: {
													sx: { maxHeight: 200, fontSize: 10 },
												},
											}}
											autoComplete="true"
										>
											{' '}
											<MenuItem value="" disabled>
												Choose a Model
											</MenuItem>
											{variant &&
												Array.isArray(variant) &&
												variant.map((item) => (
													<MenuItem key={item.title} value={item.title}>
														{item.title}
													</MenuItem>
												))}
										</Select>
									</FormControl>
								</Grid>
							)}

							{error === 4 ? (
								<Grid item xs={11} sm={11} md={5} lg={4} xl={4} mt={1.5}>
									<InputLabel
										sx={{
											color: '#000',
											mb: 1,
											fontSize: '15px',
											fontWeight: 500,
										}}
									>
										Vehicle Variant
									</InputLabel>
									<TextField
										fullWidth
										size="small"
										id="outlined-basic"
										autoComplete="off"
										label=""
										placeholder="Enter Your Vehicle Variant"
										variant="outlined"
										value={vechileInfo.vechileVariant}
										onChange={(e) => {
											const input = e.target.value;
											if (!input.startsWith(' ')) {
												setVechileInfo({
													...vechileInfo,
													vechileVariant: input,
												});
											}
										}}
									/>
								</Grid>
							) : (
								<Grid item xs={11} sm={11} md={5} lg={4} xl={4} mt={1.5}>
									<InputLabel
										sx={{
											color: '#000',
											mb: 1,
											fontSize: '15px',
											fontWeight: 500,
										}}
									>
										Vehicle Variant
									</InputLabel>
									<TextField
										fullWidth
										id="outlined-basic"
										autoComplete="off"
										label=""
										size="small"
										placeholder="Enter Your Vehicle Variant"
										variant="outlined"
										value={vechileInfo.vechileVariant}
										onChange={(e) => {
											const input = e.target.value;
											if (!input.startsWith(' ')) {
												setVechileInfo({
													...vechileInfo,
													vechileVariant: input,
												});
											}
										}}
									/>
								</Grid>
							)}

							{error === 5 ? (
								<Grid item xs={11} sm={11} md={5} lg={5} xl={5} mt={1.5}>
									<InputLabel
										sx={{
											color: '#000',
											mb: 1,
											fontSize: '15px',
											fontWeight: 500,
										}}
									>
										Year of Registration
									</InputLabel>
									<TextField
										fullWidth
										id="outlined-basic"
										autoComplete="off"
										label=""
										placeholder="Enter Year of Registration"
										variant="outlined"
										value={vechileInfo.vechileRegistration}
										onChange={(e) =>
											setVechileInfo({
												...vechileInfo,
												vechileRegistration: e.target.value,
											})
										}
										size="small"
									/>
								</Grid>
							) : (
								<Grid item xs={11} sm={11} md={5} lg={5} xl={5} mt={1.5}>
									<InputLabel
										sx={{
											color: '#000',
											mb: 1,
											fontSize: '15px',
											fontWeight: 500,
										}}
									>
										Year of Registration
									</InputLabel>
									<TextField
										fullWidth
										id="outlined-basic"
										autoComplete="off"
										label=""
										placeholder="Enter Year of Registration"
										variant="outlined"
										value={vechileInfo.vechileRegistration}
										onChange={(e) =>
											setVechileInfo({
												...vechileInfo,
												vechileRegistration: e.target.value,
											})
										}
										size="small"
									/>
								</Grid>
							)}
						</Grid>
						<div className="stepperButtons_next">
							{activeStep !== 0 && (
								<Button
									className="stepBtn"
									variant="contained"
									size="small"
									onClick={handleBack}
									sx={{
										textTransform: 'none',
										fontSize: { xs: '12px', sm: '12px', md: '16px' },
									}}
									fontWeight={'700'}
								>
									Back
								</Button>
							)}
							{activeStep !== steps.length - 1 && (
								<div style={{ textAlign: 'end', marginTop: 10 }}>
									<Button
										className="stepBtn"
										variant="contained"
										size="small"
										onClick={() => {
											handleNext();
										}}
										sx={{
											textAlign: 'center',
											height: '44px',
											width: {
												xl: '180px',
												lg: '180px',
												md: '150px',
												sm: '100px',
												xs: '100px',
											},
											fontWeight: 600,
											fontSize: '12px',
											lineHeight: '18px',
											letterSpacing: '.01em',
											borderRadius: '12px !important',
											boxShadow: 'none !important',
											my: 2,
											background:
												' linear-gradient(to right, #141e30, #243b55)',
										}}
									>
										Next
									</Button>
								</div>
							)}
						</div>
					</div>
				</div>
			)}

			{activeStep === 1 && (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						justifyItems: 'center',
					}}
				>
					<div className="stepperSubDiv">
						<Stepper activeStep={activeStep} alternativeLabel>
							{steps.map((label, index) => (
								<Step key={label}>
									<StepLabel>{label}</StepLabel>
								</Step>
							))}
						</Stepper>
						<Typography
							gutterBottom
							variant="h4"
							component="div"
							fontWeight={'700'}
							fontSize={{ lg: '20px', md: '20px', sm: '20px', xs: '20px' }}
							color={'#282828'}
							textAlign={'center'}
						>
							Contact Information
						</Typography>
						<Grid
							container
							xs={12}
							sm={12}
							md={12}
							lg={12}
							xl={12}
							columnGap={{ xl: 8, lg: 8, md: 4 }}
							sx={{
								display: 'flex',
								justifyContent: 'center',
								mt: 4,
							}}
						>
							{error === 6 ? (
								<Grid item xs={11} sm={11} md={3} lg={3} xl={3}>
									<InputLabel
										sx={{ color: '#000' }}
										fontSize={{
											lg: '18px',
											md: '18px',
											sm: '18px',
											xs: '18px',
										}}
									>
										Name
									</InputLabel>
									<TextField
										fullWidth
										id="outlined-basic"
										autoComplete="off"
										label=""
										placeholder="Enter Your Name"
										variant="outlined"
										value={profileData?.user?.first_name || vechileInfo.name}
										onChange={(e) => {
											const newValue = e.target.value;
											setVechileInfo((prevUserDetail) => ({
												...prevUserDetail,
												name: newValue,
											}));
											setProfileData((prevProfileData) => ({
												...prevProfileData,
												user: {
													...prevProfileData.user,
													first_name: newValue,
												},
											}));
										}}
										size="small"
										sx={{ my: 2 }}
									/>
								</Grid>
							) : (
								<Grid item xs={11} sm={11} md={3} lg={3} xl={3}>
									<InputLabel
										sx={{ color: '#000' }}
										fontSize={{
											lg: '18px',
											md: '18px',
											sm: '18px',
											xs: '18px',
										}}
									>
										Name
									</InputLabel>
									<TextField
										fullWidth
										id="outlined-basic"
										label=""
										autoComplete="off"
										placeholder="Enter Your Name"
										variant="outlined"
										value={profileData?.user?.first_name || vechileInfo.name}
										onChange={(e) => {
											const newValue = e.target.value;
											setVechileInfo((prevUserDetail) => ({
												...prevUserDetail,
												name: newValue,
											}));
											setProfileData((prevProfileData) => ({
												...prevProfileData,
												user: {
													...prevProfileData.user,
													first_name: newValue,
												},
											}));
										}}
										size="small"
										sx={{ my: 2 }}
									/>
								</Grid>
							)}
							{error === 7 ? (
								<Grid item xs={11} sm={11} md={3} lg={3} xl={3}>
									<InputLabel
										sx={{ color: '#000' }}
										fontSize={{
											lg: '18px',
											md: '18px',
											sm: '18px',
											xs: '18px',
										}}
									>
										Mobile Number
									</InputLabel>
									<TextField
										fullWidth
										id="outlined-basic"
										autoComplete="off"
										label=""
										placeholder="Enter Your Mobile Number"
										variant="outlined"
										size="small"
										sx={{ my: 2 }}
										value={profileData.phone || vechileInfo.mobileNumber}
										onChange={(e) =>
											setVechileInfo({
												...vechileInfo,
												mobileNumber: e.target.value,
											})
										}
									/>
								</Grid>
							) : (
								<Grid item xs={11} sm={11} md={3} lg={3} xl={3}>
									<InputLabel
										sx={{ color: '#000' }}
										fontSize={{
											lg: '18px',
											md: '18px',
											sm: '18px',
											xs: '18px',
										}}
									>
										Mobile Number
									</InputLabel>
									<TextField
										fullWidth
										id="outlined-basic"
										autoComplete="off"
										label=""
										placeholder="Enter Your Mobile Number"
										variant="outlined"
										size="small"
										sx={{ my: 2 }}
										value={profileData.phone || vechileInfo.mobileNumber}
										onChange={(e) =>
											setVechileInfo({
												...vechileInfo,
												mobileNumber: e.target.value,
											})
										}
									/>
								</Grid>
							)}

							{error === 8 ? (
								<Grid item xs={11} sm={11} md={5} lg={5} xl={5}>
									<InputLabel
										sx={{ color: '#000' }}
										fontSize={{
											lg: '18px',
											md: '18px',
											sm: '18px',
											xs: '18px',
										}}
									>
										Address
									</InputLabel>
									<TextField
										fullWidth
										id="outlined-basic"
										autoComplete="off"
										label=""
										placeholder="Enter Your Address"
										variant="outlined"
										size="small"
										sx={{ my: 2 }}
										value={
											profileData?.profile_address[0]
												? `${
														profileData.profile_address[0]?.type_of_address ||
														''
												  } ${profileData.profile_address[0]?.door_no || ''} ${
														profileData.profile_address[0]?.street || ''
												  } ${profileData.profile_address[0]?.area || ''} ${
														profileData.profile_address[0]?.landmark || ''
												  } ${profileData.profile_address[0]?.city || ''}`
												: vechileInfo.address
										}
										onChange={(e) => {
											const newValue = e.target.value;
											setVechileInfo((prevVechileInfo) => ({
												...prevVechileInfo,
												address: newValue,
											}));
										}}
									/>
								</Grid>
							) : (
								<Grid item xs={11} sm={11} md={5} lg={5} xl={5}>
									<InputLabel
										sx={{ color: '#000' }}
										fontSize={{
											lg: '18px',
											md: '18px',
											sm: '18px',
											xs: '18px',
										}}
									>
										Address
									</InputLabel>
									<TextField
										fullWidth
										id="outlined-basic"
										autoComplete="off"
										label=""
										placeholder="Enter Your Address"
										variant="outlined"
										size="small"
										multiline
										sx={{ my: 2 }}
										onClick={() => setAddressListModal(true)}
										value={
											autoFill
												? `${autoFill.door_no || ''}, ${autoFill.street || ''}`
												: outlet.address
										}
										onChange={(e) => {
											const newValue = e.target.value;
											setVechileInfo((prevVechileInfo) => ({
												...prevVechileInfo,
												address: newValue,
											}));
										}}
									/>
								</Grid>
							)}

							{/* {error === 8 && !autoFill ? (
                <Grid item xs={11} sm={11} md={6} lg={6} xl={6}>
                  <div>
                    <Typography
                      gutterBottom
                      variant="h4"
                      component="div"
                      fontWeight={"700"}
                      fontSize={"20px"}
                      color={"#282828"}
                      sx={{ my: { xs: 1, sm: 1, md: 3 }, borderRadius: "10px" }}
                      textAlign={"start"}
                    >
                      Address Details <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <ToastContainer
                      position="top-right"
                      autoClose={5000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                      progressStyle={{
                        background:
                          "linear-gradient(to right, #aa4b6b, #6b6b83, #3b8d99)",
                      }}
                      theme="light"
                    />
                    <TextField
                      fullWidth
                      label=""
                      variant="outlined"
                      size="small"
                      value={
                        autoFill
                          ? `${autoFill.door_no || ""}, ${
                              autoFill.street || ""
                            }`
                          : outlet.address
                      }
                      onChange={(e) =>
                        setOutlet({
                          ...outlet,
                          address: e.target.value,
                        })
                      }
                      onClick={() => setAddressListModal(true)}
                      multiline
                      autoComplete="off"
                    />
                  </div>
                </Grid>
              ) : (
                <Grid item xs={11} sm={11} md={5} lg={5} xl={5}>
                  <Typography
                    gutterBottom
                    variant="h4"
                    component="div"
                    fontWeight={"700"}
                    fontSize={"20px"}
                    color={"#282828"}
                    sx={{ my: { xs: 1, sm: 1, md: 3 }, borderRadius: "10px" }}
                    textAlign={"start"}
                  >
                    Address Details <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    fullWidth
                    label=""
                    variant="outlined"
                    size="medium"
                    // defaultValue={outlet.address}
                    value={
                      autoFill
                        ? `${autoFill.door_no || ""}, ${autoFill.street || ""}`
                        : outlet.address
                    }
                    onChange={(e) =>
                      setOutlet({
                        ...outlet,
                        address: e.target.value,
                      })
                    }
                    onClick={() => setAddressListModal(true)}
                    autoComplete="off"
                    multiline
                  />
                </Grid>
              )} */}
						</Grid>

						<div className="stepperButtons">
							{activeStep !== 0 && (
								<Button
									className="stepBtn"
									variant="contained"
									size="small"
									onClick={handleBack}
									sx={{
										textAlign: 'center',
										height: '44px',
										width: {
											xl: '180px',
											lg: '180px',
											md: '150px',
											sm: '100px',
											xs: '100px',
										},
										fontWeight: 600,
										fontSize: '12px',
										lineHeight: '18px',
										letterSpacing: '.01em',
										borderRadius: '12px !important',
										boxShadow: 'none !important',
										my: 2,
										background: ' linear-gradient(to right, #141e30, #243b55)',
									}}
								>
									Back
								</Button>
							)}
							{activeStep !== steps.length - 1 && (
								<Button
									className="stepBtn"
									variant="contained"
									size="small"
									onClick={() => contactValidation()}
									sx={{
										textAlign: 'center',
										height: '44px',
										width: {
											xl: '180px',
											lg: '180px',
											md: '150px',
											sm: '100px',
											xs: '100px',
										},
										fontWeight: 600,
										fontSize: '12px',
										lineHeight: '18px',
										letterSpacing: '.01em',
										borderRadius: '12px !important',
										boxShadow: 'none !important',
										my: 2,
										background: ' linear-gradient(to right, #141e30, #243b55)',
									}}
									fontWeight={'700'}
								>
									Next
								</Button>
							)}
						</div>
					</div>
				</div>
			)}

			{activeStep === 2 && (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						justifyItems: 'center',
					}}
				>
					<div className="stepperSubDiv">
						<Stepper activeStep={activeStep} alternativeLabel>
							{steps.map((label, index) => (
								<Step key={label}>
									<StepLabel>{label}</StepLabel>
								</Step>
							))}
						</Stepper>
						<Typography
							gutterBottom
							variant="h4"
							component="div"
							fontWeight={'700'}
							fontSize={{ lg: '20px', md: '20px', sm: '20px', xs: '20px' }}
							color={'#282828'}
							textAlign={'center'}
						>
							Vechile Evaluation Information
						</Typography>
						<div className="formmain">
							<form>
								<div>
									<Typography
										gutterBottom
										variant="h4"
										component="div"
										fontWeight={'500'}
										fontSize={{
											lg: '13px',
											md: '13px',
											sm: '13px',
											xs: '13px',
										}}
										color={'#282828'}
										sx={{ my: 2 }}
										textAlign={'center'}
									>
										How Do You Want Your Car Evaluated?
									</Typography>
									<div className="evaluation">
										<FormControl textAlign={'center'}>
											{error === 12 ? (
												<div>
													<RadioGroup
														aria-labelledby="demo-radio-buttons-group-label"
														name="radio-buttons-group"
														value={vechileInfo.evaluation_type}
														onChange={handleEvaluationTypeChange}
														sx={{ textAlign: 'center' }}
													>
														<div>
															<FormControlLabel
																value="(Doorstep)"
																style={{ color: '#000' }}
																control={<Radio />}
																label="Doorstep Evaluation"
															/>
															<FormControlLabel
																value="showroom"
																style={{ color: '#000' }}
																control={<Radio />}
																label="Showroom Evaluation"
															/>
														</div>
													</RadioGroup>
												</div>
											) : (
												<RadioGroup
													aria-labelledby="demo-radio-buttons-group-label"
													name="radio-buttons-group"
													value={vechileInfo.evaluation_type}
													onChange={handleEvaluationTypeChange}
													sx={{ textAlign: 'center' }}
												>
													<div>
														<FormControlLabel
															value="(Doorstep)"
															style={{ color: '#000' }}
															control={<Radio />}
															label="Doorstep Evaluation"
														/>
														<FormControlLabel
															value="showroom"
															style={{ color: '#000' }}
															control={<Radio />}
															label="Showroom Evaluation"
														/>
													</div>
												</RadioGroup>
											)}
										</FormControl>
									</div>
								</div>
								{door === true && (
									<div>
										<Typography
											gutterBottom
											variant="h4"
											component="div"
											fontWeight={'500'}
											fontSize={{
												lg: '14px',
												md: '14px',
												sm: '14px',
												xs: '14px',
											}}
											color={'#282828'}
											sx={{ my: 2 }}
											textAlign={'center'}
										>
											Book Your Evaluation Slot When You Are Available
										</Typography>

										<div>
											{error === 10 ? (
												<div
													style={{
														textAlign: 'center',
														padding: 8,
														margin: '20px 0px',
													}}
												>
													<div className="card flex justify-content-center">
														<Calendar
															minDate={minSelectableDate}
															readOnlyInput={true}
															placeholder="Pick your date"
															className="custom-calendar"
															value={date}
															onChange={(e) => setDate(e.value)}
															showIcon
														/>
													</div>
												</div>
											) : (
												<div
													style={{
														textAlign: 'center',
														padding: 8,
														margin: '20px 0px',
													}}
												>
													<div className="card flex justify-content-center">
														<Calendar
															minDate={minSelectableDate}
															readOnlyInput={true}
															placeholder="Pick your date"
															className="custom-calendar"
															value={date}
															onChange={(e) => setDate(e.value)}
															showIcon
														/>
													</div>
												</div>
											)}
										</div>

										{error === 11 ? (
											<div>
												<Grid container spacing={2} my={2}>
													{slots.map((item, index) => (
														<Grid
															item
															xs={6}
															sm={3}
															md={3}
															lg={2}
															xl={2}
															key={index}
															sx={{
																display: 'flex',
																justifyContent: 'center',
																alignItems: 'center',
															}}
														>
															<Card
																className="slots"
																onClick={() =>
																	handleSlotClick(item.time, item.title)
																}
																sx={{
																	minWidth: 120,
																	display: 'flex',
																	justifyContent: 'center',
																	alignItems: 'center',
																	backgroundColor:
																		slot === item.time ? 'blue' : 'white',
																	cursor: filterTime.includes(item)
																		? 'pointer'
																		: 'not-allowed',
																}}
															>
																<Typography
																	gutterBottom
																	variant="h5"
																	component="div"
																	fontWeight={'500'}
																	fontSize={{
																		lg: '11px',
																		md: '11px',
																		sm: '11px',
																		xs: '11px',
																	}}
																	color={
																		slot === item.time ? '#fff' : '#282828'
																	}
																	textAlign={'center'}
																	p={1.5}
																	m={0}
																>
																	{item.title}
																</Typography>
															</Card>
														</Grid>
													))}
												</Grid>
											</div>
										) : (
											<div>
												<Grid container spacing={2} my={2}>
													{slots.map((item, index) => (
														<Grid
															item
															xs={6}
															sm={3}
															md={3}
															lg={2}
															xl={2}
															key={index}
															sx={{
																display: 'flex',
																justifyContent: 'center',
																alignItems: 'center',
															}}
														>
															<Card
																className="slots"
																onClick={() =>
																	handleSlotClick(item.time, item.title)
																}
																sx={{
																	minWidth: 120,
																	display: 'flex',
																	justifyContent: 'center',
																	alignItems: 'center',
																	cursor: 'pointer',
																	backgroundColor:
																		slot === item.time ? 'blue' : 'white',
																	cursor: filterTime.includes(item)
																		? 'pointer'
																		: 'not-allowed',
																}}
															>
																<Typography
																	gutterBottom
																	variant="h5"
																	component="div"
																	fontWeight={'500'}
																	fontSize={{
																		lg: '11px',
																		md: '11px',
																		sm: '11px',
																		xs: '11px',
																	}}
																	color={
																		slot === item.time ? '#fff' : '#282828'
																	}
																	textAlign={'center'}
																	p={1.5}
																	m={0}
																>
																	{item.title}
																</Typography>
															</Card>
														</Grid>
													))}
												</Grid>
											</div>
										)}
									</div>
								)}
								{showroom === true && (
									<div>
										<Typography
											gutterBottom
											variant="h4"
											component="div"
											fontWeight={'500'}
											fontSize={{
												lg: '14px',
												md: '14px',
												sm: '14px',
												xs: '14px',
											}}
											color={'#282828'}
											sx={{ my: 2 }}
											textAlign={'center'}
										>
											Select Your Slot When You are Coming
										</Typography>
										{error === 10 ? (
											<div
												style={{
													textAlign: 'center',
													padding: 8,
													margin: '20px 0px',
												}}
											>
												<div className="card flex justify-content-center">
													<Calendar
														minDate={minSelectableDate}
														readOnlyInput={true}
														placeholder="Pick your date"
														value={date}
														onChange={(e) => setDate(e.value)}
														showIcon
													/>
												</div>
											</div>
										) : (
											<div
												style={{
													textAlign: 'center',
													padding: 8,
													margin: '20px 0px',
												}}
											>
												<div className="card flex justify-content-center">
													<Calendar
														minDate={minSelectableDate}
														readOnlyInput={true}
														placeholder="Pick your date"
														value={date}
														onChange={(e) => setDate(e.value)}
														showIcon
													/>
												</div>
											</div>
										)}

										{error === 11 ? (
											<div>
												<Grid container spacing={2} my={2}>
													{slots.map((item, index) => (
														<Grid
															item
															xs={6}
															sm={3}
															md={3}
															lg={2}
															xl={2}
															key={index}
															sx={{
																display: 'flex',
																justifyContent: 'center',
																alignItems: 'center',
															}}
														>
															<Card
																className="slots"
																onClick={() =>
																	handleSlotClick(item.time, item.title)
																}
																sx={{
																	minWidth: 120,
																	display: 'flex',
																	justifyContent: 'center',
																	alignItems: 'center',
																	cursor: 'pointer',
																	backgroundColor:
																		slot === item.time ? 'blue' : 'white',
																	cursor: filterTime.includes(item)
																		? 'pointer'
																		: 'not-allowed',
																}}
															>
																<Typography
																	gutterBottom
																	variant="h5"
																	component="div"
																	fontWeight={'500'}
																	fontSize={{
																		lg: '11px',
																		md: '11px',
																		sm: '11px',
																		xs: '11px',
																	}}
																	color={
																		slot === item.time ? '#fff' : '#282828'
																	}
																	textAlign={'center'}
																	p={1.5}
																	m={0}
																>
																	{item.title}
																</Typography>
															</Card>
														</Grid>
													))}
												</Grid>
											</div>
										) : (
											<div>
												<Grid container spacing={2} my={2}>
													{slots.map((item, index) => (
														<Grid
															item
															xs={6}
															sm={3}
															md={3}
															lg={2}
															xl={2}
															key={index}
															sx={{
																display: 'flex',
																justifyContent: 'center',
																alignItems: 'center',
															}}
														>
															<Card
																className="slots"
																onClick={() =>
																	handleSlotClick(item.time, item.title)
																}
																sx={{
																	minWidth: 120,
																	display: 'flex',
																	justifyContent: 'center',
																	alignItems: 'center',
																	cursor: 'pointer',
																	backgroundColor:
																		slot === item.time ? 'blue' : 'white',
																	cursor: filterTime.includes(item)
																		? 'pointer'
																		: 'not-allowed',
																}}
															>
																<Typography
																	gutterBottom
																	variant="h5"
																	component="div"
																	fontWeight={'500'}
																	fontSize={{
																		lg: '11px',
																		md: '11px',
																		sm: '11px',
																		xs: '11px',
																	}}
																	color={
																		slot === item.time ? '#fff' : '#282828'
																	}
																	textAlign={'center'}
																	p={1.5}
																	m={0}
																>
																	{item.title}
																</Typography>
															</Card>
														</Grid>
													))}
												</Grid>
											</div>
										)}
									</div>
								)}
							</form>
						</div>
						<div className="stepperButtons">
							{activeStep !== 0 && (
								<div style={{ textAlign: 'end', marginTop: 10 }}>
									<Button
										className="stepBtn"
										variant="contained"
										size="small"
										onClick={handleBack}
										sx={{
											textAlign: 'center',
											height: '44px',
											width: {
												xl: '180px',
												lg: '180px',
												md: '150px',
												sm: '100px',
												xs: '100px',
											},
											fontWeight: 600,
											fontSize: '12px',
											lineHeight: '18px',
											letterSpacing: '.01em',
											borderRadius: '12px !important',
											boxShadow: 'none !important',
											my: 2,
											background:
												' linear-gradient(to right, #141e30, #243b55)',
										}}
										fontWeight={'700'}
									>
										Back
									</Button>
								</div>
							)}
							{activeStep !== steps.length - 1 && (
								<div style={{ textAlign: 'end', marginTop: 10 }}>
									<Button
										className="stepBtn"
										variant="contained"
										size="small"
										onClick={handleNext}
										sx={{
											textAlign: 'center',
											height: '44px',
											width: {
												xl: '180px',
												lg: '180px',
												md: '150px',
												sm: '100px',
												xs: '100px',
											},
											fontWeight: 600,
											fontSize: '12px',
											lineHeight: '18px',
											letterSpacing: '.01em',
											borderRadius: '12px !important',
											boxShadow: 'none !important',
											my: 2,
											background:
												' linear-gradient(to right, #141e30, #243b55)',
										}}
										fontWeight={'700'}
									>
										Next
									</Button>
								</div>
							)}
							{activeStep === 2 && (
								<div style={{ textAlign: 'end', marginTop: 10 }}>
									<Button
										className="stepBtn"
										variant="contained"
										size="small"
										onClick={evaluationStepValidation}
										sx={{
											textAlign: 'center',
											height: '44px',
											width: {
												xl: '180px',
												lg: '180px',
												md: '150px',
												sm: '100px',
												xs: '100px',
											},
											fontWeight: 600,
											fontSize: '12px',
											lineHeight: '18px',
											letterSpacing: '.01em',
											borderRadius: '12px !important',
											boxShadow: 'none !important',
											my: 2,
											background:
												' linear-gradient(to right, #141e30, #243b55)',
										}}
									>
										Submit
									</Button>
								</div>
							)}
						</div>
					</div>
				</div>
			)}
			<Modal open={addressListModal} className="address_modal_container">
				<div
					className="addressList_modal_service"
					onClick={() => setAddressListModal(false)}
				>
					<div
						className="addressList_service"
						onClick={(e) => {
							e.stopPropagation();
						}}
					>
						<div style={{ textAlign: 'end' }}>
							<CloseIcon onClick={() => setAddressListModal(false)} />
						</div>

						<List>
							{AddressList?.profile_address?.map((item, index) => (
								<ListItem
									onClick={() => {
										setAutoFill(item);
										setAddressListModal(false);
									}}
									sx={{
										justifyContent: 'center',
										display: 'flex',
									}}
								>
									<div className="list_service">
										<Box sx={{ flexGrow: 1 }}>
											<ListItemText
												primary={
													<span
														style={{
															fontSize: 21,
															fontWeight: '700',
															paddingTop: 12,
															paddingBottom: 12,
														}}
													>
														<span></span> <span>{item.type_of_address}</span>
													</span>
												}
											/>

											<ListItemText
												sx={{ my: 1 }}
												primary={
													<span>
														{item.door_no}
														{','}
														{item.street}
													</span>
												}
											/>
										</Box>
									</div>
								</ListItem>
							))}
						</List>
					</div>
				</div>
			</Modal>

			<Modal
				open={open}
				onClose={handleSuccessClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Success_Screen
					handleSuccessClose={handleSuccessClose}
					title={'Successfully Submitted details'}
					ThankTitle={'Thank You!'}
				/>
			</Modal>

			<Modal
				open={failure}
				onClose={handleFailureClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Failure_Screen
					handleFailureClose={handleFailureClose}
					title={'Failed to Submit Details'}
				/>
			</Modal>
		</div>
	);
};

export default SellForm;
